import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class Collection extends Component {
  render() {
    let { appType } = this.props;
    return (
      <div className="collection">
        <MultiDropdownList
          className="form-field"
          title="Collection"
          componentId="Collection"
          dataField={"Collection.keyword"}
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          size={1000}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(Collection);
