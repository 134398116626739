import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
    cartItems: state.cartActions,
  };
};

class BasketChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onModalHide = this.onModalHide.bind(this);
  }
  onModalHide() {
    let { hide } = this.props;
    this.setState(() => {
      hide && hide();
    });
  }
  render() {
    let {
      show,
      handleSave,
      showChooseLayout,
      goto,
      basketInputObj,
    } = this.props;
    return (
      <Modal
        animation={false}
        autoFocus={false}
        enforceFocus={false}
        className="basket_change_modal"
        centered="true"
        size="sm"
        show={show}
        onHide={() => this.onModalHide()}
      >
        <Modal.Body>
          <div className="basket_change_title"> You have unsaved changes. </div>
          <div className="action-buttons">
            <button
              onClick={async () => {
                this.onModalHide();
                let type = basketInputObj.orderNbr === "New" ? "New" : "Update";
                await handleSave(type);
                showChooseLayout(goto);
              }}
            >
              Save and Continue
            </button>
            <button
              onClick={() => {
                this.onModalHide();
                showChooseLayout(goto);
              }}
            >
              Continue without Saving
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(BasketChangeModal);
