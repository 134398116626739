import React from "react";
import { Modal } from "react-bootstrap";
import { BaseURL } from "../utils/constants";

export default function ShowFileModal(props) {
  let { show, hide, fileData } = props;
  return (
    <Modal
      show={show}
      onHide={() => hide()}
      animation={false}
      dialogClassName="show-more_modal-dailog"
      className="more-details-modal"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          File Details:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="item__description__box">
          <ul>
            {fileData &&
              fileData.map((f, i) => {
                let fileID = f.slice(f.indexOf(":") + 1);
                // console.log("fileID: ", fileID);
                // console.log(`${BaseURL}/Frames/GetFile.ashx?fileID=${fileID}`);
                return (
                  <li key={i}>
                    <a
                      href={`${BaseURL}/Frames/GetFile.ashx?fileID=${fileID}`}
                      target="_blank"
                    >
                      {f.slice(0, f.indexOf(":"))}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
