import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

const item_order = [
  "Black",
  "Blue",
  "Brown",
  "Chameleon",
  "Champagne",
  "Cognac",
  "Gray",
  "Green",
  "Orange",
  "Pink",
  "Purple",
  "Red",
  "Violet",
  "Yellow",
  "White",
  "Other",
];

class FancyColor extends Component {
  mapOrder(array, order, key) {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }
  render() {
    return (
      <div className="maker">
        <MultiDropdownList
          title="Fancy Color"
          className="form-field"
          componentId="FancyColor"
          dataField={"JewFancyColorHue.keyword"}
          // dataField={"JewFancyColorHue"}
          size={100}
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={this.props.appType === "original" ? "Select values" : ""}
          transformData={(list) => {
            // console.log("list: ", list);
            // return list;
            var ordered_array;
            ordered_array = [... new Set(list.map((value) => {
              let str = value.key.includes(",") ? value.key.split(",") : value.key
              // console.log("str: ",str)
              return str;
            }).flat())].map((value) => {
              return { key: value };
            });
            // ordered_array = this.mapOrder(list, item_order, "key");
            // console.log("ordered_array: ", ordered_array);
            return ordered_array;
          }}
          customQuery={
            function (value, props) {
              // console.log("value : ", value)

              if (!["", null, undefined].includes(value) && (value && value.length !== 0)) {
                // console.log("value join : ", value.join(","))
                return {
                  query: {
                    // bool: {
                    //   should: [
                    //     ...value.map((val) => {
                    //       return {
                    //         match: {
                    //           "JewFancyColorHue.keyword": val
                    //         }
                    //       }
                    //     })
                    // {
                    match: {
                      "JewFancyColorHue": value.join(",")
                    }
                    // }
                    //     ]
                    //   }
                  }
                }
              }
              return {};
            }
          }
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(FancyColor);
