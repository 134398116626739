import React from "react";
import { Modal } from "react-bootstrap";
import ShowMore from "react-show-more";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MobilePricingModal from "./MobilePricingModal";
import isEmpty from "lodash/isEmpty";
import { toggleIframeModal } from "../../actions/index";
import HoldNotesArchievedModal from "../../components/HoldNotesArchievedModal";
import HoldNoteCreateModal from "../../components/HoldNoteCreateModal";
import { getHoldNotesData } from "../../components/holdNotesApiData";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { AppbaseHoldNotesApp } from "../../utils/constants";
import { toggleLoader } from "../../actions";
import axios from "axios";
import { holdNoteApi, holdNoteApiKey } from "../../utils/constants";
const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    basketInputObj: state.basketInputChange,
  };
};

class MobileProductDetailModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mobilePricingModalShow: false,
      result: this.props.result,
      showHoldNotesModal: false,
      showHoldNotesEditModal: false,
      holdNotesData: "",
      showHoldNoteCreateModal: false,
      customers: "",
      feedRestrictionsData: this.props.apiData.feedsData,
      jewelryRestrictionData: this.props.apiData.jewelryHoldData,
      employeeData: this.props.apiData.empData,
      holdNoteItem: "",
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.showMobilePricingModal = this.showMobilePricingModal.bind(this);
    this.hideMobilePricingModal = this.hideMobilePricingModal.bind(this);
    this.handleMail = this.handleMail.bind(this);
    this.handleTextMsg = this.handleTextMsg.bind(this);
    this.handleWhatsApp = this.handleWhatsApp.bind(this);
    this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    // if (nextProps.result !== this.state.result) {
    if (nextProps.result.SerialNumber !== this.state.result.SerialNumber) {
      this.setState({ result: nextProps.result });
    }
  }
  handleItemHoldNoteUpdate(data) {
    this.setState((prevState) => ({
      result: data,
      // result: prevState.result.map((result) => {
      //   if (result.SerialNumber === data.SerialNumber) {
      //     return data;
      //   } else {
      //     return result;
      //   }
      // }),
    }));
  }

  async handleHoldNoteArchive(res) {
    let result = window.confirm("Confirm Hold Note Archieve ?");
    if (result) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
        },
      };
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // this.props.toggleLoader({
          //   isLoading: false,
          // });
        });
      console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        this.props.toggleLoader({
          isLoading: false,
        });
        this.setState((prevState) => ({
          // result: prevState.result.map((result) => {
          //   if (result.SerialNumber === res.SerialNumber) {
          //     return {
          //       ...result,
          //       // HoldBy: null,
          //       HoldCustomerId: null,
          //       HoldCustomerName: null,
          //       HoldDate: null,
          //       HoldEnteredByID: null,
          //       HoldEnteredByName: null,
          //       HoldEnteredForID: null,
          //       HoldEnteredForName: null,
          //       HoldNoteID: null,
          //       HoldNoteNbr: null,
          //       HoldText: null,
          //       ReleaseDate: null,
          //       IsHoldSold: null,
          //     };
          //   } else {
          //     return result;
          //   }
          // }),
          result: {
            ...prevState.result,
            // HoldBy: null,
            HoldCustomerId: null,
            HoldCustomerName: null,
            HoldDate: null,
            HoldEnteredByID: null,
            HoldEnteredByName: null,
            HoldEnteredForID: null,
            HoldEnteredForName: null,
            HoldNoteID: null,
            HoldNoteNbr: null,
            HoldText: null,
            ReleaseDate: null,
            IsHoldSold: null,
          },
        }));
      } else {
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }

  async handleHoldNotesModals(id, value, type, res) {
    this.setState({ holdNoteItem: res });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      this.setState({ holdNotesData: response });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    this.setState({
      ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
      ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
      ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
      ...(!value
        ? { showHoldNotesEditModal: false, holdNotesData: "", holdNoteItem: "" }
        : {}),
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  }

  onLoad() {
    this.setState({
      isLoading: false,
    });
  }
  handleMail(msgBody) {
    window.location = "mailto:?body=" + msgBody;
  }
  handleTextMsg(msgBody) {
    window.location = "sms:?&body=" + msgBody;
  }
  handleWhatsApp(msgBody) {
    window.location = "https://api.whatsapp.com/send?text=" + msgBody;
    // window.location = "whatsapp://send?text=" + msgBody;
  }
  onModalHide() {
    let { callback } = this.props;
    this.setState(() => {
      callback && callback();
    });
  }
  showMobilePricingModal() {
    this.setState({ mobilePricingModalShow: true });
  }

  hideMobilePricingModal() {
    this.setState({ mobilePricingModalShow: false });
  }

  render() {
    let { show, checked } = this.props;
    let { result } = this.state;
    var largeImg, largeStr;
    let img =
      "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
      result.Shape +
      ".svg";

    // Zoomed image
    if (result.LargeImageName && result.LargeImageName != null) {
      let searchLargeImg;
      searchLargeImg = result.LargeImageName;
      largeStr = searchLargeImg.split("\\");
      searchLargeImg = largeStr[largeStr.length - 1];
      largeImg = "https://cdn.kwiat.com/source-images/large/" + searchLargeImg;
    } else {
      largeImg = "";
      largeStr = "";
    }

    function isValueEmpty(res) {
      let result = "";
      if (!isEmpty(res) && res !== "NaN") {
        result = res;
      }
      return result;
    }

    function isMultipleValueEmpty(res, expr) {
      let result = "";
      switch (expr) {
        case "CenterDetails":
          if (!isEmpty(res.CenterShape)) {
            result = `Center Details:
            ${(res.CenterCaratWeight && res.CenterCaratWeight + " cts") || ""}
            ${res.CenterShape || ""} ${
              (res.CenterColor && res.CenterColor + " /") || ""
            }
            ${(res.CenterClarity && res.CenterClarity + " /") || ""} ${
              res.CenterCut || ""
            } ${res.CenterEnhancement || ""} ${
              (res.CenterOrigin && res.CenterOrigin + " - #") || ""
            }  ${res.CenterStoneNbr || ""}`;
          }
          break;

        case "WholesalePrice":
          if (checked === true) {
            result = res.WholesalePriceCode || "";
          } else {
            result =
              (res.WholesalePrice &&
                currencyFormatter.format(`${res.WholesalePrice}`, {
                  code: "USD",
                  precision: 0,
                })) ||
              "";
          }
          break;

        case "WholesalePerCaratCode":
          if (checked === true) {
            result = res.WholesalePerCaratCode;
          } else {
            result =
              (res.WholesalePerCarat &&
                currencyFormatter.format(`${res.WholesalePerCarat}`, {
                  code: "USD",
                  precision: 0,
                })) ||
              "";
          }
          break;

        case "ItemSubtype":
          if (!isEmpty(res.ItemSubtype)) {
            result = res.ItemSubtype;
          } else {
            result = res.ItemType || "";
          }
          break;

        case "RetailPrice":
          if (!isEmpty(res.RetailPrice)) {
            result = currencyFormatter.format(`${res.RetailPrice}`, {
              code: "USD",
              precision: 0,
            });
          }
          break;
        default:
          return result;
      }
      return result;
    }

    let msgBody = `Inventory ID: ${isValueEmpty(
      result.StyleNumber
    )}%0aSerial Number: ${isValueEmpty(
      result.SerialNumber
    )}%0aImage: ${largeImg}%0aDescription: ${result.Description.replace(
      /,/g,
      " and "
    )}%0aPrice: ${isValueEmpty(
      parseFloat(result.RetailPrice).toFixed(2)
    )}%0a %0aDETAILS:%0a${isMultipleValueEmpty(
      result,
      "ItemSubType"
    )}%0aMaker: ${isValueEmpty(result.Maker)}%0aPeriod: ${isValueEmpty(
      result.Period
    )}%0aCollection: ${isValueEmpty(
      result.Collection
    )}%0aLength/Size: ${isValueEmpty(result.Length)}${isValueEmpty(
      result.RingSize
    )}%0aCenter Details: ${isMultipleValueEmpty(
      result,
      "CenterDetails"
    )}%0a${isValueEmpty(result.Metal)} - ${isValueEmpty(
      result.DiamondCarats
    )}cts, ${isValueEmpty(result.ColorCarats)}/${isValueEmpty(result.Clarity)}`;

    return (
      <div>
        <Modal
          show={show}
          onHide={() => this.onModalHide()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered="true"
          className="es__mobile-popup-modal"
          animation={false}
        >
          <Modal.Header closeButton>
            <span className="es__product-dark-title">DETAILS</span>
          </Modal.Header>
          <Modal.Body>
            <div className="es__product-modal-section1">
              <div className="es__product-image-title pos-relative">
                <div className="es__product-image-container">
                  {result.Shape && result.Shape !== null ? (
                    <img
                      className="es__product-image"
                      src={img}
                      alt="product"
                    />
                  ) : (
                    <ReactImageFallback
                      fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                      alt=""
                      className="es__product-image"
                    />
                  )}
                </div>
              </div>
              <div className="es__product-details es__bordered-box">
                <div className="es__product-description">
                  <span className="es__product-dark-title">
                    {isValueEmpty(result.StyleNumber)}
                  </span>
                  <span className="es__product-dark-title">
                    {isValueEmpty(result.SerialNumber)}
                  </span>
                  <span>{isValueEmpty(result.Description)}</span>
                </div>
              </div>
            </div>
            <div className="es__product-modal-section2 es__bordered-box">
              <table className="es__general-product-details">
                <tbody>
                  <tr>
                    <td>{isValueEmpty(result.Shape)}</td>
                    <td>
                      {isValueEmpty(result.Polish)}
                      {result.Symmetry && result.Symmetry !== null
                        ? `/${result.Symmetry}`
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.Polish) ? "Polish / Symmetry" : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {isValueEmpty(result.DiamondCaratWeight)}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.DiamondCaratWeight)
                          ? "CTS Wt."
                          : ""}
                      </span>
                    </td>
                    <td>
                      {isValueEmpty(result.StoneFluoresence)}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.StoneFluoresence)
                          ? "Fluoresence"
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {result.DiamondColorRange &&
                      result.DiamondColorRange != null
                        ? `${result.DiamondColorRange}/`
                        : `${result.FancyColorGIA}/`}
                      {result.DiamondClarityRange &&
                      result.DiamondClarityRange != null
                        ? `${result.DiamondClarityRange}`
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.DiamondColorRange)
                          ? "Color / Clarity"
                          : ""}
                      </span>
                    </td>
                    <td>
                      {isValueEmpty(result.DepthP)}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.DepthP) ? "Depth" : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      {isValueEmpty(result.TableP)}
                      <span className="es__mobile-product-details-table__label">
                        {isValueEmpty(result.TableP) ? "Table" : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="100%">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {result.Length && result.Length !== null
                        ? `${result.Length} x ${result.Width} x ${result.Depth}`
                        : ""}
                    </td>
                    <td
                      onClick={() =>
                        this.props.toggleIframeModal({
                          show: true,
                          url: result.Giapdfurl,
                        })
                      }
                    >
                      {!isEmpty(result.GradingLab)
                        ? `${result.GradingLab} -  ${result.LabReportNbr || ""}`
                        : ""}
                      {!isEmpty(result.Giapdfurl) ? (
                        <img
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                          alt="icon"
                          className="pdf-icon"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {isValueEmpty(result.StoneRatio)}
                      <span className="es__mobile-product-details-table__label">
                        {result.StoneRatio && result.StoneRatio !== null
                          ? "Stone Ratio"
                          : ""}
                      </span>
                    </td>
                    <td>
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        {isValueEmpty(result.Giashape)}
                      </ShowMore>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {isValueEmpty(result.StoneGirdle)}
                      <span className="es__mobile-product-details-table__label">
                        {result.StoneGirdle && result.StoneGirdle !== null
                          ? "Girdle"
                          : ""}
                      </span>
                    </td>
                    <td>
                      {result.GIAReportDate && result.GIAReportDate != null
                        ? moment(new Date(`${result.GIAReportDate}`)).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {result.GIAReportDate && result.GIAReportDate !== null
                          ? "Report Date"
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {isValueEmpty(result.StoneCulet)}
                      <span className="es__mobile-product-details-table__label">
                        {result.StoneCulet && result.StoneCulet !== "None"
                          ? "Culet"
                          : ""}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="es__product-modal-section3">
              <div className="es__product-pricing-section es__bordered-box pos-relative">
                <h5 className="es__product-details-heading es__product-dark-title">
                  <span onClick={this.showMobilePricingModal}>Pricing</span>
                </h5>
                <table className="es__product-pricing-details-table">
                  <tbody>
                    <tr className="es__product-details__data-row">
                      <td>{isMultipleValueEmpty(result, "RetailPrice")}</td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Retail</td>
                    </tr>
                    {this.props.basketInputObj.showWholesale && (
                      <>
                        {" "}
                        <tr className="es__product-details__data-row">
                          <td>
                            {isMultipleValueEmpty(result, "WholesalePrice")}
                          </td>
                        </tr>
                        <tr className="es__product-details__label-row">
                          <td>Wholsale</td>
                        </tr>
                      </>
                    )}
                    <tr className="es__product-details__data-row">
                      <td>
                        {result.PricingDate && result.PricingDate != null
                          ? moment(new Date(`${result.PricingDate}`)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Price Date</td>
                    </tr>
                    {this.props.basketInputObj.showWholesale && (
                      <>
                        {" "}
                        <tr className="es__product-details__data-row">
                          <td>
                            {result.WholesalePricePerCarat &&
                            result.WholesalePricePerCarat != null
                              ? isMultipleValueEmpty(
                                  result,
                                  "WholesalePerCaratCode"
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr className="es__product-details__label-row">
                          <td>Wholesale PPC</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="es__product-status-section es__bordered-box pos-relative">
                <h5 className="es__product-details-heading es__product-dark-title">
                  <span>Status</span>
                </h5>
                <table className="es__product-status-details-table">
                  <tbody>
                    <tr className="es__product-details__data-row">
                      <td>
                        {isValueEmpty(result.Warehouse)}
                        {result.Warehouse !== result.SerialStatus &&
                        isValueEmpty(result.Warehouse) &&
                        isValueEmpty(result.SerialStatus)
                          ? "/"
                          : ""}
                        {result.IsVirtual === "1" ? "Virtual - " : ""}
                        {result.IsPhantom === "1" ? "Phantom - " : ""}
                        {result.Warehouse !== result.SerialStatus &&
                          isValueEmpty(result.SerialStatus)}
                      </td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Status</td>
                    </tr>
                    <tr className="es__product-details__data-row">
                      <td>
                        <ShowMore
                          lines={1}
                          more="&#43;"
                          less="&#8722;"
                          anchorClass=""
                        >
                          <div className="location iframe-link">
                            {result.MemoOutCustomer
                              ? isValueEmpty(result.MemoOutCustomer)
                              : result.StatusCustomer
                              ? isValueEmpty(result.StatusCustomer)
                              : ""}
                          </div>
                        </ShowMore>
                      </td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Customer</td>
                    </tr>
                    <tr className="es__product-details__data-row">
                      <td>
                        <div>
                          <ShowMore
                            lines={1}
                            more="&#43;"
                            less="&#8722;"
                            anchorClass=""
                          >
                            {result.StatusDate && result.StatusDate !== null
                              ? moment(new Date(`${result.StatusDate}`)).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                            {result.StatusRefNbr &&
                            result.StatusRefNbr !== null ? (
                              <span className="iframe-link">
                                {" "}
                                - ({result.StatusRefNbr})
                              </span>
                            ) : (
                              ""
                            )}
                          </ShowMore>
                        </div>
                      </td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Date/Ref #</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="es__product-hold-notes es__bordered-box pos-relative">
                <h5 className="es__product-details-heading es__product-dark-title">
                  <span>Hold Notes</span>
                </h5>
                {this.props.basketInputObj.holdNotesAccess && (
                  <div class="hold_notes_actions">
                    {!result._id.toString().includes("JOB") &&
                      result.HoldNoteID && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip className="tooltip" id="Edit">
                              Edit
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <span
                            onClick={() => {
                              this.setState({ showHoldNotesEditModal: true });
                              this.handleHoldNotesModals(
                                "",
                                true,
                                "createHoldNotes",
                                result
                              );
                            }}
                          >
                            <i
                              class="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </OverlayTrigger>
                      )}
                    {!result._id.toString().includes("JOB") && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip className="tooltip" id="Create">
                            Create
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <span
                          onClick={() =>
                            this.handleHoldNotesModals(
                              result.InventoryID.toString() +
                                result.SerialNumber.toString(),
                              true,
                              "createHoldNotes",
                              result
                            )
                          }
                        >
                          +
                        </span>
                      </OverlayTrigger>
                    )}
                    {result.HoldNoteID &&
                      !result._id.toString().includes("JOB") && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip className="tooltip" id="Archive">
                              Archive
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <span
                            onClick={() => this.handleHoldNoteArchive(result)}
                          >
                            x
                          </span>
                        </OverlayTrigger>
                      )}
                    {!result._id.toString().includes("JOB") && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip className="tooltip" id="View">
                            View
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <span
                          onClick={(e) =>
                            this.handleHoldNotesModals(
                              result.InventoryID.toString() +
                                result.SerialNumber.toString(),
                              true,
                              "archivedHoldNotes",
                              ""
                            )
                          }
                        >
                          <i class="fa fa-history" aria-hidden="true"></i>
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                )}
                <table className="es__product-pricing-details-table">
                  <tbody>
                    <tr className="es__product-details__data-row">
                      <td> {isValueEmpty(result.HoldEnteredByName)}</td>
                      <td>{isValueEmpty(result.HoldCustomerName)}</td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Hold By</td>
                      <td>Hold Customer name</td>
                    </tr>
                    <tr className="es__product-details__data-row">
                      <td>
                        {" "}
                        {isValueEmpty(result.HoldDate)
                          ? moment(new Date(`${result.HoldDate}`)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                      <td>
                        {" "}
                        {isValueEmpty(result.ReleaseDate)
                          ? moment(new Date(`${result.ReleaseDate}`)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Hold Date</td>
                      <td>Release Date</td>
                    </tr>
                    <tr className="es__product-details__data-row">
                      <td> {isValueEmpty(result.HoldText)}</td>
                      {result.IsHoldSold === "1" && <td>SOLD</td>}
                    </tr>
                    <tr className="es__product-details__label-row">
                      <td>Hold Text</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="es__product-modal-section4 es__bordered-box">
              <img
                src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                alt="Email"
                className="share-icons"
                onClick={() => this.handleMail(msgBody)}
              />
              <img
                src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/text.svg"
                alt="SMS"
                className="share-icons"
                onClick={() => this.handleTextMsg(msgBody)}
              />
              <img
                src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/whatsapp.svg"
                alt="Whatsapp"
                className="share-icons"
                // data-action="share/whatsapp/share"
                onClick={() => this.handleWhatsApp(msgBody)}
              />
            </div>
          </Modal.Body>
        </Modal>
        <MobilePricingModal
          showPricingModal={this.state.mobilePricingModalShow}
          callback={this.hideMobilePricingModal}
          result={result}
          checked={checked}
        />
        <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleIframeModal,
      toggleLoader,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileProductDetailModal);
