import React, { Component } from 'react';
import { DataSearch } from '@appbaseio/reactivesearch';
class MountedNumberStock extends Component {
  constructor(props) {
    super(props);

    this.handleMountedChange = this.handleMountedChange.bind(this);
  }
  handleMountedChange(value) {
    // console.log('mounted component', value);
    if (!value) {
      // console.log('not mount');
      this.props.mountedSearchChanged('NOT_ACTIVE');
      return;
    }
    // console.log('mounted active');
    this.props.mountedSearchChanged('ACTIVE');
  }
  render() {
    return (
      <div className="mounted-number-stock form-field-wrap">
        <DataSearch
          className="form-field"
          componentId="MountedNumberStock"
          title={this.props.title || "Mounted #"}
          placeholder={this.props.placeHolder ||"Enter mounted #"}
          dataField={this.props.data}
          showClear
          debounce={250}
          autosuggest={true}
          onValueChange={value => this.handleMountedChange(value)}
        />
      </div>
    );
  }
}
export default MountedNumberStock;
