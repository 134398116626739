import React, { Component } from "react";
import { RangeInput } from "@appbaseio/reactivesearch";
import RangeFilterWithSelect from "./../../components/RangeFilterWithSelect";
class RingSizeRange extends Component {
  constructor(props) {
    super(props);
    this.handleSelected = this.handleSelected.bind(this);
  }
  handleSelected(value) {
    if (value.start === 0 && value.end === 15) {
      return;
    } else {
      // console.log("Inside Handle Selected");
      this.props.showSelectedResults(true);
    }
  }
  render() {
    return (
      <div className="carat-range form-field-wrap">
        <RangeInput
          className="carat-range-input"
          innerClass={{
            "slider-container": "carat-range-input__carat-slider",
            "input-container": "carat-range-input__carat-input",
          }}
          title="Ring Size:"
          componentId="RingSizeRange"
          showSlider={false}
          dataField="RingSizeValue"
          range={{
            start: 0,
            end: 15,
          }}
          customQuery={function (value, props) {
            if (value[0] === 0 && value[1] === 15) {
              return {
                query: {
                  match_all: {},
                },
              };
            } else {
              return {
                query: {
                  range: {
                    RingSizeValue: {
                      gte: value[0],
                      lte: value[1],
                      boost: 2,
                    },
                  },
                  // range: {
                  //   RingSizeValue: {
                  //     gte: value[0],
                  //     lte: value[1],
                  //     boost: 2,
                  //     relation: "within",
                  //   },
                  // },
                  // bool: {
                  //   filter: [
                  //     {
                  //       range: {
                  //         RingSizeValue: {
                  //           gte: value[0],
                  //           lte: value[1],
                  //           boost: 2,
                  //         },
                  //       },
                  //     },
                  //   ],
                  // },
                  // bool: {
                  //   must: [
                  //     {
                  //       range: {
                  //         RingSizeValue: {
                  //           gte: value[0],
                  //           lte: value[1],
                  //         },
                  //       },
                  //     },
                  //   ],
                  // },
                },
              };
            }
          }}
          onValueChange={(value) => this.handleSelected(value)}
        />
      </div>
    );
  }
}
export default RangeFilterWithSelect(RingSizeRange);
