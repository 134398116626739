import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class CenterOrigin extends React.PureComponent {
  render() {
    return (
      <div className="center-origin">
        <MultiDropdownList
          className="form-field"
          title="Center Origin"
          componentId="CenterOrigin"
          dataField="CenterOrigin.keyword"
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={label => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          placeholder=""
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(CenterOrigin);
