import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setBasketFormInput } from "../../../actions/index";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
  };
};

class QuickAddMobile extends Component {
  render() {
    return (
      <div className="quickadd__price-visibility__grouped">
        <div className="price__checkbox__wrapper">
          <div className="price__toggle retail__checkbox">
            <label htmlFor="retail_check">Include Retail Price:</label>
            <input
              type="checkbox"
              id="retail_check"
              checked={this.props.basketInputObj.includeRetail}
              onChange={(e) =>
                this.props.setBasketFormInput({
                  includeRetail: e.target.checked,
                })
              }
            />
          </div>
          <div className="price__toggle wholesale__checkbox">
            <label htmlFor="wholesale_check">Include Wholesale Price:</label>
            <input
              type="checkbox"
              id="wholesale_check"
              checked={this.props.basketInputObj.includeWholesale}
              onChange={(e) =>
                this.props.setBasketFormInput({
                  includeWholesale: e.target.checked,
                })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBasketFormInput,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickAddMobile);
