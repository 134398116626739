import React, { Component } from "react";
import { RangeInput } from "@appbaseio/reactivesearch";
import RangeFilterWithSelect from "./../../components/RangeFilterWithSelect";

class CaratWeight extends Component {
  constructor(props) {
    super(props);
    this.handleSelected = this.handleSelected.bind(this);
  }

  handleSelected(value) {
    // console.log("value : ", value);
    if (value.start === 0 && value.end === 100000) {
      // console.log("firsttime load");
      return;
    } else {
      // console.log("Inside Handle Selected");
      this.props.showSelectedResults(true);
    }
  }
  render() {
    return (
      <div className="carat-weight form-field-wrap">
        <RangeInput
          className="carat-weight-input"
          innerClass={{
            "slider-container": "carat-weight-slider-container",
            "input-container": "carat-weight-container",
          }}
          title="Carat Weight"
          componentId="CaratWeight"
          dataField={"CaratWeight"}
          showSlider={false}
          range={{
            start: 0,
            end: 100000,
          }}
          customQuery={function(value, props) {
            if (value[0] === 0 && value[1] === 100000) {
              return {
                query: {
                  match_all: {},
                },
              };
            } else {
              return {
                query: {
                  range: {
                    CaratWeight: { gte: value[0], lte: value[1], boost: 2 },
                  },
                },
              };
            }
          }}
          onValueChange={(value) => this.handleSelected(value)}
        />
      </div>
    );
  }
}
export default RangeFilterWithSelect(CaratWeight);
