import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Basket from "./Basket/Desktop/Basket";
import { connect } from "react-redux";
import MobileBasket from "./Basket/Mobile/MobileBasket";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions,
  };
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      showBasketModal: false,
      items: props.cartItems.items,
    };
    this.onSelect = this.onSelect.bind(this);
    this.showBasketModal = this.showBasketModal.bind(this);
    this.hideBasketModal = this.hideBasketModal.bind(this);
  }

  componentDidMount() {
    let url = window.location.href;
    if (!url.includes("kim.kwiat.com")) {
      let urlString = window.location.href;
      let paramString = urlString.split("?")[1];
      let queryString = new URLSearchParams(paramString);
      // console.log({ queryString });
      for (let pair of queryString.entries()) {
        // console.log({ pair });
        // console.log("Key is: " + pair[0]);
        // console.log("Value is: " + pair[1]);

        if (pair[0] === "Basket") {
          this.setState({ showBasketModal: true });
          // document.location.reload(true);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.items !== nextProps.cartItems.items) {
      this.setState({
        items: nextProps.cartItems.items,
      });
    }
  }
  onSelect(value) {
    this.setState({
      title: value,
    });
  }
  showBasketModal() {
    this.setState({
      showBasketModal: true,
    });
  }
  hideBasketModal() {
    this.setState({
      showBasketModal: false,
    });
  }

  render() {
    let { activeTabKey } = this.props;
    let { title, showBasketModal, items } = this.state;
    return (
      <header>
        <ul className="nav nav-tabs">
          <li
            className={title === "Jewelry" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("Jewelry", e)}
          >
            <Link to="/jewelry-search/serial">Jewelry</Link>
            <div className="activeTab">
              <div
                className={
                  title === "Jewelry" && activeTabKey === "serial"
                    ? "tab-con selected"
                    : "tab-con"
                }
              >
                <Link to="/jewelry-search/serial">serial</Link>
              </div>
              <div
                className={
                  title === "Jewelry" && activeTabKey === "style"
                    ? "tab-con selected"
                    : "tab-con"
                }
              >
                <Link to="/jewelry-search/style">style</Link>
              </div>
            </div>
          </li>

          <li
            className={title === "Diamond" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("Diamond", e)}
          >
            <Link to="/diamond-search">Diamonds</Link>
            {title === "Diamond" && <div className="activeTab" />}
          </li>
          <li
            className={title === "Studs" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("Studs", e)}
          >
            <Link to="/studs-search">Studs</Link>
            {title === "Studs" && <div className="activeTab" />}
          </li>
          <li
            className={title === "Gemstone" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("Gemstone", e)}
          >
            <Link to="/gemstone-search">Gemstones</Link>
            {title === "Gemstone" && <div className="activeTab" />}
          </li>
          <li
            className={title === "MTS" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("MTS", e)}
          >
            <Link to="/MTS-search">MTS APP</Link>
            {title === "MTS" && <div className="activeTab" />}
          </li>
          <li
            className={title === "MTSDiamond" ? "active" : "notActive"}
            role="presentation"
            onClick={(e) => this.onSelect("MTSDiamond", e)}
          >
            <Link to="/MTS-diamond-search">MTS Diamonds</Link>
            {title === "MTSDiamond" && <div className="activeTab" />}
          </li>
        </ul>
        <div className="es__basket-icon" onClick={() => this.showBasketModal()}>
          <span className="items__count">{items.length}</span>
          <img
            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/open-basket.png"
            alt="basket_icon"
          />
        </div>
        {showBasketModal && !isMobileOnly && (
          <Basket show={showBasketModal} callback={this.hideBasketModal} />
        )}
        {showBasketModal && isMobileOnly && (
          <MobileBasket
            show={showBasketModal}
            callback={this.hideBasketModal}
          />
        )}
      </header>
    );
  }
}

export default connect(mapStateToProps, null)(Header);
