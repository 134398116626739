import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class CenterEnhancement extends React.PureComponent {
  render() {
    return (
      <div className="center-enhancement">
        <MultiDropdownList
          className="form-field"
          title="Center Enhancement"
          componentId="CenterEnhancement"
          dataField="CenterEnhancement.keyword"
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={label => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          placeholder=""
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(CenterEnhancement);
