
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import JewelrySearch from './jewelry-search/index';
import GemstoneSearch from './gemstone-search/index';
import DiamondSearch from './diamond-search/index';
import IndexRoute from './IndexRoute';
import MTSSearch from './MTS-search/index'
import MTSDiamondSearch from './MTS-Diamonds-search/index'
import StudsSearch from './Studs-search/index' 

const AppRouter = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={IndexRoute} />
        <Route path="/jewelry-search" component={JewelrySearch} />
        <Route path="/diamond-search" component={DiamondSearch} />
        <Route path="/gemstone-search" component={GemstoneSearch} />
        <Route path="/MTS-search" component={MTSSearch} />
        <Route path="/MTS-diamond-search" component={MTSDiamondSearch} />
        <Route path="/studs-search" component={StudsSearch} />
      </Switch>
    </React.Fragment>
  )
};

export default AppRouter;