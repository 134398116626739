import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class GemstoneType extends Component {
  render() {
    return (
      <div className="maker">
        <MultiDropdownList
          title="Gemstone Type"
          className="form-field"
          componentId="GemstoneType"
          dataField={"JewGemstoneType.keyword"}
          size={100}
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          transformData={(list) => {
            // console.log("list: ", list);
            // return list;
            var ordered_array;
            ordered_array = [... new Set(list.map((value) => {
              let str = value.key.includes(",") ? value.key.split(",") : value.key
              // console.log("str: ",str)
              return str;
            }).flat())].map((value) => {
              return { key: value };
            });
            // ordered_array = this.mapOrder(list, item_order, "key");
            // console.log("ordered_array: ", ordered_array);
            return ordered_array;
          }}
          placeholder={this.props.appType === "original" ? "Select values" : ""}
          customQuery={
            function (value, props) {
              // console.log("value : ", value)

              if (!["", null, undefined].includes(value) && (value && value.length !== 0)) {
                // console.log("value join : ", value.join(","))
                return {
                  query: {
                    // bool: {
                    //   should: [
                    //     ...value.map((val) => {
                    //       return {
                    //         match: {
                    //           "JewFancyColorHue.keyword": val
                    //         }
                    //       }
                    //     })
                    // {
                    match: {
                      "JewGemstoneType": value.join(",")
                    }
                    // }
                    //     ]
                    //   }
                  }
                }
              }
              return {};
            }
          }
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(GemstoneType);
