import { React, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import "./ProductBrochure.scss";

export default function ReoprtLayout(props) {
  const {
    basketDetails,
    cartDetails,
    showDiaImage,
    showZoomImage,
    diaIcon,
    thumbnailImage,
    includeGIA,
    includeKTDShapes,
  } = props;
  // console.log("cartDetails: ", cartDetails);

  const handleImage = (item) => {
    // console.log(item);
    // console.log(item);
    if (
      (item.transformType === "JewelrySerial" || !item.transformType) &&
      item.WebImage1
    ) {
      let str = item.WebImage1.replace(".jpg", "-product@2x.jpg");
      let imageurl = "https://cdn.kwiat.com/source-images/web/product/" + str;
      return imageurl;
    } else if (
      item.transformType === "DiamondSerial" ||
      item.transformType === "GemstoneSerial" ||
      ((item.transformType === "JewelrySerial" || !item.transformType) &&
        item.search_dia_image_url &&
        item.search_dia_image_url !== null)
    ) {
      let imageurl = item.search_dia_image_url;
      return imageurl;
    } else if (item.LargeImageName) {
      // console.log("Inside Large Image Name");

      let searchimage;
      searchimage = item.LargeImageName;
      let str = searchimage.split("\\");
      searchimage = str[str.length - 1];
      let imageurl = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      return imageurl;
    } else {
      let imageurl =
        "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
      return imageurl;
    }
  };

  // useEffect(() => {
  //   // $("img").error(function() {
  //   //   $(this).hide();
  //   //   // or $(this).css({visibility:"hidden"});
  //   // });
  //   let img = document.getElementsByTagName("img");
  //   img.onerror = function() {
  //     this.style.display = "none";
  //   };
  // }, []);
  // console.log("cartdetails: ",cartDetails)
  return (
    <div className="layout_first">
      {cartDetails.map((item, index) => {
        let url = [];
        let ktdUrls = [];
        if (includeGIA === "Yes" && item.ReportJpgUrls) {
          // let url;
          // let ktdUrl;
          if (item.ReportJpgUrls.includes("|")) {
            if (item.ReportJpgUrls.charAt(0) === " ") {
              url = item.ReportJpgUrls.replace(/ /, "").split("|");
            } else {
              url = item.ReportJpgUrls.split("|");
            }
          } else {
            url = [item.ReportJpgUrls];
          }
        }
        if (includeKTDShapes === "Yes" && item.KTDjpgurl1 && item.KTDjpgurl2) {
          // let ktdUrls = [];
          if (item.KTDjpgurl1.includes("|")) {
            ktdUrls = item.KTDjpgurl1.split("|");
          } else {
            ktdUrls = [item.KTDjpgurl1];
          }
          ktdUrls.push(item.KTDjpgurl2);
        }

        if (url?.length > 0 || ktdUrls?.length > 0) {
          return (
            <>
              <div key={index}>
                {includeKTDShapes === "Yes" &&
                  ktdUrls.length !== 0 &&
                  ktdUrls.map((src, i) => {
                    let flag = 0;
                    if (flag === 0) {
                      return (
                        <div className="Item_KTD" key={i}>
                          <img
                            src={src}
                            // width="100%"
                            style={{ maxWidth: "100%" }}
                            onError={(event) => {
                              event.target.parentNode.style.display = "none";
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                {includeGIA === "Yes" &&
                  url.length !== 0 &&
                  url.map((src, i) => {
                    let flag = 0;
                    if (flag === 0) {
                      return (
                        <div className="Item_GIA" key={i}>
                          {/*<div className="logo">
                        <img
                          className={
                            item.Brand === "Fred Leighton"
                              ? "fred_logo"
                              : "kwiat_logo"
                          }
                          src={
                            item.Brand === "Fred Leighton"
                              ? "https://cdn4.kwiat.com/source-images/web/logos/fredleighton.jpg"
                              : "https://cdn4.kwiat.com/source-images/web/logos/kwiat.jpg"
                          }
                          // onError={(e) => console.log(e)}
                        />
                        </div>*/}
                          <img
                            src={src}
                            // width="100%"
                            style={{ maxWidth: "90%" }}
                            onError={(event) => {
                              event.target.parentNode.style.display = "none";
                            }}
                          />
                          {/* <div className="footer">
                        <div>
                          <span>
                            {new Date().toLocaleString("default", {
                              month: "short",
                            })}{" "}
                            {new Date().getFullYear()} - #
                            {basketDetails.orderNbr}
                          </span>
                        </div>
                        <div>
                          <span>
                            Copyright {new Date().getFullYear()} All Rights
                            Reserved
                          </span>
                        </div>
                        <a
                          href={
                            item.Brand === "Kwiat"
                              ? "Kwiat.com"
                              : "www.FredLeighton.com"
                          }
                        >
                          {item.Brand === "Kwiat"
                            ? "Kwiat.com"
                            : "www.FredLeighton.com"}
                        </a>
                        </div>*/}
                        </div>
                      );
                    }
                  })}
              </div>
            </>
          );
        } else {
          return <div>{""}</div>;
        }
      })}
    </div>
  );
}
