import React from 'react';
import DiamondDashboard from './components/DiamondDashboard';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';

const DiamondSearch = () => {
  return (
    <div id="diamond-search" className="page">
      <DiamondDashboard />
      <div className="es-scroll-button">
        <ScrollUpButton />
      </div>
    </div>
  );
};
export default DiamondSearch;
