import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

class AvailableOnline extends Component {
  constructor(props) {
    super(props);

    this.handleCustomQuery = this.handleCustomQuery.bind(this);
  }

  handleCustomQuery(value) {
    var choice = JSON.stringify(value);
    if (value && value.length) {
      switch (true) {
        case choice === JSON.stringify(["KW Web"]):
          return {
            query: {
              match: {
                ShowOnKwiatcom: "1"
              }
            }
          };
        case choice === JSON.stringify(["FL Web"]):
          return {
            query: {
              match: {
                ShowonFLcom: "1"
              }
            }
          };
        case choice === JSON.stringify(["3rd Part Feeds"]):
          return {
            query: {
              bool: {
                must: {
                  exists: {
                    field: "Feeds"
                  }
                }
              }
            }
          };
        case choice === JSON.stringify(["KW Web", "FL Web"]) ||
          choice === JSON.stringify(["FL Web", "KW Web"]):
          return {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      ShowOnKwiatcom: "1"
                    }
                  },
                  {
                    term: {
                      ShowonFLcom: "1"
                    }
                  }
                ]
              }
            }
          };
        case choice === JSON.stringify(["KW Web", "3rd Part Feeds"]) ||
          choice === JSON.stringify(["3rd Part Feeds", "KW Web"]):
          return {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      ShowOnKwiatcom: "1"
                    }
                  },
                  {
                    exists: {
                      field: "Feeds"
                    }
                  }
                ]
              }
            }
          };
        case choice === JSON.stringify(["FL Web", "3rd Part Feeds"]) ||
          choice === JSON.stringify(["3rd Part Feeds", "FL Web"]):
          return {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      ShowonFLcom: "1"
                    }
                  },
                  {
                    exists: {
                      field: "Feeds"
                    }
                  }
                ]
              }
            }
          };
        case value.length === 3:
          return {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      ShowOnKwiatcom: "1"
                    }
                  },
                  {
                    term: {
                      ShowonFLcom: "1"
                    }
                  },
                  {
                    exists: {
                      field: "Feeds"
                    }
                  }
                ]
              }
            }
          };
        default:
          break;
      }
    }
    return;
  }

  render() {
    return (
      <div className="form-field-wrap">
        <MultiDropdownList
          className="form-field"
          title="Available Online"
          componentId="IsOnline"
          dataField="ShowOnKwiatcom.keyword"
          size={100}
          transformData={() => {
            var customLabels = [
              { key: "KW Web" },
              { key: "FL Web" },
              { key: "3rd Part Feeds" }
            ];
            return customLabels;
          }}
          customQuery={this.handleCustomQuery}
        />
      </div>
    );
  }
}

export default AvailableOnline;
