import React, { Component } from 'react'
import { DropdownButton } from "react-bootstrap";

class SortDropdown extends Component {
  render() {
    let { title, active, onSelect, soldSignal } = this.props;
    return (
      <div className="dropdown-sort">
        <DropdownButton title={title} id={"sort"} className="sort-by">
          <ul className="nav navbar-nav nav-sort">
            <li
              className={active === "active1" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "DiamondCaratWeight",
                  "Carat Weight",
                  "asc",
                  "active1"
                )
              }
            >
              Carat Weight - Asc
            </li>
            <li
              className={active === "active2" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "DiamondCaratWeight",
                  "Carat Weight",
                  "desc",
                  "active2"
                )
              }
            >
              Carat Weight - Desc
            </li>
            <li
              className={active === "active3" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "RetailPrice",
                  "Retail Price-Asc",
                  "asc",
                  "active3"
                )
              }
            >
              Retail - Asc
            </li>
            <li
              className={active === "active4" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "RetailPrice",
                  "Retail Price-Desc",
                  "desc",
                  "active4"
                )
              }
            >
              Retail - Desc
            </li>
            <li
              className={active === "active5" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "WholesalePrice",
                  "Wholesale Price-Asc",
                  "asc",
                  "active5"
                )
              }
            >
              Wholesale - Asc
            </li>
            <li
              className={active === "active6" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "WholesalePrice",
                  "Wholesale - Desc",
                  "desc",
                  "active6"
                )
              }
            >
              Wholesale - Desc
            </li>
            <li
              className={active === "active7" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "StyleNumber.keyword",
                  "Style Number-Asc",
                  "asc",
                  "active7"
                )
              }
            >
              Style # - Asc
            </li>
            <li
              className={active === "active8" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "StyleNumber.keyword",
                  "Style Number-Desc",
                  "desc",
                  "active8"
                )
              }
            >
              Style # - Desc
            </li>
            <li
              className={active === "active9" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "SerialNumber.keyword",
                  "Serial Number-Asc",
                  "asc",
                  "active9"
                )
              }
            >
              Serial # - Asc
            </li>
            <li
              className={active === "active10" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "SerialNumber.keyword",
                  "Serial Number-Desc",
                  "desc",
                  "active10"
                )
              }
            >
              Serial # - Desc
            </li>
            {soldSignal ? (
              <li
                className={active === "active11" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect(
                    "SoldDate",
                    "Sold Date # - Asc",
                    "asc",
                    "active11"
                  )
                }
              >
                Sold Date # - Asc
              </li>
            ) : (
              ""
            )}
            {soldSignal ? (
              <li
                className={active === "active12" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect(
                    "SoldDate",
                    "Sold Date # - Desc",
                    "desc",
                    "active12"
                  )
                }
              >
                Sold Date # - Desc
              </li>
            ) : (
              ""
            )}
          </ul>
        </DropdownButton>
      </div>
    );
  }
}

export default SortDropdown;