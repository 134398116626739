import currencyFormatter from "currency-formatter";
export function ProductSummary() {
  const summaryDataJson = localStorage.getItem("summaryData");
  const {
    assetLineTotal,
    memoLineTotal,
    noOfAssetItems,
    noOfMemoItems,
    retailCount,
    retailPrice,
    wholesaleCount,
    wholesalePrice,
    customerClass,
    basketInputs,
    totalItems,
  } = JSON.parse(summaryDataJson);
  const mainStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5F7F8",
    width: "500px",
    padding: "10px",
    boxSizing: "border-box",
  }

  const twoDivContainStyle = {
    display: "flex",
    width: "450px",
    justifyContent: "space-between",
    alignItems: "flex-start",
  }

  const style1 = {
    display: "flex",
    width: "40%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  }

  const style2 = {
    width: "50px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    fontWeight: "600",
    color: "#000"
  }

  const style3 = {
    fontWeight: "600",
    color: "#828282"
  }

  return (
    <div
      style={mainStyle}
    >
      <h4>SUMMARY</h4>
      <div
      // style={{
      //   display: "flex",
      // }}
      >
        {customerClass === "WHOLESALE" && basketInputs.showWholesale ? (
          <>
            {" "}
            {/* two div contain */}
            <div
              style={twoDivContainStyle}
            >
              <div
                style={style1}
              >
                <span style={style3}># of Asset Items: </span>
                <span
                  style={style2}
                >
                  {noOfAssetItems}
                </span>
              </div>
              <div
                style={style1}
              >
                <span style={style3} ># of Memo Items: </span>
                <span
                  style={style2}
                >
                  {noOfMemoItems}
                </span>
              </div>
            </div>
            {/* two div contain */}
            <div
              style={twoDivContainStyle}
            >
              <div
                style={style1}
              >
                <span style={style3} >Asset Line Total: </span>
                <span
                  style={style2}
                >
                  {currencyFormatter.format(`${assetLineTotal}`, {
                    code: "USD",
                    precision: 0,
                  })}
                </span>
              </div>
              <div
                style={style1}
              >
                <span style={style3} >Memo Line Total: </span>
                <span
                  style={style2}
                >
                  {currencyFormatter.format(`${memoLineTotal}`, {
                    code: "USD",
                    precision: 0,
                  })}
                </span>
              </div>
            </div>
          </>
        ) : (
          ``
        )}
        {/* two div contain */}
        <div
          style={twoDivContainStyle}
        >
          <div
            style={style1}
          >
            <span style={style3} >Total Items: </span>
            <span
              style={style2}
            >
              {totalItems}
            </span>
          </div>
          <div
            style={style1}
          >
            <span style={style3} ># w/o Price: </span>
            <span
              style={{
                width: "50px",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontWeight: "600",
                color: "red"
              }}
            >
              {customerClass === "RETAIL"
                ? retailCount
                : basketInputs.showWholesale
                ? wholesaleCount
                : ``
              // wholesaleCount
              }
            </span>
          </div>
        </div>
        {/* two div contain */}
        <div
          style={twoDivContainStyle}
        >
          <div
            style={style1}
          >
            <span style={style3} >Total Retail: </span>
            <span
              style={style2}
            >
              {currencyFormatter.format(`${retailPrice}`, {
                code: "USD",
                precision: 0,
              })}
            </span>
          </div>
          {customerClass !== "RETAIL" && basketInputs.showWholesale ? (
            <div
              style={style1}
            >
              <span style={style3} >Total Wholesale: </span>
              <span
                style={style2}
              >
                {currencyFormatter.format(`${wholesalePrice}`, {
                  code: "USD",
                  precision: 0,
                })}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
