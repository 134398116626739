import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class StyleStatus extends Component {
  render() {
    return (
      <div className="item-subcollection">
        <MultiDropdownList
          className="form-field"
          title="StyleStatus"
          componentId="Style_Status"
          dataField={"StyleStatus.keyword"}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(StyleStatus);
