import React, { Component } from "react";
import ShowMore from "react-show-more";

class StyleWebResult extends Component {
  render() {
    let { res } = this.props;
    return (
      <div className="product-info_box">
        <h5 className="product-info_box_heading product-light_title_color">
          <span>Web</span>
        </h5>
        <div className="product-info_background">
          <div className="product-status-detail">
            <div className="product-status-con">
              <div
                className={
                  res.ShowOnKwiatcom === "1" ||
                  res.ShowonFLcom === "1" ||
                  res.Feeds
                    ? "known product-details-label"
                    : "unknown product-details-label unknown-status-margin-jewelry"
                }
              >
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.ShowOnKwiatcom === "1" ? "KW Web" : ""}
                  {res.ShowonFLcom === "1" ? (res.ShowOnKwiatcom === "1" ? ", FL Web": "FL Web") : ""}
                  {res.Feeds ? (res.ShowOnKwiatcom === "1" || res.ShowonFLcom === "1" ? `, ${res.Feeds}`: res.Feeds) : ""}
                </ShowMore>
                <span className="product-details-view-label">Feeds</span>
              </div>

              <div
                className={
                  res.DefaultChildPricingMode ||
                  res.EcomEnabled === "1" ||
                  res.ShoppableSettings === "1" ||
                  res.ShowSerailAsVar === "1" ||
                  res.ShowSerialAsIndProduct === "1"
                    ? "known product-details-label"
                    : "unknown product-details-label unknown-status-margin-jewelry"
                }
              >
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.DefaultChildPricingMode ? res.DefaultChildPricingMode : ""}
                  {res.EcomEnabled === "1" ? ", Ecomm Enabled" : ""}
                  {res.ShoppableSettings === "1" ? ", Shoppable Setting" : ""}
                  {res.ShowSerailAsVar === "1" ? ", Serial as Variations" : ""}
                  {res.ShowSerialAsIndProduct === "1" ? ", Serial as Individual Products" : ""}
                </ShowMore>
                <span className="product-details-view-label">Shoppable Configuration</span>
              </div>
             </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StyleWebResult;
