import React, { Component } from "react";
import { connect } from "react-redux";
import currencyFormatter from "currency-formatter";
import QuickAddProduct from "../QuickAddProduct";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
  };
};

class MobileSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalItems: 0,
      wholesalePrice: 0,
      retailPrice: 0,
      retailCount: 0,
      wholesaleCount: 0,
      noOfMemoItems: 0,
      noOfAssetItems: 0,
      memoLineTotal: 0,
      assetLineTotal: 0,
      formCollapsed: true,
      customerClass: props.basketInputObj.customer.CustomerClass || "",
    };
  }
  componentDidMount() {
    this.setSummaryStates(this.props.items, this.props.basketInputObj);
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.items !== nextProps.items ||
      this.props.basketInputObj !== nextProps.basketInputObj
    ) {
      this.setSummaryStates(nextProps.items, nextProps.basketInputObj);
    }
  }
  setSummaryStates(items, basketInputObj) {
    let { basketInputs, customerClass } = this.state;
    // console.log("Inside setSummary States");
    var retailPrice = 0,
      wholesalePrice = 0,
      retailCount = 0,
      wholesaleCount = 0,
      noOfMemoItems = 0,
      noOfAssetItems = 0,
      memoLineTotal = 0,
      assetLineTotal = 0;
    items.map((el) => {
      retailPrice += +el.RetailPrice * el.quantity;
      wholesalePrice += +el.WholesalePrice * el.quantity;
      retailCount =
        parseInt(el.RetailPrice, 10) === parseFloat(0) ||
        el.RetailPrice === null
          ? retailCount++
          : retailCount;
      wholesaleCount =
        parseInt(el.WholesalePrice, 10) === parseFloat(0) ||
        el.WholesalePrice === null
          ? wholesaleCount++
          : wholesaleCount;
      el.assetOrmemo === "M" ||
      (!el.assetOrmemo && basketInputObj.assetOrmemo === "M")
        ? noOfMemoItems++
        : noOfMemoItems;
      el.assetOrmemo === "A" ||
      (!el.assetOrmemo && basketInputObj.assetOrmemo === "A")
        ? noOfAssetItems++
        : noOfAssetItems;
      customerClass === "RETAIL" &&
      (el.assetOrmemo === "M" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
        ? (memoLineTotal += +el.RetailPrice)
        : memoLineTotal;
      customerClass === "RETAIL" &&
      (el.assetOrmemo === "A" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
        ? (assetLineTotal += +el.RetailPrice)
        : assetLineTotal;
      customerClass !== "RETAIL" &&
      (el.assetOrmemo === "M" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
        ? (memoLineTotal += +el.WholesalePrice)
        : memoLineTotal;
      customerClass !== "RETAIL" &&
      (el.assetOrmemo === "A" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
        ? (assetLineTotal += +el.WholesalePrice)
        : assetLineTotal;
      return {
        retailPrice,
        wholesalePrice,
        retailCount,
        wholesaleCount,
        noOfAssetItems,
        noOfMemoItems,
        memoLineTotal,
        assetLineTotal,
      };
    });
    this.setState({
      totalItems: items.length,
      retailPrice,
      wholesalePrice,
      retailCount,
      wholesaleCount,
      noOfAssetItems,
      noOfMemoItems,
      memoLineTotal,
      assetLineTotal,
    });
  }

  render() {
    let {
      retailPrice,
      totalItems,
      retailCount,
      wholesaleCount,
      noOfAssetItems,
      noOfMemoItems,
      memoLineTotal,
      assetLineTotal,
      customerClass,
    } = this.state;

    let { basketInputObj } = this.props;
    return (
      <div className="items__summary__quickadd">
        <div className="basket__items__summary">
          <div className="summary__total">
            <span className="summary__label">Total:</span>
            <span className="summary__value">{totalItems}</span>
          </div>
          <div className="summary__divider">|</div>
          <div className="summary__wo__price">
            <span className="summary__label">W/O Price:</span>
            <span className="summary__value price__count">
              {customerClass === "RETAIL"
                ? retailCount
                : basketInputObj.showWholesale
                ? wholesaleCount
                : ``}
            </span>
          </div>
          <div className="summary__divider">|</div>
          <div className="summary__retail-price">
            <span className="summary__label">Total Retail:</span>
            <span className="summary__value">
              {currencyFormatter.format(`${retailPrice}`, {
                code: "USD",
                precision: 0,
              })}
            </span>
          </div>
          {customerClass === "WHOLESALE" && basketInputObj.showWholesale ? (
            <>
              <div className="summary__total">
                <span className="summary__label"># of Asset Items: </span>
                <span className="summary__value">{noOfAssetItems}</span>
              </div>
              <div className="summary__divider">|</div>
              <div className="summary__total">
                <span className="summary__label"># of Memo Items: </span>
                <span className="summary__value">{noOfMemoItems}</span>
              </div>
              {/* <div className="summary__divider">|</div> */}
              <div className="summary__total">
                <span className="summary__label">Asset Line Total: </span>
                <span className="summary__value">
                  {currencyFormatter.format(`${assetLineTotal}`, {
                    code: "USD",
                    precision: 0,
                  })}
                </span>
              </div>
              <div className="summary__divider">|</div>
              <div className="summary__total">
                <span className="summary__label">Memo Line Total: </span>
                <span className="summary__value">
                  {currencyFormatter.format(`${memoLineTotal}`, {
                    code: "USD",
                    precision: 0,
                  })}
                </span>
              </div>
            </>
          ) : (
            ``
          )}
        </div>
        <QuickAddProduct
          showQuickAddBulkProductModal={this.props.showQuickAddBulkProductModal}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(MobileSummary);
