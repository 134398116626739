import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CustomerSearch from "../CustomerSearch";
import ContactSearch from "../ContactSearch";
import { setBasketFormInput } from "../../../actions/index";
import { basketBaseUrl } from "../../../utils/constants";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
  };
};

class BasketMobileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basketType: props.basketInputObj.basketType,
      desc: props.basketInputObj.desc,
      internalNotes: props.basketInputObj.internalNotes,
      occasion: props.basketInputObj.occasion,
      basketInputs: props.basketInputObj,
    };

    this.handleAcumaticaExport = this.handleAcumaticaExport.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setSummaryStates(nextProps.items);
    }
    if (this.state.basketInputs !== nextProps.basketInputObj) {
      this.setState({
        basketInputs: nextProps.basketInputObj,
        basketType: nextProps.basketInputObj.basketType,
        desc: nextProps.basketInputObj.desc,
        internalNotes: nextProps.basketInputObj.internalNotes,
        occasion: nextProps.basketInputObj.occasion,
      });
    }
  }

  handleMoreInputs(e) {
    if (e.target.getAttribute("aria-expanded") === "true") {
      e.target.innerHTML = "- Close Options";
    } else {
      e.target.innerHTML = "+ More Options...";
    }
  }

  handleAcumaticaExport(basketId) {
    if (basketId === "New") {
      toast("Unsaved Basket !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
    } else {
      window.open(
        basketBaseUrl +
          `/(W(4))/Main?ScreenId=SO301000&OrderType=BO&OrderNbr=${basketId}`,
        "_blank"
      );
    }
  }

  render() {
    let { desc, internalNotes, occasion, basketType } = this.state;
    return (
      <div className="basket__details__form">
        {/* <div
          className="basket__export__group"
          id="basket-export"
          style={{ display: "flex" }}
        >
          <button
            className="acumatica__export"
            onClick={() =>
              this.handleAcumaticaExport(this.props.basketInputObj.orderNbr)
            }
          >
            <img
              src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/open-acumatica.png"
              alt="accumatica export"
            />
          </button>
        </div> */}
        <div className="basket__input__wrapper">
          {/* <div className="basket__input type__input">
            <label>Basket Type:</label>
            <select
              name="type"
              id="basket_type"
              value={basketType}
              onChange={(e) =>
                this.props.setBasketFormInput({ basketType: e.target.value })
              }
            >
              <option value="Retail Proposal">Retail Proposal</option>
            </select>
          </div> */}
          <div className="basket__input type__input">
            <label>Description:</label>
            <input
              type="text"
              placeholder="Enter Description"
              value={!desc ? "" : desc}
              onChange={(e) => {
                this.props.setBasketFormInput({ desc: e.target.value });
              }}
            />
          </div>
          <CustomerSearch />
          <ContactSearch />
          <button
            className="basket__more__inputs collapsed"
            data-toggle="collapse"
            data-target="#basket__collapse__more--inputs"
            onClick={this.handleMoreInputs}
          >
            + More Options...
          </button>
          <div className="collapse" id="basket__collapse__more--inputs">
            <div className="basket__input internal-notes__input">
              <label>Internal Notes:</label>
              <input
                type="text"
                placeholder="Enter Internal Notes"
                value={internalNotes ? internalNotes : ""}
                onChange={(e) => {
                  this.props.setBasketFormInput({
                    internalNotes: e.target.value,
                  });
                }}
              />
            </div>
            <div className="basket__input occasion__input">
              <label>Occasion:</label>
              <select
                name="occasion"
                id="basket_occasion"
                value={occasion}
                onChange={(e) => {
                  this.props.setBasketFormInput({ occasion: e.target.value });
                }}
              >
                <option value="default" disabled>
                  Select Occasion
                </option>
                <option value="ANNIV">Anniversary</option>
                <option value="BABY">Baby Celebration</option>
                <option value="BIRTHDAY">Birthday</option>
                <option value="ENGAGE">Engagement</option>
                <option value="HOLIDAY">Holiday</option>
                <option value="JUSTBEC">Just Because</option>
                <option value="MOTHDAY">Mothers Day</option>
                <option value="OTHER">Other Celebration</option>
                <option value="UNKNOWN">Unknown</option>
                <option value="VALDAY">Valentines Day</option>
                <option value="WEDDING">Wedding</option>
                <option value="GIFT4ANOTH">Gift for Friend</option>
                <option value="GRADUATION">Graduation</option>
                <option value="WHOLESALEPROPOSAL">Wholesale Proposal</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBasketFormInput,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketMobileForm);
