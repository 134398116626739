export const holdNotesFields = [
  { field: "HoldDate", order: 1, label: "Hold Date" },
  { field: "HoldCustomerName", order: 2, label: "CustomerName" },
  { field: "HoldText", order: 3, label: "Hold Note" },
  { field: "ReleaseDate", order: 4, label: "Hold Release" },
  { field: "HoldBy", order: 5, label: "Entered By" },
  { field: "HoldFor", order: 6, label: "Entered For" },
  { field: "IsHoldSold", order: 7, label: "Is Sold" },
  { field: "Feeds", order: 8, label: "Feeds" },
  { field: "HoldRestriction", order: 9, label: "Hold Restriction" },
];
