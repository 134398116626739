import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { isMobileOnly, isMobile, isIPad13 } from "react-device-detect";
import { CSVLink } from "react-csv";
import WRShape from "./WRShape";
import WRSetting from "./WRSetting";
import EternPart from "./EternPart";
import Warehouse from "../../components/Warehouse";
import MemoOut from "../../components/MemoOut";
import StyleNumber from "../../components/StyleNumber";
import SerialNumber from "../../components/SerialNumber";
import ProductLoader from "../../components/ProductLoader";
import RetailPriceRange from "../../components/RetailPriceRange";
import WholesalePriceRange from "../../components/WholesalePriceRange";
import ShowCode from "../../components/ShowCode";
import IsSold from "../../components/IsSold";
import IsRtv from "../../components/IsRtv";
import IsCom from "../../components/IsCom";
import IsVirtual from "../../components/IsVirtual";
import KwiatOnly from "../../components/KwiatOnly";
import TiaraOnly from "../../components/TiaraOnly";
import AshokaOnly from "./AshokaOnly";
import FLRoundOnly from "../../components/FLRoundOnly";
import FredLeightonOnly from "../../components/FredLeightonOnly";
import SearchCriteria from "../../components/SearchCriteria";
import StudsSearchCriteria from "./StudsSearchCriteria"
import KeywordMain from "../../components/Keyword";
import Keyword from "./Keyword"
import ItemType from "./ItemType";
import ColorCarat from "./ColorCarat";
import DiamondCarat from "./DiamondCarat";
import Period from "./Period";
import Maker from "./Maker";
import Metal from "./Metal";
import CenterShape from "./CenterShape";
import ItemSubtype from "./ItemSubtype";
import Collection from "./Collection";
import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
import toLower from "lodash/toLower";
import merge from "lodash/merge";
// import InProduction from "../../components/InProduction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleIframeModal, setBasketFormInput } from "../../actions/index";
import Appbase from "appbase-js";
import async from "async";
import {
  jewelrySerialRemovedFields,
  jewelryStyleRemovedFields
} from "./../../components/RemoveFieldsExport";
import ImageModal from "../../components/ImageModal";
import MobileProductDetailModal from "./MobileProductDetailModal";
import StockModal from "../../components/JewelryStockModal";
import SoldModal from "../../components/SoldModal";
import SortDropdown from "./SortDropdown";
import AvailableOnline from "./AvailableOnline";
import SubCollection from "./SubCollection";
import Switching from "./Switching";
import AdvancedSearch from "./AdvancedSearch";
import OriginalAppResults from "./OriginalAppResults";
import CustomerAppResults from "./CustomerAppResults";
import isEmpty from "lodash/isEmpty";
import currencyFormatter from "currency-formatter";
import IsSemimount from "./IsSemimount";
import KWCushionOnly from "../../components/KWCushionOnly";
import IsOpenJob from "./IsOpenJob";
import IsPermMemo from "./IsPermMemo";
import SoldCustomerSearch from "../../components/SoldCustomerSearch";
import RingSizeRange from "./RingSizeRange";
import PurchasDateRange from "./PurchaseDateRange";
import HandleWholesale from "../../components/HandleWholesale";
import RfidSearch from "../../components/RfidSearch";
import StyleStatus from "./StyleStatus";
import InShowCase from "./InShowCase";
import IsConsignmentItem from "../../components/IsConsignmentItem";
import {
  AppbaseEmployeeApp,
  AppbaseCredentials,
  AppbaseAppUrl
} from "../../utils/constants";
import FancyColor from "./FancyColor";
import GemstoneType from "./GemstoneType";
import KWEmerald from "../../components/KWEmerald";
import KWRadiant from "../../components/KWRadiant";
import KWOval from "../../components/KWOval";
import IsOh from "../../components/IsOh";
import DavidWebb from "./DavidWebb";
import IsMTS from "../../components/IsMTS";
import { fetchApiData } from "../../components/holdNotesApiData";
import MountedNumberStock from "../../components/MountedNumberStock";
import DiamondColorRangeStuds from "./DiamondColorRangeStuds";
import DiamondClarityRangStuds from "./DiamondClarityRangStuds";

const mapStateToProps = state => {
  return {
    basketInputObj: state.basketInputChange,
    groups: state.SetGroups.groups
  };
};

class Main extends Component {
  csvLink = "#";
  csvLink = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selected: "RetailPrice",
      sortField: "RetailPrice",
      title: "Sort By",
      sort: "asc",
      active: "active1",
      checked: false,
      showSelectedResultsOnEnter: false,
      showChangedResultsOnEnter: "NOT_ACTIVE",
      showMobileProductModal: false,
      showImageModal: false,
      zoomImage: "",
      webImage: false,
      showStockModal: false,
      showCustomLoader: false,
      checkKwiat: false,
      checkFred: false,
      access: "basic",
      result: [],
      soldModal: false,
      serialSearchSignal: "NOT_ACTIVE",
      keywordSearchSignal: "NOT_ACTIVE",
      keywordSearchSignalMain: "NOT_ACTIVE",
      soldCustSearchSignal: "NOT_ACTIVE",
      rfidSearchSignal: "NOT_ACTIVE",
      mountedSearchSignal: "NOT_ACTIVE",
      soldSignal: false,
      ohSignal: false,
      serialSearchSelectedSignal: false,
      keywordSearchSelectedSignal: false,
      keywordSearchSelectedSignalMain: false,
      soldCustSearchSelectedSignal: false,
      rfidSearchSelectedSignal: false,
      showFilteredData: false,
      mobileClassToggle: false,
      csvData: [],
      nextQuery: {},
      fromLoadMore: false,
      sizeLimit: 15,
      countValue: 1,
      loadMoreClicked: false,
      advancedSearch: false,
      advancedDomNode: "",
      dwebbDefault: this.props.basketInputObj.dwebbDefault,
      dwebbNoDefault: this.props.basketInputObj.dwebbNoDefault
    };
    this.onSearch = this.onSearch.bind(this);
    this.getProduct = this.getProduct.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCheckSelect = this.onCheckSelect.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.serialSearchChanged = this.serialSearchChanged.bind(this);
    this.rfidSearchChanged = this.rfidSearchChanged.bind(this);
    this.keywordSearchChanged = this.keywordSearchChanged.bind(this);
    this.keywordSearchChangedMain = this.keywordSearchChangedMain.bind(this);
    this.keywordSearchSelected = this.keywordSearchSelected.bind(this);
    this.keywordSearchSelectedMain = this.keywordSearchSelectedMain.bind(this);
    this.soldCustSearchChanged = this.soldCustSearchChanged.bind(this);
    this.soldCustSearchSelected = this.soldCustSearchSelected.bind(this);
    this.serialSearchSelected = this.serialSearchSelected.bind(this);
    this.rfidSearchSelected = this.rfidSearchSelected.bind(this);
    this.showSelectedResults = this.showSelectedResults.bind(this);
    this.showChangeResults = this.showChangeResults.bind(this);
    this.checkIsKwiatStatus = this.checkIsKwiatStatus.bind(this);
    this.checkIsFredStatus = this.checkIsFredStatus.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleAllOptions = this.handleAllOptions.bind(this);
    this.downloadResults = this.downloadResults.bind(this);
    this.handleSoldSignal = this.handleSoldSignal.bind(this);
    this.handleOhSignal = this.handleOhSignal.bind(this);
    this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
    this.showZoomImage = this.showZoomImage.bind(this);
    this.hideImageModal = this.hideImageModal.bind(this);
    this.showStockPopUpModal = this.showStockPopUpModal.bind(this);
    this.hideStockPopUpModal = this.hideStockPopUpModal.bind(this);
    this.showMobileProductModal = this.showMobileProductModal.bind(this);
    this.hideMobileProductModal = this.hideMobileProductModal.bind(this);
    this.showSoldModal = this.showSoldModal.bind(this);
    this.hideSoldModal = this.hideSoldModal.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMail = this.handleMail.bind(this);
    this.handleTextMsg = this.handleTextMsg.bind(this);
    this.handleWhatsApp = this.handleWhatsApp.bind(this);
    this.fetchSortResult = this.fetchSortResult.bind(this);
    this.mountedSearchChanged = this.mountedSearchChanged.bind(this)
    // this.getUserEmail = this.getUserEmail.bind(this);
  }

  mountedSearchChanged(mountval) {
    // console.log('mountval', mountval);
    this.setState({
      mountedSearchSignal: mountval
    });
  }

  async componentDidMount() {
    // this.props.setBasketFormInput({
    //   access: "advanced",
    // });
    var node = document.querySelector(".es__advanced-search");
    this.setState({
      advancedDomNode: node
    });
    let uRoleEl = document.getElementById("ctl00_phF_form_edRolename");
    if (!uRoleEl) {
      // return;
    }
    setTimeout(() => {
      if (Object.keys(this.props.groups).length !== 0) {
        this.setState({
          access: this.props.basketInputObj.access
        });
      } else if (uRoleEl) {
        let uRole = document.getElementById("ctl00_phF_form_edRolename").value;
        uRole = toLower(uRole);
        if (
          uRole.includes("admin") ||
          uRole.includes("executive") ||
          uRole.includes("production") ||
          uRole.includes("elastic")
        ) {
          this.setState({
            access: "advanced"
          });
          this.props.setBasketFormInput({
            access: "advanced",
            dwebbNoDefault: true
          });
        }
        // if (uRole.includes("ADCRM".toLowerCase())) {
        // if (!uRole.includes("ADCRM".toLowerCase())) {
        //   this.props.setBasketFormInput({
        //     holdNotesAccess: true
        //   });
        // } else {
        //   this.props.setBasketFormInput({
        //     dwebbDefault: true
        //   });
        // }
      }
      // location.reload();
    }, 1500);
    let empData = await fetchApiData(AppbaseEmployeeApp);
    const loggedUser = JSON.parse(sessionStorage.getItem('reduxState'))
    const loggedUserEmail = loggedUser?.basketInputChange?.user?.Email
    empData.forEach((item) => {
      if (item?._source?.Email === loggedUserEmail && item?._source?.bulkHoldNotes == "1") {
        this.props.setBasketFormInput({
          holdNotesAccess: true
        });
      }
    })

    // await this.getUserEmail();
  }
  // async getUserEmail() {
  //   let userEmail;
  //   if (this.props.groups.email) {
  //     userEmail = this.props.groups.email;
  //   } else if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector(
  //         "li.menu-form > div.inline > span:nth-child(3)"
  //       ).textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //     // userEmail = "megan@kwiat.com";
  //     // userEmail = "nagaraj@kwiat.com";
  //     // userEmail = "josh@kwiat.com";
  //     // userEmail = "cory@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: [
  //             "FirstName",
  //             "LastName",
  //             "Email",
  //             "BasketEmail",
  //             "EmployeeLogin",
  //             "Title",
  //             "Phone1",
  //             "AddressLine1",
  //             "AddressLine2",
  //             "City",
  //             "State",
  //             "PostalCode",
  //             "BasketBccEmail",
  //             "BasketCcEmail",
  //             "CRMBccEmail",
  //           ],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     // let payload = {
  //     //   data: {
  //     //     defaults: {
  //     //       baseURL: basketBaseUrl,
  //     //       token: token,
  //     //     },
  //     //     inputs: {
  //     //       EmployeeLogin: {
  //     //         value: this.props.basketInputObj.user.EmployeeLogin,
  //     //       },
  //     //     },
  //     //   },
  //     // };
  //     // // console.log("payload: ", payload);
  //     // await axios
  //     //   .post(appbaseBasketUrl + "user-signature", payload, {
  //     //     headers: {
  //     //       "x-api-key": basketApikey,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     // console.log("resp : ", res);
  //     //     if (
  //     //       res.data.statusCode === 200 &&
  //     //       res.data.body.KWMailSignature.value
  //     //     ) {
  //     //       console.log("User signature found.");
  //     //       this.props.setBasketFormInput({
  //     //         user: {
  //     //           ...this.props.basketInputObj.user,
  //     //           Signature: res.data.body.KWMailSignature.value,
  //     //         },
  //     //       });
  //     //     } else {
  //     //       console.log("response is empty");
  //     //     }
  //     //     // console.log("Basket form data: ", this.props.basketInputObj);
  //     //     // return JSON.parse(res.data.body);
  //     //     // return res;
  //     //   })
  //     //   .catch((err) => {
  //     //     // console.log("error : ", err);
  //     //     console.log("User Signature error.", err);
  //     //     // return;
  //     //   });
  //   } else {
  //     console.log("No user");
  //   }
  //   // if (window.parent.document.getElementById("btnUserMenu")) {
  //   //   let userDetails = window.parent.document
  //   //     .getElementById("userMenu")
  //   //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
  //   //   console.log("userDetails: ", userDetails);
  //   // }
  // }
  async fetchSortResult(query) {
    let res;
    const appbaseRef = Appbase({
      app: this.props.app,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials
    });

    await appbaseRef
      .msearch({
        body: [{}, query]
      })
      .then(response => {
        console.log("response", response.responses[0].hits.hits);
        res = response.responses[0].hits.hits;
      })
      .catch(err => {
        console.log("err", err);
        console.log("error while appbase fetch");
      });
    return res;
  }

  clearFilters() {
    this.setState({
      showFilteredData: false,
      serialSearchSelectedSignal: false,
      keywordSearchSelectedSignal: false,
      keywordSearchSelectedSignalMain: false,
      soldCustSearchSelectedSignal: false,
      rfidSearchSelectedSignal: false,
      showSelectedResultsOnEnter: false
    });
  }
  onSearch(value) {
    let { countValue } = this.state;
    if (value === "loadMore") {
      this.setState({
        showFilteredData: true,
        sizeLimit: countValue * 15,
        countValue: countValue + 1,
        fromLoadMore: true,
        loadMoreClicked: true
      });
    } else {
      this.setState({
        showFilteredData: true,
        sizeLimit: 1 * 15,
        countValue: 1 + 1
      });
    }
    setTimeout(() => {
      this.setState({
        loadMoreClicked: false
      });
    }, 1200);
  }
  handleAdvancedSearch() {
    this.setState({
      advancedSearch: !this.state.advancedSearch
    });
  }
  handleMouseEnter(e) {
    if (isMobileOnly) {
      return;
    }
    e.preventDefault();
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    // console.log("share node : ", shareNode);
    serialNode.classList.add(
      "product-index_container",
      "pos-relative",
      "serial-hovered"
    );
    shareNode.classList.add(
      "pos-relative",
      "es__bordered-box",
      "share-hovered"
    );
  }
  handleMouseLeave(e) {
    if (isMobileOnly) {
      return;
    }
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    serialNode.classList.remove("serial-hovered");
    shareNode.classList.remove("share-hovered");
  }
  handleMail(msgBody) {
    window.location = "mailto:?&body=" + msgBody;
  }
  handleWhatsApp(msgBody) {
    window.open("https://api.whatsapp.com/send?text=" + msgBody, "_blank");
  }
  handleTextMsg(msgBody) {
    window.location = "sms:321-555-1111?&body=" + msgBody;
  }
  showZoomImage(res, eventPosition) {
    function showWebImage(img) {
      var src = "https://cdn4.kwiat.com/source-images/web/original/" + img;
      return src;
    }
    function showimage(image) {
      let img,
        str = "";
      if (image && image != null) {
        let searchimage;
        searchimage = image;
        str = searchimage.split("\\");
        searchimage = str[str.length - 1];
        img = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      } else {
        img = "";
      }
      return img;
    }
    const webImgName = img => img.replace(/ /g, "");
    const largeImgName = img => {
      var str = img.split("\\");
      return str[str.length - 1];
    };

    var imgArr = [];
    if (eventPosition === "onWebImageIcon" && res.WebImage1) {
      imgArr.push({
        original: showWebImage(res.WebImage1),
        thumbnail: showWebImage(res.WebImage1),
        imgName: webImgName(res.WebImage1)
      });
    } else if (eventPosition === "onWebImageIcon" && res.InvWebImage1) {
      imgArr.push({
        original: showWebImage(res.InvWebImage1),
        thumbnail: showWebImage(res.InvWebImage1),
        imgName: webImgName(res.InvWebImage1)
      });
    } else if (res.LargeImageName) {
      imgArr.push({
        original: showimage(res.LargeImageName),
        thumbnail: showimage(res.LargeImageName),
        imgName: largeImgName(res.LargeImageName)
      });
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field])
          });
        }
      }

    } else if (res.InvLargeImageName) {
      imgArr.push({
        original: showimage(res.InvLargeImageName),
        thumbnail: showimage(res.InvLargeImageName),
        imgName: largeImgName(res.InvLargeImageName)
      });
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field])
          });
        }
      }
    } else {
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field])
          });
        }
      }
    }
    this.setState({
      showImageModal: true,
      zoomImage: imgArr
    });
  }
  hideImageModal() {
    this.setState({
      showImageModal: false
    });
  }
  showStockPopUpModal(e, item) {
    let { access } = this.state;
    if (access !== "advanced") {
      return;
    }
    this.setState({
      result: item,
      showStockModal: true
    });
  }
  hideStockPopUpModal() {
    this.setState({
      showStockModal: false
    });
  }
  showSoldModal(e, item) {
    // let { access } = this.state;
    // if (access !== "advanced") {
    //   return;
    // }
    this.setState({
      soldModal: true,
      result: item
    });
  }
  hideSoldModal() {
    this.setState({
      soldModal: false
    });
  }
  showMobileProductModal(item) {
    this.setState({
      result: item,
      showMobileProductModal: true
    });
  }
  hideMobileProductModal() {
    this.setState({
      showMobileProductModal: false
    });
  }
  checkIsKwiatStatus(value) {
    this.setState({
      checkKwiat: value
    });
  }
  checkIsFredStatus(value) {
    this.setState({
      checkFred: value
    });
  }
  serialSearchChanged(signal) {
    // console.log("Inside Serial Search");
    this.setState({
      serialSearchSignal: signal
    });
  }
  rfidSearchChanged(signal) {
    console.log("Inside rfid Search");
    this.setState({
      rfidSearchSignal: signal
    });
  }
  keywordSearchChanged(signal) {
    // console.log("Inside keywordSearchChanged");
    this.setState({
      keywordSearchSignal: signal
    });
  }
  keywordSearchChangedMain(signal) {
    // console.log("Inside keywordSearchChanged");
    this.setState({
      keywordSearchSignalMain: signal
    });
  }
  soldCustSearchChanged(signal) {
    // console.log("Inside soldCustSearchChanged");
    this.setState({
      soldCustSearchSignal: signal
    });
  }
  handleSoldSignal(signal) {
    this.setState({
      soldSignal: signal
    });
  }
  handleOhSignal(signal) {
    this.setState({
      ohSignal: signal
    });
  }
  serialSearchSelected(signal) {
    // console.log("Inside serial search selected");
    this.setState({
      serialSearchSelectedSignal: signal
    });
  }
  rfidSearchSelected(signal) {
    // console.log("Inside serial search selected");
    this.setState({
      rfidSearchSelectedSignal: signal
    });
  }
  keywordSearchSelected(signal) {
    // console.log("Inside keywordSearchSelected");
    this.setState({
      keywordSearchSelectedSignal: signal
    });
  }

  keywordSearchSelectedMain(signal) {
    // console.log("Inside keywordSearchSelected");
    this.setState({
      keywordSearchSelectedSignalMain: signal
    });
  }

  soldCustSearchSelected(signal) {
    // console.log("Inside soldCustSearchSelected");
    this.setState({
      soldCustSearchSelectedSignal: signal
    });
  }

  showChangeResults(signal) {
    this.setState({
      showChangedResultsOnEnter: signal
    });
  }
  showSelectedResults(signal) {
    this.setState({ showSelectedResultsOnEnter: signal });
  }

  onCheckSelect(value) {
    this.setState({
      checked: value
    });
    this.props.setBasketFormInput({
      showCodeChecked: value
    });
  }
  onSelect(value, title, sort, active) {
    this.setState({
      selected: value,
      sortField: value,
      title: title,
      sort: sort,
      active: active
    });
  }
  handleAllOptions() {
    let { mobileClassToggle } = this.state;
    this.setState({
      mobileClassToggle: !mobileClassToggle
    });
  }
  downloadResults(dynamicQuery) {
    console.log("dynamic query: ", dynamicQuery);
    const jewelryType = this.props.activeTabKey;
    const appbase = Appbase({
      url: this.props.url,
      app: this.props.app,
      credentials: this.props.credential
    });
    var limit = 1000;
    var offset = 0; // The offset from the first result you want to fetch.
    var doiExists = true;
    var totalRecords = 0;
    let data = [];
    var removeFieldsForUser;
    if (jewelryType === "serial") {
      removeFieldsForUser = jewelrySerialRemovedFields;
      console.log("remove fields for user: ", removeFieldsForUser);
    } else {
      removeFieldsForUser = jewelryStyleRemovedFields;
      console.log("remove fields for user: ", removeFieldsForUser);
    }
    async.whilst(
      function () {
        return doiExists;
      },
      callback => {
        appbase
          .search({
            from: offset,
            size: limit,
            body: [dynamicQuery]
          })
          .then(response => {
            console.log("response: ", response);
            offset = offset + limit;
            if (response.hits.hits.length < limit) {
              doiExists = false;
            }
            totalRecords = totalRecords + response.hits.hits.length;
            let resData = response.hits.hits;
            console.log("resData: ", resData);

            for (var i = resData.length - 1; i >= 0; i--) {
              // console.log("resData [", i, "] : ", resData[i]);
              var onlySource = resData[i]["_source"];
              // console.log("only source : ", onlySource);
              delete resData[i]["_source"];
              // console.log("resData [", i, "] : ", resData[i]);
              var newObject = merge({}, resData[i], onlySource);
              // console.log("newObject: ", newObject);
              if (this.state.access !== "advanced") {
                for (var j = removeFieldsForUser.length - 1; j >= 0; j--) {
                  // console.log(
                  //   "removeFieldsForUser [",
                  //   j,
                  //   "] : ",
                  //   removeFieldsForUser[j]
                  // );
                  delete newObject[removeFieldsForUser[j]];
                }
                // console.log("newObject : ", newObject);
              }
              data.push(newObject);
            }
            callback();
          })
          .catch(error => {
            callback(error);
          });
      },
      err => {
        if (err) {
          console.log(err);
        }
        // console.log("data: ", data);
        this.setState(
          {
            csvData: data
          },
          () => {
            this.csvLink.current.link.click();
          }
        );
      }
    );
  }
  async handleQueryChange(prevQuery, nextQuery) {
    let {
      sizeLimit,
      countValue,
      fromLoadMore,
      loadMoreClicked,
      sort,
      selected
    } = this.state;
    this.setState({
      countValue: fromLoadMore ? countValue : 2
    });
    if (loadMoreClicked) {
      this.setState({
        showCustomLoader: false
      });
    } else if (prevQuery) {
      this.setState({
        showCustomLoader: true,
        sizeLimit: fromLoadMore ? sizeLimit : 15
      });
    }
    // // console.log("Inside handle Query Change")
    // console.log("prev query: ", prevQuery)
    // console.log("next query: ", nextQuery)
    // console.log("sort: ", sort)
    // let sortQuery = sort === "asc" || sort === "desc" ? { ...nextQuery, from: 0, sort: [{ [selected]: { order: sort } }] } : nextQuery
    // console.log("sort query: ", sortQuery)
    // if (sort === "asc" || sort === "desc") {
    //   let res = await this.fetchSortResult(sortQuery)
    //   console.log(res)
    //   if (res && res.length !== 0) {
    //     console.log("Inside calling get product")
    //     this.getProduct(res)
    //   }
    // }
    setTimeout(() => {
      this.setState({
        showCustomLoader: false,
        fromLoadMore: false,
        nextQuery
        // ...(sort !=="asc" ?{nextQuery}:{})
        // ...(sort === "asc" || sort === "desc" ? {nextQuery:sortQuery} : { nextQuery })
      });
    }, 1200);
  }

  getProduct(results) {
    let { checked } = this.state;
    function isValueEmpty(res) {
      // console.log("res & name :", res, name);
      let result = "";
      if (!isEmpty(res) && res !== "0.00") {
        // result = `${name} : ${res}`;
        result = res;
      }
      // else {
      //   result = `${name} : null`;
      // }
      // console.log("result : ", result);
      return result;
    }
    function isMultipleValueEmpty(res, expr) {
      let result = "";
      switch (expr) {
        case "CenterDetails":
          if (!isEmpty(res.CenterShape)) {
            result = `Center Details:
            ${(res.CenterCaratWeight && res.CenterCaratWeight + " cts") || ""}
            ${res.CenterShape || ""} ${(res.CenterColor &&
                res.CenterColor + " /") ||
              ""}
            ${(res.CenterClarity && res.CenterClarity + " /") ||
              ""} ${res.CenterCut || ""} ${res.CenterEnhancement ||
              ""} ${(res.CenterOrigin && res.CenterOrigin + " - #") ||
              ""}  ${res.CenterStoneNbr || ""}`;
          }
          break;

        case "WholesalePrice":
          if (checked === true) {
            result = res.WholesalePriceCode || "";
          } else {
            result =
              (res.WholesalePrice &&
                currencyFormatter.format(`${res.WholesalePrice}`, {
                  code: "USD",
                  precision: 0
                })) ||
              "";
          }
          break;

        case "ItemSubtype":
          if (!isEmpty(res.ItemSubtype)) {
            result = res.ItemSubtype;
          } else {
            result = res.ItemType || "";
          }
          break;
        case "RetailPrice":
          if (!isEmpty(res)) {
            result = currencyFormatter.format(`${res}`, {
              code: "USD",
              precision: 0
            });
          }
          break;
        case "ColorClarity":
          result = `${res.Color || ""}
            ${res.Color && res.Clarity ? "/" : ""}
            ${res.Clarity || ""}
          `;
          break;
        case "DiamondDetails":
          result = `${res.DiamondDetails || ""}
            ${res.DiamondDetails && res.ColorComment ? " & " : ""}
            ${res.ColorComment || ""}
          `;
          break;
        default:
          return result.trim();
      }
      return result.trim();
    }
    function showWebImage(img) {
      var src =
        "https://cdn4.kwiat.com/source-images/web/altviews/" +
        img.replace(/\.[^.]*$/, "") +
        "-altview@2x.jpg";
      return src;
    }
    function showimage(image) {
      let img,
        str = "";
      if (image && image != null) {
        let searchimage;
        searchimage = image;
        str = searchimage.split("\\");
        searchimage = str[str.length - 1].replace(/icon/g, "search");
        searchimage = searchimage.replace(/Icon/g, "search");
        img = "https://cdn.kwiat.com/source-images/search/" + searchimage;
      } else {
        img = "";
        str = "";
      }
      return img;
    }
    function largeImage(image) {
      let largeImg,
        largeStr = "";
      if (image && image != null) {
        let searchLargeImg;
        searchLargeImg = image;
        largeStr = searchLargeImg.split("\\");
        searchLargeImg = largeStr[largeStr.length - 1];
        largeImg =
          "https://cdn.kwiat.com/source-images/large/" + searchLargeImg;
      } else {
        largeImg = "";
        largeStr = "";
      }
      return largeImg;
    }
    function thumbnailImage(res) {
      var src = false;
      var thumbnailImageType;
      if (
        res.SerialVideoLink &&
        res.SerialVideoLink.includes("/v360/") &&
        res.IconImageName &&
        res.IconImageName.includes("still")
      ) {
        var productId = res.SerialVideoLink.match("imaged/(.*)/")[1];
        src = `https://cdn.kwiat.com/kwiat/v360/imaged/${productId}/still.jpg`;
        thumbnailImageType = "v360";
      } else if (res.IconImageName) {
        src = showimage(res.IconImageName);
        thumbnailImageType = "iconImage";
      } else if (res.WebImage1) {
        src = showWebImage(res.WebImage1);
        thumbnailImageType = "webImage";
      } else if (res.InvIconImageName) {
        src = showimage(res.InvIconImageName);
        thumbnailImageType = "iconImage";
      } else if (res.InvWebImage1) {
        src = showWebImage(res.InvWebImage1);
        thumbnailImageType = "webImage";
      }

      return { src, thumbnailImageType };
    }
    if (this.props.appType === "original") {
      // console.log("results inside main :", results);
      return (
        <OriginalAppResults
          results={results}
          checked={checked}
          activeTabKey={this.props.activeTabKey}
          showZoomImage={this.showZoomImage}
          showStockPopUpModal={this.showStockPopUpModal}
          showMobileProductModal={this.showMobileProductModal}
          showSoldModal={this.showSoldModal}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
          handleMail={this.handleMail}
          handleTextMsg={this.handleTextMsg}
          handleWhatsApp={this.handleWhatsApp}
          isValueEmpty={isValueEmpty}
          isMultipleValueEmpty={isMultipleValueEmpty}
          thumbnailImage={thumbnailImage}
          largeImage={largeImage}
        />
      );
    } else {
      return (
        <CustomerAppResults
          results={results}
          showZoomImage={this.showZoomImage}
          showStockPopUpModal={this.showStockPopUpModal}
          showMobileProductModal={this.showMobileProductModal}
          showSoldModal={this.showSoldModal}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
          handleMail={this.handleMail}
          handleTextMsg={this.handleTextMsg}
          handleWhatsApp={this.handleWhatsApp}
          isValueEmpty={isValueEmpty}
          isMultipleValueEmpty={isMultipleValueEmpty}
          thumbnailImage={thumbnailImage}
          largeImage={largeImage}
        />
      );
    }
  }

  render() {
    // console.log(
    //   "showSelectedResultsOnEnter : ",
    //   this.state.showSelectedResultsOnEnter
    // );
    // console.log("state :", this.state);
    // console.log(this.state.ohSignal);
    let {
      showCustomLoader,
      countValue,
      sizeLimit,
      title,
      selected,
      sort,
      active,
      serialSearchSignal,
      serialSearchSelectedSignal,
      rfidSearchSignal,
      rfidSearchSelectedSignal,
      keywordSearchSignal,
      keywordSearchSelectedSignal,
      keywordSearchSelectedSignalMain,
      soldCustSearchSignal,
      soldCustSearchSelectedSignal,
      showChangedResultsOnEnter,
      showSelectedResultsOnEnter,
      checkKwiat,
      checkFred,
      showFilteredData,
      mobileClassToggle,
      nextQuery,
      csvData,
      soldSignal,
      ohSignal,
      advancedSearch,
      advancedDomNode,
      result,
      soldModal,
      showImageModal,
      showStockModal,
      webImage,
      zoomImage,
      access,
      showMobileProductModal,
      checked,
      sortField,
      mountedSearchSignal
    } = this.state;

    let soldCustQuery = [
      'WRShape',
      'WRSetting',
      'EternPart',
      'StyleNumber',
      'RetailPriceRange',
      'WholeSalePriceRange',
      'ItemType',
      'SubType',
      'Collection',
      'SubCollection',
      'Style_Status',
      'Period',
      'Maker',
      'Metal',
      'CenterShape',
      'SearchKeyword2',
      'SearchKeyword',
      'GemstoneType',
      'FancyColor',
      'Warehouse',
      'MemoOut',
      'ExcludeConsignmentItem',
      'KwiatOnly',
      'KwiatRoundOnly',
      'AshokaOnly',
      'FLRoundOnly',
      'FredLeightonOnly',
      'ColorCarats',
      'DiamondCarats',
      'DiamondClarityRange',
      'DiamondColorRange',
      'RingSizeRange',
      'IsOnline',
      'CenterStone',
      'CenterSize',
      'CenterColor',
      'CenterClarity',
      'CenterOrigin',
      'CenterEnhancement',
      'RingSize',
      'PurchaseDate',
      'JewelrySize',
      'KWCushionOnly',
      'In_ShowCase',
      'KWRadiant',
      'KWEmerald',
      'KWOval',
      'MTSOnly'
    ]

    let {
      app,
      url,
      credential,
      appType,
      activeTabKey,
      onTabChange,
      serialData,
      styleData
    } = this.props;

    let mobileClass;
    mobileClassToggle
      ? (mobileClass = "mobile-view-all")
      : (mobileClass = "mobile-view-less");

    let andQuery = [];
    if (!advancedSearch && advancedDomNode && !isMobileOnly) {
      advancedDomNode.style.display = "none";
    } else if (advancedSearch && !isMobileOnly) {
      advancedDomNode.style.display = "flex";
    } else if (isMobileOnly && advancedDomNode) {
      advancedDomNode.style.display = "block";
    }

    if (serialSearchSignal === "ACTIVE") {
      andQuery = ["SerialSearch"];
    }
    else if (mountedSearchSignal === "ACTIVE") {
      andQuery = ["MountedNumberStock"]
    }
    else if (soldCustSearchSignal === "ACTIVE") {
      // console.log("Inside soldCustSearchSignal");
      andQuery = ["SoldCust", ...soldCustQuery];
    } else if (rfidSearchSignal === "ACTIVE") {
      // console.log("Inside rfidCustSearchSignal");
      andQuery = ["RFID_Search"];
    } else {
      if (checkKwiat === "Active" && checkFred === "Active") {
        andQuery = [
          "WRShape",
          "WRSetting",
          "EternPart",
          "StyleNumber",
          "RetailPriceRange",
          "WholeSalePriceRange",
          "ItemType",
          "SubType",
          "Collection",
          "SubCollection",
          "Style_Status",
          "Period",
          "Maker",
          "Metal",
          "CenterShape",
          "SearchKeyword",
          "SearchKeyword2",
          "GemstoneType",
          "FancyColor",
          "Warehouse",
          "MemoOut",
          "IncludeSold",
          "ExcludeVirtual",
          "IncludeRTV",
          "ExcludeConsignmentItem",
          "IncludeCom",
          "FLRoundOnly",
          "AshokaOnly",
          "ColorCarats",
          "DiamondCarats",
          "DiamondClarityRange",
          "DiamondColorRange",
          "RingSizeRange",
          "IsOnline",
          "CenterStone",
          "CenterSize",
          "CenterColor",
          "CenterClarity",
          "CenterOrigin",
          "CenterEnhancement",
          "RingSize",
          "JewelrySize",
          "IncludeSemimount",
          "KWCushionOnly",
          "MTSOnly",
          "IncludeOpenJob",
          "IncludePermMemo",
          "PurchaseDate",
          "In_ShowCase",
          "IncludeDavidWebb",
          "IncludeOh",
          "IncludeOH"

          // "SoldCust",
        ];
      } else {
        andQuery = [
          "WRShape",
          "WRSetting",
          "EternPart",
          "StyleNumber",
          "RetailPriceRange",
          "WholeSalePriceRange",
          "ItemType",
          "SubType",
          "Collection",
          "SubCollection",
          "Style_Status",
          "Period",
          "Maker",
          "Metal",
          "CenterShape",
          "SearchKeyword",
          "SearchKeyword2",
          "GemstoneType",
          "FancyColor",
          "Warehouse",
          "MemoOut",
          // "IncludeSold",
          // ...(soldSignal ? ["IncludeOh"]: []),
          ...(!ohSignal ? ["IncludeSold"] : []),
          "ExcludeVirtual",
          "IncludeRTV",
          "ExcludeConsignmentItem",
          "IncludeCom",
          "KwiatOnly",
          // "TiaraOnly",
          "KwiatRoundOnly",
          "AshokaOnly",
          "FLRoundOnly",
          "FredLeightonOnly",
          "ColorCarats",
          "DiamondCarats",
          "DiamondClarityRange",
          "DiamondColorRange",
          "RingSizeRange",
          "IsOnline",
          "CenterStone",
          "CenterSize",
          "CenterColor",
          "CenterClarity",
          "CenterOrigin",
          "CenterEnhancement",
          "RingSize",
          "PurchaseDate",
          "JewelrySize",
          "IncludeSemimount",
          "KWCushionOnly",
          "IncludeOpenJob",
          "IncludePermMemo",
          "In_ShowCase",
          "IncludeDavidWebb",
          "KWRadiant",
          "KWEmerald",
          "KWOval",
          "MTSOnly",
          ...(!soldSignal ? ["IncludeOh"] : [])
          // "SoldCust",
        ];
      }
    }
    this.defaultQuery = function (activeTabKey) {
      if (activeTabKey === "serial") {
        return {
          track_total_hits: true,
          query: {
            match: { ItemStatus: "Active" }
          },
          from: 0,
          sort: [
            {
              [sortField]: {
                order: sort
              }
            }
          ]
        };
      } else {
        return {
          track_total_hits: true,
          from: 0,
          sort: [
            {
              [sortField]: {
                order: sort
              }
            }
          ]
        };
      }
    };
    return (
      <ReactiveBase
        className="es__base-component"
        app={app}
        url={url}
        credentials={credential}
      >
        <div className="searchContainer es__filters">
          {appType === "customer" && (
            <Switching onTabChange={onTabChange} activeTabKey={activeTabKey} />
          )}
          <div className="es__d-flex es__flex-xs-column">
            <div className="es__filters-list-column">
              {activeTabKey === "serial" && appType === "original" && (
                <SerialNumber
                  data={serialData}
                  serialSearchChanged={this.serialSearchChanged}
                  serialSearchSelected={this.serialSearchSelected}
                  appType={appType}
                />
              )}
              <StyleNumber data={styleData} appType={appType} />
              {appType === "original" && (
                <DiamondCarat
                  appType={appType}
                  showSelectedResults={this.showSelectedResults}
                />
              )}
              {activeTabKey === "serial" && appType === "customer" && (
                <SerialNumber
                  data={serialData}
                  serialSearchChanged={this.serialSearchChanged}
                  serialSearchSelected={this.serialSearchSelected}
                  appType={appType}
                />
              )}
              <div style={{ display: 'none' }} >
                <ItemType appType={appType} />
              </div>
              <MountedNumberStock
                data={["ComponentSerialNumbers"]}
                mountedSearchChanged={this.mountedSearchChanged}
                title="Dia Serial #"
                placeHolder="Enter Dia Serial"
              />
              <div className={mobileClass} style={{ display: 'none' }} >
                <ItemSubtype appType={appType} />
                {appType === "original" && <Collection appType={appType} />}
                {appType === "original" && <SubCollection />}
                {appType === "original" && <StyleStatus />}
                <div className="show-code-desktop">
                  {!(isMobile || isIPad13) && appType === "original" ? (
                    <>
                      <ShowCode onCheck={this.onCheckSelect} />
                      <HandleWholesale />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={mobileClass} >
                  {!(isMobile || isIPad13) && appType === "original" ? (
                    <>
                      <ShowCode onCheck={this.onCheckSelect} />
                      <HandleWholesale />
                    </>
                  ) : (
                    ""
                  )}
              </div>
            </div>
            <div className="es__filters-list-column">
              <div className={"keyword-search " + mobileClass}>
                {activeTabKey === "serial" && appType === "original" && (
                  <div style={{ display: 'none' }} ><CenterShape appType={appType} /></div>
                )}
                {appType === "customer" && <div style={{ display: 'none' }} ><Collection appType={appType} /></div>}
                {appType === "original" && <Metal appType={appType} />}
                <div style={{ display: 'none' }} >
                  <Period appType={appType} />
                  <Maker appType={appType} />
                  <RfidSearch
                    rfidSearchChanged={this.rfidSearchChanged}
                    rfidSearchSelected={this.rfidSearchSelected}
                  />
                </div>
                <Keyword
                  keywordSearchSelected={this.keywordSearchSelected}
                  keywordSearchChanged={this.keywordSearchChanged}
                  data={this.props.keywords}
                  appType={appType}
                />
                <KeywordMain
                  keywordSearchSelected={this.keywordSearchSelectedMain}
                  keywordSearchChanged={this.keywordSearchChangedMain}
                  data={["ComponentReportNumbers"]}
                />
                <div style={{ display: 'none' }}>
                  <GemstoneType appType={appType} />
                </div>
                <DiamondColorRangeStuds/>
                <DiamondClarityRangStuds/>
                {/* {appType === "original" && (
                 <DiamondColorRangeStuds 
                 appType={appType}
                 showSelectedResults={this.showSelectedResults} 
                />
                )} */}
              </div>
            </div>
            <div className="es__filters-list-column">
              <div className={mobileClass}>
                <div style={{ display: 'none' }}>
                  {appType === "customer" && <Metal appType={appType} />}
                  {appType === "original" && (
                    <WRShape data={this.props.wrshape} appType={appType} />
                  )}
                  {appType === "original" && <WRSetting appType={appType} />}
                  {appType === "original" && <EternPart appType={appType} />}
                </div>
                {activeTabKey === "serial" && appType === "original" && (
                  <Warehouse appType={appType} />
                )}
                {activeTabKey === "serial" && appType === "original" && (
                  <MemoOut appType={appType} />
                )}
                {activeTabKey === "serial" && appType === "original" && (
                  <SoldCustomerSearch
                    data={serialData}
                    soldCustSearchChanged={this.soldCustSearchChanged}
                    soldCustSearchSelected={this.soldCustSearchSelected}
                    appType={appType}
                  />
                )}
                <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                  <Col
                    md={5}
                    className="toggle-filters-listCol FiltersListCol Col-1"
                  >
                    {activeTabKey === "serial" && (
                      <IsSold handleSoldSignal={this.handleSoldSignal} />
                    )}

                    {activeTabKey === "serial" && (
                      <IsOh
                        ohSignal={this.state.ohSignal}
                        handleOhSignal={this.handleOhSignal}
                      />
                    )}
                    {/*{activeTabKey === "serial" &&<IsOh/>}*/}

                    <div style={{ display: 'none' }}>
                      {activeTabKey === "serial" && <IsCom />}
                      {activeTabKey === "serial" && <IsVirtual />}
                    </div>
                    {activeTabKey === "serial" && <IsRtv />}
                    <KwiatOnly />
                    {/* <FredLeightonOnly appType={appType} />
                    <div style={{ display: 'none' }}>{activeTabKey === "serial" && <IsSemimount />}</div> */}
                    {/* {activeTabKey === "serial" && <IsOpenJob />} */}
                    {/* {activeTabKey === "serial" && <IsPermMemo />} */}
                    {/* {this.state.access === "advanced" && <IsConsignmentItem />} */}
                    {/* <InShowCase /> */}
                    {/*{this.props.basketInputObj.dwebbDefault || 
                    this.props.basketInputObj.dwebbNoDefault ? (*/}
                    <div style={{ display: 'none' }} ><DavidWebb
                      dwebbDefault={this.props.basketInputObj.dwebbDefault}
                      dwebbNoDefault={this.props.basketInputObj.dwebbNoDefault}
                    /></div>
                    {/* ) : (
                    //   <></>
                    // )}*/}
                  </Col>
                  <Col
                    md={5}
                    className="toggle-filters-listCol FiltersListCol Col-1"
                  >
                    <FredLeightonOnly appType={appType} />
                    <div style={{ display: 'none' }}>{activeTabKey === "serial" && <IsSemimount />}</div>
                    {activeTabKey === "serial" && <IsOpenJob />}
                    {activeTabKey === "serial" && <IsPermMemo />}
                    {this.state.access === "advanced" && <IsConsignmentItem />}
                    <InShowCase />
                    {/*{this.props.basketInputObj.dwebbDefault || 
                    this.props.basketInputObj.dwebbNoDefault ? (*/}
                    <div style={{ display: 'none' }} ><DavidWebb
                      dwebbDefault={this.props.basketInputObj.dwebbDefault}
                      dwebbNoDefault={this.props.basketInputObj.dwebbNoDefault}
                    /></div>
                    {/* ) : (
                    //   <></>
                    // )}*/}
                  </Col>
                </div>
                <div style={{ display: 'none' }} >
                  <FancyColor appType={appType} />
                  {activeTabKey === "style" && appType === "original" && (
                    <AvailableOnline appType={appType} />
                  )}
                  {appType === "customer" && (
                    <DiamondCarat
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}
                  {appType === "customer" && (
                    <ColorCarat
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}
                  {appType === "customer" && (
                    <RingSizeRange
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="es__filters-list-column">
              <RetailPriceRange
                appType={appType}
                showSelectedResults={this.showSelectedResults}
              />
              <div className={mobileClass}>
                {appType === "original" &&
                  this.props.basketInputObj.showWholesale && (
                    <WholesalePriceRange
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}

                {/* {appType === "original" && (
                    <DiamondCarat
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                )} */}
                <div style={{ display: 'none' }}>
                  {appType === "original" && (
                    <ColorCarat
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}{" "}
                  {appType === "original" && (
                    <RingSizeRange
                      appType={appType}
                      showSelectedResults={this.showSelectedResults}
                    />
                  )}
                </div>
                {appType === "original" && <PurchasDateRange />}
                <div className="toggle-filters">
                  {appType === "customer" ? (
                    <div className="check-filters">
                      <h2 className="options-label">Additional Options:</h2>
                      <KwiatOnly callback={this.checkIsKwiatStatus} />
                      <FredLeightonOnly
                        callback={this.checkIsFredStatus}
                        appType={appType}
                      />
                      {activeTabKey === "serial" && <TiaraOnly />}
                      {activeTabKey === "serial" && !isMobileOnly ? (
                        <button
                          className="advanced-search-btn"
                          onClick={e => this.handleAdvancedSearch(e)}
                        >
                          {advancedSearch
                            ? "Close Advanced Search -"
                            : "Open Advanced Search +"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* ------------------------------------------ */}
                  <h5
                    style={{
                      textAlign: "center",
                      marginBottom: 10,
                      marginTop: 10
                    }}
                  >
                    <b>Kwiat Tiara Diamonds</b>
                  </h5>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Col
                      md={4}
                      className="toggle-filters-listCol FiltersListCol Col-2"
                    >
                      <TiaraOnly />
                      <KWCushionOnly />
                      <KWOval />
                      <KWEmerald />
                    </Col>
                    <Col
                      md={4}
                      className="toggle-filters-listCol FiltersListCol Col-3"
                    >
                      <AshokaOnly />
                      <KWRadiant />
                      {activeTabKey === "serial" && <FLRoundOnly />}
                      {activeTabKey === "style" && <FLRoundOnly />}
                      <IsMTS />
                      <div className="show-code-mobile">
                        {isMobile || isIPad13 ? (
                          <>
                            <ShowCode onCheck={this.onCheckSelect} />
                            <HandleWholesale />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={mobileClass}>
            {activeTabKey === "serial" && appType === "customer" ? (
              <AdvancedSearch data={this.props} />
            ) : (
              ""
            )}
          </div>
          <div className="es__d-flex es__flex-xs-column">
            <div id="btn-target" className="search-btn col-sm-12">
              <button
                className="btn search-btn__button"
                onClick={() => this.onSearch("onSearch")}
                type="button"
              >
                Search
              </button>
            </div>
            <div
              className="mobile-view-option"
              onClick={() => this.handleAllOptions()}
            >
              {mobileClassToggle ? "View Less Options" : "View All Options"}
            </div>
          </div>
        </div>
        <div className="es__filters__selection">
          {/* <SearchCriteria callback={this.clearFilters} /> */}
          <StudsSearchCriteria callback={this.clearFilters} />
          <SortDropdown
            onSelect={this.onSelect}
            title={title}
            active={active}
            soldSignal={soldSignal}
            activeTabKey={activeTabKey}
          />
        </div>
        <div className="clearfix" />
        {showCustomLoader && <ProductLoader />}
        {showFilteredData ||
          serialSearchSelectedSignal ||
          keywordSearchSelectedSignal ||
          keywordSearchSelectedSignalMain ||
          soldCustSearchSelectedSignal ||
          rfidSearchSelectedSignal ||
          showSelectedResultsOnEnter ? (
          <ReactiveList
            className={`es__results ${showCustomLoader ? "visiblityHidden" : ""
              }`}
            componentId="results"
            // dataField={selected}
            size={sizeLimit}
            // sortBy={sort}
            loader={<ProductLoader />}
            react={{
              and: andQuery
              // or: andQuery,
            }}
            renderResultStats={({ numberOfResults, time }) => {
              var node = document.getElementsByClassName("load__more--button");
              if (sizeLimit >= numberOfResults) {
                if (node.length) node[0].style.display = "none";
              } else {
                if (node.length) node[0].style.display = "block";
              }
              if (isMobileOnly) {
                return numberOfResults + " results found in " + time + "ms";
              } else {
                return (
                  <button
                    className="csv-btn"
                    onClick={() => {
                      appType === "original" && this.downloadResults(nextQuery);
                    }}
                  >
                    {numberOfResults} results found in {time}ms
                  </button>
                );
              }
            }}
            render={({ data }) => this.getProduct(data)}
            onQueryChange={(prevQuery, nextQuery) => {
              // console.log("prev query", prevQuery);
              // console.log("next query", nextQuery);
              return this.handleQueryChange(prevQuery, nextQuery);
            }}
            defaultQuery={() => this.defaultQuery(activeTabKey)}
          // customQuery={
          //   function (value, props) {
          //     return {
          //       query: {
          //       },
          //       ...(sort==="asc"?{from:0}:{})
          //     }
          //   }
          // }
          // scrollTarget={isMobile || isIPad13 ? "btn-target" : ""}
          />
        ) : (
          <div className="banner text-center">
            <img
              src={
                appType === "original"
                  ? "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Search-Background-Jewelry.png"
                  : "https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/Search-Background-Jewelry.svg"
              }
              alt="banner"
              className="img-responsive"
            />
          </div>
        )}
        {(isMobile || isIPad13) && countValue > 1 && (
          <div className="text-center load__more--button">
            <button
              className="btn btn-default"
              onClick={() => this.onSearch("loadMore")}
              type="button"
            >
              Load More
            </button>
          </div>
        )}
        <CSVLink
          className="csv-link"
          data={csvData}
          target="_self"
          filename={this.props.type + ".csv"}
          ref={this.csvLink}
        />
        {showImageModal && zoomImage !== null && (
          <ImageModal
            show={showImageModal}
            callback={this.hideImageModal}
            image={zoomImage}
            webImage={webImage}
          />
        )}
        {showStockModal && (
          <StockModal
            show={showStockModal}
            callback={this.hideStockPopUpModal}
            result={result}
            checked={checked}
          />
        )}
        {soldModal && (
          <SoldModal
            show={soldModal}
            access={access}
            callback={this.hideSoldModal}
            result={result}
            checked={checked}
          />
        )}
        {showMobileProductModal && (
          <MobileProductDetailModal
            show={showMobileProductModal}
            callback={this.hideMobileProductModal}
            result={result}
            checked={checked}
            appType={appType}
          />
        )}
      </ReactiveBase>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleIframeModal,
      setBasketFormInput
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
