import { find, map, flatten } from "lodash";

export default function SortItemsNew({ items, options }) {
  // console.log("old ", items);
  // console.log("options ", options);

  // First level sort functions

  const checkPresent = (option) =>
    find(flatten(map(options)), { value: option });
  const sortByAlpha = (query) => {
    // console.log("Inside Alpha asc");
    // console.log({ query });
    return items.sort(function (a, b) {
      return (
        (a[query] === null) - (b[query] === null) ||
        +(a[query] > b[query]) ||
        -(a[query] < b[query])
      );
    });
  };
  const sortByAlphaDesc = (query) => {
    // console.log("Inside Alpha desc");
    // console.log({ query });
    return items.sort(function (a, b) {
      return (
        (b[query] === null) - (a[query] === null) ||
        +(b[query] > a[query]) ||
        -(b[query] < a[query])
      );
    });
  };
  const sortByRangeAsc = (query) => {
    // console.log("Inside Range asc");
    // console.log({ query });
    return items.sort(function (a, b) {
      // console.log(a[query], "/", b[query]);
      return (
        (a[query] === null) - (b[query] === null) ||
        +(parseFloat(a[query]) > parseFloat(b[query])) ||
        -(parseFloat(a[query]) < parseFloat(b[query]))
      );
    });
  };
  // const sortByOrderAddedAsc = (query) => {
  //   console.log("Inside orderAdded asc");
  //   console.log({ query });
  //   return  items.sort((a, b) => b - a);
     
  // };
  const sortByRangeDesc = (query) => {
    // console.log("Inside Range desc");
    // console.log({ query });
    return items.sort(function (a, b) {
      return (
        (a[query] === null) - (b[query] === null) ||
        -(parseFloat(a[query]) > parseFloat(b[query])) ||
        +(parseFloat(a[query]) < parseFloat(b[query]))
      );
    });
  };
// const SortBasketItems = ( { items, options } ) =>{
//     console.log("old ", items);
//     switch (options) {
//       case "orderAdded-asc":
//         let sortByAddedAsc = items
//           .slice(0)
//           .reverse()
//           .map((el, index) => {
//             return el;
//           });
//         return sortByAddedAsc;
      
//       // case "orderAdded-asc":
//       //   let sortByAddedAsc = [...items].reverse()
//       //   return sortByAddedAsc;
//       case "orderAdded-desc":
//         let sortByAddedDesc = items.sort((a, b) => b - a);
//         return sortByAddedDesc;
//       default:
//         return [];
//     }
//   }

  // Second level sort functions

  const secondLevelSort = (optionArr, priceFlag) => {
    // console.log("optionArr : ", optionArr);
    // console.log("priceFlag : ", priceFlag);
    let [prevOption, query] = optionArr;
    let prevDatafield = prevOption.dataField;
    let queryDatafield = query.dataField;
    function compare(a, b) {
      if (a[prevDatafield] !== b[prevDatafield]) {
        return 0;
      } else if (a[prevDatafield] === b[prevDatafield]) {
        if (priceFlag && priceFlag === "priceAsc") {
          return (
            (b[queryDatafield] === null) - (a[queryDatafield] === null) ||
            +(parseFloat(a[queryDatafield]) > parseFloat(b[queryDatafield])) ||
            -(parseFloat(a[queryDatafield]) < parseFloat(b[queryDatafield]))
          );
        } else if (priceFlag && priceFlag === "priceDesc") {
          return (
            (a[queryDatafield] === null) - (b[queryDatafield] === null) ||
            -(parseFloat(a[queryDatafield]) > parseFloat(b[queryDatafield])) ||
            +(parseFloat(a[queryDatafield]) < parseFloat(b[queryDatafield]))
          );
        } else {
          if (a[queryDatafield] < b[queryDatafield]) {
            return -1;
          }
          if (a[queryDatafield] > b[queryDatafield]) {
            return 1;
          }
          return 0;
        }
      } else {
        return 0;
      }
    }
    return items.sort(compare);
  };

  // sort combinations

  if (options.length === 1) {
    if (
      checkPresent("retail-asc") ||
      checkPresent("ct-weight-asc") ||
      checkPresent("wholesale-asc") ||
      checkPresent("serial-asc") ||
      checkPresent("orderAdded-asc")
    ) {
      let sortByRangeAscRes = sortByRangeAsc(options[0].dataField);
      return sortByRangeAscRes;
      
    } else if (
      checkPresent("retail-desc") ||
      checkPresent("ct-weight-desc") ||
      checkPresent("wholesale-desc") ||
      checkPresent("serial-desc") ||
      checkPresent("orderAdded-desc")
    ) {
      let sortByRangeDescRes = sortByRangeDesc(options[0].dataField);
      return sortByRangeDescRes;
    } else if (checkPresent("style-desc") || checkPresent("warehouse-desc") ||  checkPresent("location-desc")) {
      let sortByRangeDescRes = sortByAlphaDesc(options[0].dataField);
      return sortByRangeDescRes;
    } else {
      let sortByAlphaRes = sortByAlpha(options[0].dataField);
      console.log(sortByAlphaRes);
      return sortByAlphaRes;
    }
  } else if (options.length === 2) {
    const findIndexFn = (indexOf) =>
      options.findIndex((x) => x.value === indexOf);
    var retailPriceAscIndex = findIndexFn("retail-asc");
    var retailPriceDescIndex = findIndexFn("retail-desc");
    var wholesalePriceAscIndex = findIndexFn("wholesale-asc");
    var wholesalePriceDescIndex = findIndexFn("wholesale-desc");
    var ctWeightAscIndex = findIndexFn("ct-weight-asc");
    var ctWeightDescIndex = findIndexFn("ct-weight-desc");
    var serialAscIndex = findIndexFn("serial-asc");
    var serialDescIndex = findIndexFn("serial-desc");
    if (
      retailPriceAscIndex > -1 ||
      retailPriceDescIndex > -1 ||
      wholesalePriceAscIndex > -1 ||
      wholesalePriceDescIndex > -1 ||
      ctWeightAscIndex > -1 ||
      ctWeightDescIndex > -1 ||
      serialAscIndex > -1 ||
      serialDescIndex > -1
    ) {
      if (
        retailPriceAscIndex > -1 ||
        wholesalePriceAscIndex > -1 ||
        ctWeightAscIndex > -1 ||
        serialAscIndex > -1
      ) {
        if (
          retailPriceAscIndex === 0 ||
          wholesalePriceAscIndex === 0 ||
          ctWeightAscIndex === 0 ||
          serialAscIndex === 0
        ) {
          sortByRangeAsc(options[0].dataField);
          secondLevelSort(options);
        } else {
          sortByAlpha(options[0].dataField);
          secondLevelSort(options, "priceAsc");
        }
      } else if (
        retailPriceDescIndex > -1 ||
        wholesalePriceDescIndex > -1 ||
        ctWeightDescIndex > -1 ||
        serialDescIndex > -1
      ) {
        if (
          retailPriceDescIndex === 0 ||
          wholesalePriceDescIndex === 0 ||
          ctWeightDescIndex === 0 ||
          serialDescIndex === 0
        ) {
          sortByRangeDesc(options[0].dataField);
          secondLevelSort(options);
        } else {
          sortByAlpha(options[0].dataField);
          secondLevelSort(options, "priceDesc");
        }
      }
    } else {
      sortByAlpha(options[0].dataField);
      secondLevelSort(options);
      SortBasketItems(options[0].dataField)
    }
    return items;
  } else {
    return [];
  }
}
