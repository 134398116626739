import React, { Component } from 'react';
import { MultiDropdownList } from '@appbaseio/reactivesearch';
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class EternPart extends Component {
  render() {
    return (
      <div className="eternpart">
        <MultiDropdownList
          title={
            this.props.appType === "original"
              ? "Etern/Part"
              : "Eternity / Partway"
          }
          className="form-field"
          componentId="EternPart"
          dataField= 'PartwayEternity.keyword'
          renderListItem={label => (
            <div>{label !== 'NULL' ? label : 'None'}</div>
          )}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={this.props.appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(EternPart);