import React, { Component } from "react";
import moment from "moment";
import ShowMore from "react-show-more";
import { BaseURL } from "../../utils/constants";
import { toggleIframeModal } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class SerialStatusResult extends Component {
  createStatusLink(linktype) {
    let link;
    if (linktype === "SOLD") {
      link = "/pages/ar/ar301000.aspx?PopupPanel=On&DocType=INV&RefNbr=";
    } else if (linktype === "MEMO") {
      link = "/pages/kw/KW801001.aspx?PopupPanel=On&MemoNbr=";
    } else if (linktype === "RTV") {
      link = "/pages/po/po302000.aspx?PopupPanel=On&ReceiptType=RN&ReceiptNbr=";
    } else if (linktype === "ON SALES ORDER") {
      link = "/pages/so/so301000.aspx?PopupPanel=On&OrderNbr=";
    } else if (linktype === "ON SHIPMENT") {
      link = "/pages/so/so302000.aspx?PopupPanel=On&ShipmentNbr=";
    } else if (linktype === "In Production") {
      link = "/pages/am/AM300000.aspx?PopupPanel=On&DocType=M&BatNbr=";
    } else if (linktype === "Adjusted Out") {
      link = "/pages/in/IN303000.aspx?PopupPanel=On&DocType=A&RefNbr=";
    }
    return link;
  }

  render() {
    let { res, isValueEmpty } = this.props;
    return (
      <div className="product-info_box">
        <h5 className="product-info_box_heading product-light_title_color">
          <span>Status</span>
        </h5>
        <div className="product-info_background">
          <div className="product-status-detail">
            <div className="product-status-con">
              <div
                className={
                  isValueEmpty(res.SerialStatus)
                    ? "known product-details-label"
                    : "unknown product-details-label unknown-status-margin-jewelry"
                }
              >
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  <span className="status product-details_line-break ">
                    {isValueEmpty(res.Warehouse)}
                    {(res.Warehouse !== res.SerialStatus) && isValueEmpty(res.Warehouse) &&
                    isValueEmpty(res.SerialStatus) ? (
                        "/"
                    ) : (
                      ""
                    )}
                    {res.IsVirtual === "1" ? "Virtual - " : ""}
                    {res.IsPhantom === "1" ? "Phantom - " : ""}
                    {(res.Warehouse !== res.SerialStatus) && isValueEmpty(res.SerialStatus)}
                    {res.MemoClass && " - "+res.MemoClass}
                  </span>
                </ShowMore>
                <span className="product-details-view-label">Status</span>
              </div>

              <div
                className={
                  isValueEmpty(res.StatusCustomer)
                    ? "known product-details-label"
                    : "unknown product-details-label unknown-status-margin-jewelry"
                }
              >
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  <div
                    className="location iframe-link"
                    onClick={() =>
                      this.props.toggleIframeModal({
                        show: true,
                        url: `${BaseURL}/pages/kw/kw501003.aspx?PopupPanel=On&AcctCD=${
                          res.StatusCustomerID
                        }`,
                      })
                    }
                  >
                    {res.MemoOutCustomer
                      ? isValueEmpty(res.MemoOutCustomer)
                      : res.StatusCustomer
                      ? isValueEmpty(res.StatusCustomer)
                      : ""}
                    {/* {isValueEmpty(res.StatusCustomer)} */}
                  </div>
                </ShowMore>
                <span className="product-details-view-label">Customer</span>
              </div>
              <div
                className={
                  isValueEmpty(res.StatusDate)
                    ? "known product-details-label"
                    : "unknown product-details-label unknown-status-margin-jewelry"
                }
              >
                <div>
                  {isValueEmpty(res.StatusRefNbr) &&
                  isValueEmpty(res.StatusDate) ? (
                    <span
                      className="iframe-link"
                      onClick={() =>
                        this.props.toggleIframeModal({
                          show: true,
                          url: `${BaseURL}${this.createStatusLink(
                            res.SerialStatus
                          )}${res.StatusRefNbr}`,
                        })
                      }
                    >
                      {isValueEmpty(res.StatusDate)
                        ? moment(new Date(`${res.StatusDate}`)).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                      &nbsp;-&nbsp;{res.StatusRefNbr}
                    </span>
                  ) : (
                    ""
                  )}
                  {isValueEmpty(res.StatusDate) && !res.StatusRefNbr
                    ? moment(new Date(`${res.StatusDate}`)).format("MM/DD/YYYY")
                    : ""}
                </div>
                <span className="product-details-view-label">Date/Ref #</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleIframeModal,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(SerialStatusResult);
