import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../../styles/original.scss";
import TemplateEdit from "./TemplateEdit";
// import { templates } from "./Templates";
import { useDispatch } from "react-redux";
import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseTemplateApp,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { toggleLoader } from "../../../actions";

export default function TemplateIndex(props) {
  const [state, setState] = useState({
    showTemplateEdit: false,
    templateDetails: "",
    templates: [],
  });
  const Dispatch = useDispatch();

  const handleTemplateEdit = (value, template) => {
    console.log({ value, template });
    setState({
      ...state,
      showTemplateEdit: value,
      templateDetails: template ? template : "",
    });
  };

  const handleTemplateRefresh = (value) => {
    let updatedTemplates =
      state.templates.filter((temp) => temp.id === value.id).length !== 0
        ? state.templates.map((temp) => {
            if (temp.id === value.id) {
              return value;
            } else {
              return temp;
            }
          })
        : [];
    console.log({ updatedTemplates });
    if (updatedTemplates.length !== 0) {
      setState({ ...state, templates: updatedTemplates });
      return;
    }
    setState({ ...state, templates: [...state.templates, value] });
  };

  const handleTempleteDelete = async (id) => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    const appbaseRef = Appbase({
      app: AppbaseTemplateApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .delete({
        id: id,
      })
      .then(function(res) {
        // console.log("successfully deleted: ", res);
        return res;
      })
      .catch(function(err) {
        console.log("deletion error: ", err);
        toast.error(`Error in deleting template`, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        });
        return err;
      });
    if (response && response.result && response.result === "deleted") {
      console.log("Template deleted.");
      let deletedTemplateId = response._id;
      setState({
        ...state,
        templates: state.templates.filter(
          (temp) => temp.id !== deletedTemplateId
        ),
      });
      Dispatch(
        toggleLoader({
          isLoading: false,
        })
      );
      toast.success(`Template Deleted`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    } else {
      toast.error(`Error in deleting template`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const fetchTemplates = async () => {
    const appbaseRef = Appbase({
      app: AppbaseTemplateApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .search({
        body: {
          query: {
            match_all: {},
          },
        },
      })
      .then(function(res) {
        // console.log("Templates results: ", res);
        return res;
      })
      .catch(function(err) {
        console.log("Templates error: ", err);
        return err;
      });

    if (response && response.hits.hits.length !== 0) {
      console.log("templates found successfully");
      setState({
        ...state,
        templates: response.hits.hits.map((data) => {
          return { id: data._id, ...data._source };
        }),
      });
    } else {
      console.log("templates not found");
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);
  // console.log({ state });
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleEmailTemplate(false)}
        className="email-template-modal"
      >
        <Modal.Header closeButton>Templates</Modal.Header>
        <Modal.Body>
          <div className="template_list">
            {/* <h5>Templates :</h5> */}

            {state.templates &&
              state.templates.map((template, index) => (
                <div key={template.Name + index} className="template_container">
                  <label>{template.Name} </label>
                  <button
                    onClick={() => {
                      handleTemplateEdit(true, template);
                    }}
                  >
                    Edit
                  </button>
                  <button onClick={() => handleTempleteDelete(template.id)}>
                    Delete
                  </button>
                </div>
              ))}

            <button
              onClick={() => {
                handleTemplateEdit(true, "");
              }}
            >
              New
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <TemplateEdit
        show={state.showTemplateEdit}
        templateDetails={state.templateDetails}
        handleTemplateEdit={handleTemplateEdit}
        handleTemplateRefresh={handleTemplateRefresh}
      />
    </>
  );
}
