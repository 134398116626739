import React from 'react';
import GemstoneDashboard from './components/GemstoneDashboard';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';

const GemstoneSearch = () => {
  return (
    <div id="gemstone-search" className="page">
      <GemstoneDashboard />
      <div className="es-scroll-button">
        <ScrollUpButton />
      </div>
    </div>
  );
};
export default GemstoneSearch;
