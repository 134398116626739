import React, { Component } from "react";
import { MultiList } from "@appbaseio/reactivesearch";

class DavidWebb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.dwebbDefault
        ? ["1", "0"]
        : this.props.dwebbNoDefault
        ? ["0"]
        : ["0"]
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.dwebbDefault !== this.props.dwebbDefault ||
      prevProps.dwebbNoDefault !== this.props.dwebbNoDefault
    ) {
      this.setState(prevState => ({
        value: this.props.dwebbDefault
          ? ["1", "0"]
          : this.props.dwebbNoDefault
          ? ["0"]
          : prevState.value
      }));
    }
  }
  render() {
    let { dwebbNoDefault, dwebbDefault } = this.props;
    let { value } = this.state;
    // console.log({ dwebbDefault, dwebbNoDefault, value });
    return (
      <div
        className="toggle__filters"
        style={
          dwebbDefault || dwebbNoDefault
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <MultiList
          componentId="IncludeDavidWebb"
          dataField="dwebb_vegas.keyword"
          // defaultValue={
          //   dwebbDefault ? ["1", "0"] : dwebbNoDefault ? ["0"] : ["0"]
          // }
          queryFormat="or"
          showFilter={true}
          showSearch={false}
          sortBy="asc"
          beforeValueChange={function(value) {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject();
              }
              resolve();
            });
          }}
          value={value}
          onChange={value => {
            // console.log({ value });
            this.setState({ value: value });
          }}
          render={({ data, value, handleChange }) => {
            var checkedFlag;
            // console.log(value);
            value.hasOwnProperty("1")
              ? (checkedFlag = true)
              : (checkedFlag = false);
            if (data.length === 1) {
              return (
                <ul>
                  <li></li>
                  <li>
                    <input
                      type="checkbox"
                      value={1}
                      onChange={handleChange}
                      checked={checkedFlag}
                    />
                    <span className="toggle--label"> + DavidWebb</span>
                  </li>
                </ul>
              );
            } else {
              return (
                <ul>
                  {data.map(item => (
                    <li key={item.key}>
                      <input
                        type="checkbox"
                        value={item.key}
                        onChange={handleChange}
                        checked={checkedFlag}
                      />
                      <span className="toggle--label"> + DavidWebb</span>
                    </li>
                  ))}
                </ul>
              );
            }
          }}
        />
      </div>
    );
  }
}

export default DavidWebb;
