import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class ExtendedDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { show, res, hide } = this.props;
    return (
      <Modal
        show={show}
        onHide={() => hide()}
        animation={false}
        dialogClassName="show-more_modal-dailog"
        className="more-details-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Extended Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="item__description__box">
            <span>{res.LongDescription}</span>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExtendedDetailModal;
