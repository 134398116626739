import React from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

class CenterStone extends React.PureComponent {
  render() {
    return (
      <div className="center-stone form-field-wrap">
        <DataSearch
          title="Center Stone #"
          className="form-field"
          placeholder="Start Search..."
          dataField={["CenterStoneNbr"]}
          autosuggest={true}
          queryFormat="and"
          componentId="CenterStone"
          iconPosition="right"
        />
      </div>
    );
  }
}

export default CenterStone;
