import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";
class StyleNumber extends Component {
  render() {
    let { appType, data } = this.props;
    return (
      <div className="style-number form-field-wrap">
        <DataSearch
          title={appType && appType === "customer" ? "Style Number" : "Style #"}
          className="form-field"
          placeholder={
            appType && appType === "customer"
              ? "Start Search..."
              : "Enter Style"
          }
          dataField={data}
          autosuggest={true}
          queryFormat="and"
          componentId="StyleNumber"
          iconPosition={appType && appType === "customer" ? "right" : "left"}
        />
      </div>
    );
  }
}

export default StyleNumber;
