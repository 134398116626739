import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Main from "./Main";
import Header from "../../components/Header";
import {
  AppbaseAppUrl,
  JewelrySerialApp,
  JewelryStyleApp,
  AppbaseCredentials,
  SerialDataField,
  StyleDataField,
  KeywordsSearch,
  WRShapeSerial,
  WRShapeStyle,
  AppbaseEmployeeApp,
} from "../../utils/constants";
import { setAppType, setGroups, setBasketFormInput } from "../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import Appbase from "appbase-js";

const mapStateToProps = (state) => {
  return {
    App: state,
    basketInputObj: state.basketInputChange,
    groups: state.SetGroups.groups,
    cartItems: state.cartActions,
  };
};

class StudsDashboard extends Component {
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.state = {
      isLoading: false,
      activeTabKey: "",
      title: "Studs",
      items: props.cartItems.items,
      showBasketModal: false
    };
    // this.handleGetGroups = this.handleGetGroups.bind(this);
    // this.getUserEmail = this.getUserEmail.bind(this);
    this.showBasketModal = this.showBasketModal.bind(this);
    this.hideBasketModal = this.hideBasketModal.bind(this);
  }

  showBasketModal() {
    this.setState({
      showBasketModal: true,
    });
  }
  hideBasketModal() {
    this.setState({
      showBasketModal: false,
    });
  }


  async componentDidMount() {
    let pathName = this.props.location.pathname;
    pathName === "/studs-search" &&
      this.props.history.push(`/studs-search/serial`);
    let pathStyle = this.props.location.pathname.indexOf("style") > -1;
    this.setState({
      activeTabKey: pathStyle ? "style" : "serial",
    });
    let url = window.location.href;
    if (!url.includes("kim.kwiat.com")) {
      await this.handleGetGroups();
      let groups = this.props.groups.groups;
      // console.log("groups :", groups);
      if (groups) {
        let accessGroup = groups.filter((group) =>
          [
            "227b908c-faec-4169-a1f0-a45e217725e5",
            "1dd4718d-e6a6-4d12-b536-d9f0d6025a51",
            "6e9595ab-6424-43e7-a367-91248b278798",
          ].includes(group.id)
        );
        // console.log({ accessGroup });
        if (accessGroup.length !== 0) {
          this.props.setBasketFormInput({
            access: "advanced",
          });
        }
      }
    }
    // await this.getUserEmail();
  }
  componentWillReceiveProps(props) {
    let pathStyle = this.props.location.pathname.indexOf("style") > -1;
    this.setState({
      activeTabKey: pathStyle ? "style" : "serial",
    });
  }

  onTabChange(tabKey) {
    this.props.history.push(`/studs-search/${tabKey}`);
    this.setState({
      activeTabKey: tabKey,
    });
  }

  async handleGetGroups() {
    let res = await axios.get(`/api/index`, { credentials: "include" });

    // console.log("res: ", res);
    if (res.data.response.status === 200) {
      let groupData = res.data.data;
      this.props.setGroups({ groups: groupData });
    } else {
      console.log("Error in fetching details");
    }
  }

  // async getUserEmail() {
  //   let userEmail;
  //   if (this.props.groups.email) {
  //     userEmail = this.props.groups.email;
  //   } else if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector(
  //         "li.menu-form > div.inline > span:nth-child(3)"
  //       ).textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //     // userEmail = "megan@kwiat.com";
  //     // userEmail = "nagaraj@kwiat.com";
  //     // userEmail = "josh@kwiat.com";
  //     // userEmail = "cory@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: [
  //             "FirstName",
  //             "LastName",
  //             "Email",
  //             "BasketEmail",
  //             "EmployeeLogin",
  //             "Title",
  //             "Phone1",
  //             "AddressLine1",
  //             "AddressLine2",
  //             "City",
  //             "State",
  //             "PostalCode",
  //             "BasketBccEmail",
  //             "BasketCcEmail",
  //             "CRMBccEmail",
  //           ],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     // let payload = {
  //     //   data: {
  //     //     defaults: {
  //     //       baseURL: basketBaseUrl,
  //     //       token: token,
  //     //     },
  //     //     inputs: {
  //     //       EmployeeLogin: {
  //     //         value: this.props.basketInputObj.user.EmployeeLogin,
  //     //       },
  //     //     },
  //     //   },
  //     // };
  //     // // console.log("payload: ", payload);
  //     // await axios
  //     //   .post(appbaseBasketUrl + "user-signature", payload, {
  //     //     headers: {
  //     //       "x-api-key": basketApikey,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     // console.log("resp : ", res);
  //     //     if (
  //     //       res.data.statusCode === 200 &&
  //     //       res.data.body.KWMailSignature.value
  //     //     ) {
  //     //       console.log("User signature found.");
  //     //       this.props.setBasketFormInput({
  //     //         user: {
  //     //           ...this.props.basketInputObj.user,
  //     //           Signature: res.data.body.KWMailSignature.value,
  //     //         },
  //     //       });
  //     //     } else {
  //     //       console.log("response is empty");
  //     //     }
  //     //     // console.log("Basket form data: ", this.props.basketInputObj);
  //     //     // return JSON.parse(res.data.body);
  //     //     // return res;
  //     //   })
  //     //   .catch((err) => {
  //     //     // console.log("error : ", err);
  //     //     console.log("User Signature error.", err);
  //     //     // return;
  //     //   });
  //   } else {
  //     console.log("No user");
  //   }
  //   // if (window.parent.document.getElementById("btnUserMenu")) {
  //   //   let userDetails = window.parent.document
  //   //     .getElementById("userMenu")
  //   //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
  //   //   console.log("userDetails: ", userDetails);
  //   // }
  // }

  render() {
    let appType = this.props.App.AppType.app;
    const { activeTabKey, isLoading, title, items, showBasketModal } = this.state;
    // console.log("App Type: ", appType);

    let tabContent = {
      serial: (
        <div className="TabContentLoader">
          <i
            className="fa fa-spinner fa-2x fa-spin"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      ),
      style: (
        <div className="TabContentLoader">
          <i
            className="fa fa-spinner fa-2x fa-spin"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      ),
    };
    if (!isLoading) {
      tabContent = {
        serial: (
          <Main
            app={JewelrySerialApp}
            url={AppbaseAppUrl}
            credential={AppbaseCredentials}
            activeTabKey={activeTabKey}
            serialData={SerialDataField}
            styleData={StyleDataField}
            keywords={KeywordsSearch}
            wrshape={WRShapeSerial}
            appType={appType}
            onTabChange={this.onTabChange}
          />
        ),
        style: (
          <Main
            app={JewelryStyleApp}
            url={AppbaseAppUrl}
            credential={AppbaseCredentials}
            activeTabKey={activeTabKey}
            serialData={SerialDataField}
            styleData={StyleDataField}
            keywords={KeywordsSearch}
            wrshape={WRShapeStyle}
            appType={appType}
            onTabChange={this.onTabChange}
          />
        ),
      };
    }
    return (
      <div className="dashboard">
        {appType === "original" && (
          <div className="main-content">
            <div className="header-con">
              <Header
                title={title}
                activeTabKey={activeTabKey}
                onTabChange={this.onTabChange}
              />
            </div>
          </div>
        )}
        <div className="body-con">
          {activeTabKey === "serial" && tabContent.serial}
          {activeTabKey === "style" && tabContent.style}
          <div className="es__basket-icon" onClick={() => this.showBasketModal()}>
          <span className="items__count">{items.length}</span>
          <img
            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/open-basket.png"
            alt="basket_icon"
          />
        </div>
        {showBasketModal && !isMobileOnly && (
          <Basket show={showBasketModal} callback={this.hideBasketModal} />
        )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAppType,
      setBasketFormInput,
      setGroups,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudsDashboard));
