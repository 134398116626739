import React from 'react';
import MTSDiamondDashboard from './components/MTSDiamondDashboard';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';

const MTSDiamondSearch = () => {
  return (
    <div id="diamond-search" className="page">
      <MTSDiamondDashboard />
      <div className="es-scroll-button">
        <ScrollUpButton />
      </div>
    </div>
  );
};
export default MTSDiamondSearch;
