import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import ReactImageFallback from "react-image-fallback";
import moment from "moment";
import cx from "classnames";
import LoadingOverlay from "react-loading-overlay";
import ShowMore from "react-show-more";
import Appbase from "appbase-js";
import axios from "axios";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import {
  setBasketFormInput,
  toggleIframeModal,
  addToCart,
  toggleLoader,
  setToken,
  setEmployeeData,
  setFeedsHoldData,
  setJewelryHoldData,
} from "../../actions/index";
import {
  AppbaseEmployeeApp,
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  basketApikey,
  appbaseBasketUrl,
  AppbaseHoldNotesApp,
  AppbaseCoverApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseCustomerApp,
  AppbaseAttributesApp,
  holdNoteApi,
  holdNoteApiKey,
} from "../../utils/constants";
import GetAuthToken from "../../components/Api/Authenticate";
import { BaseURL } from "../../utils/constants";
import MobileProductDetails from "./MobileProductDetails";
import SerialStatusResult from "./SerialStatusResult";
import StyleWebResult from "./StyleWebResult";
import MoreDetailsModal from "./MoreDetailsModal";
import PreviewEmailModal from "../../components/Basket/PDF/PreviewEmailModal";
import PDFModal from "../../components/Basket/PDF/PDFModal";
import ChooseLayoutModal from "../../components/Basket/ChooseLayoutModal";
import PDF from "../../assets/icons/pdf-black-16.png";
import ExtendedDetailModal from "../../components/ExtendedDetailModal";
import ShowFileModal from "../../components/ShowFileModal";
import HoldNotesArchievedModal from "../../components/HoldNotesArchievedModal";
import HoldNoteCreateModal from "../../components/HoldNoteCreateModal";
import {
  fetchApiData,
  getHoldNotesData,
} from "../../components/holdNotesApiData";
import { Modal, Button } from "react-bootstrap";

const mapStateToProps = (state) => {
  return {
    // cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    loaderActions: state.loaderActions,
    tokenState: state.tokenState,
    apiData: state.apiData,
  };
};

class OriginalAppResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: this.props.results,
      showMoreModal: false,
      currentRes: [],
      showPreviewModal: false,
      showPDFModal: false,
      showChooseLayout: false,
      layoutType: "",
      coverType: "NoCover",
      goto: "",
      includeGIA: "No",
      showExtendedDetailModal: false,
      showFileModal: false,
      fileIdNames:
        "noimage.png:3FE31D95-96F4-48D7-9B34-E89E600DE610|noimagebig.png:402E021D-4706-4999-AE9E-C4498162AC2F",
      // fileIdNames: "noimage.png:3FE31D95-96F4-48D7-9B34-E89E600DE610",
      // |noimagebig.png:402E021D-4706-4999-AE9E-C4498162AC2F",
      // user: "",
      fileData: [],
      coverSelected: "",
      coverCategories: [],
      covers: [],
      showHoldNotesModal: false,
      showHoldNotesEditModal: false,
      holdNotesData: "",
      showHoldNoteCreateModal: false,
      customers: "",
      feedRestrictionsData: this.props.apiData.feedsData,
      jewelryRestrictionData: this.props.apiData.jewelryHoldData,
      employeeData: this.props.apiData.empData,
      holdNoteItem: "",
      archivePopUp: false,
      blankMTSCustomer: false,
      includeKTDShapes: "Yes"
    };

    this.handleMail = this.handleMail.bind(this);
    this.handleTextMsg = this.handleTextMsg.bind(this);
    this.handleWhatsApp = this.handleWhatsApp.bind(this);
    this.showMoreModal = this.showMoreModal.bind(this);
    this.hideMoreModal = this.hideMoreModal.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.hidePreviewModal = this.hidePreviewModal.bind(this);
    this.showPDFModal = this.showPDFModal.bind(this);
    this.hidePDFModal = this.hidePDFModal.bind(this);
    this.showChooseLayout = this.showChooseLayout.bind(this);
    this.hideChooseLayout = this.hideChooseLayout.bind(this);
    this.handleSetLayout = this.handleSetLayout.bind(this);
    this.handleSetCover = this.handleSetCover.bind(this);
    this.handleCurrentRes = this.handleCurrentRes.bind(this);
    // this.getContactEmail = this.getContactEmail.bind(this);
    // this.getUserEmail = this.getUserEmail.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleIncludeGIA = this.handleIncludeGIA.bind(this);
    this.handleAddToBasket = this.handleAddToBasket.bind(this);
    this.showExtendedDetailModal = this.showExtendedDetailModal.bind(this);
    this.hideExtendedDetailModal = this.hideExtendedDetailModal.bind(this);
    this.handleShowFileIdNames = this.handleShowFileIdNames.bind(this);
    this.hideFileModal = this.hideFileModal.bind(this);

    this.fetchCovers = this.fetchCovers.bind(this);
    this.handleSelectedCover = this.handleSelectedCover.bind(this);
    this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
    this.handleIncludeKTDShapes = this.handleIncludeKTDShapes.bind(this);
    // this.handleHoldNotesCreateModal =
    //   this.handleHoldNotesCreateModal.bind(this);
    // this.getHoldNotesData = this.getHoldNotesData.bind(this);
    // this.getContactsData = this.getContactsData.bind(this);
  }

  handleIncludeKTDShapes (value) {
    this.setState({includeKTDShapes: value})
   };

  async componentDidMount() {
    // if (this.props.tokenState.token === "") {
    //   var token = await GetAuthToken();
    //   if (token) {
    //     this.props.setToken(token.access_token);
    //   }
    // }
    // this.props.handleItemsSearched(this.props.results)
    // let user;
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    //   // user = "Sayyed, Sahil";
    //   // user = "Jangam, Nagaraj";
    // }
    // this.setState({ userLoggedIn: user ? user : "" });
    // await this.fetchCovers();
    // let empResponse = await fetchApiData(AppbaseEmployeeApp);
    // if (empResponse) {
    //   // this.setState({ employeeData: empResponse });
    //   setEmployeeData(empResponse);
    // }
    // // let custResponse = await fetchApiData(AppbaseCustomerApp);
    // // if (custResponse) {
    // //   this.setState({ customers: custResponse });
    // // }
    // let feedResponse = await fetchApiData(AppbaseAttributesApp, "feed");
    // if (feedResponse) {
    //   // this.setState({ feedRestrictionsData: feedResponse });
    //   setFeedsHoldData(feedResponse);
    // }
    // let jewelryRespone = await fetchApiData(AppbaseAttributesApp, "jewelry");
    // if (jewelryRespone) {
    //   // this.setState({ jewelryRestrictionData: jewelryRespone });
    //   setJewelryHoldData(jewelryRespone);
    // }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.results.length !== this.state.results.length) {
      this.setState({ results: nextProps.results });
    } else if (nextProps.results.length == this.state.results.length) {
      let refreshItems = nextProps.results.every((item) =>
        this.state.results.some((data) => data._id === item._id)
      );
      if (!refreshItems) {
        this.setState({ results: nextProps.results });
      }
    }
    if (
      JSON.stringify(
        this.state.results.map((item) => {
          return item.InventoryID;
        })
      ) !==
      JSON.stringify(
        nextProps.results.map((item) => {
          return item.InventoryID;
        })
      )
    ) {
      this.setState({ results: nextProps.results });
    }
  }

  handleItemHoldNoteUpdate(data) {
    // console.log({ data });
    this.setState((prevState) => ({
      results: prevState.results.map((result) => {
        if (result.SerialNumber === data.SerialNumber) {
          // console.log("Inside serial number match.");
          return data;
        } else {
          return result;
        }
      }),
    }));
  }

  async handleHoldNoteArchive(res) {
    //let result = window.confirm("Confirm Hold Note Archieve ?");
    if (true) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
          //blankMTSCustomer: this.state.blankMTSCustomer,
        },
      };
      if(res?.MTSCustomerExists === "Yes"){
        payload.data.blankMTSCustomer = this.state.blankMTSCustomer
      }
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // this.props.toggleLoader({
          //   isLoading: false,
          // });
        });
      // console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        this.props.toggleLoader({
          isLoading: false,
        });
        this.setState((prevState) => ({
          results: prevState.results.map((result) => {
            if (result.SerialNumber === res.SerialNumber) {
              return {
                ...result,
                // HoldBy: null,
                HoldCustomerId: null,
                HoldCustomerName: null,
                HoldDate: null,
                HoldEnteredByID: null,
                HoldEnteredByName: null,
                HoldEnteredForID: null,
                HoldEnteredForName: null,
                HoldNoteID: null,
                HoldNoteNbr: null,
                HoldText: null,
                ReleaseDate: null,
                IsHoldSold: null,
              };
            } else {
              return result;
            }
          }),
        }));
      } else {
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }

  async handleHoldNotesModals(id, value, type, res) {
    this.setState({ holdNoteItem: res });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      this.setState({ holdNotesData: response });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    this.setState({
      ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
      ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
      ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
      ...(!value
        ? { showHoldNotesEditModal: false, holdNotesData: "", holdNoteItem: "" }
        : {}),
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  }

  handleSelectedCover(value) {
    // console.log(value);
    if (value) {
      this.setState(
        (prevState) => ({
          coverSelected: value,
          // prevState.covers.filter(
          //   (cover) => cover.id === value
          // )[0],
        })
        // () => console.log("coverSelecetd: ", this.state.coverSelected)
      );
    } else {
      this.setState({ coverSelected: "" });
    }
  }

  async fetchCovers() {
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .search({
        body: {
          query: {
            match_all: {},
          },
        },
      })
      .then(function(res) {
        // console.log("Templates results: ", res);
        return res;
      })
      .catch(function(err) {
        console.log("Covers error: ", err);
        return err;
      });

    if (response && response.hits.hits.length !== 0) {
      console.log("Covers found successfully");
      this.setState((prevState) => ({
        ...prevState,
        covers: response.hits.hits.map((data) => {
          return { id: data._id, ...data._source };
        }),
        coverCategories: [
          ...new Set(
            response.hits.hits.map((cover) => {
              return cover._source.Category;
            })
          ),
        ],
      }));
    } else {
      console.log("covers not found");
    }
  }

  handleShowFileIdNames(res) {
    let { dwebbDefault } = this.props.basketInputObj;
    if (dwebbDefault) {
      window
        .open(
          `${BaseURL}/Main?ScreenId=KW801120&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
          "_blank"
        )
        .focus();
    } else {
      let fileIdNames = res.FileIdNames;
      let fileData = fileIdNames.split("|");
      // console.log("fileData: ", fileData);
      if (fileData.length === 1) {
        let fileID = fileData[0].slice(fileData[0].indexOf(":") + 1);
        // console.log("fileID: ", fileID);
        window
          .open(BaseURL + "/Frames/GetFile.ashx?fileID=" + fileID, "_blank")
          .focus();
      } else {
        this.setState({ fileData, showFileModal: true });
      }
    }
  }

  hideFileModal() {
    this.setState({ showFileModal: false });
  }

  handleAddToBasket(results) {
    results.map((res, i) => {
      this.props.addToCart({
        product: res,
        productType: "J",
        assetOrmemo: this.props.basketInputObj.assetOrmemo,
      });
    });
  }

  // async getUserEmail() {
  //   let { basketInputObj, tokenState } = this.props;
  //   let userEmail;
  //   if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector(
  //         "li.menu-form > div.inline > span:nth-child(3)"
  //       ).textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: [
  //             "FirstName",
  //             "LastName",
  //             "Email",
  //             "BasketEmail",
  //             "EmployeeLogin",
  //             "Title",
  //             "Phone1",
  //             "AddressLine1",
  //             "AddressLine2",
  //             "City",
  //             "State",
  //             "PostalCode",
  //             "BasketBccEmail",
  //             "BasketCcEmail",
  //             "CRMBccEmail",
  //           ],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         // this.setState({ user: response.hits.hits[0]._source });
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     // let payload = {
  //     //   data: {
  //     //     defaults: {
  //     //       baseURL: basketBaseUrl,
  //     //       token: this.props.tokenState.token,
  //     //     },
  //     //     inputs: {
  //     //       EmployeeLogin: {
  //     //         value: this.props.basketInputObj.user.EmployeeLogin,
  //     //       },
  //     //     },
  //     //   },
  //     // };
  //     // // console.log("payload: ", payload);
  //     // await axios
  //     //   .post(appbaseBasketUrl + "user-signature", payload, {
  //     //     headers: {
  //     //       "x-api-key": basketApikey,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     // console.log("resp : ", res);
  //     //     if (
  //     //       res.data.statusCode === 200 &&
  //     //       res.data.body.KWMailSignature.value
  //     //     ) {
  //     //       console.log("User signature found.");
  //     //       // this.setState((prevState) => ({
  //     //       //   user: {
  //     //       //     ...prevState.user,
  //     //       //     Signature: res.data.body.KWMailSignature.value,
  //     //       //   },
  //     //       // }));
  //     //       this.props.setBasketFormInput({
  //     //         user: {
  //     //           ...this.props.basketInputObj.user,
  //     //           Signature: res.data.body.KWMailSignature.value,
  //     //         },
  //     //       });
  //     //     } else {
  //     //       console.log("response is empty");
  //     //     }
  //     //     // console.log("Basket form data: ", this.props.basketInputObj);
  //     //     // return JSON.parse(res.data.body);
  //     //     // return res;
  //     //   })
  //     //   .catch((err) => {
  //     //     // console.log("error : ", err);
  //     //     console.log("User Signature error.", err);
  //     //     // return;
  //     //   });
  //   } else {
  //     console.log("No user");
  //   }
  //   // if (window.parent.document.getElementById("btnUserMenu")) {
  //   //   let userDetails = window.parent.document
  //   //     .getElementById("userMenu")
  //   //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
  //   //   console.log("userDetails: ", userDetails);
  //   // }
  // }

  showExtendedDetailModal(res) {
    this.handleCurrentRes(res);
    this.setState({
      showExtendedDetailModal: true,
    });
  }
  hideExtendedDetailModal() {
    this.setState({ showExtendedDetailModal: false });
  }

  handleIncludeGIA(value) {
    this.setState({ includeGIA: value });
  }
  async handleEmail(res) {
    console.log("Inside ");
    // if (
    //   this.props.basketInputObj.orderNbr &&
    //   this.props.basketInputObj.orderNbr !== "New"
    // ) {
    this.props.toggleLoader({
      isLoading: true,
    });
    // await this.getUserEmail();
    // await this.getContactEmail();
    // this.handleBasketChange("Email");
    this.props.toggleLoader({
      isLoading: false,
    });
    this.showChooseLayout("Email", res);

    // } else {
    //   window.alert("Please select a basket first");
    // }
  }

  handleCurrentRes(res) {
    this.setState({ currentRes: [res] });
  }

  handleSetCover(cover) {
    // console.log("layout: ", layout);
    this.setState({ coverType: cover });
  }

  handleSetLayout(layout) {
    // console.log("layout: ", layout);
    this.setState({ layoutType: layout });
  }

  showChooseLayout(value, res) {
    this.setState({
      showChooseLayout: true,
      goto: value,
      // currentRes: [res],
    });
    this.handleCurrentRes(res);
  }
  hideChooseLayout() {
    this.setState({
      showChooseLayout: false,
    });
  }

  showPDFModal() {
    this.setState({
      showPDFModal: true,
    });
  }
  hidePDFModal() {
    this.setState({
      showPDFModal: false,
    });
  }

  showPreviewModal() {
    this.setState({
      showPreviewModal: true,
    });
  }
  hidePreviewModal() {
    this.setState({
      showPreviewModal: false,
    });
  }
  showMoreModal(res) {
    this.handleCurrentRes(res);
    this.setState({
      showMoreModal: true,
    });
  }
  hideMoreModal() {
    this.setState({
      showMoreModal: false,
    });
  }
  handleMail(msgBody) {
    window.location.href = "mailto:?body=" + msgBody;
  }
  handleTextMsg(msgBody) {
    window.location = "sms:?&body=" + msgBody;
  }
  handleWhatsApp(msgBody) {
    window.location = "https://api.whatsapp.com/send?text=" + msgBody;
  }
  nLeftPad(n) {
    return n > 9 ? "" + n : "0" + n;
  }

  render() {
    let {
      // results,
      isValueEmpty,
      isMultipleValueEmpty,
      thumbnailImage,
      largeImage,
      activeTabKey,
    } = this.props;
    let { results } = this.state;
    let { isLoading } = this.props.loaderActions;
    // console.log("results inside original app results :-->", results);
    // console.log("currentRes: -->", this.state.currentRes);
    return (
      <div className="jewelry-products jewelry-res">
        <button
          className="search_result_add_basket"
          onClick={(event) => {
            event.preventDefault();
            this.handleAddToBasket(results);
            return false;
            // debugger;
            // // this.props.toggleLoader({
            // //   isLoading: true,
            // // });

            // results.map((res, i) => {
            //   this.props.addToCart({
            //     product: res,
            //     productType: "J",
            //   });
            // });

            // // this.props.toggleLoader({
            // //   isLoading: false,
            // // });
            // debugger;
          }}
        >
          Add To Basket
        </button>
        {/* <LoadingOverlay active={isLoading} spinner text="Loading..."> */}
        {results.map((res, index) => {
          {
            // console.log("jewelry", res);
          }

          let msgBody = `Inventory ID: ${isValueEmpty(
            res.StyleNumber
          )}%0D%0ASerial Number: ${isValueEmpty(
            res.SerialNumber
          )}%0D%0AImage: ${largeImage(
            res.LargeImageName
          )}%0D%0ADescription: ${isValueEmpty(
            res.Description
          )}%0D%0APrice: ${parseFloat(isValueEmpty(res.RetailPrice)).toFixed(
            2
          )}%0D%0A%0D%0ADETAILS:%0D%0A${isMultipleValueEmpty(
            res,
            "ItemSubType"
          )}%0D%0AMaker: ${isValueEmpty(res.Maker)}%0D%0APeriod: ${isValueEmpty(
            res.Period
          )}%0D%0ACollection: ${isValueEmpty(
            res.Collection
          )}%0D%0ALength/Size: ${isValueEmpty(res.Length)}${isValueEmpty(
            res.RingSize
          )}%0D%0ACenter Details: ${isMultipleValueEmpty(
            res,
            "CenterDetails"
          )}%0D%0A${isValueEmpty(res.Metal)} - ${isValueEmpty(
            res.DiamondCarats
          )}cts, ${isValueEmpty(res.ColorCarats)}/${isValueEmpty(res.Clarity)}`;

          return (
            <div
              // style={{background: res.IsTiara === "1" || res.IsKWCushion === "1" || res.IsKWRadiant === "1" || res.IsKWEmeraldCut === "1" || res.IsKWOval === "1" || res.IsAshoka === "1" || res.IsFLRound === "1" ? "#E3EDF3": "#f5f7f8" ? res.IsOH === "1" ? "#d6a69ad9" : "#f5f7f8" : "#f5f7f8"}}
              style={{
                background:
                  res.IsOH === "1"
                    ? "#d6a69ad9"
                    : res.IsTiara === "1" ||
                      res.IsKWCushion === "1" ||
                      res.IsKwiatRadiant === "1" ||
                      res.IsKwiatEmeraldCut === "1" ||
                      res.IsKwiatOval === "1" ||
                      res.IsAshoka === "1" ||
                      res.IsFLRound === "1"
                    ? "#E3EDF3"
                    : "#f5f7f8",
              }}
              className="product"
              key={res._id}
              onMouseEnter={(e) => this.props.handleMouseEnter(e)}
              onMouseLeave={(e) => this.props.handleMouseLeave(e)}
            >
              <div className="index-share">
                <div className="product-index_container pos-relative">
                  <div className="product-index">
                    {this.nLeftPad(index + 1)}
                  </div>
                  {isMobileOnly && (
                    <div className="mobile__basket__add-section">
                      <img
                        className="es__basket-icon"
                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/add-to-basket.png"
                        alt="basket_icon"
                        onClick={() => {
                          this.props.addToCart({
                            product: res,
                            productType: "J",
                            assetOrmemo: this.props.basketInputObj.assetOrmemo,
                          });
                        }}
                      />
                      <img
                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                        alt="Email"
                        className="print__share-icon"
                        onClick={() => {
                          this.handleIncludeGIA("No");
                          this.props.setBasketFormInput({
                            includePrice: false,
                            includeRetail: false,
                            includeWholesale: false,
                          });
                          this.props.setBasketFormInput({
                            includeLinks: "No",
                          });
                          this.handleSetCover("NoCover");

                          this.handleEmail(res);
                        }}
                      />
                      <img
                        src={PDF}
                        alt="Email"
                        className="mail__share-icon"
                        onClick={() => {
                          this.handleIncludeGIA("No");
                          this.props.setBasketFormInput({
                            includePrice: false,
                            includeRetail: false,
                            includeWholesale: false,
                          });
                          this.props.setBasketFormInput({
                            includeLinks: "No",
                          });
                          this.handleSetCover("NoCover");

                          this.showChooseLayout("Print", res);
                        }}
                      />
                    </div>
                  )}
                </div>
                {!isMobileOnly && (
                  <div className="es__product__hovered pos-relative es__bordered-box">
                    <img
                      className="es__basket-icon"
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/add-to-basket.png"
                      alt="basket_icon"
                      onClick={() => {
                        this.props.addToCart({
                          product: res,
                          productType: "J",
                          assetOrmemo: this.props.basketInputObj.assetOrmemo,
                        });
                      }}
                    />

                    <img
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                      alt="Email"
                      className="print__share-icon share-icons"
                      onClick={() => {
                        this.props.setBasketFormInput({
                          includePrice: false,
                          includeRetail: false,
                          includeWholesale: false,
                        });
                        this.props.setBasketFormInput({
                          includeLinks: "No",
                        });
                        this.handleSetCover("NoCover");
                        this.handleIncludeGIA("No");
                        this.handleEmail(res);
                      }}
                    />
                    <img
                      src={PDF}
                      alt="Email"
                      className="mail__share-icon share-icons"
                      onClick={() => {
                        this.props.setBasketFormInput({
                          includePrice: false,
                          includeRetail: false,
                          includeWholesale: false,
                        });
                        this.props.setBasketFormInput({
                          includeLinks: "No",
                        });
                        this.handleSetCover("NoCover");
                        this.handleIncludeGIA("No");
                        this.showChooseLayout("Print", res);
                      }}
                    />
                    {/*<img
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/text.svg"
                      alt="SMS"
                      className="txt__share-icons share-icons"
                      onClick={() => this.handleTextMsg(msgBody)}
                    />
                    <img
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/whatsapp.svg"
                      alt="Whatsapp"
                      className="whatsapp__share-icons share-icons"
                      onClick={() => this.handleWhatsApp(msgBody)}
                    /> */}
                  </div>
                )}
              </div>
              <div className="product-image_container pos-relative">
                <div className="product-image_box ">
                  <h5 className="product-info_box_heading product-dark_title_color">
                    <div>
                      <span
                        className="iframe-link"
                        onClick={() =>
                          this.props.toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                          })
                        }
                      >
                        {isValueEmpty(res.SerialNumber)}
                      </span>
                      {isValueEmpty(res.EditorialVideo) ? (
                        <img
                          onClick={() =>
                            this.props.toggleIframeModal({
                              show: true,
                              url: "",
                              editorial: res.EditorialVideo,
                              // editorial: "35d7c305934139786d1e06833d4a5aaa",
                            })
                          }
                          className="iframe-link"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                          height="14px"
                          width="20px"
                          alt="product"
                        />
                      ) : (
                        ""
                      )}
                      {isValueEmpty(res.SerialVideoLink) ? (
                        <img
                          onClick={() =>
                            this.props.toggleIframeModal({
                              show: true,
                              url: res.SerialVideoLink,
                              editorial: "",
                            })
                          }
                          className="iframe-link"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                          height="14px"
                          width="20px"
                          alt="product"
                        />
                      ) : (
                        ""
                      )}
                      {res.WebImage1 && (
                        <img
                          className="web-image-icon"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/White-Background-Available.svg"
                          alt="web Icon"
                          onClick={() =>
                            this.props.showZoomImage(res, "onWebImageIcon")
                          }
                        />
                      )}
                    </div>
                  </h5>
                  <div className="product-image_background">
                    <div
                      className={
                        isValueEmpty(res.LargeImageFileID)
                          ? "product-image clickable"
                          : "product-image not-clickable"
                      }
                    >
                      {thumbnailImage(res).src ? (
                        <img
                          className="product-image-icon"
                          src={thumbnailImage(res).src}
                          onClick={() =>
                            this.props.showZoomImage(res, "onImage")
                          }
                          alt="product"
                        />
                      ) : (
                        <ReactImageFallback
                          fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                          // initialImage="loader.gif"
                          alt=""
                          className="product-image-icon"
                        />
                      )}

                      <div
                        className={cx({
                          "product-status": true,
                          "text-capitalize": true,
                          RTV: res.IsRtv === "1",
                          "in-production": res.SerialStatus === "In Production",
                          adjusted: res.SerialStatus === "Adjusted Out",
                          mounted: res.IsMounted === "1",
                          sold: res.IsSold === "1",
                          "SOLD-OH": res.IsOH === "1" && res.IsSold === "1",
                          semimount: res.IsSemimount === "1",
                          ponumber: res.isOpenJob === "1",
                          "is-showcase": res.InShowCase === "1",
                        })}
                        onClick={(e) =>
                          res.IsSold === "1"
                            ? this.props.showSoldModal(e, res)
                            : ""
                        }
                      >
                        {res.IsSold === "1" ? "Sold" : ""}
                        {res.IsOH === "1" && res.IsSold === "1" ? "-OH" : ""}
                        {res.IsRtv === "1" ? "RTV" : ""}
                        {res.SerialStatus === "In Production"
                          ? "In Production"
                          : ""}
                        {res.IsSemimount === "1" ? "Semimount" : ""}
                        {res.SerialStatus === "Adjusted Out"
                          ? "Adjusted Out"
                          : ""}
                        {res.IsMounted === "1" && res.IsSold !== "1"
                          ? "mounted"
                          : ""}
                        {res.isOpenJob === "1" && res.PONumber !== null
                          ? `ON ORDER`
                          : ""}
                        {res.isOpenJob === "1" && res.PONumber === null
                          ? `STOCK CREATE`
                          : ""}
                        {res.InShowCase === "1" && res.InShowCase === "1"
                          ? "In Showcase"
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section id="main" className="product-d-flex-container">
                <div className="product-details pos-relative">
                  <div className="product-info_box">
                    <h5 className="product-stylenumber_heading product-dark_title_color">
                      <div>
                        <span
                          className="iframe-link"
                          onClick={() =>
                            this.props.toggleIframeModal({
                              show: true,
                              url: `${BaseURL}/pages/in/in202500.aspx?PopupPanel=On&InventoryCD=${res.StyleNumber}`,
                            })
                          }
                        >
                          {isValueEmpty(res.StyleNumber)}
                        </span>
                        {res.IsTiara === "1" ||
                        res.IsKWCushion === "1" ||
                        res.IsKwiatRadiant === "1" ||
                        res.IsKwiatEmeraldCut === "1" ||
                        res.IsKwiatOval === "1" ||
                        res.IsAshoka === "1" ? (
                          <span className="es__istiara" />
                        ) : (
                          ""
                        )}
                        {res.IsFLRound === "1" ? (
                          <span className="es__isfl" />
                        ) : (
                          ""
                        )}
                        {res.IsFLCushion === "1" ? (
                          <span className="es__isfl" />
                        ) : (
                          ""
                        )}
                        {isValueEmpty(res.EditorialVideo) ? (
                          <img
                            onClick={() =>
                              this.props.toggleIframeModal({
                                show: true,
                                url: "",
                                editorial: res.EditorialVideo,
                              })
                            }
                            className="iframe-link"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                            height="14px"
                            width="20px"
                            alt="product"
                          />
                        ) : (
                          ""
                        )}
                        {isValueEmpty(res.StyleVideoLink) ? (
                          <img
                            onClick={() =>
                              this.props.toggleIframeModal({
                                show: true,
                                url: res.StyleVideoLink,
                                editorial: "",
                              })
                            }
                            className="iframe-link"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                            height="14px"
                            width="20px"
                            alt="product"
                          />
                        ) : (
                          ""
                        )}
                        {res.VendorRefDisplay && (
                          <span className="iframe-link">
                            ({res.VendorRefDisplay})
                          </span>
                        )}

                        {res.FileIdNames && (
                          <img
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                            height="14px"
                            width="20px"
                            onClick={() => this.handleShowFileIdNames(res)}
                          />
                        )}
                        {isValueEmpty(res.TryOnPNGLink) ? (
                          <img
                            onClick={() =>
                              window.open(res.TryOnPNGLink, "_blank")
                            }
                            className="iframe-link"
                            src="https://kwiat.com/wp-content/uploads/try-on-icon-5.svg"
                            height="14px"
                            width="20px"
                            alt="product"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {res.dwebb_vegas === "1" && <span>Kwiat WYNN ONLY</span>}
                      <div style={{ marginLeft: "auto" }}>
                        {res.IsMTS === "1" && (
                          <span>
                            <img
                              src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/mts-icon.png"
                              height="16px"
                              width="33px"
                              style={{ cursor: "inherit" }}
                            />
                          </span>
                        )}
                      </div>
                    </h5>

                    <div
                      className="product-info_background mobile-view"
                      style={
                        res.IsTiara === "1" || res.IsKWCushion === "1"
                          ? { paddingTop: "8px" }
                          : {}
                      }
                    >
                      <div className="product-description">
                        {/* <ShowMore
                          lines={1}
                          more="&#43;"
                          less="&#8722;"
                          anchorClass="product-description_accordion"
                        > */}
                        <span className="product-description-con">
                          {isValueEmpty(res.ShortDescription)
                            ? res.ShortDescription
                            : res.Description}
                        </span>
                        {/* </ShowMore> */}
                        {res.LongDescription ? (
                          <span
                            onClick={() => {
                              this.showExtendedDetailModal(res);
                            }}
                          >
                            {" "}
                            +{" "}
                          </span>
                        ) : (
                          ``
                        )}
                      </div>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.ItemSubtype)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(res, "ItemSubtype")}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <p
                            className={
                              isValueEmpty(res.DiamondCarats)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <span className="diamond-carats-con">
                              {isValueEmpty(res.DiamondCarats)}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.DiamondCarats)
                                ? " cts dia "
                                : ""}
                            </span>
                            <span className="color-carats-con">
                              {isValueEmpty(res.ColorCarats)}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.ColorCarats)
                                ? " cts color "
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.Maker)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.Maker)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.RingSize)
                                ? "known flex-con product-details-label "
                                : "unknown flex-con  product-details-label"
                            }
                          >
                            <span className="prouct-description_info">
                              {isValueEmpty(res.RingSize)}
                            </span>
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.Collection)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.Collection)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.Color)
                                ? "known product-details-label "
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isMultipleValueEmpty(
                                  res,
                                  "ColorClarity"
                                ).replace(/\s+/g, " ")}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.Period)
                                ? "known product-details-label "
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.Period)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_m-rcol">
                          <div
                            className={
                              isValueEmpty(res.Length) ||
                              isValueEmpty(res.BangleSize) ||
                              isValueEmpty(res.Diameter)
                                ? "known flex-con  product-details-label "
                                : "unknown flex-con product-details-label"
                            }
                          >
                            <span className="prouct-description_info">
                              {isValueEmpty(res.Length)}
                              {isValueEmpty(res.BangleSize)}
                              {isValueEmpty(res.Diameter)}
                              {isValueEmpty(res.HoopDiameter)}
                            </span>
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="product-details_lcol">
                          <div
                            className={
                              isValueEmpty(res.Metal)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.Metal)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div
                            className={
                              isValueEmpty(res.ColorComment)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="product-description_info">
                                {isMultipleValueEmpty(
                                  res,
                                  "DiamondDetails"
                                ).replace(/\s+/g, " ")}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_m-lcol">
                          <div
                            className={
                              isValueEmpty(res.CircaDate)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="prouct-description_info">
                                {isValueEmpty(res.CircaDate)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_m-rcol ">
                          <div
                            className={
                              isValueEmpty(res.WidthOD)
                                ? "known product-details-label p-left"
                                : "unknown product-details-label"
                            }
                          >
                            <span>{isValueEmpty(res.WidthOD)}</span>
                          </div>
                        </div>

                        <span
                          className="show-more"
                          onClick={() => {
                            this.showMoreModal(res);
                          }}
                        >
                          +
                        </span>
                      </section>

                      <div className="product-description">
                        <span className="product-description-con">
                          {isMultipleValueEmpty(res, "CenterDetails")}
                        </span>
                      </div>
                    </div>
                    <MobileProductDetails
                      res={res}
                      handleProductModal={this.props.showMobileProductModal}
                    />
                  </div>
                </div>
                {/* pricing */}
                <div className="product-pricing_details pos-relative mobile-view">
                  <div className="product-info_box">
                    <h5 className="product-info_box_heading product-light_title_color">
                      <span
                        className="pricing-title"
                        onClick={(e) => this.props.showStockPopUpModal(e, res)}
                      >
                        Pricing
                      </span>
                    </h5>
                    <div className="product-info_background">
                      <div className="product-pricing-detail">
                        <div className="product-pricing-con">
                          <p
                            className={
                              isValueEmpty(res.RetailPrice)
                                ? "known product-details-label"
                                : "unknown product-details-label"
                            }
                          >
                            <span className="product-details_line-break">
                              {isMultipleValueEmpty(
                                res.RetailPrice,
                                "RetailPrice"
                              )}
                            </span>
                            <span className="product-details-view-label">
                              Retail
                            </span>
                          </p>
                          {this.props.basketInputObj.showWholesale && (
                            <p
                              className={
                                isValueEmpty(res.WholesalePrice)
                                  ? "known product-details-label"
                                  : "unknown product-details-label unknown-status-margin-jewelry"
                              }
                            >
                              <span className="product-details_line-break">
                                {isMultipleValueEmpty(res, "WholesalePrice")}
                              </span>

                              <span className="product-details-view-label">
                                Wholesale
                              </span>
                            </p>
                          )}
                          <p
                            className={
                              isValueEmpty(res.PricingDate)
                                ? "known product-details-label"
                                : "unknown product-details-label unknown-status-margin-jewelry"
                            }
                          >
                            <span className="product-details_line-break">
                              {isValueEmpty(res.PricingDate)
                                ? moment(new Date(`${res.PricingDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              Price Date
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* status */}
                <div className="product-status_details pos-relative mobile-view">
                  {activeTabKey === "serial" ? (
                    <SerialStatusResult res={res} isValueEmpty={isValueEmpty} />
                  ) : (
                    <StyleWebResult res={res} />
                  )}
                </div>

                {/* Holding Notes */}
                <div className="holding-details pos-relative mobile-view">
                  <div className="product-info_box">
                    <h5 className="product-info_box_heading product-light_title_color">
                      <span
                        onClick={() =>
                          this.props.toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                          })
                        }
                      >
                        Hold Notes
                      </span>
                    </h5>
                    {/* holdNotesAccess */}
                    {this.props.basketInputObj.holdNotesAccess && (
                      <div class="hold_notes_actions">
                        {!res._id.toString().includes("JOB") && res.HoldNoteID && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Edit">
                                Edit
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() => {
                                this.setState({ showHoldNotesEditModal: true });
                                this.handleHoldNotesModals(
                                  "",
                                  true,
                                  "createHoldNotes",
                                  res
                                );
                              }}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </OverlayTrigger>
                        )}
                        {!res._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Create">
                                Create
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() =>
                                this.handleHoldNotesModals(
                                  res.InventoryID.toString() +
                                    res.SerialNumber.toString(),
                                  true,
                                  "createHoldNotes",
                                  res
                                )
                              }
                            >
                              +
                            </span>
                          </OverlayTrigger>
                        )}
                        {res.HoldNoteID && !res._id.toString().includes("JOB") && (
                          <>
                            <OverlayTrigger
                              overlay={
                                <Tooltip className="tooltip" id="Archive">
                                  Archive
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span
                                onClick={() => {
                                  if (res?.MTSCustomerExists === "Yes") {
                                    this.setState({
                                      ...this.state,
                                      archivePopUp: true,
                                    });
                                  } else {
                                    this.handleHoldNoteArchive(res)
                                  }
                                  // this.setState({
                                  //   ...this.state,
                                  //   archivePopUp: true,
                                  // });
                                  //this.handleHoldNoteArchive(res)
                                }}
                              >
                                x
                              </span>
                            </OverlayTrigger>
                            <Modal
                              show={this.state.archivePopUp}
                              onHide={() => {}}
                              animation={false}
                              // dialogClassName="show-more_modal-dailog"
                              className="hold_notes_create_modal"
                              aria-labelledby="contained-modal-title-vcenter"
                              style={{
                                position: "fixed",
                                left: "20%",
                                top: "20%",
                                width: "60%",
                              }}
                            >
                              <Modal.Body>
                                <div>
                                  <p>Do you want to blank out MTS Customer ?</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "end",
                                    }}
                                  >
                                    <Button
                                      variant="secondary"
                                      style={{ marginRight: "2vw" }}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            ...this.state,
                                            archivePopUp: false,
                                            blankMTSCustomer: false,
                                          },
                                          () => {
                                            if (res)
                                              this.handleHoldNoteArchive(res);
                                          }
                                        );
                                      }}
                                    >
                                      NO
                                    </Button>
                                    <Button
                                      variant="secondary"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            ...this.state,
                                            archivePopUp: false,
                                            blankMTSCustomer: true,
                                          },
                                          () => {
                                            if (res)
                                              this.handleHoldNoteArchive(res);
                                          }
                                        );
                                      }}
                                    >
                                      YES
                                    </Button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>
                        )}
                        {!res._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="View">
                                View
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={(e) =>
                                this.handleHoldNotesModals(
                                  res.InventoryID.toString() +
                                    res.SerialNumber.toString(),
                                  true,
                                  "archivedHoldNotes",
                                  ""
                                )
                              }
                            >
                              <i class="fa fa-history" aria-hidden="true"></i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                    <div className="product-info_background">
                      <section className="responsive-hold-notes">
                        <div className="product-details_lcol">
                          <div className="unknown product-details-label">
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="entered-by">
                                {isValueEmpty(res.HoldEnteredByName)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div className="unknown product-details-label">
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              <span className="customer-name">
                                {isValueEmpty(res.HoldCustomerName)}
                              </span>
                            </ShowMore>
                          </div>
                        </div>
                      </section>
                      <section className="responsive-hold-notes">
                        <div className="product-details_lcol">
                          <div className="unknown product-details-label">
                            <span className="hold-date">
                              {isValueEmpty(res.HoldDate)
                                ? moment(new Date(`${res.HoldDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.HoldDate) ? " Hold Date" : ""}
                            </span>
                          </div>
                        </div>
                        <div className="product-details_mcol">
                          <div className="unknown product-details-label">
                            <span className="release-date">
                              {isValueEmpty(res.ReleaseDate)
                                ? moment(new Date(`${res.ReleaseDate}`)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                            <span className="product-details-view-label">
                              {isValueEmpty(res.ReleaseDate)
                                ? " Release Date"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </section>
                      <div className="holding-description">
                        <ShowMore
                          lines={3}
                          more="&#43;"
                          less="&#8722;"
                          anchorClass="holding-description_accordion"
                        >
                          <span className="holding-con">
                            {isValueEmpty(res.HoldText)}
                          </span>
                        </ShowMore>
                      </div>
                      {res.IsHoldSold === "1" && (
                        <div className="is_hold_sold">SOLD</div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        })}
        {/* </LoadingOverlay> */}
        <MoreDetailsModal
          show={this.state.showMoreModal}
          hide={this.hideMoreModal}
          res={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes[0]
              : ""
          }
        />
        <ExtendedDetailModal
          show={this.state.showExtendedDetailModal}
          hide={this.hideExtendedDetailModal}
          res={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes[0]
              : ""
          }
        />
        <ShowFileModal
          show={this.state.showFileModal}
          hide={this.hideFileModal}
          fileData={this.state.fileData}
        />
        <PreviewEmailModal
          show={this.state.showPreviewModal}
          hide={this.hidePreviewModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          handleCurrentRes={this.handleCurrentRes}
          filename={"Kwiat-Fred-Leighton"}
          includeGIA={this.state.includeGIA}
          coverSelected={this.state.coverSelected}
          // user={this.stat}
        />
        <PDFModal
          show={this.state.showPDFModal}
          hide={this.hidePDFModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          handleCurrentRes={this.handleCurrentRes}
          filename={"KWFL"}
          includeGIA={this.state.includeGIA}
          coverSelected={this.state.coverSelected}
          type="SingleItem"
          isMultipleValueEmpty={isMultipleValueEmpty}
        />
        <ChooseLayoutModal
          show={this.state.showChooseLayout}
          hide={this.hideChooseLayout}
          setLayout={this.handleSetLayout}
          setCover={this.handleSetCover}
          showPreviewModal={this.showPreviewModal}
          showPDFModal={this.showPDFModal}
          goto={this.state.goto}
          showChooseLayout={this.showChooseLayout}
          coverType={this.state.coverType}
          includeGIA={this.state.includeGIA}
          handleIncludeGIA={this.handleIncludeGIA}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          covers={this.state.covers}
          handleSelectedCover={this.handleSelectedCover}
          coverCategories={this.state.coverCategories}
          coverSelected={this.state.coverSelected}
          includeKTDShapes={this.state.includeKTDShapes}
          handleIncludeKTDShapes={this.handleIncludeKTDShapes}
        />
        <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleIframeModal,
      toggleLoader,
      addToCart,
      setToken,
      setBasketFormInput,
      setEmployeeData,
      setFeedsHoldData,
      setJewelryHoldData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OriginalAppResults);
