import React from 'react';

class IndexRoute extends React.PureComponent {

  componentDidMount() {
    let appEl = document.getElementById('root');
    let defaultRoute = appEl.dataset.default_route;
    let redirectTo = defaultRoute || '/jewelry-search/serial'
    let { history } = this.props
    setTimeout(() => {
      history.push(redirectTo)
    }, 200);
  }

  render() {
    return (
      <div className="AppLoader" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center'
      }}>
        <div>
        </div>
      </div>
    );
  }
}
export default IndexRoute;
