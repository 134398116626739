import React from "react";
import StudsDashboard from "./components/StudsDashboard";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
const JewelrySearch = () => {
  return (
    <div id="jewelry-search" className="page">
      <StudsDashboard />
      <div className="es-scroll-button">
        <ScrollUpButton />
      </div>
    </div>
  );
};

export default JewelrySearch;
