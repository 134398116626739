import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Main from './Main';
import {
  DiamondSerialApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  SerialDataField,
  StyleDataField,
  MountedStock,
  DiamondCaratWeight,
  StoneRatio,
  DiamondSearchKeyword,
} from "../../utils/constants";
import Header from '../../components/Header';

class DiamondDashboard extends Component {
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.state = {
      isLoading: false,
      activeTabKey: 'serial',
      title: 'Diamond'
    };
  }
  componentDidMount() {
    let pathName = this.props.location.pathname;
    pathName === '/diamond-search' &&
      this.props.history.push(`/diamond-search/serial`);
  }
  onTabChange(tabKey) {
    this.props.history.push(`/diamond-search/${tabKey}`);
    this.setState({
      isLoading: true,
      activeTabKey: tabKey
    });
    // Simulate ajax calls
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 800);
  }

  render() {
    const { activeTabKey, isLoading, title } = this.state;
    let tabContent = {
      serial: (
        <div className="TabContentLoader">
          <i
            className="fa fa-spinner fa-2x fa-spin"
            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
          />
        </div>
      ),
      style: (
        <div className="TabContentLoader">
          <i
            className="fa fa-spinner fa-2x fa-spin"
            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
          />
        </div>
      )
    };
    if (!isLoading) {
      tabContent = {
        serial: (
          <Main
            app={DiamondSerialApp}
            url={AppbaseAppUrl}
            credential={AppbaseCredentials}
            activeTabKey={activeTabKey}
            data={SerialDataField}
            styleData={StyleDataField}
            mountedStock={MountedStock}
            diamondCaratWeight={DiamondCaratWeight}
            StoneRatio={StoneRatio}
            keyword={DiamondSearchKeyword}
          />
        ),
        style: (
          <Main
            app={DiamondSerialApp}
            url={AppbaseAppUrl}
            credential={AppbaseCredentials}
            activeTabKey={activeTabKey}
            data={SerialDataField}
            styleData={StyleDataField}
            mountedStock={MountedStock}
            diamondCaratWeight={DiamondCaratWeight}
            StoneRatio={StoneRatio}
            keyword={DiamondSearchKeyword}
          />
        )
      };
    }
    return (
      <div className="dashboard">
        <div className="main-content">
          <div className="header-con">
            <Header title={title}  activeTabKey= {activeTabKey} onTabChange = {this.onTabChange} />
          </div>
        </div>
        <div className="body-con">
          {activeTabKey === 'serial' && tabContent.serial}
          {activeTabKey === 'style' && tabContent.style}
        </div>
      </div>
    );
  }
}
export default withRouter(DiamondDashboard);
