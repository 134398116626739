
import React, { Component } from "react";
import { SingleList } from "@appbaseio/reactivesearch";

class IsMTSDiamond extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <SingleList
                className="toggle__filters"
                componentId="MTSOnly"
                dataField="IsMTS.keyword"
                showSearch={false}
                defaultValue={"1"}
                showFilter={false}
                sortBy="asc"
                render={({ data, value, handleChange }) => {
                    var checkedFlag;
                    if (value === "1") {
                        checkedFlag = true;
                    } else {
                        checkedFlag = false;
                    }
                    if (data.length === 1) {
                        return (
                            <ul>
                                <li></li>
                                <li>
                                    <input
                                        type="checkbox"
                                        value={1}
                                        onChange={(e)=>{
                                            // handleChange(e)
                                        }}
                                        checked={checkedFlag || true}
                                    />
                                    <span className="toggle--label">Is MTS</span>
                                </li>
                            </ul>
                        );
                    } else {
                        return (
                            <ul>
                                {data.map(item => (
                                    <li key={item.key}>
                                        <input
                                            type="checkbox"
                                            value={item.key}
                                            onChange={(e)=>{
                                                // handleChange(e)
                                            }}
                                            checked={checkedFlag || true}
                                        />
                                        <span className="toggle--label">Is MTS</span>
                                    </li>
                                ))}
                            </ul>
                        );
                    }
                }}
            />
        );
    }
}
export default IsMTSDiamond;

