import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class MoreDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: this.props.res,
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    let { hide } = this.props;
    this.setState(() => {
      hide && hide();
    });
  }

  render() {
    let { show, res } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => this.onModalHide()}
        animation={false}
        dialogClassName="show-more_modal-dailog"
        className="more-details-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dimensions:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="item__description__box">
            <table className="modal-table" width="100%">
              <tbody>
                <tr>
                  <td>{res.RingSize ? `Ring Size: ${res.RingSize}` : " "}</td>
                </tr>
                <tr>
                  <td>
                    {res.ClosureType ? `Closure Type: ${res.ClosureType}` : " "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {res.Length ? `Length: ${res.Length}` : " "}
                    {res.BangleSize ? `Bangle Size: ${res.BangleSize}` : ""}
                    {res.Diameter ? `Diameter: ${res.Diameter}` : " "}
                    {res.HoopDiameter
                      ? `Hoop  Diameter: ${res.HoopDiameter}`
                      : " "}
                  </td>
                </tr>
                <tr>
                  <td>{res.Height ? `Height: ${res.Height}` : ""}</td>
                </tr>
                <tr>
                  <td>{res.WidthOD ? `Width OD: ${res.WidthOD}` : " "}</td>
                </tr>
                <tr>
                  <td>
                    {res.DropLength ? `Drop Length: ${res.DropLength}` : " "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {res.CaseLength ? `Case Length: ${res.CaseLength}` : " "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {res.CaseWidth ? `Case Width: ${res.CaseWidth}` : " "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {res.ElementLength
                      ? `Ele Length: ${res.ElementLength}`
                      : " "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {res.ElementWidth ? `Ele Width: ${res.ElementWidth}` : " "}
                  </td>
                </tr>
                <tr>
                  <td>{res.FlushBand ? `FlushBand: ${res.FlushBand}` : " "}</td>
                </tr>
                <tr>
                  <td>
                    {res.SizingNotes ? `Sizing Notes: ${res.SizingNotes}` : " "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
