import React, { Component } from "react";
import Downshift from "downshift";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import Appbase from "appbase-js";
import { setBasketFormInput, setToken } from "../../actions/index";
import { debounce } from "lodash";
import {
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  insightlyApi,
  insightlyApiKey,
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseContactApp,
} from "../../utils/constants";
import GetAuthToken from "../Api/Authenticate";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
    tokenState: state.tokenState,
    groups: state.SetGroups.groups,
  };
};

class ContactSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      customerSelected: props.basketInputObj.customer,
      contactUpdating: props.basketInputObj.contact.DisplayName || "",
    };

    this.fetchContacts = this.fetchContacts.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.downshiftOnChange = this.downshiftOnChange.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 700);
  }

  async componentDidMount() {
    let url = window.location.href;
    if (!url.includes("kim.kwiat.com")) {
      let urlString = window.location.href;
      let paramString = urlString.split("?")[1];
      let queryString = new URLSearchParams(paramString);
      // console.log({ queryString });
      let contact = {};

      for (let pair of queryString.entries()) {
        // console.log({ pair });
        // console.log("Key is: " + pair[0]);
        // console.log("Value is: " + pair[1]);
        if (pair[0] === "ContactEmail") {
          let value = pair[1].replace(/"/g, "");
          contact["ContactEmail"] = value;
          // this.inputOnChange(pair[1]);
          // this.setState({ customerName: pair[1] });
          // this.props.setBasketFormInput({
          //   contact: { DisplayName: value },
          // });
          // let res = await this.fetchContacts(value);
          // console.log({ res });
          // if (res.length !== 0) {
          //   this.props.setBasketFormInput({ contact: res[0] });
          // }

          // this.setState({ showBasketModal: pair[1] === "true" && true });
          // document.location.reload(true);
        } else if (pair[0] === "ContactName") {
          let value = pair[1].replace(/"/g, "");
          contact["DisplayName"] = value;
        } else if (pair[0] === "ContactID") {
          let value = pair[1].replace(/"/g, "");
          contact["ContactId"] = value;
        }
      }
      if (Object.keys(contact).length !== 0) {
        this.props.setBasketFormInput({ contact: contact });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.basketInputs !== nextProps.basketInputObj) {
      this.setState({
        contactUpdating: nextProps.basketInputObj.contact.DisplayName,
        customerSelected: nextProps.basketInputObj.customer,
      });
    }
  }

  inputOnChange(value) {
    this.setState({
      contactUpdating: value,
    });
    this.onChangeDebounced(value);
  }
  async onChangeDebounced(val) {
    this.setState({
      contactUpdating: val,
    });
    var respo = await this.fetchContacts(val);
    // console.log("contact respo: ",respo)
    this.setState({ contacts: respo });
  }

  downshiftOnChange(selected) {
    // console.log("Contact Selected: ", selected);
    if (selected) {
      this.props.setBasketFormInput({ contact: selected });
      this.setState({
        contactUpdating: selected.DisplayName,
      });
    }
  }

  async fetchContacts(val) {
    // if (Object.keys(this.props.groups).length === 0)
    let url = window.location.href;
    if (url.includes("kim.kwiat.com")) {
      console.log("contact val: ", val);
      var custobj = this.state.customerSelected;
      // var token = this.props.tokenState.token;
      var custId;
      if (custobj.CustomerId) {
        custId = custobj.CustomerId;
      } else {
        custId = null;
      }
      // var payload = {
      //   data: {
      //     defaults: {
      //       baseURL: basketBaseUrl,
      //       token: token,
      //     },
      //     inputs: {
      //       CustomerID: { value: custId },
      //       Contact: { value: val },
      //     },
      //   },
      // };
      // var response = await axios.post(ApiBaseUrl + "contact", payload, {
      //   headers: {
      //     "x-api-key": ApiKey,
      //   },
      // });
      // if (
      //   response.data &&
      //   response.data.statusCode === 200 &&
      //   JSON.parse(JSON.parse(response.data.body).Contacts.value)
      // ) {
      //   // console.log(
      //   //   "Contact Response: ",
      //   //   JSON.parse(JSON.parse(response.data.body).Contacts.value)
      //   // );
      //   return JSON.parse(JSON.parse(response.data.body).Contacts.value);
      // } else if (JSON.parse(response.data.body).errorCode === 401) {
      //   let token = await GetAuthToken();
      //   if (token) {
      //     this.props.setToken(token.access_token);
      //     await this.onChangeDebounced(val);
      //   } else {
      //     return [];
      //   }
      // } else {
      //   return [];
      // }
      const appbaseRef = Appbase({
        app: AppbaseContactApp,
        url: AppbaseAppUrl,
        credentials: AppbaseCredentials,
      });
      let response = await appbaseRef
        .search({
          body: {
            query: {
              ...(custId
                ? {
                    bool: {
                      must: [
                        {
                          match_phrase: {
                            // match_phrase: {
                            // match: {
                            CustomerID: {
                              query: custId,
                            },
                          },
                        },
                        {
                          // prefix: {
                          //   DisplayName: val,
                          // match_phrase_prefix: {
                          // match_phrase: {
                          match: {
                            DisplayName: {
                              query: val,
                            },
                          },
                        },
                      ],
                    },
                  }
                : {
                    // prefix: {
                    //   DisplayName: val,
                    // match_phrase_prefix: {
                    // match_phrase: {
                    match: {
                      DisplayName: {
                        query: val,
                      },
                    },
                  }),
            },
            size: 10000,
          },
        })
        .then(function (res) {
          // console.log("Templates results: ", res);
          return res;
        })
        .catch(function (err) {
          console.log("contact error: ", err);
          return err;
        });

      if (response && response.hits.hits && response.hits.hits.length !== 0) {
        return response.hits.hits.map((res) => {
          return res._source;
        });
      } else {
        // this.setState({ customers: [] });

        return [];
      }
    } else if (this.props.basketInputObj.customer.ORGANISATION_ID || val) {
      let org_ID = this.props.basketInputObj.customer.ORGANISATION_ID;

      var payload = {
        data: {
          Org_ID: org_ID,
          contact: val,
        },
      };
      var response = await axios.post(
        insightlyApi + "get_contact_insightly",
        payload,
        {
          headers: {
            "x-api-key": insightlyApiKey,
          },
        }
      );
      // console.log("contact response : ", response)
      if (
        response.data &&
        response.data.statusCode &&
        response.data.statusCode === 200 &&
        response.data.data &&
        response.data.data.length !== 0
      ) {
        // let filteredData = response.data.data.filter((data)=> data.DisplayName.toLowerCase().includes(val.toLowerCase()))
        // return filteredData
        return response.data.data;
      } else if (
        !response.data ||
        !response.data.statusCode ||
        response.data.data.length === 0
      ) {
        await this.onChangeDebounced(val);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  render() {
    let { contactUpdating, contacts } = this.state;
    return (
      <Downshift
        onChange={this.downshiftOnChange}
        itemToString={(item) => (item ? item.DisplayName : "")}
        inputValue={contactUpdating ? contactUpdating : ""}
      >
        {({
          selectedItem,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          inputValue,
          clearSelection,
        }) => (
          <div className="basket__input customer__input form-group has-feedback">
            <label className="control-label" htmlFor="customer">
              Contact:
            </label>
            <div className="input__dropdown__container">
              <div className="input__container">
                <input
                  type="text"
                  className="form-control"
                  id="contact"
                  required
                  {...getInputProps({
                    placeholder: "Contact Quicksearch",
                    onChange: (e) => {
                      this.inputOnChange(e.target.value, selectedItem);
                      if (e.target.value === "") {
                        clearSelection();
                        this.setState({
                          contactUpdating: "",
                        });
                        this.props.setBasketFormInput({ contact: {} });
                      }
                    },
                  })}
                />
                <span className="form-control-feedback">
                  <img
                    src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/customer-search.png"
                    alt="contact search"
                  />
                </span>
              </div>
              {isOpen ? (
                <ul className="downshift-dropdown">
                  {contacts
                    .filter(
                      (item) =>
                        !inputValue ||
                        item.DisplayName.toLowerCase().includes(
                          inputValue.toLowerCase()
                        )
                    )
                    .slice(0, 20)
                    .map((item, index) => (
                      <li
                        className="dropdown-item"
                        {...getItemProps({ key: index, index, item })}
                        style={{
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        }}
                      >
                        {item.DisplayName} - {item.ContactEmail}
                      </li>
                    ))}
                </ul>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBasketFormInput,
      setToken,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearch);
