import React, { Component } from 'react';
import { MultiDropdownList } from '@appbaseio/reactivesearch';
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class ItemType extends Component {
  render() {
    let { appType } = this.props;
    return (
      <div className="item-type">
        <MultiDropdownList
          className="form-field"
          title={appType === "original" ? "Type" : "Item Type"}
          componentId="ItemType"
          dataField={"ItemType.keyword"}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={label => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          placeholder={appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}


export default MultiDropdownListWithFocus(ItemType);