import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class ItemSubtype extends Component {
  render() {
    let { appType } = this.props;
    return (
      <div className="item-subtype">
        <MultiDropdownList
          className="form-field"
          title={appType === "original" ? "SubType" : "Item Subtype"}
          componentId="SubType"
          dataField={"ItemSubtype.keyword"}
          react={{ and: ["ItemType"] }}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          placeholder={appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(ItemSubtype);
