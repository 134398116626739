import React from "react";
import { useSelector } from "react-redux";

export default function BasketMobileHeader({
  onModalHide,
  orderNbr,
  handleAcumaticaExport,
}) {

  let groups = useSelector((state) => state.SetGroups.groups)
  let url = window.location.href
  // if (!url.includes("kim.kwiat.com"))

  return (
    <React.Fragment>
      <div className="es__basket-title">
        <h3>BASKET</h3>
        <span className="basket__name">#{orderNbr}</span>
      </div>
     { url.includes("kim.kwiat.com")? (<div
        className="basket__export__group"
        id="basket-export"
        style={{ display: "flex" }}
      >
        <button
          className="acumatica__export"
          onClick={() => handleAcumaticaExport(orderNbr)}
        >
          <img
            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/open-acumatica.png"
            alt="accumatica export"
          />
        </button>
      </div>):(<></>)}
      <button className="basket__close--btn" onClick={onModalHide}>
        X
      </button>
    </React.Fragment>
  );
}
