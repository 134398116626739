import React, { Component } from "react";
import currencyFormatter from "currency-formatter";

class MobileProductDetails extends Component {
  render() {
    let { res } = this.props;
    return (
      <div className="es__mobile-product">
        <table className="es__mobile-product-details-table">
          <tbody>
            <tr>
              <td>
                {res.Shape && res.Shape !== null
                  ? `${res.Shape} ${res.GemstoneType}`
                  : ""}
              </td>
              <td>
                {res.RetailPrice && res.RetailPrice != null
                  ? currencyFormatter.format(`${res.RetailPrice}`, {
                      code: "USD",
                      precision: 0
                    })
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPrice && res.RetailPrice != null
                    ? "Retail Price"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.CaratWeight && res.CaratWeight !== null
                  ? `${Number(res.CaratWeight).toFixed(2)}cts`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.CaratWeight && res.CaratWeight != null
                    ? "Carat Wt."
                    : ""}
                </span>
              </td>
              <td>
                {res.RetailPriceCode}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPriceCode && res.RetailPriceCode != null
                    ? "Code"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.GemEnhancement && res.GemEnhancement != null
                  ? `${res.GemEnhancement}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.GemEnhancement && res.GemEnhancement != null
                    ? "Enhancement"
                    : ""}
                </span>
              </td>
              <td>
                {res.IsVirtual === "1" ? "Virtual - " : ""}
                {res.IsPhantom === "1" ? "Phantom - " : ""}
                {res.SerialStatus && res.SerialStatus !== null
                  ? ` ${res.SerialStatus}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.SerialStatus && res.SerialStatus != null
                    ? "Location"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.CountryofOrigin && res.CountryofOrigin != null
                  ? `${res.CountryofOrigin}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.CountryofOrigin && res.CountryofOrigin != null ? "Origin" : ""}
                </span>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-default align-self-end"
                  onClick={() => this.props.handleProductModal(res)}
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default MobileProductDetails;
