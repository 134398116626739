import React, { Component } from 'react';
import { MultiDropdownList } from '@appbaseio/reactivesearch';
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class GemstoneType extends Component {
  render() {
    return (
      <div className="gemstonetype">
        <MultiDropdownList
          className="form-field unknown"
          title="Gem Type"
          componentId="GemstoneType"
          dataField={"GemstoneType.keyword"}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          renderListItem={label => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
        />
      </div>
    );
  }
}
export default MultiDropdownListWithFocus(GemstoneType);