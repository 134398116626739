import React, { Component } from "react";

class Switching extends Component {
  constructor(props) {
    super(props);

    this.handleRadioChange = this.handleRadioChange.bind(this);
  }
  
  handleRadioChange(e) {
    this.props.onTabChange(e.target.value);
  }

  render() {
    return (
      <React.Fragment>
        <div className="es__filters__line-break">
          <span className="es__filters-title">JEWELRY SEARCH</span>
        </div>
        <div className="switching">
          <h2 className="options-label">Results by</h2>
          <span>
            <input
              type="radio"
              value="style"
              checked={this.props.activeTabKey === "style"}
              onChange={(e) => this.handleRadioChange(e)}
              id="style"
            />
            <label
              className={
                this.props.activeTabKey === "style"
                  ? "switching__radio-label switching__label--active"
                  : "switching__radio-label"
              }
              htmlFor="style"
            >
              Style
            </label>
          </span>
          <span>
            <input
              type="radio"
              value="serial"
              checked={this.props.activeTabKey === "serial"}
              onChange={(e) => this.handleRadioChange(e)}
              id="serial"
            />
            <label
              className={
                this.props.activeTabKey === "serial"
                  ? "switching__radio-label switching__label--active"
                  : "switching__radio-label"
              }
              htmlFor="serial"
            >
              Serial
            </label>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default Switching;
