import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class WRSetting extends Component {
  render() {
    return (
      <div className="wr-setting">
        <MultiDropdownList
          title={
            this.props.appType === "original"
              ? "WR - Setting"
              : "Setting (WR)"
          }
          className="form-field"
          componentId="WRSetting"
          dataField="SettingType.keyword"
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={this.props.appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(WRSetting);
