import React, { Component } from "react";
import { RangeInput } from "@appbaseio/reactivesearch";
import RangeFilterWithSelect from "./../../components/RangeFilterWithSelect";

class ColorCarat extends Component {
  constructor(props) {
    super(props);
    this.handleSelected = this.handleSelected.bind(this);
  }

  handleSelected(value) {
    if (value.start === 0 && value.end === 100) {
      return;
    } else {
      // console.log("Inside Handle Selected");
      this.props.showSelectedResults(true);
    }
  }
  render() {
    return (
      <div className="carat-range form-field-wrap">
        <RangeInput
          className="carat-range-input"
          innerClass={{
            "slider-container": "carat-range-input__carat-slider",
            "input-container": "carat-range-input__carat-input",
          }}
          title={
            this.props.appType === "original"
              ? "Color Cts "
              : "Carat Total Weight (Color)"
          }
          componentId="ColorCarats"
          showSlider={false}
          dataField="ColorCarats"
          range={{
            start: 0,
            end: 100,
          }}
          customQuery={function(value, props) {
            if (value[0] === 0 && value[1] === 100) {
              return {
                query: {
                  match_all: {},
                },
              };
            } else {
              return {
                query: {
                  range: {
                    ColorCarats: {
                      gte: value[0],
                      lte: value[1],
                      boost: 2,
                    },
                  },
                },
              };
            }
          }}
          onValueChange={(value) => this.handleSelected(value)}
        />
      </div>
    );
  }
}
export default RangeFilterWithSelect(ColorCarat);
