import React from "react";
import { Modal } from "react-bootstrap";
import { BaseURL } from "../../utils/constants";

export default function BasketToAcuExportModal (props) {
  const { prNumber, prMessage, show, hide } = props;
  const prNumberPattern = /PR-\d+/g;
  const prNumberMatches = prMessage.match(prNumberPattern);
  
  const clickablePrMessage = prMessage.replace(
    prNumberPattern,
    (match) => `<a href="${BaseURL}/Main?ScreenId=KW801121&PullRequestNbr=${match}" target="_blank">${match}</a>`
  );

  return (
    <div className="modal-body">
      <Modal
        animation={true}
        autoFocus={false}
        enforceFocus={false}
        className="choose-layout-modal"
        centered="true"
        size="lg"
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "red" }}>ONE more step REQUIRED</h4>
        </Modal.Header>
        <Modal.Body>
          {clickablePrMessage.includes("not created") ? null : (
            <div>
              <a
                href={
                  BaseURL +
                  `/Main?ScreenId=KW801121&PullRequestNbr=${prNumber}`
                }
                target="_blank"
              >
                Click here to open the Pull Request{" "}
              </a>
              #{prNumber} and review and RELEASE
            </div>
          )}
          <br />
          <p>{""}</p>
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: clickablePrMessage }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
