import React, { Component } from "react";
import { MultiList } from "@appbaseio/reactivesearch";

class IsOh extends Component {
  render() {
    const { ohSignal, handleOhSignal } = this.props;
    return (
      <div className="toggle__filters">
        <MultiList
          componentId="IncludeOh"
          dataField="IsOH.keyword"
          defaultValue={["0"]}
          queryFormat="or"
          showFilter={true}
          showSearch={false}
          sortBy="asc"
          customQuery={() => {
            if(ohSignal === true){
              // console.log("signal is ", ohSignal);
              return {
                query: {
                  bool: {
                    should: [
                      {
                        term: {
                          "IsOH.keyword": "1",
                        },
                      },
                      {
                        term: {
                          "IsSold.keyword": "0",
                        },
                      },
                    ],
                  },
                },
              } 
            } else{
              return{
                query: {
                  bool:{
                    should: [
                      {
                        terms:{
                          "IsOH.keyword": ["0"]
                        }
                      }
                    ]
                  }
                }
              }
            }
           }}
         
          render={({ data, value, handleChange }) => {
            var checkedFlag;
            value.hasOwnProperty("1")
              ? (checkedFlag = true)
              : (checkedFlag = false);
            if (data.length === 1) {
              return (
                <ul>
                  <li />
                  <li>
                    <input
                      type="checkbox"
                      value={"1"}
                      onChange={handleChange}
                      checked={checkedFlag}
                    />
                    <span className="toggle--label">+ On Hand</span>
                  </li>
                </ul>
              );
            } else {
              return (
                <ul>
                  {data.map((item) => (
                    <li key={item.key}>
                      <input
                        type="checkbox"
                        value={item.key}
                        onChange={handleChange}
                        checked={checkedFlag}
                      />
                      <span className="toggle--label">+ On Hand</span>
                    </li>
                  ))}
                </ul>
              );
            }
          }}
          beforeValueChange={function(value) {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject();
              }
              resolve();
            });
          }}
          onValueChange={(value) => {
            if (value.includes("1")) {
              this.props.handleOhSignal(true);
            } else {
              this.props.handleOhSignal(false);
            }
          }}
        />
      </div>
    );
  }
}

export default IsOh;
