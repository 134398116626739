import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import Appbase from "appbase-js";
import {
  AppbaseEmployeeApp,
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  basketApikey,
  AppbaseCredentials,
  AppbaseAppUrl,
  appbaseBasketUrl,
  AppbaseBasketApp,
} from "../../../utils/constants";
import SaveExisting from "../SaveExisting";
import OpenExisting from "../OpenExisting";
import GetAuthToken from "../../Api/Authenticate";
import {
  resetStates,
  setBasketFormInput,
  toggleLoader,
  openCartItems,
  setAssetOrMemo,
  setToken,
} from "../../../actions/index";
import {
  fetchBasket,
  getUniqueItemWithQty,
  getItemFromAppBase,
} from "../ReadAndOpenBasket";
import Delete from "../../../assets/icons/delete-16.png";
import Pdf from "../../../assets/icons/pdf-16.png";
import Clear from "../../../assets/icons/erase-16.png";
import Refresh from "../../../assets/icons/refresh-16.png";
import Email from "../../../assets/icons/email-12-16.png";
import Open from "../../../assets/icons/open-in-browser-16.png";
import Clone from "../../../assets/icons/copy-16.png";
import SaveAs from "../../../assets/icons/save-as-16.png";
import Save from "../../../assets/icons/save-16.png";
import New from "../../../assets/icons/add-file-16.png";
import toLower from "lodash/toLower";
import {fetchApiData} from "../../../components/holdNotesApiData"

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    tokenState: state.tokenState,
    groups: state.SetGroups.groups,
  };
};

class BasketActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaExpanded: false,
      allBaskets: [],
      myBaskets: [],
      allMyBaskets: [],
      allMyBasketsSaveExisting: [],
      showTemplateButtons: false,
    };

    this.handleMoreActions = this.handleMoreActions.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.handleClone = this.handleClone.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.getContactEmail = this.getContactEmail.bind(this);
    // this.getUserEmail = this.getUserEmail.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleItemRefresh = this.handleItemRefresh.bind(this);
    // this.handleBasketChange = this.handleBasketChange.bind(this);
  }

  // async handleBasketChange(value) {
  //   let { basketInputObj, cartItems } = this.props;
  //   if (basketInputObj.orderNbr === "New") {
  //     this.props.toggleLoader({
  //       isLoading: false,
  //     });
  //     toast.success("New Basket !", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 2000,
  //       pauseOnHover: false,
  //     });
  //     this.props.showChooseLayout(value);
  //     return;
  //   }

  //   this.props.toggleLoader({
  //     isLoading: true,
  //   });

  //   // let token = this.props.tokenState.token;
  //   var data;
  //   var OrderList;
  //   var rawData = await fetchBasket(
  //     // {
  //     basketInputObj.orderNbr
  //     // token: token,
  //     // }
  //   );
  //   // console.log("rawData: ", rawData);
  //   if (!rawData || rawData.statusCode !== 200) {
  //     this.props.toggleLoader({
  //       isLoading: false,
  //     });
  //     return;
  //   }
  //   // if (rawData.errorCode === 401) {
  //   //   let token = await GetAuthToken();
  //   //   if (token) {
  //   //     this.props.setToken(token.access_token);
  //   //     rawData = await fetchBasket({
  //   //       basketSelected: basketInputObj.orderNbr,
  //   //       token: token.access_token,
  //   //     });
  //   //   }
  //   // }
  //   if (
  //     rawData.data._source.OrdersList &&
  //     rawData.data._source.OrdersList.value
  //   ) {
  //     data = rawData.data._source;
  //     // console.log("data: ", data);
  //     OrderList = JSON.parse(data.OrdersList.value.replace(/'/g, '"'));
  //   }
  //   // Appbase call

  //   let uniqueArrayFromBasket = getUniqueItemWithQty(OrderList);
  //   // console.log("uniqueArrayFromBasket: ", uniqueArrayFromBasket);
  //   var fromAppbaseCall = [];
  //   for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
  //     var res = await getItemFromAppBase({
  //       item: uniqueArrayFromBasket[i],
  //       searchOption: "mixed",
  //     });
  //     // console.log("res: ", res);
  //     if (typeof res[0]["_source"] !== "object") {
  //       toast.error("Appbase fetching products error !", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 2000,
  //         pauseOnHover: false,
  //       });
  //       continue;
  //     }
  //     let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
  //     fromAppbaseCall.push({
  //       ...omitted,
  //       RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
  //       WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
  //       quantity: uniqueArrayFromBasket[i].Quantity,
  //       InternalNote: uniqueArrayFromBasket[i].InternalNote,
  //       priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
  //       productType: uniqueArrayFromBasket[i].ProductType,
  //       LineNbr: uniqueArrayFromBasket[i].LineNbr,
  //     });
  //   }
  //   this.props.toggleLoader({
  //     isLoading: false,
  //   });
  //   // sort by LineNbr
  //   // fromAppbaseCall.sort(function(a, b) {
  //   //   return b.LineNbr - a.LineNbr;
  //   // });
  //   // comparison

  //   var localStateObj = {
  //     ContactID:
  //       basketInputObj.contact.ContactId === undefined
  //         ? null
  //         : basketInputObj.contact.ContactId,
  //     ContactName:
  //       basketInputObj.contact.DisplayName === undefined
  //         ? null
  //         : basketInputObj.contact.DisplayName,
  //     CustomerID:
  //       basketInputObj.customer.CustomerId === undefined
  //         ? null
  //         : basketInputObj.customer.CustomerId,
  //     CustomerName:
  //       basketInputObj.customer.Customer === undefined
  //         ? null
  //         : basketInputObj.customer.Customer,
  //     CustomerClass:
  //       basketInputObj.customer.CustomerClass === undefined
  //         ? null
  //         : basketInputObj.customer.CustomerClass,
  //     Description: basketInputObj.desc === "" ? null : basketInputObj.desc,
  //     IncludeRetail: basketInputObj.includeRetail,
  //     IncludeWholesale: basketInputObj.includeWholesale,
  //     InternalNotes:
  //       basketInputObj.internalNotes === ""
  //         ? null
  //         : basketInputObj.internalNotes,
  //     Occasion:
  //       basketInputObj.occasion === "default" ? null : basketInputObj.occasion,
  //     // OrderNbr: basketInputObj.orderNbr,
  //     OrdersList: cartItems.items,
  //   };
  //   var apiStateObj = {
  //     ContactID: data.Contact.value,
  //     ContactName: data.ContactName.value,
  //     CustomerID: data.Customer.value,
  //     CustomerName: data.CustomerName.value,
  //     CustomerClass: data.CustomerClass.value,
  //     Description: data.Description.value,
  //     IncludeRetail: data.IncludeRetailPrice.value,
  //     IncludeWholesale: data.IncludeWholesalePrice.value,
  //     InternalNotes: data.InternalNotes.value,
  //     Occasion: data.Occasion.value,
  //     // OrderNbr: data.OrderNbr.value,
  //     OrdersList: fromAppbaseCall,
  //   };
  //   // console.log("Local data ", localStateObj);
  //   // console.log("API state data ", apiStateObj);
  //   if (JSON.stringify(localStateObj) === JSON.stringify(apiStateObj)) {
  //     toast.success("No channges found !", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 2000,
  //       pauseOnHover: false,
  //     });
  //     this.props.showChooseLayout(value);
  //   } else {
  //     this.props.toggleLoader({
  //       isLoading: false,
  //     });
  //     this.props.showBasketChangeModal(value);
  //   }
  // }

  // async getUserEmail() {
  //   let { basketInputObj, tokenState } = this.props;
  //   let userEmail;
  //   if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector("li.menu-form > div.inline > span:nth-child(3)")
  //       .textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: ["FirstName", "LastName", "Email", "EmployeeLogin"],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     let payload = {
  //       data: {
  //         defaults: {
  //           baseURL: basketBaseUrl,
  //           token: this.props.tokenState.token,
  //         },
  //         inputs: {
  //           EmployeeLogin: {
  //             value: this.props.basketInputObj.user.EmployeeLogin,
  //           },
  //         },
  //       },
  //     };
  //     // console.log("payload: ", payload);
  //     await axios
  //       .post(appbaseBasketUrl + "user-signature", payload, {
  //         headers: {
  //           "x-api-key": basketApikey,
  //         },
  //       })
  //       .then((res) => {
  //         // console.log("resp : ", res);
  //         if (
  //           res.data.statusCode === 200 &&
  //           res.data.body.KWMailSignature.value
  //         ) {
  //           console.log("User signature found.");
  //           this.props.setBasketFormInput({
  //             user: {
  //               ...this.props.basketInputObj.user,
  //               Signature: res.data.body.KWMailSignature.value,
  //             },
  //           });
  //         } else {
  //           console.log("response is empty");
  //         }
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // return JSON.parse(res.data.body);
  //         // return res;
  //       })
  //       .catch((err) => {
  //         // console.log("error : ", err);
  //         console.log("User Signature error.", err);
  //         // return;
  //       });
  //   } else {
  //     console.log("No user");
  //   }
  // }

  async componentDidMount() {
    let uRoleEl = document.getElementById("ctl00_phF_form_edRolename");
    if (!uRoleEl) {
      // return;
    }
    setTimeout(() => {
      if (Object.keys(this.props.groups).length !== 0) {
        let groups = this.props.groups.groups;
        // console.log("groups :", groups);
        if (groups) {
          let accessGroup = groups.filter((group) =>
            [
              "8b471960-ea76-4fe1-a392-3255cda71785",
              "6e9595ab-6424-43e7-a367-91248b278798",
            ].includes(group.id)
          );
          // console.log({ accessGroup });
          if (accessGroup.length !== 0) {
            this.setState({
              showTemplateButtons: true,
            });
          }
        }
        // this.setState({
        //   access: this.props.basketInputObj.access,
        // });
      } else if (uRoleEl) {
        let uRole = document.getElementById("ctl00_phF_form_edRolename").value;
        uRole = toLower(uRole);
        if (
          // uRole.includes("admin") ||
          // uRole.includes("executive") ||
          // uRole.includes("production") ||
          // uRole.includes("elastic")
          uRole.includes("ES Template Admin".toLowerCase())
        ) {
          this.setState({
            showTemplateButtons: true,
          });
        }
        // if (uRole.includes("ADCRM".toLowerCase())) {
        // if (
        //   !uRole.includes("ADCRM".toLowerCase())
        // ) {
        //   this.props.setBasketFormInput({
        //     holdNotesAccess: true,
        //   });
        // }
      }
    }, 1500);
    let empData = await fetchApiData(AppbaseEmployeeApp);
    const loggedUser = JSON.parse(sessionStorage.getItem('reduxState'))
    const loggedUserEmail = loggedUser?.basketInputChange?.user?.Email
    empData.forEach((item)=>{
      if(item?._source?.Email === loggedUserEmail && item?._source?.bulkHoldNotes == "1"){
        this.props.setBasketFormInput({
          holdNotesAccess: true
        });
      }
    })
  }

  async handleItemRefresh(items) {
    this.props.toggleLoader({
      isLoading: true,
    });
    items = items.map((el) => {
      var productArr = {
        SerialNumber: el.SerialNumber,
        StyleNumber: el.StyleNumber,
        InternalNote: el.InternalNote,
        RetailPrice: el.RetailPrice,
        WholesalePrice: el.WholesalePrice,
        Quantity: el.quantity,
        PriceVisibility: el.priceVisibility,
        LinkVisibility: el.linkVisibility,
        ItemSelected: el.itemSelected,
        ProductType: el.productType,
        AssetOrMemo: el.assetOrmemo,
      };
      return productArr;
    });

    let uniqueArrayFromBasket = getUniqueItemWithQty(items);
    // console.log("uniqueArrayFromBasket: ", uniqueArrayFromBasket);
    var fromAppbaseCall = [];
    for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
      var res = await getItemFromAppBase({
        item: uniqueArrayFromBasket[i],
        searchOption: "mixed",
      });
      // console.log("res: ", res);
      if (typeof res[0]["_source"] !== "object") {
        toast.error("Appbase fetching products error !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        continue;
      }
      let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
      fromAppbaseCall.push({
        ...omitted,
        RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
        WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
        quantity: uniqueArrayFromBasket[i].Quantity,
        InternalNote: uniqueArrayFromBasket[i].InternalNote,
        priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
        productType: uniqueArrayFromBasket[i].ProductType,
        linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
          ? uniqueArrayFromBasket[i].LinkVisibility
          : "Default",
        itemSelected: uniqueArrayFromBasket[i].ItemSelected
          ? uniqueArrayFromBasket[i].ItemSelected
          : true,
        LineNbr: uniqueArrayFromBasket[i].LineNbr,
        assetOrmemo:
          uniqueArrayFromBasket[i].AssetOrMemo &&
          uniqueArrayFromBasket[i].AssetOrMemo !== true &&
          uniqueArrayFromBasket[i].AssetOrMemo !== false
            ? uniqueArrayFromBasket[i].AssetOrMemo
            : true,
      });
    }
    this.props.openCartItems(fromAppbaseCall);
    this.props.toggleLoader({
      isLoading: false,
    });
  }

  async getContactEmail() {
    var custobj = this.props.basketInputObj.customer;
    var contobj = this.props.basketInputObj.contact;
    // console.log("Customer details: ", custobj, "\nContact details: ", contobj);
    if (custobj && custobj.CustomerId && contobj && contobj.DisplayName) {
      var token = this.props.tokenState.token;
      var custId;
      if (custobj.CustomerId) {
        custId = custobj.CustomerId;
      } else {
        custId = null;
      }
      var payload = {
        data: {
          defaults: {
            baseURL: basketBaseUrl,
            token: token,
          },
          inputs: {
            CustomerID: { value: custId },
            Contact: { value: contobj.DisplayName },
          },
        },
      };
      var response = await axios
        .post(ApiBaseUrl + "contact", payload, {
          headers: {
            "x-api-key": ApiKey,
          },
        })
        .then((res) => {
          // console.log("resp : ", res);
          // return JSON.parse(res.data.body);
          return res;
        })
        .catch((err) => {
          // console.log("error : ", err);
          console.log("Contact error.");
          this.props.toggleLoader({
            isLoading: false,
          });
          return;
        });
      if (
        response &&
        response.data &&
        response.data.statusCode === 200 &&
        JSON.parse(JSON.parse(response.data.body).Contacts.value)
      ) {
        console.log("Contact found");
        // console.log(
        //   "Contact Response: ",
        //   JSON.parse(JSON.parse(response.data.body).Contacts.value)
        // );
        let contDetails = JSON.parse(
          JSON.parse(response.data.body).Contacts.value
        );
        for (let i = 0; i < contDetails.length; i++) {
          if (contDetails[i].ContactId === contobj.ContactId) {
            this.props.setBasketFormInput({ contact: contDetails[i] });
          }
        }
        // console.log("Basket form data: ", this.props.basketInputObj);
      } else if (JSON.parse(response.data.body).errorCode === 401) {
        // window.alert("Conatct error.");
        console.log("Contact Error");
        // console.log("contact error: ", JSON.parse(response.data.body));
        return;
      }
    } else {
      console.log("Customer or contact empty.");
    }
  }

  async handleEmail() {
    // if (
    //   this.props.basketInputObj.orderNbr &&
    //   this.props.basketInputObj.orderNbr !== "New"
    // ) {
    if (!this.props.selectedItems.length) {
      toast.error("There are no items selected in the basket.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    }
    this.props.toggleLoader({
      isLoading: true,
    });
    // await this.getUserEmail();
    // await this.getContactEmail();
    this.props.setBasketFormInput({
      includePrice: false,
      includeRetail: false,
      includeWholesale: false,
    });
    this.props.setBasketFormInput({
      includeLinks: "No",
    });
    this.props.handleSetCover("NoCover");
    this.props.handleIncludeGIA("No");
    this.props.handleIncludeKTDShapes("Yes");
    // this.handleBasketChange("Email");
    this.props.toggleLoader({
      isLoading: false,
    });
    this.props.showChooseLayout("Email");

    // } else {
    //   window.alert("Please select a basket first");
    // }
  }

  handlePrint() {
    // if (
    //   this.props.basketInputObj.orderNbr &&
    //   this.props.basketInputObj.orderNbr !== "New"
    // ) {
    // this.handleBasketChange("Print");
    if (!this.props.selectedItems.length) {
      toast.error("There are no items selected in the basket.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    }
    this.props.setBasketFormInput({
      includePrice: false,
      includeRetail: false,
      includeWholesale: false,
    });
    this.props.setBasketFormInput({
      includeLinks: "No",
    });
    this.props.handleSetCover("NoCover");
    this.props.handleIncludeGIA("No");
    this.props.handleIncludeKTDShapes("Yes");
    this.props.showChooseLayout("Print");

    // } else {
    //   window.alert("Please select a basket first");
    // }
  }
  handleMoreActions(e) {
    if (e.target.getAttribute("aria-expanded") === "true") {
      e.target.innerHTML = "- Close Options...";
      this.setState({
        areaExpanded: true,
      });
    } else {
      e.target.innerHTML = "+ More Options...";
      this.setState({
        areaExpanded: false,
      });
      var openNode = document.querySelector("#open-basket");
      var saveNode = document.querySelector("#save-to-existing");
      var openBtnNode = document.querySelector(".basket__open__actions");
      var saveBtnNode = document.querySelector(".basket__saveas__actions");
      openNode.style.display = "none";
      saveNode.style.display = "none";
      openBtnNode.style.background = "#58585a";
      openBtnNode.style.color = "#fff";
      saveBtnNode.style.background = "#58585a";
      saveBtnNode.style.color = "#fff";
    }
  }
  async handleOpen(e) {
    let { areaExpanded } = this.state;
    var openNode = document.querySelector("#open-basket");
    var saveNode = document.querySelector("#save-to-existing");
    var moreActionBtn = document.querySelector(".basket__more__actions");
    var saveBtnNode = document.querySelector(".basket__saveas__actions");
    saveNode.style.display = "none";
    saveBtnNode.style.background = "#58585a";
    saveBtnNode.style.color = "#fff";
    if (openNode.style.display === "none") {
      this.fetchBasketIntermediate();
      openNode.style.display = "block";
      // e.currentTarget.style.background = "#f5f7f8";
      // e.currentTarget.style.color = "#000";
    } else {
      openNode.style.display = "none";
      e.currentTarget.style.background = "#58585a";
      e.currentTarget.style.color = "#fff";
    }
    if (!areaExpanded) {
      moreActionBtn.click();
    }
  }
  handleSaveAs(e) {
    let { areaExpanded } = this.state;
    var saveNode = document.querySelector("#save-to-existing");
    var openNode = document.querySelector("#open-basket");
    var moreActionBtn = document.querySelector(".basket__more__actions");
    var openBtnNode = document.querySelector(".basket__open__actions");
    openNode.style.display = "none";
    openBtnNode.style.background = "#58585a";
    openBtnNode.style.color = "#fff";
    if (saveNode.style.display === "none") {
      this.fetchBasketIntermediate();
      saveNode.style.display = "block";
      // e.currentTarget.style.background = "#f5f7f8";
      // e.currentTarget.style.color = "#000";
    } else {
      saveNode.style.display = "none";
      e.currentTarget.style.background = "#58585a";
      e.currentTarget.style.color = "#fff";
    }
    if (!areaExpanded) {
      moreActionBtn.click();
    }
  }
  async fetchBasketIntermediate() {
    let { userLoggedIn } = this.props;
    // let user;
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    // }
    var allBasketResults = await this.fetchBaskets("All");
    var myBasketResults = await this.fetchBaskets(userLoggedIn);
    // console.log("allBasketResults: ", allBasketResults);
    if (allBasketResults || myBasketResults) {
      if (allBasketResults) {
        this.setState({
          allBaskets: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                (!basket._source.Status ||
                  basket._source.Status !== "Deleted") &&
                ((basket._source.UILoggedInUser &&
                  (userLoggedIn === basket._source.UILoggedInUser.value ||
                    (userLoggedIn !== basket._source.UILoggedInUser.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false)))) ||
                  !basket._source.UILoggedInUser)
            ),
          allMyBasketsSaveExisting: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                !basket._source.Status ||
                (basket._source.Status !== "Deleted" &&
                  basket._source.Status !== "Archived" &&
                  basket._source.UILoggedInUser &&
                  (userLoggedIn === basket._source.UILoggedInUser.value ||
                    (userLoggedIn !== basket._source.UILoggedInUser.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false) &&
                      (!basket._source.Edit ||
                        basket._source.Edit.value === true))))
              //   ||
              // !basket._source.UILoggedInUser)
            ),
        });
      }
      if (allBasketResults && Object.keys(this.props.groups).length === 0) {
        this.setState({
          allBaskets: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                (!basket._source.Status ||
                  basket._source.Status !== "Deleted") &&
                ((basket._source.UILoggedInUser &&
                  (userLoggedIn === basket._source.UILoggedInUser.value ||
                    (userLoggedIn !== basket._source.UILoggedInUser.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false)))) ||
                  !basket._source.UILoggedInUser)
            ),
          allMyBasketsSaveExisting: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                !basket._source.Status ||
                (basket._source.Status !== "Deleted" &&
                  basket._source.Status !== "Archived" &&
                  basket._source.UILoggedInUser &&
                  (userLoggedIn === basket._source.UILoggedInUser.value ||
                    (userLoggedIn !== basket._source.UILoggedInUser.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false) &&
                      (!basket._source.Edit ||
                        basket._source.Edit.value === true))))
              //   ||
              // !basket._source.UILoggedInUser)
            ),
        });
      } else if (
        allBasketResults &&
        Object.keys(this.props.groups).length !== 0
      ) {
        this.setState({
          allBaskets: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                (!basket._source.Status ||
                  basket._source.Status !== "Deleted") &&
                ((basket._source.Email &&
                  (userLoggedIn === basket._source.Email.value ||
                    (userLoggedIn !== basket._source.Email.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false)))) ||
                  !basket._source.Email)
            ),
          allMyBasketsSaveExisting: allBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                !basket._source.Status ||
                (basket._source.Status !== "Deleted" &&
                  basket._source.Status !== "Archived" &&
                  basket._source.Email &&
                  (userLoggedIn === basket._source.Email.value ||
                    (userLoggedIn !== basket._source.Email.value &&
                      (!basket._source.MakePrivate ||
                        basket._source.MakePrivate.value === false) &&
                      (!basket._source.Edit ||
                        basket._source.Edit.value === true))))
              //   ||
              // !basket._source.UILoggedInUser)
            ),
        });
      }
      // console.log("allBaskets: ", this.state.allBaskets);
      if (myBasketResults) {
        this.setState({
          allMyBaskets: myBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter((basket) => basket._source.Status !== "Deleted"),
          myBaskets: myBasketResults
            .sort((a, b) => parseInt(b._id) - parseInt(a._id))
            .filter(
              (basket) =>
                !basket._source.Status || basket._source.Status === "Active"
              // ||
              // basket._source.Status !== "Archived"
            ),
        });
      }
    } else {
      // this.props.toggleLoader({
      //   isLoading: false,
      // });
      return;
    }
    // this.props.toggleLoader({
    //   isLoading: false,
    // });
  }
  async fetchBaskets(user) {
    // let token = this.props.tokenState.token;
    var payload = {
      data: {
        defaults: {
          // baseURL: basketBaseUrl,
          // token: token,
          index: AppbaseBasketApp,
        },
        inputs: {
          user: user,
        },
      },
    };
    if (user === "All") {
      // code for getting all basket from appbase using elastic search

      // var resp = await axios
      //   // .post(ApiBaseUrl + "orderlist", payload, {
      //   //   headers: {
      //   //     "x-api-key": ApiKey,
      //   //   },
      //   // })

      //   .post(appbaseBasketUrl + "get-all-basket", payload, {
      //     headers: {
      //       "x-api-key": basketApikey,
      //     },
      //   })
      //   .catch((error) => {
      //     toast.error("Error while fetching basket !", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 2000,
      //       pauseOnHover: false,
      //     });
      //     this.props.toggleLoader({
      //       isLoading: false,
      //     });
      //     return;
      //   });

      const appbaseRef = Appbase({
        app: AppbaseBasketApp,
        url: AppbaseAppUrl,
        credentials: AppbaseCredentials,
      });

      var resp = await appbaseRef
        .search({
          body: {
            query: {
              match_all: {},
            },
            // _source: ["StyleNumber"],
            size: 10000,
          },
        })
        .then((response) => {
          console.log("Basket Found Successfully");
          // console.log("response : ", response);
          return response;
          // return response.hits.hits.sort(
          //   (a, b) => parseInt(a._id) - parseInt(b._id)
          // );
        })
        .catch((error) => {
          console.log("error : ", error);
        });

      if (!resp) {
        return;
      }
      if (resp.hits && resp.hits.hits && resp.hits.hits.length !== 0) {
        // console.log("Basket fetched successfully");
        // console.log("resp : ", resp);
        return resp.hits.hits;
        // if (JSON.parse(resp.data.body).OrdersList.value) {
        //   return JSON.parse(JSON.parse(resp.data.body).OrdersList.value);
        // } else {
        //   return [];
        // }
      } else {
        console.log("Error while fetching All baskets");
        // console.log("resp : ", resp);
        // let token = await GetAuthToken();
        // if (token) {
        //   this.props.setToken(token.access_token);
        //   await this.fetchBasketIntermediate();
        // }
      }
    } else {
      var resp = await axios
        // .post(ApiBaseUrl + "orderlist", payload, {
        //   headers: {
        //     "x-api-key": ApiKey,
        //   },
        // })

        .post(appbaseBasketUrl + "get-user-basket", payload, {
          headers: {
            "x-api-key": basketApikey,
          },
        })
        .catch((error) => {
          toast.error("Error while fetching user baskets !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          this.props.toggleLoader({
            isLoading: false,
          });
          return;
        });
      if (!resp) {
        return;
      }
      if (resp.data && resp.data.statusCode === 200) {
        // console.log("User Basket fetched successfully");
        // console.log("resp : ", resp);
        return resp.data.data;
        // if (JSON.parse(resp.data.body).OrdersList.value) {
        //   return JSON.parse(JSON.parse(resp.data.body).OrdersList.value);
        // } else {
        //   return [];
        // }
      } else {
        console.log("Error while fetching user baskets");
        // console.log("resp : ", resp);
        // let token = await GetAuthToken();
        // if (token) {
        //   this.props.setToken(token.access_token);
        //   await this.fetchBasketIntermediate();
        // }
      }
    }
  }

  async handleClone() {
    let { basketInputObj, cartItems, userLoggedIn, selectedItems } = this.props;
    if (!selectedItems.length) {
      toast.error("There are no items selected in the basket.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    }
    if (basketInputObj.orderNbr !== "New") {
      var currentdate = new Date();
      var dateTime =
        currentdate.getFullYear() +
        "" +
        (currentdate.getMonth() + 1) +
        "" +
        currentdate.getDate() +
        "" +
        currentdate.getHours() +
        "" +
        currentdate.getMinutes() +
        "" +
        currentdate.getSeconds();
      // console.log("dateTime: ", dateTime);
      // let initialID = "B-100";
      let initialID = 1000;
      let basketID;
      this.props.toggleLoader({
        isLoading: true,
      });
      if (basketInputObj.orderNbr !== "New") {
        var payload = {
          data: {
            defaults: {
              // baseURL: basketBaseUrl,
              // token: token,
              index: AppbaseBasketApp,
            },
          },
        };
        let id_Results = await axios
          .post(appbaseBasketUrl + "get-all-basket", payload, {
            headers: {
              "x-api-key": basketApikey,
            },
          })
          .then((response) => {
            console.log("Basket Found Successfully");
            // console.log("response : ", response.data.data);
            return response.data.data.sort(
              (a, b) => parseInt(a._id) - parseInt(b._id)
            );
          })
          .catch((error) => {
            console.log("error : ", error);
            return error;
          });
        // console.log("idResults: ", id_Results);
        // lastID = typeof lastID;
        // console.log("lastID: ", lastID);
        // if (lastID.startsWith("B-")) {
        //   let firstPart = lastID.slice(0, 2);
        //   let lastPart = parseInt(lastID.slice(2)) + 1;
        //   let newID = firstPart + lastPart;
        //   basketID = newID;
        // }
        if (id_Results.length !== 0) {
          let lastID = parseInt(id_Results[id_Results.length - 1]._id);
          // console.log("lastID: ", lastID);
          basketID = lastID + 1;
        } else {
          // let firstPart = initialID.slice(0, 2);
          // let lastPart = parseInt(initialID.slice(2)) + 1;
          // let newID = firstPart + lastPart;
          // basketID = newID;
          basketID = initialID + 1;
        }
        // console.log("basketID: ", basketID);
      }

      var contactInput = basketInputObj.contact.ContactId;
      var customerInput = basketInputObj.customer.CustomerId;
      // var items = cartItems.items;
      let items =
        selectedItems && selectedItems.length ? selectedItems : cartItems.items;
      // let user;
      // if (window.parent.document.getElementById("btnUserMenu")) {
      //   user = window.parent.document
      //     .getElementById("btnUserMenu")
      //     .querySelector(".user-name").textContent;
      // } else {
      //   user = null;
      // }
      // if (!contactInput && !customerInput) {
      //   alert("Please enter customer or contact");
      //   return;
      // }
      var contactName = basketInputObj.contact.DisplayName;
      // var contactEmail = basketInputObj.contact.ContactEmail;
      var customerName = basketInputObj.customer.Customer;
      var customerClass = basketInputObj.customer.CustomerClass;
      this.props.toggleLoader({
        isLoading: true,
      });
      items = items.map((el) => {
        var productArr = {
          SerialNumber: el.SerialNumber,
          StyleNumber: el.StyleNumber,
          InternalNote: el.InternalNote,
          RetailPrice: el.RetailPrice,
          WholesalePrice: el.WholesalePrice,
          Quantity: el.quantity,
          PriceVisibility: el.priceVisibility,
          LinkVisibility: el.linkVisibility,
          ItemSelected: el.itemSelected,
          ProductType: el.productType,
          AssetOrMemo: el.assetOrmemo,
        };
        return productArr;
      });
      // var products = JSON.stringify(items).replace(/"/g, "'");
      var products = JSON.stringify(items);
      var productObject = {
        BasketType: { value: basketInputObj.basketType },
        Contact: { value: contactInput === undefined ? null : contactInput },
        ContactName: { value: contactName === undefined ? null : contactName },
        // ContactEmail: { value: contactEmail === undefined ? null : contactEmail },
        Customer: { value: customerInput === undefined ? null : customerInput },
        CustomerName: {
          value: customerName === undefined ? null : customerName,
        },
        CustomerClass: {
          value: customerClass === undefined ? null : customerClass,
        },
        Description: {
          value:
            basketInputObj.desc === "" ? null : "COPY " + basketInputObj.desc,
        },
        IncludeRetailPrice: { value: basketInputObj.includeRetail },
        IncludeWholesalePrice: { value: basketInputObj.includeWholesale },
        InternalNotes: {
          value:
            basketInputObj.internalNotes === ""
              ? null
              : basketInputObj.internalNotes,
        },
        Occasion: {
          value:
            basketInputObj.occasion === "default"
              ? null
              : basketInputObj.occasion,
        },
        // OrderNbr: { value: "New" },
        OrdersList: {
          value: products,
        },
        ActionType: { value: "Clone" },
        UILoggedInUser: { value: this.state.userLoggedIn },
        // ...( Object.keys(this.props.groups).length===0? {
        //   UILoggedInUser: { value: this.state.userLoggedIn },
        // } : {}),
        // ...( Object.keys(this.props.groups).length!==0? {
        //   Email: { value: this.state.userLoggedIn },
        // }:{}),
        CreatedAt: parseInt(dateTime),
        MakePrivate: { value: basketInputObj.makePrivate },
        Edit: { value: basketInputObj.edit },
        AssetOrMemo: {
          value:
            basketInputObj.assetOrmemo === ""
              ? null
              : basketInputObj.assetOrmemo,
        },
        // ...(basketInputObj.orderNbr !== "New" && {
        //   UpdatedAt: parseInt(dateTime),
        // }),
        // CreatedDate:
        //   basketInputObj.orderNbr === "New" ? parseInt(dateTime) : undefined,
        // UpdatedDate:
        //   basketInputObj.orderNbr !== "New" ? parseInt(dateTime) : undefined,
        Status: basketInputObj.status,
      };
      // console.log("saved...", productObject);
      // let token = this.props.tokenState.token;
      var payload = {
        data: {
          defaults: {
            // baseURL: basketBaseUrl,
            // token: token,
            index: AppbaseBasketApp,
          },
          id: basketID,
          type: "New",
          inputs: productObject,
        },
      };
      var resp = await axios
        // .post(ApiBaseUrl + "order", payload, {
        //   headers: {
        //     "x-api-key": ApiKey,
        //   },
        // })
        .post(appbaseBasketUrl + "basket", payload, {
          headers: {
            "x-api-key": basketApikey,
          },
        })
        .catch((error) => {
          toast.error("Error while clonning basket !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          this.props.toggleLoader({
            isLoading: false,
          });
        });
      if (resp.data.statusCode === 200) {
        // console.log("resp: ", resp);
        toast.success("Basket Cloned !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.resetStates();

        this.props.toggleLoader({
          isLoading: true,
        });
        this.props.setBasketFormInput({
          orderNbr: resp.data.data._id,
        });
        // Read Basket api
        // let token = this.props.tokenState.token;
        var data;
        var rawData = await fetchBasket(
          // basketSelected: JSON.parse(resp.data.body).OrderNbr.value,
          resp.data.data._id
          // token: token,
        );
        // console.log("rawData: ", rawData);
        if (!rawData || rawData.statusCode !== 200) {
          // console.log("rawData: ", rawData);
          // console.log("rawdata is empty or statusCode !==200");
          this.props.toggleLoader({
            isLoading: false,
          });
          return;
        }
        // if (rawData.errorCode === 401) {
        //   let token = await GetAuthToken();
        //   if (token) {
        //     this.props.setToken(token.access_token);
        //     rawData = await fetchBasket({
        //       basketSelected: JSON.parse(resp.data.body).OrderNbr.value,
        //       token: token.access_token,
        //     });
        //   }
        // }
        if (
          rawData.data._source.OrdersList &&
          rawData.data._source.OrdersList.value
        ) {
          data = rawData.data._source;
        }
        var desc = data.Description.value;
        var includeRetail = data.IncludeRetailPrice.value;
        var includeWholesale = data.IncludeWholesalePrice.value;
        var internalNotes = data.InternalNotes.value;
        var occasion = data.Occasion.value;
        var basketUserDetails = data.UILoggedInUser
          ? data.UILoggedInUser.value
          : "";
        var makePrivate = data.MakePrivate ? data.MakePrivate.value : false;
        var status = data.Status ? data.Status : "Active";
        var edit = data.Edit ? data.Edit.value : false;
        var assetOrmemo =
          data.AssetOrMemo &&
          data.AssetOrMemo.value &&
          data.AssetOrMemo.value !== true &&
          data.value !== false
            ? data.AssetOrMemo.value
            : "";
        var basketItems = JSON.parse(data.OrdersList.value.replace(/'/g, '"'));
        // var basketItems = JSON.parse(data.OrdersList.value);
        // console.log("basketItem : ", basketItems);
        this.props.setBasketFormInput({
          contact: {
            ContactId: data.Contact.value,
            DisplayName: data.ContactName.value,
          },
          customer: {
            CustomerId: data.Customer.value,
            Customer: data.CustomerName.value,
            CustomerClass: data.CustomerClass.value,
          },
          desc: desc,
          internalNotes: internalNotes,
          occasion: occasion,
          includeRetail: includeRetail,
          includeWholesale: includeWholesale,
          basketUserDetails: basketUserDetails,
          makePrivate: makePrivate,
          status: status,
          edit: edit,
          assetOrmemo: assetOrmemo,
        });
        // Appbase call
        let uniqueArrayFromBasket = getUniqueItemWithQty(basketItems);
        var fromAppbaseCall = [];
        for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
          var res = await getItemFromAppBase({
            item: uniqueArrayFromBasket[i],
            searchOption: "mixed",
          });
          if (typeof res[0]["_source"] !== "object") {
            toast.error("Appbase fetching products error !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              pauseOnHover: false,
            });
            continue;
          }
          let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
          fromAppbaseCall.push({
            ...omitted,
            RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
            WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
            quantity: uniqueArrayFromBasket[i].Quantity,
            InternalNote: uniqueArrayFromBasket[i].InternalNote,
            priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
            linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
              ? uniqueArrayFromBasket[i].LinkVisibility
              : "Default",
            itemSelected: uniqueArrayFromBasket[i].ItemSelected
              ? uniqueArrayFromBasket[i].ItemSelected
              : true,
            productType: uniqueArrayFromBasket[i].ProductType,
            LineNbr: uniqueArrayFromBasket[i].LineNbr,
            assetOrmemo:
              uniqueArrayFromBasket[i].AssetOrMemo &&
              uniqueArrayFromBasket[i].AssetOrMemo !== true &&
              uniqueArrayFromBasket[i].AssetOrMemo !== false
                ? uniqueArrayFromBasket[i].AssetOrMemo
                : "",
          });
        }
        // sort by LineNbr
        fromAppbaseCall.sort(function (a, b) {
          return b.LineNbr - a.LineNbr;
        });
        this.props.openCartItems(fromAppbaseCall);
        this.props.toggleLoader({
          isLoading: false,
        });
        // } else if (JSON.parse(resp.data.body).errorCode === 401) {
        //   let token = await GetAuthToken();
        //   if (token) {
        //     this.props.setToken(token.access_token);
        //     await this.handleClone();
      } else {
        toast.error("Error while clonning basket !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.toggleLoader({
          isLoading: false,
        });
        return [];
      }
    } else {
      window.alert("Please select a basket");
    }
  }
  async handleRefresh() {
    let { basketInputObj, cartItems } = this.props;
    if (basketInputObj.orderNbr === "New") {
      this.handleItemRefresh(cartItems.items);
      toast.success("New Basket !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    }

    this.props.toggleLoader({
      isLoading: true,
    });

    // let token = this.props.tokenState.token;
    var data;
    var OrderList;
    var rawData = await fetchBasket(
      // {
      basketInputObj.orderNbr
      // token: token,
      // }
    );
    // console.log("rawData: ", rawData);
    if (!rawData || rawData.statusCode !== 200) {
      this.props.toggleLoader({
        isLoading: false,
      });
      return;
    }
    // if (rawData.errorCode === 401) {
    //   let token = await GetAuthToken();
    //   if (token) {
    //     this.props.setToken(token.access_token);
    //     rawData = await fetchBasket({
    //       basketSelected: basketInputObj.orderNbr,
    //       token: token.access_token,
    //     });
    //   }
    // }
    if (
      rawData.data._source.OrdersList &&
      rawData.data._source.OrdersList.value
    ) {
      data = rawData.data._source;
      // console.log("data: ", data);
      OrderList = JSON.parse(data.OrdersList.value.replace(/'/g, '"'));
    }
    // Appbase call

    let uniqueArrayFromBasket = getUniqueItemWithQty(OrderList);
    // console.log("uniqueArrayFromBasket: ", uniqueArrayFromBasket);
    var fromAppbaseCall = [];
    for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
      var res = await getItemFromAppBase({
        item: uniqueArrayFromBasket[i],
        searchOption: "mixed",
      });
      // console.log("res: ", res);
      if (typeof res[0]["_source"] !== "object") {
        toast.error("Appbase fetching products error !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        continue;
      }
      let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
      fromAppbaseCall.push({
        ...omitted,
        RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
        WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
        quantity: uniqueArrayFromBasket[i].Quantity,
        InternalNote: uniqueArrayFromBasket[i].InternalNote,
        priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
        productType: uniqueArrayFromBasket[i].ProductType,
        linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
          ? uniqueArrayFromBasket[i].LinkVisibility
          : "Default",
        itemSelected: uniqueArrayFromBasket[i].ItemSelected
          ? uniqueArrayFromBasket[i].ItemSelected
          : true,
        LineNbr: uniqueArrayFromBasket[i].LineNbr,
        assetOrmemo:
          uniqueArrayFromBasket[i].AssetOrMemo &&
          uniqueArrayFromBasket[i].AssetOrMemo !== true &&
          uniqueArrayFromBasket[i].AssetOrMemo !== false
            ? uniqueArrayFromBasket[i].AssetOrMemo
            : true,
      });
    }
    this.props.toggleLoader({
      isLoading: false,
    });
    // sort by LineNbr
    // fromAppbaseCall.sort(function(a, b) {
    //   return b.LineNbr - a.LineNbr;
    // });
    // comparison

    var localStateObj = {
      ContactID:
        basketInputObj.contact.ContactId === undefined
          ? null
          : basketInputObj.contact.ContactId,
      ContactName:
        basketInputObj.contact.DisplayName === undefined
          ? null
          : basketInputObj.contact.DisplayName,
      CustomerID:
        basketInputObj.customer.CustomerId === undefined
          ? null
          : basketInputObj.customer.CustomerId,
      CustomerName:
        basketInputObj.customer.Customer === undefined
          ? null
          : basketInputObj.customer.Customer,
      CustomerClass:
        basketInputObj.customer.CustomerClass === undefined
          ? null
          : basketInputObj.customer.CustomerClass,
      Description: basketInputObj.desc === "" ? null : basketInputObj.desc,
      IncludeRetail: basketInputObj.includeRetail,
      IncludeWholesale: basketInputObj.includeWholesale,
      InternalNotes:
        basketInputObj.internalNotes === ""
          ? null
          : basketInputObj.internalNotes,
      Occasion:
        basketInputObj.occasion === "default" ? null : basketInputObj.occasion,
      MakePrivate: basketInputObj.makePrivate,
      Status: basketInputObj.status,
      Edit: basketInputObj.edit,
      AssetOrMemo: basketInputObj.assetOrmemo ? basketInputObj.assetOrmemo : "",
      // OrderNbr: basketInputObj.orderNbr,
      OrdersList: cartItems.items,
    };
    var apiStateObj = {
      ContactID: data.Contact.value,
      ContactName: data.ContactName.value,
      CustomerID: data.Customer.value,
      CustomerName: data.CustomerName.value,
      CustomerClass: data.CustomerClass.value,
      Description: data.Description.value,
      IncludeRetail: data.IncludeRetailPrice.value,
      IncludeWholesale: data.IncludeWholesalePrice.value,
      InternalNotes: data.InternalNotes.value,
      Occasion: data.Occasion.value,
      MakePrivate: data.MakePrivate ? data.MakePrivate.value : false,
      Status: data.Status ? data.Status : "Active",
      Edit: data.Edit ? data.Edit.value : false,
      AssetOrMemo: data.AssetOrMemo ? data.AssetOrMemo : "",
      // OrderNbr: data.OrderNbr.value,
      OrdersList: fromAppbaseCall,
    };
    // console.log("Local data ", localStateObj);
    // console.log("API state data ", apiStateObj);
    if (JSON.stringify(localStateObj) === JSON.stringify(apiStateObj)) {
      toast.success("No channges found !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    } else {
      var confirmFlag = window.confirm(
        "Any unsaved changes will be lost. Click ok to continue."
      );
      if (confirmFlag) {
        this.props.setBasketFormInput({
          contact: {
            ContactId: data.Contact.value,
            DisplayName: data.ContactName.value,
          },
          customer: {
            CustomerId: data.Customer.value,
            Customer: data.CustomerName.value,
            CustomerClass: data.CustomerClass.value,
          },
          desc: data.Description.value,
          internalNotes: data.InternalNotes.value,
          occasion: data.Occasion.value,
          includeRetail: data.IncludeRetailPrice.value,
          includeWholesale: data.IncludeWholesalePrice.value,
          makePrivate: data.MakePrivate ? data.MakePrivate.value : false,
          status: data.Status ? data.Status : "Active",
          edit: data.Edit ? data.Edit.value : false,
        });
        this.props.openCartItems(fromAppbaseCall);
      } else {
        return;
      }
    }
  }
  async handleAcumaticaExport(basketId) {
    if (basketId === "New") {
      toast("Unsaved Basket !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
    } else {
      let { basketInputObj, cartItems, userLoggedIn, selectedItems } =
        this.props;
      if (
        !selectedItems.length ||
        !basketInputObj.customer ||
        !basketInputObj.customer.CustomerClass
      ) {
        toast.error(
          `${
            !selectedItems.length
              ? `There are no items selected in the basket.`
              : `Basket should have customer saved before export`
          }`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          }
        );
        return;
      }
      this.props.toggleLoader({
        isLoading: true,
      });
      let items =
        selectedItems && selectedItems.length ? selectedItems : cartItems.items;
      var retailPrice = 0,
        wholesalePrice = 0,
        retailCount = 0,
        wholesaleCount = 0,
        noOfMemoItems = 0,
        noOfAssetItems = 0,
        memoLineTotal = 0,
        assetLineTotal = 0;
      items.map((el) => {
        retailPrice += +el.RetailPrice * el.quantity;
        wholesalePrice += +el.WholesalePrice * el.quantity;
        parseInt(el.RetailPrice, 10) === parseFloat(0) ||
        el.RetailPrice === null
          ? retailCount++
          : retailCount;
        parseInt(el.WholesalePrice, 10) === parseFloat(0) ||
        el.WholesalePrice === null
          ? wholesaleCount++
          : wholesaleCount;
        el.assetOrmemo === "M" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "M")
          ? noOfMemoItems++
          : noOfMemoItems;
        el.assetOrmemo === "A" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "A")
          ? noOfAssetItems++
          : noOfAssetItems;
        basketInputObj.customer.CustomerClass === "RETAIL" &&
        (el.assetOrmemo === "M" ||
          (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
          ? (memoLineTotal += +el.RetailPrice)
          : memoLineTotal;
        basketInputObj.customer.CustomerClass === "RETAIL" &&
        (el.assetOrmemo === "A" ||
          (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
          ? (assetLineTotal += +el.RetailPrice)
          : assetLineTotal;
        basketInputObj.customer.CustomerClass !== "RETAIL" &&
        (el.assetOrmemo === "M" ||
          (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
          ? (memoLineTotal += +el.WholesalePrice)
          : memoLineTotal;
        basketInputObj.customer.CustomerClass !== "RETAIL" &&
        (el.assetOrmemo === "A" ||
          (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
          ? (assetLineTotal += +el.WholesalePrice)
          : assetLineTotal;
        // return { retailPrice, wholesalePrice, retailCount, wholesaleCount };
      });
      var contactInput = basketInputObj.contact.ContactId;
      var customerInput = basketInputObj.customer.CustomerId;

      // var contactName = basketInputObj.contact.DisplayName;
      // var contactEmail = basketInputObj.contact.ContactEmail;
      // var customerName = basketInputObj.customer.Customer;
      var customerClass = basketInputObj.customer.CustomerClass;
      // this.props.toggleLoader({
      //   isLoading: true,
      // });
      console.log("items:", items);
      items = items.map((el) => {
        var productArr = {
          // InventoryID: el.InventoryID,
          SerialNumber: el.SerialNumber,
          StyleNumber: el.StyleNumber,
          InternalNote: el.InternalNote,
          RetailPrice: el.RetailPrice,
          WholesalePrice: el.WholesalePrice,
          Quantity: 1,
          // PriceVisibility: el.priceVisibility,
          // LinkVisibility: el.linkVisibility,
          // ItemSelected: el.itemSelected,
          // ProductType: el.productType,
          LineNbr: items.indexOf(el),
          AssetOrMemo: el.assetOrmemo,
          // CustomerClass: customerClass === undefined ? null : customerClass,
        };
        return productArr;
      });
      // var products = JSON.stringify(items).replace(/"/g, "'");
      var products = JSON.stringify(items);
      var productObject = {
        // BasketType: { value: basketInputObj.basketType },
        ActionType: { value: "Save" },
        OrderNbr: { value: "New" },
        Contact: { value: contactInput === undefined ? null : contactInput },
        // ContactName: { value: contactName === undefined ? null : contactName },
        // ContactEmail: { value: contactEmail === undefined ? null : contactEmail },
        Customer: { value: customerInput === undefined ? null : customerInput },
        CustomerClass: {
          value: customerClass === undefined ? null : customerClass,
        },
        // CustomerName: {
        //   value: customerName === undefined ? null : customerName,
        // },
        // CustomerClass: {
        //   value: customerClass === undefined ? null : customerClass,
        // },
        UserEmail: {
          value: basketInputObj.user ? basketInputObj.user.Email : null,
        },
        Description: {
          value: basketInputObj.desc === "" ? null : basketInputObj.desc,
        },
        // IncludeRetailPrice: { value: basketInputObj.includeRetail },
        // IncludeWholesalePrice: { value: basketInputObj.includeWholesale },
        InternalNotes: {
          value:
            basketInputObj.internalNotes === ""
              ? null
              : basketInputObj.internalNotes,
        },
        Occasion: {
          value:
            basketInputObj.occasion === "default"
              ? null
              : basketInputObj.occasion,
        },
        TotalQty: {
          value: items.length ? items.length : null,
        },
        TotalWholesale: { value: wholesalePrice ? wholesalePrice : null },
        TotalRetail: { value: retailPrice ? retailPrice : null },
        OrdersList: {
          value: products,
        },
        AssetOrMemo: {
          value: basketInputObj.assetOrmemo ? basketInputObj.assetOrmemo : "",
        },
        NoOfMemoItems: {
          value: noOfMemoItems,
        },
        NoOfAssetItems: {
          value: noOfAssetItems,
        },
        MemoLineTotal: { value: memoLineTotal },
        AssetLineTotal: { value: assetLineTotal },
        ItemsWithoutWholesale: { value: wholesaleCount },
        ItemsWithoutRetail: { value: retailCount },

        // UILoggedInUser: { value: userLoggedIn },
        // CreatedAt: parseInt(dateTime),
        // MakePrivate: { value: basketInputObj.makePrivate },
        // Edit: { value: basketInputObj.edit },
        // // MakePrivate: { value: basketInputObj.makePrivate },
        // // ...(basketInputObj.orderNbr !== "New" && {
        // //   UpdatedAt: parseInt(dateTime),
        // // }),
        // // CreatedDate:
        // //   basketInputObj.orderNbr === "New" ? parseInt(dateTime) : undefined,
        // // UpdatedDate:
        // //   basketInputObj.orderNbr !== "New" ? parseInt(dateTime) : undefined,
        // Status: basketInputObj.status,
      };
      // let getToken = await GetAuthToken();
      // if (token) {
      // this.props.setToken(getToken.access_token);
      // }
      // console.log("productObject: ", productObject);
      // let token = getToken.access_token;
      let token = "";
      var payload = {
        data: {
          defaults: {
            baseURL: basketBaseUrl,
            token: token,
          },
          inputs: productObject,
        },
      };
      // console.log("payload: ", payload);
      var response = await axios
        .post(ApiBaseUrl + "order", payload, {
          headers: {
            "x-api-key": ApiKey,
          },
        })
        .then((res) => {
          // console.log("resp : ", res);
          // return JSON.parse(res.data.body);
          return res;
        })
        .catch((err) => {
          // console.log("error : ", err);
          console.log("Accumatica error.");
          this.props.toggleLoader({
            isLoading: false,
          });
          return;
        });
      // console.log("response: ", response);
      if (
        response &&
        response.data.statusCode &&
        response.data.statusCode === 200
      ) {
        toast.success(`Exported To Acumatica Successfully`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.toggleLoader({
          isLoading: false,
        });
        // return;
      } else {
        toast.error(`Failed To Export`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        // let token = await GetAuthToken();
        // if (token) {
        //   this.props.setToken(token.access_token);
        // }
        this.props.toggleLoader({
          isLoading: false,
        });
        return;
      }
      let SEMOrderNbr = response.data.body.replace(/"/g, "'");
      // console.log("SEMOrderNbr: ", SEMOrderNbr);
      SEMOrderNbr = JSON.parse(response.data.body).OrderNbr.value;
      // console.log("SEMOrderNbr: ", SEMOrderNbr);
      this.props.showAccuExportModal(SEMOrderNbr);

      // window.open(
      //   basketBaseUrl +
      //     `/(W(4))/Main?ScreenId=SO301000&OrderType=BO&OrderNbr=${SEMOrderNbr}`,
      //   "_blank"
      // ).location;
      // console.log("after window object");
    }
  }

  async handleDelete() {
    if (this.props.basketInputObj.orderNbr === "New") {
      window.alert("The basket is not saved");
    } else {
      let result = window.confirm("Confirm Deletion ?");
      // console.log("result: ", result);
      if (result) {
        this.props.setBasketFormInput({
          status: "Deleted",
        });
        await this.props.handleSave("Deleted");
        this.props.resetStates();
      }
    }
  }

  render() {
    let { allMyBasketsSaveExisting, allBaskets, myBaskets, allMyBaskets } =
      this.state;
    let { basketInputObj, cartItems, userLoggedIn, selectedItems } = this.props;
    return (
      <div className="basket__actions__grouped">
        <div className="visible-action__button__wrapper">
          <div className="initial__visible__buttons">
            {(basketInputObj.basketUserDetails &&
              userLoggedIn === basketInputObj.basketUserDetails) ||
            (basketInputObj.orderNbr !== "New" &&
              basketInputObj.edit === true &&
              basketInputObj.makePrivate === false) ||
            basketInputObj.orderNbr === "New" ? (
              <button
                onClick={() => {
                  let type =
                    basketInputObj.orderNbr === "New" ? "New" : "Update";
                  this.props.handleSave(type);
                }}
              >
                <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/save-16.png" />
                {/* <i className="far fa-save" />  */}
                Save
              </button>
            ) : (
              ``
            )}
            <button className="basket__open__actions" onClick={this.handleOpen}>
              <img src={Open} />
              {/* <i className="fas fa-envelope-open-text" />  */}
              Open
            </button>
          </div>
          <button
            className="basket__more__actions collapsed"
            data-toggle="collapse"
            data-target="#basket__collapse__more--actions"
            onClick={this.handleMoreActions}
          >
            + More Options...
          </button>
        </div>
        <div className="collapse" id="basket__collapse__more--actions">
          <div className="toggled__action__buttons">
            <button
              onClick={this.handleSaveAs}
              className="basket__saveas__actions"
            >
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/save-as-16.png" />
              {/* <i className="far fa-save" />  */}
              Save to Existing
            </button>
            <button onClick={this.handleClone}>
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/copy-16.png" />
              Clone as New
            </button>
          </div>
          <div className="toggled__action__buttons">
            <button
              className="basket__email__actions"
              onClick={() => this.handleEmail()}
            >
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/open-in-browser-16.png" />
              {/* <i className="fas fa-envelope" />  */}
              Email
            </button>
            <button
              className="basket__print__actions"
              onClick={() => this.handlePrint()}
            >
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/pdf-16.png" />
              {/* <i className="far fa-file-pdf" />  */}
              Print
            </button>
          </div>
          <div className="toggled__action__buttons">
            <button
              className="basket__export__actions"
              onClick={() => {
                selectedItems && selectedItems.length
                  ? this.props.showExportModal()
                  : toast.error("There are no items selected in the basket.", {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                      pauseOnHover: false,
                    });
                return;
              }}
            >
              {/* <i className="fas fa-trash" /> */}
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/csv-16.png" />
              Export
            </button>
            {/* <OverlayTrigger
              overlay={
                <Tooltip className="tooltip" id="clear">
                  Clear
                </Tooltip>
              }
              placement="top"
            > */}
            <button
              className="basket__clear__actions"
              onClick={() => this.props.resetStates()}
            >
              <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/add-file-16.png" />{" "}
              New
              {/* <i className="fas fa-eraser" /> */}
            </button>
            {/* </OverlayTrigger> */}
          </div>

          <div className="toggled__action__buttons">
            <OverlayTrigger
              overlay={
                <Tooltip className="tooltip" id="refresh">
                  Refresh
                </Tooltip>
              }
              placement="top"
            >
              <button onClick={this.handleRefresh}>
                {/* <i className="fas fa-redo" /> */}
                <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/refresh-16.png" />
              </button>
            </OverlayTrigger>
            {userLoggedIn === basketInputObj.basketUserDetails ||
            basketInputObj.orderNbr === "New" ? (
              <OverlayTrigger
                overlay={
                  <Tooltip className="tooltip" id="Delete">
                    Delete
                  </Tooltip>
                }
                placement="top"
              >
                <button
                  className="basket__email__actions"
                  onClick={() => this.handleDelete()}
                >
                  {/* <i className="fas fa-trash" /> */}
                  <img src="https://cdn4.kwiat.com/apps/kwiat-elastic-search/icons/delete-16.png" />
                </button>
              </OverlayTrigger>
            ) : (
              ``
            )}
          </div>
          <div className="toggled__action__buttons">
            {this.state.showTemplateButtons && (
              <>
                {" "}
                <button
                  className="basket__email__actions"
                  onClick={() => this.props.handleEmailTemplate(true)}
                >
                  Templates
                </button>
                <button
                  className="basket__email__actions"
                  onClick={() => this.props.handleCoverModal(true)}
                >
                  Covers
                </button>
              </>
            )}
          </div>
          <div
            className="open__bakset__section"
            id="open-basket"
            style={{ display: "none" }}
          >
            <OpenExisting
              allBaskets={allBaskets ? allBaskets : []}
              myBaskets={myBaskets ? myBaskets : []}
              userLoggedIn={userLoggedIn}
              allMyBaskets={allMyBaskets ? allMyBaskets : []}
            />
          </div>
          <div
            className="open__bakset__section"
            id="save-to-existing"
            style={{ display: "none" }}
          >
            <SaveExisting
              allBaskets={allBaskets ? allBaskets : []}
              allMyBasketsSaveExisting={
                allMyBasketsSaveExisting ? allMyBasketsSaveExisting : []
              }
              myBaskets={myBaskets ? myBaskets : []}
              userLoggedIn={this.props.userLoggedIn}
              selectedItems={selectedItems}
            />
          </div>
        </div>
        <div
          className="additional_basket_actions"
          // style={{
          //   marginTop:
          //     basketInputObj.customer.CustomerClass !== "RETAIL"
          //       ? "20px"
          //       : "50px",
          // }}
        >
          {userLoggedIn === basketInputObj.basketUserDetails ||
          basketInputObj.orderNbr === "New" ? (
            <>
              <input
                type="checkbox"
                id="Make-Private"
                checked={basketInputObj.makePrivate}
                onChange={(e) =>
                  this.props.setBasketFormInput({
                    makePrivate: e.target.checked,
                  })
                }
                // disabled={false}
                // disabled={
                //   this.state.user ===
                //   this.props.basketInputObj.basketUserDetails
                //     ? false
                //     : true
                // }
              />
              <label htmlFor="Make-Private">Make Private</label>
            </>
          ) : (
            ``
          )}
          {userLoggedIn === basketInputObj.basketUserDetails ||
          basketInputObj.orderNbr === "New" ? (
            <>
              <input
                type="checkbox"
                id="Allow-Edit"
                checked={basketInputObj.edit}
                // disabled={
                //   userLoggedIn === this.props.basketInputObj.basketUserDetails
                //     ? false
                //     : true
                // }
                onChange={(e) =>
                  this.props.setBasketFormInput({
                    edit: e.target.checked,
                  })
                }
                // disabled={false}
                // disabled={
                //   this.state.user ===
                //   this.props.basketInputObj.basketUserDetails
                //     ? false
                //     : true
                // }
              />
              <label htmlFor="Allow-Edit">Allow Editing</label>
            </>
          ) : (
            ``
          )}
          {(userLoggedIn === basketInputObj.basketUserDetails &&
            basketInputObj.status !== "Archived") ||
          basketInputObj.orderNbr === "New" ? (
            <>
              {/* <input
                      type="checkbox"
                      id="Archived"
                      checked={
                        basketInputObj.status === "Archived" ? true : false
                      }
                      onChange={(e) =>
                        this.props.setBasketFormInput({
                          status: e.target.checked ? "Archived" : "",
                        })
                      }
                      // disabled={false}
                      // disabled={
                      //   this.state.user ===
                      //   this.props.basketInputObj.basketUserDetails
                      //     ? false
                      //     : true
                      // }
                    />
                    <label htmlFor="Archived" onClick={}>Archive</label> */}
              <button
                onClick={() => {
                  this.props.setBasketFormInput({ status: "Archived" });
                  this.props.handleSave("Archived");
                }}
              >
                Archive
              </button>
            </>
          ) : userLoggedIn === basketInputObj.basketUserDetails &&
            basketInputObj.status !== "Active" ? (
            <label
              href=""
              onClick={() => {
                this.props.setBasketFormInput({
                  status: "Active",
                });
                this.props.handleSave("Active");
              }}
            >
              Make Active
            </label>
          ) : (
            ``
          )}
        </div>
        {basketInputObj.customer.CustomerClass &&
        basketInputObj.customer.CustomerClass !== "RETAIL" ? (
          <div className="setAssetOrMemo">
            <label>Select Asset OR Memo:</label>
            {/* <select
              defaultValue={basketInputObj.assetOrmemo || ""}
              onChange={(e) => {
                this.props.setBasketFormInput({
                  assetOrmemo: e.target.value,
                });
              }}
            >
              <option value="" disabled>
                Select Asset OR Memo
              </option>
              <option value="A">Asset</option>
              <option value="M">Memo</option>
            </select> */}
            <div className="asset-memo__toggle">
              <input
                type="radio"
                id="asset"
                name="asset-memo"
                value="A"
                checked={basketInputObj.assetOrmemo === "A" ? true : false}
                onChange={(e) => {
                  // console.log("value : ", e.target.value);
                  let value = e.target.value;
                  this.props.setBasketFormInput({
                    assetOrmemo: e.target.value,
                  });
                  // cartItems.items.map((el) => {
                  //   this.props.setAssetOrMemo({
                  //     item: el,
                  //     value: value,
                  //   });
                  // });
                }}
              />
              <label htmlFor="asset">Asset</label>
            </div>
            <div className="asset-memo__toggle">
              <input
                type="radio"
                id="memo"
                name="asset-memo"
                value="M"
                checked={basketInputObj.assetOrmemo === "M" ? true : false}
                onChange={(e) => {
                  // console.log("value : ", e.target.value);
                  let value = e.target.value;
                  this.props.setBasketFormInput({
                    assetOrmemo: e.target.value,
                  });
                  // cartItems.items.map((el) => {
                  //   this.props.setAssetOrMemo({
                  //     item: el,
                  //     value: value,
                  //   });
                  // });
                }}
              />
              <label htmlFor="memo">Memo</label>
            </div>
          </div>
        ) : (
          ``
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetStates,
      setBasketFormInput,
      toggleLoader,
      openCartItems,
      setToken,
      setAssetOrMemo,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketActions);
