import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import Appbase from "appbase-js";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from "react-bootstrap";
import { toggleLoader } from "../../actions";
import {
  ApiBaseUrl,
  ApiKey,
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseEmployeeApp,
  basketBaseUrl,
} from "../../utils/constants";
import BasketToAcuExportModal from "./BasketToAcuExportModal";
import ProductLoader from "../ProductLoader"
import "../../styles/original.css"
import "../../styles/index.css"

class BasketToAcuModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredBy: [],
      enteredFor: [],
      notes: "",
      userData: null,
      siteId: "232",
      prNumber: null,
      prMessage: "",
      isExportModalOpen: false,
      initialDataFetched: false,
      isExclusive: 0,
      isLoading: false,
    };
    this.appbaseRef = Appbase({
      app: AppbaseEmployeeApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    this.handleEnterForChange = this.handleEnterForChange.bind(this);
    this.handleEnterByChange = this.handleEnterByChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSiteIdChange = this.handleSiteIdChange.bind(this);
    // this.openBasketToAcuModalPopup = this.openBasketToAcuModalPopup.bind(this);
    this.closeBasketToAcuModalPopup = this.closeBasketToAcuModalPopup.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.showToast = this.showToast.bind(this)
  }

  showToast(success, textMessage) {
    if(success){
      toast.success(textMessage, {
        position: 'top-center',
        style: {
          backgroundColor: '#D3D3D3',
          color: '#5a5959',
          padding: '10px'
        },
        autoClose: 5000,
      hideProgressBar: false,
      progress: undefined,
      dangerouslySetInnerHTML: true,
      });
    }else{
      toast.error('something went grong', {
        position: 'top-center',
        autoClose: 5000,
      hideProgressBar: false,
      progress: undefined,
      dangerouslySetInnerHTML: true,
      })
    }
  }
  

  componentDidMount() {
    const { basketInputObj } = this.props;
    if (!this.state.initialDataFetched) {
      this.appbaseRef
        .search({
          body: {
            query: {
              match_phrase_prefix: {
                Email: {
                  query: basketInputObj.user.Email,
                },
              },
            },
            size: 1,
            _source: ["FirstName", "LastName", "EmployeeDBID", "Email"],
          },
        })
        .then((response) => {
          const user = response.hits.hits[0]._source;
          const initialEnteredBy = {
            label: `${user.FirstName} ${user.LastName}`,
            value: `${user.FirstName} ${user.LastName}`,
            EmployeeDBID: user.EmployeeDBID,
          };

          this.setState({
            enteredBy: [initialEnteredBy],
            initialDataFetched: true,
          });
          // this.setState({ enteredBy: [initialEnteredBy], initialDataFetched: true }, () => {
          //   console.log("Initial State EnteredBy:", this.state.enteredBy);
          // });
        })
        .catch((error) => {
          console.log("Error fetching EnteredBy details", error);
        });
    }
  }

  handleInputChange = (name, selectedOption) => {
    const inputValue =
      selectedOption && selectedOption.value !== undefined
        ? selectedOption.value
        : selectedOption;

    if (name === "enteredBy" || name === "enteredFor") {
      this.fetchDataFromAppbase(name, inputValue);
    }
  };

  fetchDataFromAppbase = (fieldName, fieldValue) => {
    if (!fieldValue) {
      return;
    }

    this.appbaseRef
      .search({
        body: {
          query: {
            match_phrase_prefix: {
              FirstName: {
                query: fieldValue,
              },
            },
          },
          size: 1000,
          _source: ["FirstName", "LastName", "EmployeeDBID", "Email"],
        },
      })
      .then((response) => {
        const userOptions = response.hits.hits.map((user) => {
          const userData = user._source;
          return {
            label: `${userData.FirstName} ${userData.LastName}`,
            value: `${userData.FirstName} ${userData.LastName}`,
            EmployeeDBID: userData.EmployeeDBID,
          };
        });
        // console.log(userOptions);
        this.setState({
          [fieldName]: userOptions,
        });
      })
      .catch((error) => {
        console.log("User List Error", error);
      });
  };

  handleSiteIdChange = (e) => {
    this.setState({ siteId: e.target.value });
  };

  handleSave = async () => {
    try {
      const { enteredBy, enteredFor, siteId, notes, isExportModalOpen, isExclusive } = this.state;
      const { basketInputObj, cartItems, userLoggedIn, selectedItems } = this.props;

      const selectedEnteredBy = Array.isArray(enteredBy)
        ? enteredBy[0]
        : enteredBy;

      let items =
        selectedItems && selectedItems.length ? selectedItems : cartItems.items;
      items = items.map((el) => {
        const internalNoteValue =
          el.InternalNote !== "" ? el.InternalNote : notes;
        var productArr = {
          SerialNumber: el.SerialNumber,
          StyleNumber: el.StyleNumber,
          // InternalNote: `${enteredFor.label ? enteredFor.label : enteredBy.label}`- el.InternalNote,
          InternalNote: `${enteredFor.label ? enteredFor.label : selectedEnteredBy?.label
            }-${internalNoteValue}`,
          LineNbr: items.indexOf(el),
        };
        return productArr;
      });
      var products = JSON.stringify(items);
      var productObject = {
        AuthKey: "Gn5PahZ@RJb$C*jm",
        EnteredBy: selectedEnteredBy?.EmployeeDBID,
        EnteredFor: enteredFor?.EmployeeDBID,
        SiteId: siteId,
        Notes: notes,
        isExclusive: isExclusive,
        InternalNote:
          basketInputObj?.internalNotes === ""
            ? null
            : basketInputObj?.internalNotes,
        SerialsList: products,
      };

      let token = "";
      var payload = {
        data: {
          defaults: {
            baseURL: basketBaseUrl,
            token: token,
          },
          inputs: productObject,
        },
      };

      //https://acu-webhook-login.kwfl.workers.dev/ 
      //https://pull-request-worker-3.ravi-477.workers.dev/
      const pullRequestURL = "https://acu-webhook-login.kwfl.workers.dev/"

      // productObject.URL = 'https://kim.kwiat.com/entity/Kwiat24/23.200.001/PullRequest';

      this.setState({
        isLoading: true
      })

      const pullRequestRes = await axios.post(pullRequestURL, productObject)

      console.log("pullRequestRes--------------->", pullRequestRes)

      const {success, data, } = pullRequestRes.data

      var response = ''

      // var response = await axios
      //   .post(ApiBaseUrl + "exportpr", payload, {
      //     headers: { "x-api-key": ApiKey },
      //   })
      //   .then((res) => {
      //     return res;
      //   })
      //   .catch((err) => {
      //     console.log("Error", err);
      //     // this.props.toggleLoader({
      //     //   isLoading: false,
      //     // });
      //     return;
      //   });
      if (success) {
        // const pattern = /PR-\d+/;
        // const match = response.data.data.match(pattern);
        // const prNumber = match[0];
        const pattern = /PR-\d+/;
        //const pattern = /# \d+/;
        //const prNumberMatch = response?.data?.data.match(pattern);
        const prNumberMatch = data?.ResponseData?.value.match(pattern);
        const prNumber = prNumberMatch ? prNumberMatch[0] : '';
        const prMessagePattern = /([^.]*\.)|(Serial [^]*?)(?=\bSerial|$)/g;
        //const prMessageMatch = response?.data?.data.match(prMessagePattern);
        const prMessageMatch = data?.ResponseData?.value.match(prMessagePattern);
        const prMessage = prMessageMatch ? prMessageMatch.join('\n') : '';
        //const prMessage = data?.ResponseData?.value
        this.setState({
          prNumber: prNumber,
          prMessage: prMessage,
          isExportModalOpen: true,
        });
      }
    } catch (error) {
      toast.error('something went wrong', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        progress: undefined,
        dangerouslySetInnerHTML: true,
        });
      console.error('Error updating data:', error);
    } finally{
      this.setState({
        isLoading: false
      })
    }
  };

  handleEnterForChange(selectedOption) {
    this.setState({ enteredFor: selectedOption });
  }
  handleEnterByChange(selectedOption) {
    this.setState({ enteredBy: selectedOption });
  }

  handleNotes(value) {
    this.setState({ notes: value });
  }
  handleCheckboxChange(event) {
    const isChecked = event.target.checked;
    const value = isChecked ? 1 : 0;
    this.setState({ isExclusive: value });
  }

  closeBasketToAcuModalPopup = () => {
    this.setState({ isExportModalOpen: false });
    this.props.onClose();
  };
  render() {
    const {
      enteredBy,
      enteredFor,
      siteId,
      notes,
      prNumber,
      prMessage,
      isExportModalOpen,
      isExclusive,
    } = this.state;
    const { isOpen, onClose } = this.props;
    const enteredByOptions = Array.isArray(enteredBy) ? enteredBy : [];
    const enteredForOptions = Array.isArray(enteredFor) ? enteredFor : [];
    return (
      <div className="modal-body d-flex justify-content-center align-items-center">
        <div>
          <Modal
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
            className="choose-layout-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Pull Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row mb-3" style={{ marginBottom: "10px" }}>
                  <div className="col-md-2 d-flex">
                    <label className="mb-0">Entered By:</label>
                  </div>
                  <div className="col-md-9" >
                    <Select
                      value={this.state.enteredBy}
                      onChange={this.handleEnterByChange}
                      onInputChange={(inputValue) =>
                        this.handleInputChange("enteredBy", inputValue)
                      }
                      options={enteredByOptions}
                      placeholder="Search..."
                    // className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginBottom: "10px" }}>
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="mb-0">Entered For:</label>
                  </div>
                  <div className="col-md-9">
                    <Select
                      value={this.state.enteredFor}
                      onChange={this.handleEnterForChange}
                      onInputChange={(inputValue) =>
                        this.handleInputChange("enteredFor", inputValue)
                      }
                      options={enteredForOptions}
                      placeholder="Search..."
                    // className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginBottom: "10px" }}>
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="mb-2">Warehouse:</label>
                  </div>
                  <div className="col-md-9">
                    <select
                      name="siteId"
                      value={siteId}
                      onChange={this.handleSiteIdChange}
                      className="form-control"
                    >
                      <option value="232">MADAVE</option>
                    </select>
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="mb-4">Notes:</label>
                  </div>
                  <div className="col-md-9">
                    <textarea

                      name="notes"
                      rows={3}
                      value={notes}
                      onChange={(e) => this.handleNotes(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginBottom: "10px" }}>
                  <div className="col-md-2 d-flex">
                    <label className="mb-0">Exclusive:</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="checkbox"
                      checked={isExclusive === 1}
                      onChange={this.handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="" variant="secondary" onClick={onClose} disabled={this.state.isLoading}>
                Close
              </Button>
              <Button
                className=""
                variant="primary"
                onClick={this.handleSave}
                disabled={this.state.isLoading}
                style={{
                  paddingRight: this.state.isLoading ? '30px': ''
                }}
              >
                {this.state.isLoading?<>
                  <span className="spinner"></span>
                    Loading...
                </>:'Send'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {isExportModalOpen && (
          <BasketToAcuExportModal
            prNumber={prNumber}
            prMessage={prMessage}
            show={isExportModalOpen}
            hide={this.closeBasketToAcuModalPopup}
          />
        )}
      </div>
    );
  }
}
export default BasketToAcuModal;
