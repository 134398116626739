import React from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import {
  openCartItems,
  setSortOption,
  removeFromCart,
} from "../../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions.items,
  };
};

const SortableItem = SortableElement((props) => {
  const { value: item, thumbnailImage } = props;
  return (
    <div className="desktop__items__box">
      <div className="remove_desktop_product">
        <span
          onClick={() => {
            props.removeFromCart(item);
            toast.success("Removed from basket !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              pauseOnHover: false,
            });
          }}
        >
          X
        </span>
      </div>
      <div className="item__serial__number">{item.SerialNumber}</div>
      <div className="item__thumbnail__box">
        {thumbnailImage(item) ? (
          <img
            className="product-thumbnail"
            src={thumbnailImage(item)}
            alt="product"
          />
        ) : (
          <ReactImageFallback
            fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
            alt=""
            className="product-thumbnail"
          />
        )}
      </div>
      <div className="items__details">
        <div className="items__style__number">
          {/* <label>Style Number:</label> */}
          {/* <label># </label> */}
          <div>{item.StyleNumber}</div>
        </div>
        <div className="items__retail__price">
          <label>Retail:</label>
          <span>
            {currencyFormatter.format(`${item.RetailPrice}`, {
              code: "USD",
              precision: 0,
            })}
          </span>
        </div>
        <div className="items__wholesale__price">
          <label>Wholesale:</label>
          <span>
            {(item.WholesalePrice &&
              currencyFormatter.format(`${item.WholesalePrice}`, {
                code: "USD",
                precision: 0,
              })) ||
              "$0"}
          </span>
        </div>
      </div>
    </div>
  );
});

const SortableList = SortableContainer((props) => {
  const { items, thumbnailImage, ...restProps } = props;
  return (
    <div className="sortable__item__container less__details__container">
      {items.map((el, index) => {
        var idForDrag = `${
          el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
        }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
          el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
        }-${el.priceVisibility.replaceAll(/\s/g, "")}`;
        return (
          <SortableItem
            key={`item-${idForDrag}`}
            index={index}
            value={el}
            thumbnailImage={thumbnailImage}
            {...restProps}
          />
        );
      })}
    </div>
  );
});

class ItemsCondensedMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.cartItems,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.cartItems) {
      this.setState({
        data: nextProps.cartItems,
      });
    }
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    var sorted;
    sorted = arrayMove(this.state.data, oldIndex, newIndex);
    this.setState({
      data: sorted,
    });
    this.props.openCartItems(sorted);
    this.props.setSortOption([]);
  };
  render() {
    return (
      <div>
        <SortableList
          distance={2}
          helperClass="sortableHelper"
          axis="xy"
          items={this.state.data}
          thumbnailImage={this.props.thumbnailImage}
          onSortEnd={this.onSortEnd}
          removeFromCart={this.props.removeFromCart}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openCartItems,
      setSortOption,
      removeFromCart,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemsCondensedMode);
