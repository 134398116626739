import React, { Component } from "react";
import {
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  basketApikey,
  appbaseBasketUrl,
  AppbaseBasketApp,
} from "../../utils/constants";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { bindActionCreators } from "redux";
import {
  resetStates,
  setBasketFormInput,
  openCartItems,
  toggleLoader,
  setToken,
} from "../../actions/index";
import GetAuthToken from "../Api/Authenticate";
import {
  fetchBasket,
  getUniqueItemWithQty,
  getItemFromAppBase,
} from "./ReadAndOpenBasket";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    tokenState: state.tokenState,
  };
};

class SaveExisting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allBasketSelected: "",
      myBasketSelected: "",
    };
    this.handleAllBasketChange = this.handleAllBasketChange.bind(this);
    this.handleMyBasketChange = this.handleMyBasketChange.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.openOverridenBasket = this.openOverridenBasket.bind(this);
  }

  handleAllBasketChange(selectedOption) {
    if (selectedOption.value === "default") {
      // console.log("value: ", selectedOption);
      this.setState({
        allBasketSelected: null,
      });
    } else {
      // console.log("value: ", selectedOption);
      this.setState({
        allBasketSelected: selectedOption,
      });
    }
  }

  handleMyBasketChange(selectedOption) {
    if (selectedOption.value === "default") {
      // console.log("value: ", selectedOption);
      this.setState({
        myBasketSelected: null,
      });
    } else {
      // console.log("value: ", selectedOption);
      this.setState({
        myBasketSelected: selectedOption,
      });
    }
  }
  async handleSaveAs() {
    let { userLoggedIn, selectedItems, basketInputObj, cartItems } = this.props;
    if (!selectedItems.length) {
      toast.error("There are no items selected in the basket.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    }
    let { allBasketSelected, myBasketSelected } = this.state;
    var basketSelected;
    var currentdate = new Date();
    var dateTime =
      currentdate.getFullYear() +
      "" +
      (currentdate.getMonth() + 1) +
      "" +
      currentdate.getDate() +
      "" +
      currentdate.getHours() +
      "" +
      currentdate.getMinutes() +
      "" +
      currentdate.getSeconds();
    if (myBasketSelected && allBasketSelected) {
      alert("Please select single basket.");
      return;
    } else {
      basketSelected = myBasketSelected
        ? myBasketSelected.value
        : allBasketSelected
        ? allBasketSelected.value
        : null;
    }
    if (!basketSelected) {
      alert("Please select basket.");
      return;
    } else {
      this.props.toggleLoader({
        isLoading: true,
      });
      let data;
      var rawData = await fetchBasket(
        basketSelected
        // token: token,
      );
      // console.log("rawData : ", rawData);
      if (!rawData || rawData.statusCode !== 200) {
        this.props.toggleLoader({
          isLoading: false,
        });
        return;
      }
      if (
        rawData.data._source.OrdersList &&
        rawData.data._source.OrdersList.value
      ) {
        data = rawData.data._source;
        // console.log("data: ", data);
      }
      let prevOrderList = JSON.parse(data.OrdersList.value.replace(/'/g, '"'));
      // console.log("prevOrderList: ", prevOrderList);
      // this.props.resetStates();
      // Save basket Api integration, Send OrderNbr instead of "new"
      // let { basketInputObj, cartItems } = this.props;
      // let { items } = cartItems;
      let items =
        selectedItems && selectedItems.length ? selectedItems : cartItems.items;
      var contactInput = basketInputObj.contact.ContactId;
      var customerInput = basketInputObj.customer.CustomerId;
      // let user;
      // if (window.parent.document.getElementById("btnUserMenu")) {
      //   user = window.parent.document
      //     .getElementById("btnUserMenu")
      //     .querySelector(".user-name").textContent;
      // } else {
      //   user = null;
      // }

      items = items.map((el) => {
        var productArr = {
          SerialNumber: el.SerialNumber,
          StyleNumber: el.StyleNumber,
          InternalNote: el.InternalNote,
          RetailPrice: el.RetailPrice,
          WholesalePrice: el.WholesalePrice,
          Quantity: el.quantity,
          PriceVisibility: el.priceVisibility,
          LinkVisibility: el.linkVisibility,
          ItemSelected: el.itemSelected,
          ProductType: el.productType,
          AssetOrMemo: el.assetOrmemo,
        };
        return productArr;
      });
      // console.log("items: ", items);
      items = prevOrderList.concat(items);
      // console.log("items: ", items);
      // var products = JSON.stringify(items).replace(/"/g, "'");
      var products = JSON.stringify(items);
      // console.log("products: ", products);
      var productObject = {
        BasketType: { value: basketInputObj.basketType },
        Contact: { value: contactInput === undefined ? null : contactInput },
        Customer: { value: customerInput === undefined ? null : customerInput },
        Description: {
          value: basketInputObj.desc === "" ? null : basketInputObj.desc,
        },
        IncludeRetailPrice: { value: basketInputObj.includeRetail },
        IncludeWholesalePrice: { value: basketInputObj.includeWholesale },
        InternalNotes: {
          value:
            basketInputObj.internalNotes === ""
              ? null
              : basketInputObj.internalNotes,
        },
        Occasion: {
          value:
            basketInputObj.occasion === "default"
              ? null
              : basketInputObj.occasion,
        },
        // OrderNbr: { value: basketSelected },
        OrdersList: {
          value: products,
        },
        // ActionType: { value: "SaveToExisting" },
        UILoggedInUser: { value: userLoggedIn },
        AssetOrMemo: {
          value:
            basketInputObj.assetOrmemo === ""
              ? null
              : basketInputObj.assetOrmemo,
        },
      };
      // console.log("saved...", productObject);
      // var token = this.props.tokenState.token;
      var payload = {
        data: {
          defaults: {
            // baseURL: basketBaseUrl,
            // token: token,
            index: AppbaseBasketApp,
          },
          inputs: {
            OrdersList: productObject.OrdersList,
            UpdatedAt: parseInt(dateTime),
          },
          // ActionType: { value: "SaveToExisting" },
          id: basketSelected,
        },
      };
      console.log("payload : ", payload);
      var resp = await axios
        .post(appbaseBasketUrl + "basket", payload, {
          headers: {
            "x-api-key": basketApikey,
          },
        })
        .catch((error) => {
          toast.error("Error while saving basket !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          this.props.toggleLoader({
            isLoading: false,
          });
        });
      if (resp && resp.data.statusCode === 200) {
        // console.log("resp : ", resp);
        toast.success("Basket saved !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        // Accordian styling
        if (document.querySelector("#basket__collapse__save-as")) {
          var collapseNode = document.querySelector(
            "#basket__collapse__save-as"
          );
          collapseNode.className = "collapse";
          var nodeAttr = collapseNode.getAttribute("aria-expanded");
          var btnNode = document.querySelector(".basket__save-as");
          var accordianNode = document.querySelector(".basket__action__group");
          if (nodeAttr === "true") {
            accordianNode.style.border = "1px solid transparent";
            btnNode ? (btnNode.className = "basket__save-as collapsed") : "";
          } else {
            accordianNode.style.border = "1px solid #000";
          }
        } else {
          if (document.querySelector(".basket__more__actions")) {
            document.querySelector(".basket__more__actions").click();
          }
        }
        // Fetch overridden basket.
        this.openOverridenBasket(resp.data.data._id);
      } else {
        console.log("Error while saving basket");
        toast.error("Error while saving basket !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }
  async openOverridenBasket(basketToOpen) {
    this.props.resetStates();
    // Read Basket api

    // let token = this.props.tokenState.token;
    var data;
    var rawData = await fetchBasket(
      basketToOpen
      // token: token,
    );
    // console.log("rawData : ", rawData);
    if (!rawData || rawData.statusCode !== 200) {
      this.props.toggleLoader({
        isLoading: false,
      });
      return;
    }
    // if (rawData.errorCode === 401) {
    //   let token = await GetAuthToken();
    //   if (token) {
    //     this.props.setToken(token.access_token);
    //     rawData = await fetchBasket({
    //       basketSelected: basketToOpen,
    //       token: token.access_token,
    //     });
    //   }
    // }
    if (
      rawData.data._source.OrdersList &&
      rawData.data._source.OrdersList.value
    ) {
      data = rawData.data._source;
      // console.log("data: ", data);
    }
    var desc = data.Description.value;
    var includeRetail = data.IncludeRetailPrice.value;
    var includeWholesale = data.IncludeWholesalePrice.value;
    var internalNotes = data.InternalNotes.value;
    var occasion = data.Occasion.value;
    var basketUserDetails = data.UILoggedInUser
      ? data.UILoggedInUser.value
      : "";
    var makePrivate = data.MakePrivate ? data.MakePrivate.value : false;
    var status = data.Status ? data.Status : "Active";
    var edit = data.Edit ? data.Edit.value : "Active";
    var assetOrmemo =
      data.AssetOrMemo &&
      data.AssetOrMemo.value &&
      data.AssetOrMemo.value !== true &&
      data.value !== false
        ? data.AssetOrMemo.value
        : "";
    var basketItems = JSON.parse(data.OrdersList.value.replace(/'/g, '"'));
    // var basketItems = JSON.parse(data.OrdersList.value);
    // console.log("basketItem : ", basketItems);
    this.props.setBasketFormInput({
      contact: {
        ContactId: data.Contact.value,
        DisplayName: data.ContactName.value,
        // ContactEmail: data.ContactEmail.value,
      },
      customer: {
        CustomerId: data.Customer.value,
        Customer: data.CustomerName.value,
        CustomerClass: data.CustomerClass.value,
      },
      desc: desc,
      internalNotes: internalNotes,
      occasion: occasion,
      includeRetail: includeRetail,
      includeWholesale: includeWholesale,
      basketUserDetails: basketUserDetails,
      makePrivate: makePrivate,
      status: status,
      edit: edit,
      assetOrmemo: assetOrmemo,
    });
    // Appbase call

    let uniqueArrayFromBasket = getUniqueItemWithQty(basketItems);
    var fromAppbaseCall = [];
    for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
      var res = await getItemFromAppBase({
        item: uniqueArrayFromBasket[i],
        searchOption: "mixed",
      });
      if (typeof res[0]["_source"] !== "object") {
        toast.error("Appbase fetching products error !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        continue;
      }
      let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
      fromAppbaseCall.push({
        ...omitted,
        RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
        WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
        quantity: uniqueArrayFromBasket[i].Quantity,
        InternalNote: uniqueArrayFromBasket[i].InternalNote,
        priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
        linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
          ? uniqueArrayFromBasket[i].LinkVisibility
          : "Default",
        itemSelected: uniqueArrayFromBasket[i].ItemSelected
          ? uniqueArrayFromBasket[i].ItemSelected
          : true,
        productType: uniqueArrayFromBasket[i].ProductType,
        LineNbr: uniqueArrayFromBasket[i].LineNbr,
        assetOrmemo:
          uniqueArrayFromBasket[i].AssetOrMemo &&
          uniqueArrayFromBasket[i].AssetOrMemo !== true &&
          uniqueArrayFromBasket[i].AssetOrMemo !== false
            ? uniqueArrayFromBasket[i].AssetOrMemo
            : "",
      });
    }
    // sort by LineNbr
    // fromAppbaseCall.sort(function(a, b) {
    //   return b.LineNbr - a.LineNbr;
    // });
    this.props.setBasketFormInput({ orderNbr: basketToOpen });
    this.props.openCartItems(fromAppbaseCall);
    this.props.toggleLoader({
      isLoading: false,
    });
  }

  render() {
    let {
      allBaskets,
      allMyBasketsSaveExisting,
      myBaskets,
      userLoggedIn,
      basketInputObj,
    } = this.props;
    // console.log("allMyBasketsSaveExisting: ", allMyBasketsSaveExisting);
    return (
      <div className="basket__saveas__grouped">
        <div className="my__basket__container">
          <label>My Baskets:</label>
          <div className="open__input">
            {/* <select onChange={this.handleMyBasketChange} defaultValue="">
              <option value="default">Select basket</option>
              {myBaskets.length === 0 ? (
                <option>Loading...</option>
              ) : (
                myBaskets.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {el._id && `#${el._id}`}
                      {el._source.ContactName.value
                        ? ` - ${el._source.ContactName.value}`
                        : ` - ${el._source.CustomerName.value}`}
                      {el._source.Description.value &&
                        ` - ${el._source.Description.value}`}
                    </option>
                  );
                })
              )}
            </select> */}
            <Select
              className="Select"
              value={this.state.myBasketSelected}
              onChange={this.handleMyBasketChange}
              options={[
                {
                  value: "default",
                  label:
                    myBaskets.length === 0 ? "Loading..." : "Select Basket",
                },
                ...myBaskets.map((basket) => {
                  // console.log("basket: ", basket);
                  return {
                    value: basket._id,
                    label: ` ${basket._id && `#${basket._id}`}${
                      basket._source.ContactName.value
                        ? ` - ${basket._source.ContactName.value}`
                        : basket._source.CustomerName.value
                        ? ` - ${basket._source.CustomerName.value}`
                        : ``
                    }${basket._source.Description.value &&
                      ` - ${basket._source.Description.value}`}`,
                  };
                }),
              ]}
              placeholder="Select basket"
            />
            {/* <button onClick={this.handleSaveAs}>Save</button> */}
          </div>
        </div>
        {/* {(basketInputObj.basketUserDetails &&
          userLoggedIn === basketInputObj.basketUserDetails &&
          basketInputObj.orderNbr !== "New") ||
        basketInputObj.orderNbr === "New" ? ( */}
        <div className="all__basket__container">
          <label>All Baskets:</label>
          <div className="open__input">
            {/* <select onChange={this.handleAllBasketChange} defaultValue="">
              <option value="default">Select basket</option>
              {allBaskets.length === 0 ? (
                <option>Loading...</option>
              ) : (
                allBaskets.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {el._id && `#${el._id}`}
                      {el._source.UILoggedInUser.value &&
                        ` - ${el._source.UILoggedInUser.value}`}
                      {el._source.ContactName.value
                        ? ` - ${el._source.ContactName.value}`
                        : ` - ${el._source.CustomerName.value}`}
                      {el._source.Description.value &&
                        ` - ${el._source.Description.value}`}
                    </option>
                  );
                })
              )}
            </select> */}
            <Select
              className="Select"
              value={this.state.allBasketSelected}
              onChange={this.handleAllBasketChange}
              options={[
                {
                  value: "default",
                  label:
                    allMyBasketsSaveExisting.length === 0
                      ? "Loading..."
                      : "Select Basket",
                },
                ...allMyBasketsSaveExisting.map((basket) => {
                  // console.log("basket: ", basket);
                  return {
                    value: basket._id,
                    label: `${basket._id && `#${basket._id}`}${
                      basket._source.UILoggedInUser &&
                      basket._source.UILoggedInUser.value !== null
                        ? ` - ${basket._source.UILoggedInUser.value}`
                        : ``
                    }${
                      basket._source.ContactName.value
                        ? ` - ${basket._source.ContactName.value}`
                        : basket._source.CustomerName.value
                        ? ` - ${basket._source.CustomerName.value}`
                        : ``
                    }${basket._source.Description.value &&
                      ` - ${basket._source.Description.value}`}`,
                  };
                }),
              ]}
              placeholder="Select basket"
            />
            <button onClick={this.handleSaveAs}>Save</button>
          </div>
        </div>
        {/* ) : (
          ``
        )}  */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetStates,
      setBasketFormInput,
      openCartItems,
      toggleLoader,
      setToken,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveExisting);
