import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";

class SortDropdown extends Component {
  render() {
    let { title, active, onSelect, soldSignal, activeTabKey } = this.props;
    return (
      <div className="dropdown-sort">
        <DropdownButton title={title} id={"sort"} className="sort-by">
          <ul className="nav navbar-nav nav-sort">
            <li
              className={active === "active1" ? "active" : "sort-item"}
              onClick={() =>
                onSelect("RetailPrice", "Retail - Asc", "asc", "active1")
              }
            >
              Retail - Asc
            </li>
            <li
              className={active === "active2" ? "active" : "sort-item"}
              onClick={() =>
                onSelect("RetailPrice", "Retail - Desc", "desc", "active2")
              }
            >
              Retail - Desc
            </li>
            <li
              className={active === "active3" ? "active" : "sort-item"}
              onClick={() =>
                onSelect("WholesalePrice", "Wholesale - Asc", "asc", "active3")
              }
            >
              Wholesale - Asc
            </li>
            <li
              className={active === "active4" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "WholesalePrice",
                  "Wholesale - Desc",
                  "desc",
                  "active4"
                )
              }
            >
              Wholesale - Desc
            </li>
            <li
              className={active === "active5" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "StyleNumber.keyword",
                  "Style # - Asc",
                  "asc",
                  "active5"
                )
              }
            >
              Style # - Asc
            </li>
            <li
              className={active === "active6" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "StyleNumber.keyword",
                  "Style # - Desc",
                  "desc",
                  "active6"
                )
              }
            >
              Style # - Desc
            </li>
            {activeTabKey === "serial" ? (
              <li
                className={active === "active7" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect(
                    // "SerialNumber.keyword",
                    "SerialSort",
                    "Serial # - Asc",
                    "asc",
                    "active7"
                  )
                }
              >
                Serial # - Asc
              </li>
            ) : (
              ""
            )}
            {activeTabKey === "serial" ? (
              <li
                className={active === "active8" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect(
                    // "SerialNumber.keyword",
                    "SerialSort",
                    "Serial # - Desc",
                    "desc",
                    "active8"
                  )
                }
              >
                Serial # - Desc
              </li>
            ) : (
              ""
            )}
            {soldSignal ? (
              <li
                className={active === "active9" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect("SoldDate", "Sold Date # - Asc", "asc", "active9")
                }
              >
                Sold Date # - Asc
              </li>
            ) : (
              ""
            )}
            {soldSignal ? (
              <li
                className={active === "active10" ? "active" : "sort-item"}
                onClick={() =>
                  onSelect("SoldDate", "Sold Date # - Desc", "desc", "active10")
                }
              >
                Sold Date # - Desc
              </li>
            ) : (
              ""
            )}
            {/* <li
              className={active === "active11" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "Warehouse.keyword",
                  "Warehouse - Asc",
                  "asc",
                  "active11"
                )
              }
            >
              Warehouse - Asc
            </li>
            <li
              className={active === "active12" ? "active" : "sort-item"}
              onClick={() =>
                onSelect(
                  "Warehouse.keyword",
                  "Warehouse - Desc",
                  "desc",
                  "active12"
                )
              }
            >
              Warehouse - Desc
            </li> */}
          </ul>
        </DropdownButton>
      </div>
    );
  }
}

export default SortDropdown;
