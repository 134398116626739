import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class Metal extends Component {
  render() {
    return (
      <div className="metal">
        <MultiDropdownList
          className="form-field"
          title="Metal"
          componentId="Metal"
          dataField={"Metal.keyword"}
          size={100}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={this.props.appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(Metal);
