import React, { Component } from "react";
import { SingleDropdownList, MultiList } from "@appbaseio/reactivesearch";
const item_order = [
  "2.01",
  "2.02",
  "1.01",
  "0.70",
  "1.51",
  "1.02",
  "1.00",
  "3.02",
  "1.50",
  "0.71",
  "1.03",
  "3.01",
  "2.03",
  "0.90",
  "0.72",
  "1.04",
  "1.06",
  "1.20",
  "1.52",
  "0.73",
  "1.05",
  "0.91",
  "0.75",
  "3.03",
  "0.74",
  "2.51",
  "1.07",
  "1.21",
  "2.04",
  "0.80",
  "1.22",
  "1.08",
  "1.70",
  "1.11",
  "2.50",
  "0.00",
  "1.10",
  "1.53",
  "2.05",
  "4.01",
  "0.77",
  "1.14",
  "1.17",
  "1.54",
  "2.52",
  "1.09",
  "0.79",
  "1.12",
  "1.24",
  "1.30",
  "1.55",
  "1.71",
  "1.72",
  "0.76",
  "1.15",
  "1.25",
  "1.80",
  "0.78",
  "0.81",
  "0.92",
  "3.04",
  "1.28",
  "1.57",
  "0.83",
  "0.93",
  "1.13",
  "2.06",
  "0.82",
  "1.32",
  "2.20",
  "4.02",
  "1.18",
  "1.16",
  "1.73",
  "2.08",
  "2.25",
  "1.90",
  "2.12",
  "0.85",
  "1.23",
  "1.59",
  "1.61",
  "1.62",
  "2.00",
  "2.15",
  "2.21",
  "2.40",
  "2.70",
  "3.05",
  "5.02",
  "0.50",
  "1.29",
  "1.41",
  "2.07",
  "2.22",
  "2.30",
  "2.53",
  "2.60",
  "5.01",
  "1.75",
];

class CenterSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: "",
      endValue: ""
    };
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.mapOrder = this.mapOrder.bind(this);
  }
  handleStartChange(value) {
    this.setState({
      startValue: value
    });
  }
  handleEndChange(value) {
    this.setState({
      endValue: value
    });
  }
  mapOrder(array, order, key) {
    array.sort(function(a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }

  render() {
    let { startValue, endValue } = this.state;
    let value;
    if (!startValue && !endValue) {
      value = null;
    } else if (startValue && !endValue) {
      value = item_order.slice(item_order.indexOf(startValue));
    } else if (!startValue && endValue) {
      value = item_order.slice(0, item_order.indexOf(endValue) + 1);
    } else {
      value = item_order.slice(
        item_order.indexOf(startValue),
        item_order.indexOf(endValue) + 1
      );
    }
    return (
      <div className="center-size-range form-field-wrap">
        <div className="size-input-dropdowns-container">
          <h2>Center Size</h2>
          <div className="range-dropdowns">
            <SingleDropdownList
              componentId="CenterSizeStart"
              className="start-range"
              dataField="CenterCaratWeight.keyword"
              size={100}
              placeholder=""
              sortBy="count"
              showCount={false}
              showFilter={true}
              filterLabel="DiaStartCut"
              onValueChange={value => this.handleStartChange(value)}
              transformData={list => {
                var ordered_array;
                ordered_array = this.mapOrder(list, item_order, "key");
                return ordered_array;
              }}
            />
            <span> to </span>
            <SingleDropdownList
              componentId="CenterSizeEnd"
              className="end-range"
              dataField="CenterCaratWeight.keyword"
              size={100}
              placeholder=""
              sortBy="count"
              showCount={false}
              showFilter={true}
              filterLabel="DiaEndCut"
              onValueChange={value => this.handleEndChange(value)}
              transformData={list => {
                var ordered_array;
                ordered_array = this.mapOrder(list, item_order, "key");
                return ordered_array;
              }}
            />
          </div>
          <MultiList
            componentId="CenterSize"
            className="hide__multilist"
            dataField="CenterCaratWeight.keyword"
            value={value}
            size={100}
            sortBy="asc"
            queryFormat="or"
            showSearch={false}
            showFilter={false}
            react={{
              or: ["CenterSizeStart", "CenterSizeEnd"]
            }}
          />
        </div>
      </div>
    );
  }
}

export default CenterSize;
