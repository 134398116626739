import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { holdNotesFields } from "./holdNotesField";
import Select from "react-select";
import { index } from "appbase-js";
import moment from "moment";
import CustomerSearch from "./Basket/CustomerSearch";
import { connect } from "react-redux";
import axios from "axios";
import { holdNoteApi, holdNoteApiKey } from "../utils/constants";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { toggleLoader } from "../actions";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
  };
};
class HoldNoteCreateModal extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      // res: this.props.res,
      // holdDate: `${date.getFullYear()}-${
      //   date.getMonth() + 1 < 10
      //     ? `0${date.getMonth() + 1}`
      //     : date.getMonth() + 1
      // }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`,
      holdDate: date.toISOString().substr(0, 10),
      holdRelease: "",
      feedRestrictions: "",
      jewelryHoldRectriction: "",
      customerName: "",
      enteredFor: "",
      enteredBy:
        this.props.basketInputObj && this.props.employeeData
          ? this.props.employeeData
              .map((emp) => {
                if (
                  emp._source.EmployeeLogin ===
                  this.props.basketInputObj.user.EmployeeLogin
                ) {
                  return {
                    value: emp._source.EmployeeDBID,
                    label: `${emp._source.FirstName} ${emp._source.LastName}`,
                  };
                }
              })
              .filter((data) => ![undefined, null, ""].includes(data))[0]
          : "",
      holdNoteText: "",
      isSold: "",
      isProposed: false,
      isSavePopUp: false,
      IsMTSCustomer: false,
      basketItems: this.props.basketItems
        ? this.props.basketItems.map((item) => {
            // if (![null, undefined, ""].includes(item.HoldNoteID)) {
            return { ...item, checked: true };
            // }
            // return item;
          })
        : "",
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleSingleItemSave = this.handleSingleItemSave.bind(this);
    this.handleBasketItemSave = this.handleBasketItemSave.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleCustomerSelection = this.handleCustomerSelection.bind(this);
    this.handleMultiOptionDelete = this.handleMultiOptionDelete.bind(this);
    this.handleItemSelected = this.handleItemSelected.bind(this);
    // this.loadOptions = this.loadOptions.bind(this);
    // this.handleItemSelected = this.handleItemSelected.bind(this);
  }
  componentDidMount() {
    if (this.props.editHoldNote) {
      this.setState({
        holdDate:
          this.props.res.HoldDate &&
          moment(new Date(`${this.props.res.HoldDate}`)).format("YYYY-MM-DD"),
        holdRelease:
          this.props.res.ReleaseDate &&
          moment(new Date(`${this.props.res.ReleaseDate}`)).format(
            "YYYY-MM-DD"
          ),
        feedRestrictions:
          this.props.feedRestrictionsData && this.props.res.FeedRestrictions
            ? this.props.res.FeedRestrictions.split(",")
                .map((value) => {
                  let feed = this.props.feedRestrictionsData.filter(
                    (feeds) => feeds._source.ValueID === value
                  );
                  if (feed.length !== 0) {
                    return {
                      value: feed[0]._source.ValueID,
                      label: feed[0]._source.Description,
                    };
                  }
                })
                .filter((data) => ![undefined, null].includes(data))
            : "",
        jewelryHoldRectriction:
          this.props.jewelryRestrictionData &&
          this.props.res.JewelryHoldRestrictions
            ? this.props.res.JewelryHoldRestrictions.split(",")
                .map((value) => {
                  let jewelryHold = this.props.jewelryRestrictionData.filter(
                    (jewelry) => jewelry._source.ValueID === value
                  );
                  if (jewelryHold.length !== 0) {
                    return {
                      value: jewelryHold[0]._source.ValueID,
                      label: jewelryHold[0]._source.Description,
                    };
                  }
                })
                .filter((data) => ![undefined, null].includes(data))
            : "",
        customerName: {
          Customer: this.props.res.HoldCustomerName
            ? this.props.res.HoldCustomerName
            : "",
          CustomerId: this.props.res.HoldCustomerId
            ? this.props.res.HoldCustomerId
            : "",
        },
        enteredFor: {
          value: this.props.res.HoldEnteredForID,
          label: this.props.res.HoldEnteredForName,
        },
        enteredBy: {
          value: this.props.res.HoldEnteredByID,
          label: this.props.res.HoldEnteredByName,
        },
        holdNoteText: this.props.res.HoldText ? this.props.res.HoldText : "",
        isSold: this.props.res.IsHoldSold
          ? this.props.res.IsHoldSold === "1"
            ? true
            : false
          : false,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.employeeData !== nextProps.employeeData) {
    this.setState(
      {
        enteredBy:
          nextProps.basketInputObj && nextProps.employeeData
            ? nextProps.employeeData
                .map((emp) => {
                  if (
                    emp._source.EmployeeLogin ===
                    nextProps.basketInputObj.user.EmployeeLogin
                  ) {
                    return {
                      value: emp._source.EmployeeDBID,
                      label: `${emp._source.FirstName} ${emp._source.LastName}`,
                    };
                  }
                })
                .filter((data) => ![undefined, null, ""].includes(data))[0]
            : "",
        basketItems: nextProps.basketItems
          ? nextProps.basketItems.map((item) => {
              // if (![null, undefined, ""].includes(item.HoldNoteID)) {
              return { ...item, checked: true };
              // }
              // return item;
            })
          : "",
      }
      // () => console.log("entered by: ", this.state.enteredBy)
    );
    // }
    if (
      nextProps.editHoldNote &&
      JSON.stringify(nextProps.res) !== JSON.stringify(this.props.res)
    ) {
      this.setState({
        holdDate:
          nextProps.res.HoldDate &&
          moment(new Date(`${nextProps.res.HoldDate}`)).format("YYYY-MM-DD"),
        holdRelease:
          nextProps.res.ReleaseDate &&
          moment(new Date(`${nextProps.res.ReleaseDate}`)).format("YYYY-MM-DD"),
        feedRestrictions:
          nextProps.feedRestrictionsData && nextProps.res.FeedRestrictions
            ? nextProps.res.FeedRestrictions.split(",")
                .map((value) => {
                  let feed = nextProps.feedRestrictionsData.filter(
                    (feeds) => feeds._source.ValueID === value
                  );
                  if (feed.length !== 0) {
                    return {
                      value: feed[0]._source.ValueID,
                      label: feed[0]._source.Description,
                    };
                  }
                })
                .filter((data) => ![undefined, null].includes(data))
            : "",
        jewelryHoldRectriction:
          nextProps.jewelryRestrictionData &&
          nextProps.res.JewelryHoldRestrictions
            ? nextProps.res.JewelryHoldRestrictions.split(",")
                .map((value) => {
                  let jewelryHold = nextProps.jewelryRestrictionData.filter(
                    (jewelry) => jewelry._source.ValueID === value
                  );
                  if (jewelryHold.length !== 0) {
                    return {
                      value: jewelryHold[0]._source.ValueID,
                      label: jewelryHold[0]._source.Description,
                    };
                  }
                })
                .filter((data) => ![undefined, null].includes(data))
            : "",
        customerName: {
          Customer: nextProps.res.HoldCustomerName
            ? nextProps.res.HoldCustomerName
            : "",
          CustomerId: nextProps.res.HoldCustomerId
            ? nextProps.res.HoldCustomerId
            : "",
        },
        enteredFor: {
          value: nextProps.res.HoldEnteredForID,
          label: nextProps.res.HoldEnteredForName,
        },
        enteredBy: {
          value: nextProps.res.HoldEnteredByID,
          label: nextProps.res.HoldEnteredByName,
        },
        holdNoteText: nextProps.res.HoldText ? nextProps.res.HoldText : "",
        isSold: nextProps.res.IsHoldSold
          ? nextProps.res.IsHoldSold === "1"
            ? true
            : false
          : false,
      });
    }
  }

  handleItemSelected(serialID, value) {
    if (serialID === "All") {
      this.setState((prevState) => ({
        basketItems: prevState.basketItems.map((item) => {
          if (![null, undefined, ""].includes(item.SerialNumber)) {
            return { ...item, checked: value };
          } else {
            return item;
          }
        }),
      }));
    } else {
      this.setState((prevState) => ({
        basketItems: prevState.basketItems.map((item) => {
          if (serialID === item.SerialNumber) {
            return { ...item, checked: value };
          } else {
            return item;
          }
        }),
      }));
    }
  }

  handleMultiOptionDelete(value, type) {
    this.setState((prevState) => ({
      ...(type === "feeds"
        ? {
            feedRestrictions: prevState.feedRestrictions.filter(
              (feed) => feed.value !== value
            ),
          }
        : {}),
      ...(type === "jewelryHold"
        ? {
            jewelryHoldRectriction: prevState.jewelryHoldRectriction.filter(
              (jewelryHold) => jewelryHold.value !== value
            ),
          }
        : {}),
    }));
  }

  handleCustomerSelection(value) {
    this.setState({ customerName: value });
  }

  handleSelection(type) {
    const _onChange = (selectedOption) => {
      if (selectedOption.value === "default") {
        // console.log("value: ", selectedOption);
        this.setState({
          ...(type === "cust" ? { customerName: null } : {}),
          ...(type === "feed" ? { feedRestrictions: null } : {}),
          ...(type === "jewelry" ? { jewelryHoldRectriction: null } : {}),
          ...(type === "for" ? { enteredFor: null } : {}),
          ...(type === "by" ? { enteredBy: null } : {}),
        });
      } else {
        // console.log("value: ", selectedOption);
        this.setState(
          {
            ...(type === "cust" ? { customerName: selectedOption } : {}),
            ...(type === "feed"
              ? {
                  feedRestrictions: Array.isArray(selectedOption)
                    ? selectedOption
                    : [selectedOption],
                }
              : {}),
            ...(type === "jewelry"
              ? {
                  jewelryHoldRectriction: Array.isArray(selectedOption)
                    ? selectedOption
                    : [selectedOption],
                }
              : {}),
            ...(type === "for" ? { enteredFor: selectedOption } : {}),
            ...(type === "by" ? { enteredBy: selectedOption } : {}),
          }
          // () => console.log("feeds: ", this.state.feedRestrictions)
        );
      }
    };
    return _onChange;
  }

  async handleSingleItemSave() {
    this.props?.handleBulkNoteClick && this.props?.handleBulkNoteClick(false)
    // console.log(" hold note state: ", this.state);
    // let date = new Date();
    if (
      ![null, undefined, ""].includes(this.state.holdRelease)
      // &&
      // this.state.holdDate.toString().replaceAll("-", "") >=
      //   date.toISOString().substr(0, 10).toString().replaceAll("-", "")
    ) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let holdNoteText = this.state.holdNoteText;
      if (this.state.isProposed) {
        // // Append "PROPOSED ON" to the beginning of hold note text
        // holdNoteText = `PROPOSED ON ${this.state.holdDate} ${this.state.holdNoteText}`;
        const proposedOnIndex = holdNoteText.indexOf(`PROPOSED ON ${this.state.holdDate}`);
          if (proposedOnIndex !== -1) {
            holdNoteText = holdNoteText.slice(0, proposedOnIndex) + holdNoteText.slice(proposedOnIndex + (`PROPOSED ON ${this.state.holdDate}`).length);
          }
          holdNoteText = `PROPOSED ON ${this.state.holdDate} ${holdNoteText}`;
      }
      let payload = {
        data: {
          HoldDate: this.state.holdDate && this.state.holdDate,
          HoldReleaseDate: this.state.holdRelease && this.state.holdRelease,
          FeedRestrictions:
            this.state.feedRestrictions &&
            this.state.feedRestrictions
              .map((feed) => {
                return feed.value;
              })
              .join(","),
          JewelryHoldRestrictions:
            this.state.jewelryHoldRectriction &&
            this.state.jewelryHoldRectriction
              .map((jewelry) => {
                return jewelry.value;
              })
              .join(","),
          CustomerName:
            this.state.customerName && this.state.customerName.CustomerId,
          EnteredFor: this.state.enteredFor && this.state.enteredFor.value,
          EnteredBy: this.state.enteredBy && this.state.enteredBy.value,
          // HoldNoteText: this.state.holdNoteText && this.state.holdNoteText,
          HoldNoteText: holdNoteText,
          IsSold: this.state.isSold && this.state.isSold ? true : false,
          IsProposed: this.state.isProposed,
          SerialNbr: this.props.res && this.props.res.SerialNumber,
          InventoryID: this.props.res && this.props.res.InventoryID,
          IsNew:
            this.props.editHoldNote && this.props.editHoldNote
              ? "False"
              : "True",
        },
      };
      if(this.state.customerName && this.props?.res?.index !== "kwfl-acumatica-catalog-v7-prod-gemstoneserial3"){
        if(this.state.customerName && this.props?.res?.index === "kwfl-acumatica-catalog-v7-prod-jewelryserial2"){
          if(this.state.customerName && this.props?.res?.IsDiaExists === "Yes"){
            payload.data.IsMTSCustomer = this.state.IsMTSCustomer
          }
        }else{
          payload.data.IsMTSCustomer = this.state.IsMTSCustomer
        }
        //payload.data.IsMTSCustomer = this.state.IsMTSCustomer
      }
      var resp = await axios
        .post(holdNoteApi + "updateitemholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          toast.error("Error while saving hold note !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          this.props.toggleLoader({
            isLoading: false,
          });
        });
      // console.log("resp: ", resp);
      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        toast.success("Hold Note Saved !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        // console.log(" hold note state before updated res res: ", this.state);
        let updatedRes = {
          ...this.props.res,
          HoldCustomerId:
            this.state.customerName && this.state.customerName.CustomerId,
          HoldCustomerName:
            this.state.customerName && this.state.customerName.Customer,
          HoldDate: this.state.holdDate && `${this.state.holdDate}T00:00:00`,
          HoldEnteredByID: this.state.enteredBy && this.state.enteredBy.value,
          HoldEnteredByName: this.state.enteredBy && this.state.enteredBy.label,
          HoldEnteredForID:
            this.state.enteredFor && this.state.enteredFor.value,
          HoldEnteredForName:
            this.state.enteredFor && this.state.enteredFor.label,
          // HoldText: this.state.holdNoteText && this.state.holdNoteText,
          HoldText: holdNoteText,
          IsProposed: this.state.isProposed,
          ReleaseDate:
            this.state.holdRelease && `${this.state.holdRelease}T00:00:00`,
          IsHoldSold: this.state.isSold && this.state.isSold ? "1" : "0",
        };
        // console.log({ updatedRes });
        this.props.handleItemHoldNoteUpdate(updatedRes);
        this.onModalHide();
        this.props.toggleLoader({
          isLoading: false,
        });
      } else {
        toast.error("Error while saving hold note !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    } else {
      window.alert("Hold release date is necessary");
    }
  }
  async handleBasketItemSave() {
    this.props?.handleBulkNoteClick && this.props?.handleBulkNoteClick(false)
    // console.log(" hold note state: ", this.state);
    if (
      this.state.basketItems
        .filter((item) => item.checked === true)
        .filter((item) => ![null, undefined, ""].includes(item)).length !== 0
    ) {
      let checkedItems = this.state.basketItems.filter(item => item.checked === true && ![null, undefined, ""].includes(item.SerialNumber));
      // let date = new Date();
      if (
        ![null, undefined, ""].includes(this.state.holdRelease)
        // &&
        // this.state.holdDate.toString().replaceAll("-", "") >= date.toISOString().substr(0, 10).toString().replaceAll("-", "")
      ) {
        this.props.toggleLoader({
          isLoading: true,
        });

        let holdNoteText = this.state.holdNoteText;
        if (this.state.isProposed) {
          // Append "PROPOSED ON" to the beginning of hold note text
          holdNoteText = `PROPOSED ON ${this.state.holdDate} ${this.state.holdNoteText}`;
        }
        // console.log(" hold note state: ", this.state);
        let payload = {
          data: {
            HoldDate: this.state.holdDate && this.state.holdDate,
            HoldReleaseDate: this.state.holdRelease && this.state.holdRelease,
            FeedRestrictions:
              this.state.feedRestrictions &&
              this.state.feedRestrictions
                .map((feed) => {
                  return feed.value;
                })
                .join(","),
            JewelryHoldRestrictions:
              this.state.jewelryHoldRectriction &&
              this.state.jewelryHoldRectriction
                .map((jewelry) => {
                  return jewelry.value;
                })
                .join(","),
            CustomerName:
              this.state.customerName && this.state.customerName.CustomerId,
            EnteredFor: this.state.enteredFor && this.state.enteredFor.value,
            EnteredBy: this.state.enteredBy && this.state.enteredBy.value,
            // HoldNoteText: this.state.holdNoteText && this.state.holdNoteText,
            HoldNoteText: holdNoteText,
            IsSold: this.state.isSold && this.state.isSold ? true : false,
            IsProposed: this.state.isProposed,
            // SerialItemsList: JSON.stringify(
            //   this.state.basketItems
            //     .filter((item) => item.checked === true)
            //     .filter((item) => ![null, undefined, ""].includes(item))
            //     .map((item) => {
            //       return {
            //         SerialNbr: item.SerialNumber,
            //         InventoryID: item.InventoryID,
            //       };
            //     })
            // ),
            SerialItemsList: JSON.stringify(
              checkedItems.map((item) => {
                  return {
                      SerialNbr: item.SerialNumber,
                      InventoryID: item.InventoryID,
                  };
              })
            ),
            // InventoryID: this.props.res.InventoryID,
            IsNew:
              this.props.editHoldNote && this.props.editHoldNote
                ? "False"
                : "True",
          },
        };
        const {basketItems} = this.state
        const checkIsMTS = basketItems.filter(obj=>obj.checked).some(obj => obj.IsMTS === "1");
        if(checkIsMTS && this.state.customerName){
          payload.data.IsMTSCustomer = this.state.IsMTSCustomer
        }
        var resp = await axios
          .post(holdNoteApi + "updatebasketitemholdnotes", payload, {
            headers: {
              "x-api-key": holdNoteApiKey,
            },
          })
          .catch((error) => {
            console.log("error :", error);
            // toast.error("Error while saving !", {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            //   pauseOnHover: false,
            // });
            // this.props.toggleLoader({
            //   isLoading: false,
            // });
          });
        // console.log("resp: ", resp);
        if (
          resp &&
          resp.data &&
          resp.data.statusCode &&
          resp.data.statusCode === 200
        ) {
          toast.success("Hold Note Saved !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          let updatedRes = {
            HoldCustomerId:
              this.state.customerName && this.state.customerName.CustomerId,
            HoldCustomerName:
              this.state.customerName && this.state.customerName.Customer,
            HoldDate: this.state.holdDate && `${this.state.holdDate}T00:00:00`,
            HoldEnteredByID: this.state.enteredBy && this.state.enteredBy.value,
            HoldEnteredByName:
              this.state.enteredBy && this.state.enteredBy.label,
            HoldEnteredForID:
              this.state.enteredFor && this.state.enteredFor.value,
            HoldEnteredForName:
              this.state.enteredFor && this.state.enteredFor.label,
            // HoldText: this.state.holdNoteText && this.state.holdNoteText,
            HoldText: holdNoteText,
            IsProposed: this.state.isProposed,
            ReleaseDate:
              this.state.holdRelease && `${this.state.holdRelease}T00:00:00`,
            IsHoldSold: this.state.isSold && this.state.isSold ? "1" : "0",
          };
          let items = this.state.basketItems
            .filter((item) => item.checked === true)
            .filter((item) => ![null, undefined, ""].includes(item))
            .map((item) => {
              return {
                SerialNumber: item.SerialNumber,
                InventoryID: item.InventoryID,
              };
            });
          this.props.handleItemHoldNoteUpdate(updatedRes, checkedItems);
          this.props.toggleLoader({
            isLoading: false,
          });
          // this.props.handleItemRefresh();
          this.onModalHide();
        } else {
          toast.error("Error while saving hold note !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            pauseOnHover: false,
          });
          this.props.toggleLoader({
            isLoading: false,
          });
        }
      } else {
        window.alert("Hold release date is necessary");
      }
    } else {
      toast.error("There are no serials to add this hold note.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
    }
  }

  onModalHide() {
    let { hide } = this.props;
    let date = new Date();
    this.setState({
      holdDate: date.toISOString().substr(0, 10),
      holdRelease: "",
      feedRestrictions: "",
      jewelryHoldRectriction: "",
      customerName: "",
      enteredFor: "",
      enteredBy:
        this.props.basketInputObj && this.props.employeeData
          ? this.props.employeeData
              .map((emp) => {
                if (
                  emp._source.EmployeeLogin ===
                  this.props.basketInputObj.user.EmployeeLogin
                ) {
                  return {
                    value: emp._source.EmployeeDBID,
                    label: `${emp._source.FirstName} ${emp._source.LastName}`,
                  };
                }
              })
              .filter((data) => ![undefined, null, ""].includes(data))[0]
          : "",
      holdNoteText: "",
      isSold: "",
      isProposed: false,
    });
    this.setState(() => {
      hide && hide(null, false, "createHoldNotes", "");
    });
  }

  render() {
    let {
      show,
      customers,
      feedRestrictionsData,
      jewelryRestrictionData,
      employeeData,
      holdNotesData,
      editHoldNote,
      res,
    } = this.props;
    let { basketItems, isProposed  } = this.state;
    //console.log("basketItems", basketItems)
    return (
      <Modal
        show={show}
        onHide={() => {
          this.props?.handleBulkNoteClick && this.props?.handleBulkNoteClick(false)
          this.onModalHide()
        }}
        animation={false}
        // dialogClassName="show-more_modal-dailog"
        className="hold_notes_create_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Hold Note Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="hold_notes_container">
            {((holdNotesData &&
              holdNotesData
                .filter((data) => data._source.IsCurrent === "1")
                .filter((data) => ![null, undefined, ""].includes(data))
                .length !== 0) ||
              (basketItems &&
                basketItems.filter(
                  (data) => ![null, undefined, ""].includes(data.SerialNumber)
                ).length !== 0)) &&
            !editHoldNote ? (
              <>
                <div className="table_container">
                  <Table>
                    <thead>
                      <tr>
                        {basketItems &&
                          basketItems.filter(
                            (data) =>
                              ![null, undefined, ""].includes(data.SerialNumber)
                          ).length !== 0 && (
                            <th>
                              {" "}
                              <input
                                type="checkbox"
                                // id="select"
                                checked={
                                  basketItems.filter(
                                    (item) =>
                                      ![null, undefined, ""].includes(
                                        item.SerialNumber
                                      )
                                  ).length === 0
                                    ? false
                                    : basketItems
                                        .filter(
                                          (item) =>
                                            item.checked === true &&
                                            ![null, undefined, ""].includes(
                                              item.SerialNumber
                                            )
                                        )
                                        .filter(
                                          (item) =>
                                            ![null, undefined, ""].includes(
                                              item
                                            )
                                        ).length ===
                                      basketItems.filter(
                                        (item) =>
                                          ![null, undefined, ""].includes(
                                            item.SerialNumber
                                          )
                                      ).length
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  this.handleItemSelected(
                                    "All",
                                    e.target.checked
                                  )
                                }
                              />{" "}
                              Select
                            </th>
                          )}
                        <th>Serial #</th>
                        <th>InventoryID</th>
                        {holdNotesFields &&
                          holdNotesFields.map((fieldData, index) => {
                            // if (index === 0) {
                            //   return (
                            //     <>

                            //       <th>{fieldData.label}</th>
                            //     </>
                            //   );
                            // }
                            return (
                              <th key={fieldData.label + index}>
                                {fieldData.label}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {holdNotesData ? (
                        holdNotesData
                          .filter((data) => data._source.IsCurrent === "1")
                          .map((data, index) => {
                            // console.log("data: ", data);
                            return (
                              <tr key={data._source.IsCurrent + index}>
                                {holdNotesFields &&
                                  holdNotesFields.map((fieldData, index) => {
                                    // console.log({ fieldData });
                                    if (index === 0) {
                                      return (
                                        <>
                                          {/* <td>
                                          <input
                                            type="checkbox"
                                            id="select"
                                            // checked={this.state.isSold}
                                            // onChange={(e) =>
                                            //   this.setState({
                                            //     isSold: e.target.checked,
                                            //   })
                                            // }
                                          />
                                        </td> */}
                                          <td>
                                            {data._source["SerialNumber"]}
                                          </td>
                                          <td>{data._source["InventoryID"]}</td>
                                          <td>
                                            {(new Date(`${data[fieldData.field]}`)).toLocaleDateString("en-US")}
                                          </td>
                                        </>
                                      );
                                    }
                                    // console.log("date fields : ", fieldData);
                                    if (
                                      // ["HoldDate", "ReleaseDate"].includes(
                                      //   fieldData.field
                                      // )
                                      fieldData.field === "HoldDate" ||
                                      fieldData.field === "ReleaseDate"
                                    ) {
                                      // console.log("date fields : ", fieldData);
                                      return (
                                        <td>
                                          {" "}
                                          {moment(
                                            new Date(
                                              `${data._source[fieldData.field]}`
                                            )
                                          ).format("MM/DD/YYYY")}
                                        </td>
                                      );
                                    }
                                    if (fieldData.field === "IsHoldSold") {
                                      return (
                                        <td>
                                          {data._source[fieldData.field] === "0"
                                            ? "No"
                                            : "Yes"}
                                        </td>
                                      );
                                    }
                                    return (
                                      <td>{data._source[fieldData.field]}</td>
                                    );
                                  })}
                              </tr>
                            );
                          })
                      ) : basketItems ? (
                        basketItems
                          .filter(
                            (data) =>
                              ![null, undefined, ""].includes(data.SerialNumber)
                          )
                          .map((data) => {
                            // console.log("data: ", data);
                            return (
                              <tr>
                                {holdNotesFields &&
                                  holdNotesFields.map((fieldData, index) => {
                                    // console.log({ fieldData });
                                    if (index === 0) {
                                      return (
                                        <>
                                          <td>
                                            <input
                                              type="checkbox"
                                              // id="select"
                                              checked={data.checked}
                                              onChange={(e) =>
                                                this.handleItemSelected(
                                                  data["SerialNumber"],
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td>{data["SerialNumber"]}</td>
                                          <td>{data["InventoryID"]}</td>
                                          <td>{(new Date(`${data[fieldData.field]}`)).toLocaleDateString("en-US")}</td>
                                        </>
                                      );
                                    }
                                    if (
                                      // ["HoldDate", "ReleaseDate"].includes(
                                      //   fieldData.field
                                      // )
                                      fieldData.field === "HoldDate" ||
                                      fieldData.field === "ReleaseDate"
                                    ) {
                                      // console.log("date fields : ", fieldData);
                                      return (
                                        <td>
                                        {data[fieldData.field] ? (
                                          moment(new Date(`${data[fieldData.field]}`)).format("MM/DD/YYYY")
                                        ) : (
                                          'NA'
                                        )}
                                        </td>
                                      );
                                    }
                                    if (fieldData.field === "IsHoldSold") {
                                      return (
                                        <td>
                                          {data[fieldData.field] === "0"
                                            ? "No"
                                            : "Yes"}
                                        </td>
                                      );
                                    }
                                    return <td>{data[fieldData.field]}</td>;
                                  })}
                              </tr>
                            );
                          })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                </div>
                <hr></hr>
              </>
            ) : (
              <></>
            )}
            <div className="hold_note_field_wrapper">
              <label>Hold Date:</label>
              <input
                type="date"
                value={this.state.holdDate}
                onChange={(e) => {
                  let holdDate = parseInt(
                    e.target.value.toString().replaceAll("-", "")
                  );
                  let date = new Date();
                  let currentDate = parseInt(
                    date
                      .toISOString()
                      .substr(0, 10)
                      .toString()
                      .replaceAll("-", "")
                  );
                  if (holdDate >= currentDate) {
                    this.setState({ holdDate: e.target.value });
                  } else {
                    this.setState({ holdDate: "" });
                    window.alert(
                      "Hold date should be greater or equal to current date."
                    );
                  }
                }}
              />
            </div>
            <div className="hold_note_field_wrapper">
              <label>Feed Restrictions:</label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                className="Select"
                value={
                  this.state.feedRestrictions
                  // &&
                  // this.state.feedRestrictions[
                  //   this.state.feedRestrictions.length - 1
                  // ]
                }
                onChange={this.handleSelection("feed")}
                options={[
                  {
                    value: "default",
                    label:
                      feedRestrictionsData && feedRestrictionsData.length === 0
                        ? "Loading..."
                        : "Select",
                  },
                  ...(feedRestrictionsData
                    ? feedRestrictionsData.map((feed) => {
                        // console.log("basket: ", basket);
                        return {
                          value: feed._source.ValueID,
                          label: feed._source.Description,
                        };
                      })
                    : []),
                ]}
                placeholder="Search..."
              />
              {this.state.feedRestrictions &&
                this.state.feedRestrictions.length !== 0 && (
                  <div className="options_selected">
                    {this.state.feedRestrictions.map((feeds) => {
                      return (
                        <span>
                          {feeds.label}
                          <i
                            onClick={() =>
                              this.handleMultiOptionDelete(feeds.value, "feeds")
                            }
                          >
                            x
                          </i>
                        </span>
                      );
                    })}
                  </div>
                )}
            </div>
            <div className="hold_note_field_wrapper">
              <label>
                <span>*</span>Hold Release:
              </label>
              <input
                type="date"
                value={this.state.holdRelease}
                onChange={(e) => {
                  // console.log();
                  let holdDate = parseInt(
                    this.state.holdDate.toString().replaceAll("-", "")
                  );
                  let holdRelease = parseInt(
                    e.target.value.toString().replaceAll("-", "")
                  );
                  if (holdRelease >= holdDate) {
                    this.setState({ holdRelease: e.target.value });
                  } else {
                    this.setState({ holdRelease: "" });
                    window.alert(
                      "Hold release date should be greater or equal to hold date."
                    );
                  }
                }}
              />
            </div>
            <div className="hold_note_field_wrapper">
              <label>Jewelry Hold Restriction:</label>
              <Select
                className="Select"
                isMulti
                closeMenuOnSelect={false}
                value={
                  this.state.jewelryHoldRectriction
                  // &&
                  // this.state.jewelryHoldRectriction[
                  //   this.state.jewelryHoldRectriction.length - 1
                  // ]
                }
                onChange={this.handleSelection("jewelry")}
                options={[
                  {
                    value: "default",
                    label:
                      jewelryRestrictionData &&
                      jewelryRestrictionData.length === 0
                        ? "Loading..."
                        : "Select",
                  },
                  ...(jewelryRestrictionData
                    ? jewelryRestrictionData.map((jewelry) => {
                        // console.log("basket: ", basket);
                        return {
                          value: jewelry._source.ValueID,
                          label: jewelry._source.Description,
                        };
                      })
                    : []),
                ]}
                placeholder="Search..."
              />
              {this.state.jewelryHoldRectriction &&
                this.state.jewelryHoldRectriction.length !== 0 && (
                  <div className="options_selected">
                    {this.state.jewelryHoldRectriction.map((jewelryHold) => {
                      return (
                        <span>
                          {jewelryHold.label}
                          <i
                            onClick={() =>
                              this.handleMultiOptionDelete(
                                jewelryHold.value,
                                "jewelryHold"
                              )
                            }
                          >
                            x
                          </i>
                        </span>
                      );
                    })}
                  </div>
                )}
            </div>
            <div className="hold_note_field_wrapper">
              {/* <label>Customer:</label> */}
              {/* <Select
                className="Select"
                value={this.state.customerName}
                onChange={this.handleSelection("cust")}
                options={[
                  {
                    value: "default",
                    label:
                      customers && customers.length === 0
                        ? "Loading..."
                        : "Select",
                  },
                  ...(customers
                    ? customers.map((cust) => {
                        // console.log("basket: ", basket);
                        return {
                          value: cust._source.CustomerID,
                          label: `${cust._source.CustomerName} (${cust._source.CustomerID})`,
                        };
                      })
                    : []),
                ]}
                placeholder="Search..."
              /> */}
              <CustomerSearch
                handleCustomerSelection={this.handleCustomerSelection}
                customer={this.state.customerName}
              />
            </div>
            <div className="hold_note_field_wrapper">
              <label>Entered For:</label>
              <Select
                className="Select"
                value={this.state.enteredFor}
                onChange={this.handleSelection("for")}
                options={[
                  {
                    value: "default",
                    label:
                      employeeData && employeeData.length === 0
                        ? "Loading..."
                        : "Select",
                  },
                  ...(employeeData
                    ? employeeData.map((employee) => {
                        // console.log("basket: ", basket);
                        return {
                          value: employee._source.EmployeeDBID,
                          label: `${employee._source.FirstName} ${employee._source.LastName}`,
                        };
                      })
                    : []),
                ]}
                placeholder="Search..."
              />
            </div>
            <div className="hold_note_field_wrapper">
              <label>Entered By:</label>
              <Select
                // menuIsOpen={true}
                className="Select"
                value={this.state.enteredBy}
                onChange={this.handleSelection("by")}
                options={[
                  {
                    value: "default",
                    label:
                      employeeData && employeeData.length === 0
                        ? "Loading..."
                        : "Select",
                  },
                  ...(employeeData
                    ? employeeData.map((employee) => {
                        // console.log("basket: ", basket);
                        return {
                          value: employee._source.EmployeeDBID,
                          label: `${employee._source.FirstName} ${employee._source.LastName}`,
                        };
                      })
                    : []),
                ]}
                placeholder="Search..."
              />
            </div>
            <div className="hold_note_field_wrapper">
              <label>Hold Note:</label>
              <textarea
                value={this.state.holdNoteText}
                onChange={(e) =>
                  this.setState({ holdNoteText: e.target.value })
                }
              />
            </div>
            <div className="hold_note_field_wrapper">
              <input
                type="checkbox"
                id="isSold"
                checked={this.state.isSold}
                onChange={(e) =>
                  this.setState({
                    isSold: e.target.checked,
                  })
                }
              />
              <label htmlFor="isSold">Is Sold</label>
              <input
                type="checkbox"
                id="isProposed"
                checked={this.state.isProposed}
                onChange={(e) =>
                  this.setState({
                    isProposed: e.target.checked,
                  })
                }
              />
              <label htmlFor="isProposed">Is Proposed</label>
            </div>
            {/* <div className="hold_note_field_wrapper">
              <input
                type="checkbox"
                id="isSold"
                checked={this.state.isSold}
                onChange={(e) =>
                  this.setState({
                    isSold: e.target.checked,
                  })
                }
                />
              <label htmlFor="isSold">isSold</label>
            </div> */}
            <div className="hold_notes_action_buttons">
              <button onClick={() => {
                this.props?.handleBulkNoteClick && this.props?.handleBulkNoteClick(false)
                this.onModalHide()}}>Cancel</button>
              <button
                onClick={() => {
                  if(this.props.bulkNoteClicked && this.state.customerName){
                    const { basketItems } = this.state
                    const checkIsMTS = basketItems.filter(obj=>obj.checked).some(obj => obj.IsMTS === "1");
                    if(checkIsMTS){
                      this.setState({
                        ...this.state.isSavePopUp,
                        isSavePopUp: true
                      })
                    }else{
                      this.handleBasketItemSave();
                    }
                  }
                  else if(this.state.customerName && this.props?.res && this.props?.res?.index !== "kwfl-acumatica-catalog-v7-prod-gemstoneserial3" ){
                    if(this.props?.res && this.props?.res?.index === "kwfl-acumatica-catalog-v7-prod-jewelryserial2"){
                      if(this.props?.res && this.props?.res?.IsDiaExists === "Yes" ){
                        this.setState({
                          ...this.state.isSavePopUp,
                          isSavePopUp: true
                        })
                      }else{
                        if (res) {
                          this.handleSingleItemSave();
                        } else {
                          this.handleBasketItemSave();
                        }
                      }
                    } else if(this.props?.res && this.props?.res?.index === "kwfl-acumatica-catalog-v7-prod-diamondserial2" && this.props?.res?.IsMTS === "1" ){
                      this.setState({
                        ...this.state.isSavePopUp,
                        isSavePopUp: true
                      })
                    }else {
                      if (res) {
                        this.handleSingleItemSave();
                      } else {
                        this.handleBasketItemSave();
                      }
                    }
                  } else{
                     if (res) {
                       this.handleSingleItemSave();
                     } else {
                       this.handleBasketItemSave();
                     }
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal
        show={this.state.isSavePopUp}
        onHide={() => {}}
        animation={false}
        // dialogClassName="show-more_modal-dailog"
        className="hold_notes_create_modal"
        aria-labelledby="contained-modal-title-vcenter"
        style={{position: "fixed",
          left: "20%",
          top: "20%", width: '60%'}}
      >
        <Modal.Body>
          <div>
          <p>Do want to allocate this item to the hold customer in the MTS app, meaning no one else will be able to see it ?</p>
          <div style={{display:'flex', justifyContent:'end', alignItems:'end'}} >
            <Button variant="secondary" style={{marginRight:'2vw'}} onClick={()=>{
              this.setState({
               ...this.state,
               isSavePopUp: false,
               IsMTSCustomer: false
              }, ()=>{
                if (!this.props.bulkNoteClicked) {
                  this.handleSingleItemSave();
                } else {
                  this.handleBasketItemSave();
                }
              })
            }}>
            NO
          </Button>
          <Button variant="secondary" onClick={()=>{
            this.setState({
              ...this.state,
              isSavePopUp: false,
              IsMTSCustomer: true
            }, ()=>{
              if (!this.props.bulkNoteClicked) {
                this.handleSingleItemSave();
              } else {
                this.handleBasketItemSave();
              }
            })
            
          }}>
            YES
          </Button>
          </div>
        </div>
        </Modal.Body>
      </Modal>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleLoader,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoldNoteCreateModal);
