import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

const item_order = [
  "Round Brilliant",
  "Emerald Cut",
  "Oval",
  "Cushion",
  "Cushion Brilliant",
  "Radiant",
  "Ashoka",
  "Pear Shape",
  "Marquise",
  "Heart",
  "Asscher",
  "Old European",
  "Rose Cut",
  "Old Mine",
  "Briolette",
  "Princess",
  "Tapered Baguette",
  "Lozenge",
  "Hexagon",
  "Baguette",
  "Trapezoid",
  "Rough",
  "Kite",
  "Trilliant",
  "Shield",
];

class CenterShape extends Component {
  mapOrder(array, order, key) {
    array.sort(function(a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }

  render() {
    return (
      <div className="CenterShape">
        <MultiDropdownList
          className="form-field"
          title={
            this.props.appType === "original" ? "Ctr Shape" : "Center Shape"
          }
          componentId="CenterShape"
          dataField={"CenterShape.keyword"}
          size={100}
          showCount={false}
          showSearch={true}
          transformData={(list) => {
            var ordered_array;
            ordered_array = this.mapOrder(list, item_order, "key");
            return ordered_array;
          }}
          placeholder={this.props.appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(CenterShape);
