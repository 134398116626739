import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactImageFallback from "react-image-fallback";
import moment from "moment";
import ShowMore from "react-show-more";
import { toggleIframeModal } from "../../actions/index";
import { BaseURL } from "../../utils/constants";
import MobileProductDetails from "./MobileProductDetails";
import MoreDetailsModal from "./MoreDetailsModal";

class CustomerAppResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreModal: false,
      currentRes: "",
    };

    this.showMoreModal = this.showMoreModal.bind(this);
    this.hideMoreModal = this.hideMoreModal.bind(this);
  }

  showMoreModal(res) {
    this.setState({
      currentRes: res,
      showMoreModal: true,
    });
  }
  hideMoreModal() {
    this.setState({
      showMoreModal: false,
    });
  }

  nLeftPad(n) {
    return n > 9 ? "" + n : "0" + n;
  }

  render() {
    let {
      results,
      isValueEmpty,
      isMultipleValueEmpty,
      thumbnailImage,
      largeImage,
    } = this.props;
    // console.log("results : ", results);

    return (
      <div className="es__products container">
        {results.map((res, index) => {
           console.log("jewelry--->", res);
          let msgBody = `Inventory ID: ${isValueEmpty(
            res.StyleNumber
          )}%0D%0ASerial Number: ${isValueEmpty(
            res.SerialNumber
          )}%0D%0AImage: ${largeImage(
            res.LargeImageName
          )}%0D%0ADescription: ${isValueEmpty(
            res.Description
          )}%0D%0APrice: ${parseFloat(isValueEmpty(res.RetailPrice)).toFixed(
            2
          )}%0D%0A%0D%0ADETAILS:%0D%0A${isMultipleValueEmpty(
            res,
            "ItemSubType"
          )}%0D%0AMaker: ${isValueEmpty(res.Maker)}%0D%0APeriod: ${isValueEmpty(
            res.Period
          )}%0D%0ACollection: ${isValueEmpty(
            res.Collection
          )}%0D%0ALength/Size: ${isValueEmpty(res.Length)}${isValueEmpty(
            res.RingSize
          )}%0D%0ACenter Details: ${isMultipleValueEmpty(
            res,
            "CenterDetails"
          )}%0D%0A${isValueEmpty(res.Metal)} - ${isValueEmpty(
            res.DiamondCarats
          )}cts, ${isValueEmpty(res.ColorCarats)}/${isValueEmpty(res.Clarity)}`;
          return (
            <div
              className="es__product"
              key={res._id}
              onMouseEnter={(e) => this.props.handleMouseEnter(e)}
              onMouseLeave={(e) => this.props.handleMouseLeave(e)}
            >
              <div className="product-index_container es__position-relative">
                {this.nLeftPad(index + 1)}
              </div>
              <div className="es__product__hovered es__position-relative es__bordered-box">
                <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/email.svg"
                  alt="Email"
                  className="share-icons"
                  onClick={() => this.props.handleMail(msgBody)}
                />
                <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/text.svg"
                  alt="SMS"
                  className="share-icons"
                  onClick={() => this.props.handleTextMsg(msgBody)}
                />
                <img
                  src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/whatsapp.svg"
                  alt="Whatsapp"
                  className="share-icons"
                  data-action="share/whatsapp/share"
                  onClick={() => this.props.handleWhatsApp(msgBody)}
                />
              </div>

              <div className="es__product-image-title es__position-relative">
                <h5 className="es__product-details-heading es__product-dark-title">
                  <span
                    className="iframe-link"
                    onClick={() =>
                      this.props.toggleIframeModal({
                        show: true,
                        url: `${BaseURL}/pages/is/is250010.aspx?PopupPanel=On&InventoryID=${
                          res.StyleNumber
                        }&LotSerialNbr=${res.SerialNumber}`,
                      })
                    }
                  >
                    {isValueEmpty(res.SerialNumber)}
                  </span>
                  {isValueEmpty(res.SerialVideoLink) ? (
                    <img
                      onClick={() =>
                        this.props.toggleIframeModal({
                          show: true,
                          url: res.SerialVideoLink,
                        })
                      }
                      className="iframe-link"
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search-consumer/icons/Video-Icon-Stock-Black.svg"
                      height="14px"
                      width="20px"
                      alt="product"
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <div
                  className={
                    res.LargeImageFileID !== null
                      ? "es__product-image-container clickable"
                      : "es__product-image-container not-clickable"
                  }
                >
                  {thumbnailImage(res).src ? (
                    <img
                      className="es__product-image"
                      src={thumbnailImage(res).src}
                      onClick={(e) => {
                        var type;
                        thumbnailImage(res).thumbnailImageType === "v360" ||
                        thumbnailImage(res).thumbnailImageType === "webImage"
                          ? (type = "webImage")
                          : (type = "icon");
                        this.props.showZoomImage(res, type);
                      }}
                      alt="product"
                    />
                  ) : (
                    <ReactImageFallback
                      fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                      // initialImage="loader.gif"
                      alt=""
                      className="es__product-image"
                    />
                  )}
                </div>
              </div>

              <div className="es__product-details">
                <div className="es__product-basic-details es__position-relative">
                  <div className="es__bordered-box">
                    <h5 className="es__style-number-heading es__product-dark-title">
                      <span
                        className="iframe-link"
                        onClick={() =>
                          this.props.toggleIframeModal({
                            show: true,
                            url: `${BaseURL}/pages/in/in202500.aspx?PopupPanel=On&InventoryCD=${
                              res.StyleNumber
                            }`,
                          })
                        }
                      >
                        {isValueEmpty(res.StyleNumber)}
                      </span>
                      {res.IsTiara === "1" ? (
                        <span className="es__istiara" />
                      ) : (
                        ""
                      )}
                      {res.IsFLRound === "1" ? (
                        <span className="es__isfl" />
                      ) : (
                        ""
                      )}
                      {isValueEmpty(res.Description) ? (
                        <span className="es__product-title-interval">-</span>
                      ) : (
                        ""
                      )}
                      <div
                        className="es__product-title-description"
                        onClick={(e) => e.target.classList.toggle("crop")}
                      >
                        <span>{isValueEmpty(res.Description)}</span>
                      </div>
                    </h5>

                    <table className="es__product-details-table">
                      <tbody>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isMultipleValueEmpty(res, "ItemSubtype")}
                            </ShowMore>
                          </td>
                          <td>
                            {isValueEmpty(res.DiamondCarats)}
                            {isValueEmpty(res.ColorCarats)
                              ? `/${res.ColorCarats}`
                              : ""}
                          </td>
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.Maker)}
                            </ShowMore>
                          </td>
                          <td>{isValueEmpty(res.RingSize)}</td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td />
                          <td>
                            {isValueEmpty(res.DiamondCarats) ? " cts dia " : ""}
                          </td>
                        </tr>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.Collection)}
                            </ShowMore>
                          </td>
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.Color)}
                              {isValueEmpty(res.Clarity)
                                ? ` / ${res.Clarity}`
                                : ""}
                            </ShowMore>
                          </td>
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.Period)}
                            </ShowMore>
                          </td>
                          <td>
                            {/* <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            > */}
                            {res.Length}
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {res.BangleSize}
                            </ShowMore>
                            {res.Diameter}
                            {res.HoopDiameter}
                            {/* </ShowMore> */}
                          </td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td />
                        </tr>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.Metal)}
                            </ShowMore>
                          </td>
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.DiamondDetails)}
                              {/* temporarily no condition } */}
                              {isValueEmpty(res.ColorComment)
                                ? ` & ${res.ColorComment}`
                                : ""}
                            </ShowMore>
                          </td>
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {isValueEmpty(res.CircaDate)}
                            </ShowMore>
                          </td>
                          <td>{isValueEmpty(res.WidthOD)}</td>
                          <td>
                            <span
                              className="show-more"
                              onClick={() => {
                                this.showMoreModal(res);
                              }}
                            >
                              +
                            </span>
                          </td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td />
                        </tr>
                        <tr className="es__product-details-table__data-row">
                          <td colSpan="100%">
                            {isMultipleValueEmpty(res, "CenterDetails")
                              ? isMultipleValueEmpty(res, "CenterDetails")
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <MobileProductDetails
                      res={res}
                      handleProductModal={this.props.showMobileProductModal}
                    />
                  </div>
                </div>
                {/* pricing */}
                <div className="es__product-pricing-details es__position-relative">
                  <div className="es__bordered-box">
                    <h5 className="es__product-details-heading es__product-dark-title">
                      <span
                        onClick={(e) => this.props.showStockPopUpModal(e, res)}
                      >
                        Pricing
                      </span>
                    </h5>
                    <table className="es__product-pricing-details-table">
                      <tbody>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            {isMultipleValueEmpty(
                              res.RetailPrice,
                              "RetailPrice"
                            )}
                          </td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td>Retail</td>
                        </tr>
                        <tr className="es__product-details-table__data-row">
                          <td>{isValueEmpty(res.RetailPriceCode)}</td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td>Code</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* status */}
                <div className="es__product-status-details es__position-relative">
                  <div className="es__bordered-box">
                    <h5 className="es__product-details-heading es__product-dark-title">
                      <span>Status</span>
                    </h5>
                    <table className="es__product-status-details-table">
                      <tbody>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            <ShowMore
                              lines={1}
                              more="&#43;"
                              less="&#8722;"
                              anchorClass=""
                            >
                              {res.Warehouse !== res.SerialStatus &&
                              res.Warehouse !== "INTR" &&
                              res.SerialStatus !== "RTV" &&
                              res.SerialStatus !== "SOLD"
                                ? `${res.Location} /`
                                : ""}
                              {isValueEmpty(res.Location)}
                            </ShowMore>
                          </td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td>Status</td>
                        </tr>
                        <tr className="es__product-details-table__data-row">
                          <td>
                            <div>
                              <ShowMore
                                lines={1}
                                more="&#43;"
                                less="&#8722;"
                                anchorClass=""
                              >
                                {isValueEmpty(res.StatusDate)
                                  ? moment(
                                      new Date(`${res.StatusDate}`)
                                    ).format("MM/DD/YYYY")
                                  : ""}
                                {isValueEmpty(res.StatusRefNbr) ? (
                                  <span
                                    className="iframe-link"
                                    onClick={() =>
                                      this.props.toggleIframeModal({
                                        show: true,
                                        url: `${BaseURL}${this.createStatusLink(
                                          res.SerialStatus
                                        )}${res.StatusRefNbr}`,
                                      })
                                    }
                                  >
                                    - ({res.StatusRefNbr})
                                  </span>
                                ) : (
                                  ""
                                )}
                              </ShowMore>
                            </div>
                          </td>
                        </tr>
                        <tr className="es__product-details-table__label-row">
                          <td>Date/Ref #</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <MoreDetailsModal
          show={this.state.showMoreModal}
          hide={this.hideMoreModal}
          res={this.state.currentRes}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleIframeModal,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(CustomerAppResults);
