import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";
import { withRouter } from "react-router";

class Keyword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
  }

  handleChange(value) {
    if (!value) {
      this.props.keywordSearchChanged("NOT_ACTIVE");
      return;
    }
    this.props.keywordSearchChanged("ACTIVE");
  }
  handleSelected(value) {
    if (this.state.value === value) {
      return;
    } else {
      this.setState({ value: value });
      this.props.keywordSearchSelected(true);
    }
    // console.log("Inside Handle Selected")
  }

  render() {
    const { location, appType, data } = this.props;
    let title = "Report #";
    let placeholder = "Enter Report #";
    if (location.pathname.indexOf("studs") !== -1) {
      title = "Keyword";
      placeholder = "Enter Keyword";
    }

    return (
      <div className="keyword form-field-wrap">
        <DataSearch
          className="form-field"
          componentId="SearchKeyword2"
          title={title}
          debounce={250}
          placeholder={
            appType && appType === "customer" ? "Start Search..." : placeholder
          }
          dataField={data}
          autosuggest={false}
          queryFormat="and"
          searchOperators={true}
          onValueChange={(value, e) => {
            // console.log("e : ", e);
            this.handleChange(value);
          }}
          // onValueSelected={(value) => this.handleSelected(value)}
          // onQueryChange={(value) => this.handleSelected(value)}
          iconPosition={appType && appType === "customer" ? "right" : "left"}
          onKeyDown={(e) => {
            e.stopPropagation();
            // e.preventDefault();
            // console.log("e : ", e);
            // console.log("e.target : ", e.target.value);
            if (e.key === "Enter") {
              this.handleSelected(e.target.value);
            }
          }}
        />
      </div>
    );
  }
}

export default withRouter(Keyword);
