import React from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

class RingSize extends React.PureComponent {
  render() {
    return (
      <div className="ring-size form-field-wrap">
        <DataSearch
          title="Ring Size"
          className="form-field"
          placeholder="Start Search..."
          dataField={["RingSize"]}
          autosuggest={true}
          queryFormat="and"
          componentId="RingSize"
          iconPosition="right"
        />
      </div>
    );
  }
}

export default RingSize;
