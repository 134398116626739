import React from "react";
import JewelryDashboard from "./components/JewelryDashboard";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
const JewelrySearch = () => {
  return (
    <div id="jewelry-search" className="page">
      <JewelryDashboard />
      <div className="es-scroll-button">
        <ScrollUpButton />
      </div>
    </div>
  );
};

export default JewelrySearch;
