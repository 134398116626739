import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import Appbase from "appbase-js";
import GetAuthToken from "../../Api/Authenticate";
import {
  AppbaseBasketApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  basketApikey,
  appbaseBasketUrl,
  AppbaseEmployeeApp,
  AppbaseCoverApp,
} from "../../../utils/constants";
import BasketHeader from "./BasketHeader";
import BasketForm from "./BasketForm";
import BaksetItemsList from "./BaksetItemsList";
import {
  setBasketFormInput,
  toggleLoader,
  setToken,
  updateItemSelected,
} from "../../../actions/index";
import MoreDetailsModal from "../../../jewelry-search/components/MoreDetailsModal";
import PreviewEmailModal from "../PDF/PreviewEmailModal";
import PDFModal from "../PDF/PDFModal";
import ChooseLayoutModal from "../ChooseLayoutModal";
import ChooseExportModal from "../Export/ChooseExportModal";
import BasketChangeModal from "../BasketChangeModal";
import QuickBulkAddProduct from "../QuickBulkAddProduct";
import AccuExportModal from "../AccuExportModal";
import TemplateIndex from "../Templates/TemplateIndex";
import CoverIndex from "../PDF Covers/CoverIndex";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    loaderActions: state.loaderActions,
    tokenState: state.tokenState,
    groups: state.SetGroups.groups,
  };
};

class Basket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.cartItems.items,
      showModal: this.props.show || false,
      basketType: "Retail_Proposal",
      description: "",
      internalNotes: "",
      customer: {},
      occasion: "",
      basketInputs: props.basketInputObj,
      showMoreModal: false,
      showPreviewModal: false,
      showPDFModal: false,
      showChooseLayout: false,
      showExportModal: false,
      currentRes: "",
      layoutType: "",
      coverType: "NoCover",
      goto: "",
      showBasketChangeModal: false,
      showQuickAddBulkProductModal: false,
      userLoggedIn: "",
      selectedItems: props.cartItems.items.filter(
        (item) => item.itemSelected === true
      ),
      includeGIA: "No",
      includeKTDShapes: "NO",
      showAccuExportModal: false,
      accuExportLink: "",
      csvData: "",
      csvRenderData: [],
      showEmailTemplate: false,
      showCoverModal: false,
      coverSelected: "",
      coverCategories: [],
      covers: [],
      selectedShapes: [],
      MTSInfo:
        this.props.cartItems.items.filter(
          (item) => item.transformType === "DiamondSerial"
        ).length === 0
          ? false
          : true,
      selectedShapesBinary: "",
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.showMoreModal = this.showMoreModal.bind(this);
    this.hideMoreModal = this.hideMoreModal.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.hidePreviewModal = this.hidePreviewModal.bind(this);
    this.showPDFModal = this.showPDFModal.bind(this);
    this.hidePDFModal = this.hidePDFModal.bind(this);
    this.showChooseLayout = this.showChooseLayout.bind(this);
    this.hideChooseLayout = this.hideChooseLayout.bind(this);
    this.showExportModal = this.showExportModal.bind(this);
    this.hideExportModal = this.hideExportModal.bind(this);
    this.handleSetLayout = this.handleSetLayout.bind(this);
    this.handleSetCover = this.handleSetCover.bind(this);
    this.showBasketChangeModal = this.showBasketChangeModal.bind(this);
    this.hideBasketChangeModal = this.hideBasketChangeModal.bind(this);
    this.showQuickAddBulkProductModal = this.showQuickAddBulkProductModal.bind(
      this
    );
    this.hideQuickAddBulkProductModal = this.hideQuickAddBulkProductModal.bind(
      this
    );
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.handleIncludeGIA = this.handleIncludeGIA.bind(this);
    this.handleIncludeKTDShapes = this.handleIncludeKTDShapes.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleMTSCheckboxChange = this.handleMTSCheckboxChange.bind(this);
    // this.getUserEmail = this.getUserEmail.bind(this);
    this.showAccuExportModal = this.showAccuExportModal.bind(this);
    this.hideAccuExportModal = this.hideAccuExportModal.bind(this);
    this.handleCsvData = this.handleCsvData.bind(this);
    this.handleCsvRenderData = this.handleCsvRenderData.bind(this);
    this.handleEmailTemplate = this.handleEmailTemplate.bind(this);
    this.handleCoverModal = this.handleCoverModal.bind(this);
    this.fetchCovers = this.fetchCovers.bind(this);
    this.handleSelectedCover = this.handleSelectedCover.bind(this);
  }

  async componentDidMount() {
    // if (Object.keys(this.props.groups).length===0){
    let url = window.location.href;
    // if (url.includes("kim.kwiat.com")) {
    //   let token;
    //   console.log("Inside fetching token");
    //   if (this.props.tokenState.token === "") {
    //     token = await GetAuthToken();
    //     // console.log("token :", token);
    //     if (token) {
    //       this.props.setToken(token.access_token);
    //     }
    //   } else token = this.props.tokenState.token;
    // }
    // let user;
    // if (this.props.groups.email) {
    //   user = this.props.groups.email;
    // } else if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    //   // user = "Sayyed, Sahil";
    //   // user = "Jangam, Nagaraj";
    // }
    let user;
    if (this.props.groups.email) {
      user = this.props.groups.email;
    } else if (window.parent.document.getElementById("btnUserMenu")) {
      user = window.parent.document
        .getElementById("userMenu")
        .querySelector("li.menu-form > div.inline > span:nth-child(3)")
        .textContent;
    } else {
      user = null;
    }
    this.setState({ userLoggedIn: user ? user : "" });
    // if (!this.props.basketInputObj.user)
    // await this.getUserEmail(token);
    // await this.fetchCovers();
    const { cartItems } = this.props;
    const selectedShapes = new Set();

    cartItems?.items?.forEach((item) => {
      if (item.IsKWCushion === "1") {
        selectedShapes.add("Kwiat Cushion");
      } else if (item.IsKwiatOval === "1") {
        selectedShapes.add("Kwiat Oval");
      } else if (item.IsKwiatEmeraldCut === "1") {
        selectedShapes.add("Kwiat Emerald Cut");
      } else if (item.Shape === "Ashoka") {
        selectedShapes.add("Ashoka");
      } else if (item.IsKwiatRadiant === "1") {
        selectedShapes.add("Kwiat Radiant");
      } else if (item.IsKwiatRound === "1") {
        selectedShapes.add("Kwiat Round");
      } else if (item.IsFLRound === "1") {
        selectedShapes.add("Fred Leighton Round");
      }
    });
    this.setState({ selectedShapes: Array.from(selectedShapes) });
  }

  async componentWillReceiveProps(nextProps) {
    // console.log("netProps: ", nextProps);
    // let token = nextProps.tokenState.token;
    if (
      this.state.items !== nextProps.cartItems.items ||
      this.state.basketInputs !== nextProps.basketInputObj
    ) {
      // console.log("netProps: ", nextProps);
      this.setState({
        items: nextProps.cartItems.items,
        basketInputs: nextProps.basketInputObj,
        selectedItems: nextProps.cartItems.items.filter(
          (item) => item.itemSelected === true
        ),
        MTSInfo:
          nextProps.cartItems.items.filter(
            (item) => item.transformType === "DiamondSerial"
          ).length === 0
            ? false
            : true,
      });
      // this.generateBinaryShapesString();
    }
    if (!this.props.basketInputObj.user) {
      // console.log("token: ", token);
      // await this.getUserEmail(token);
    }
  }

  handleSelectedCover(value) {
    // console.log(value);
    if (value) {
      this.setState(
        (prevState) => ({
          ...prevState,
          coverSelected: value,
          // prevState.covers.filter(
          //   (cover) => cover.id === value
          // )[0],
        })
        // () => console.log("coverSelecetd: ", this.state.coverSelected)
      );
    } else {
      this.setState({ coverSelected: "" });
    }
  }

  async fetchCovers() {
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .search({
        body: {
          query: {
            match_all: {},
          },
          size: 10000,
        },
      })
      .then(function(res) {
        // console.log("Templates results: ", res);
        return res;
      })
      .catch(function(err) {
        console.log("Covers error: ", err);
        return err;
      });

    if (response && response.hits.hits.length !== 0) {
      console.log("Covers found successfully");
      this.setState((prevState) => ({
        ...prevState,
        covers: response.hits.hits.map((data) => {
          return { id: data._id, ...data._source };
        }),
        coverCategories: [
          ...new Set(
            response.hits.hits.map((cover) => {
              return cover._source.Category;
            })
          ),
        ],
      }));
    } else {
      console.log("covers not found");
    }
  }

  handleCoverModal(value) {
    this.setState({ showCoverModal: value });
  }
  handleEmailTemplate(value) {
    this.setState({ showEmailTemplate: value });
  }
  // async getUserEmail(token) {
  //   let userEmail;
  //   if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector(
  //         "li.menu-form > div.inline > span:nth-child(3)"
  //       ).textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //     // userEmail = "megan@kwiat.com";
  //     // userEmail = "nagaraj@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: [
  //             "FirstName",
  //             "LastName",
  //             "Email",
  //             "BasketEmail",
  //             "EmployeeLogin",
  //             "Title",
  //             "Phone1",
  //             "AddressLine1",
  //             "AddressLine2",
  //             "City",
  //             "State",
  //             "PostalCode",
  //             "BasketBccEmail",
  //             "BasketCcEmail",
  //             "CRMBccEmail",
  //           ],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     // let payload = {
  //     //   data: {
  //     //     defaults: {
  //     //       baseURL: basketBaseUrl,
  //     //       token: token,
  //     //     },
  //     //     inputs: {
  //     //       EmployeeLogin: {
  //     //         value: this.props.basketInputObj.user.EmployeeLogin,
  //     //       },
  //     //     },
  //     //   },
  //     // };
  //     // // console.log("payload: ", payload);
  //     // await axios
  //     //   .post(appbaseBasketUrl + "user-signature", payload, {
  //     //     headers: {
  //     //       "x-api-key": basketApikey,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     // console.log("resp : ", res);
  //     //     if (
  //     //       res.data.statusCode === 200 &&
  //     //       res.data.body.KWMailSignature.value
  //     //     ) {
  //     //       console.log("User signature found.");
  //     //       this.props.setBasketFormInput({
  //     //         user: {
  //     //           ...this.props.basketInputObj.user,
  //     //           Signature: res.data.body.KWMailSignature.value,
  //     //         },
  //     //       });
  //     //     } else {
  //     //       console.log("response is empty");
  //     //     }
  //     //     // console.log("Basket form data: ", this.props.basketInputObj);
  //     //     // return JSON.parse(res.data.body);
  //     //     // return res;
  //     //   })
  //     //   .catch((err) => {
  //     //     // console.log("error : ", err);
  //     //     console.log("User Signature error.", err);
  //     //     // return;
  //     //   });
  //   } else {
  //     console.log("No user");
  //   }
  //   // if (window.parent.document.getElementById("btnUserMenu")) {
  //   //   let userDetails = window.parent.document
  //   //     .getElementById("userMenu")
  //   //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
  //   //   console.log("userDetails: ", userDetails);
  //   // }
  // }

  handleCsvRenderData(value) {
    this.setState({ csvRenderData: value });
  }

  handleCsvData(value) {
    this.setState({ csvData: value });
  }

  handleIncludeGIA(value) {
    this.setState({ includeGIA: value });
  }

  handleIncludeKTDShapes(value) {
    this.setState({ includeKTDShapes: value });
  }

  // MTS ktd shapes values function//
  handleMTSCheckboxChange = (event) => {
    const { checked } = event.target;
    this.setState((prevState) => ({ ...prevState, MTSInfo: checked }));
  };

  //10th//

  // handleCheckboxChange = (e) => {
  //   console.log("check id", e.target.id);
  //   const { id, checked } = e.target;
  //   let { selectedShapes } = this.state;

  //   if (id === 'all_shapes') {
  //     if (checked) {
  //       selectedShapes = ['Kwiat Cushion', 'Kwiat Round', 'Kwiat Emerald Cut', 'Kwiat Radiant', 'Kwiat Oval', 'Ashoka', 'Fred Leighton Round'];
  //     } else {
  //       selectedShapes = [];
  //     }
  //   } else {
  //     if (checked) {
  //       if (!selectedShapes.includes(id)) {
  //         selectedShapes.push(id);
  //       }
  //     } else {
  //       selectedShapes = selectedShapes.filter(shape => shape !== id);
  //     }

  //     const allShapesSelected = ['Kwiat Cushion', 'Kwiat Round', 'Kwiat Emerald Cut', 'Kwiat Radiant', 'Kwiat Oval', 'Ashoka', 'Fred Leighton Round'].every(shape => selectedShapes.includes(shape));

  //     this.setState({ allShapesSelected });
  //   }

  //   this.setState({ selectedShapes });
  // }

  //final//

  handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    // let { selectedShapes } = this.state;
    let selectedShapes = this.state.selectedShapes;

    if (id === "all_shapes") {
      if (checked) {
        selectedShapes = [
          "Kwiat Cushion",
          "Kwiat Round",
          "Kwiat Emerald Cut",
          "Kwiat Radiant",
          "Kwiat Oval",
          "Ashoka",
          "Fred Leighton Round",
        ];
      } else {
        selectedShapes = [];
      }
    } else {
      if (checked) {
        if (!selectedShapes.includes(id)) {
          selectedShapes.push(id);
        }
      } else {
        selectedShapes = selectedShapes.filter((shape) => shape !== id);
      }

      const allShapesSelected = [
        "Kwiat Cushion",
        "Kwiat Round",
        "Kwiat Emerald Cut",
        "Kwiat Radiant",
        "Kwiat Oval",
        "Ashoka",
        "Fred Leighton Round",
      ].every((shape) => selectedShapes.includes(shape));

      this.setState({ allShapesSelected });
    }

    this.setState({ selectedShapes: selectedShapes.slice() });
  };

  handleSelectedItems(e, el) {
    // console.log("value : ", e.target.checked);
    // console.log("el: ", el);
    // if (!this.state.selectedItems.includes(value)) {
    //   this.setState((prevState) => ({
    //     selectedItems: [...prevState.selectedItems, value],
    //   }));
    // } else {
    //   this.setState((prevState) => ({
    //     selectedItems: [
    //       ...prevState.selectedItems.filter(
    //         (item) => JSON.stringify(item) !== JSON.stringify(value)
    //       ),
    //     ],
    //   }));
    // }
    this.props.updateItemSelected({
      item: el,
      selected: e.target.checked,
    });
    // this.setState({
    //   selectedItems: this.props.cartItems.items.filter(
    //     (item) => item.itemSelected === true
    //   ),
    // });
  }

  showAccuExportModal(link) {
    this.setState({ showAccuExportModal: true, accuExportLink: link });
  }

  hideAccuExportModal() {
    this.setState({ showAccuExportModal: false });
  }

  showQuickAddBulkProductModal(value) {
    this.setState({
      showQuickAddBulkProductModal: true,
      goto: value,
    });
  }
  hideQuickAddBulkProductModal() {
    this.setState({
      showQuickAddBulkProductModal: false,
    });
  }

  showBasketChangeModal(value) {
    this.setState({
      showBasketChangeModal: true,
      goto: value,
    });
  }
  hideBasketChangeModal() {
    this.setState({
      showBasketChangeModal: false,
    });
  }

  showExportModal() {
    this.setState({
      showExportModal: true,
    });
  }
  hideExportModal() {
    this.setState({
      showExportModal: false,
    });
  }

  handleSetCover(cover) {
    // console.log("layout: ", layout);
    this.setState({ coverType: cover });
  }

  handleSetLayout(layout) {
    // console.log("layout: ", layout);
    this.setState({ layoutType: layout });
  }

  showChooseLayout(value) {
    this.setState({
      showChooseLayout: true,
      goto: value,
    });
  }
  hideChooseLayout() {
    this.setState({
      showChooseLayout: false,
    });
  }

  showPDFModal() {
    this.setState({
      showPDFModal: true,
    });
  }
  hidePDFModal() {
    this.setState({
      showPDFModal: false,
    });
  }

  showPreviewModal() {
    this.setState({
      showPreviewModal: true,
    });
  }
  hidePreviewModal() {
    this.setState({
      showPreviewModal: false,
    });
  }
  showMoreModal(res) {
    this.setState({
      currentRes: res,
      showMoreModal: true,
    });
  }
  hideMoreModal() {
    this.setState({
      showMoreModal: false,
    });
  }
  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false,
      },
      () => {
        callback && callback();
      }
    );
  }

  async handleSave(type) {
    // let user;

    // // console.log("items : ", this.state.items);
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    //   // user = "Sayyed, Sahil";
    // }

    if (this.props.basketInputObj.orderNbr === "New") {
      this.props.setBasketFormInput({
        basketUserDetails: this.state.userLoggedIn,
      });
    }
    var currentdate = new Date();
    var dateTime =
      currentdate.getFullYear() +
      "" +
      (currentdate.getMonth() + 1) +
      "" +
      currentdate.getDate() +
      "" +
      currentdate.getHours() +
      "" +
      currentdate.getMinutes() +
      "" +
      currentdate.getSeconds();
    //  console.log("dateTime: ", dateTime);
    // let initialID = "B-100";
    let initialID = 1000;
    let basketID;
    let { basketInputObj, cartItems } = this.props;
    this.props.toggleLoader({
      isLoading: true,
    });
    if (basketInputObj.orderNbr === "New") {
      // Code for getting basket from elastic search using aws middle ware
      // var payload = {
      //   data: {
      //     defaults: {
      //       index: AppbaseBasketApp,
      //     },
      //   },
      // };
      // let id_Results = await axios
      //   .post(appbaseBasketUrl + "get-all-basket", payload, {
      //     headers: {
      //       "x-api-key": basketApikey,
      //     },
      //   })
      //   .then((response) => {
      //     console.log("Basket Found Successfully");
      //     // console.log("response : ", response.data.data);
      //     return response.data.data.sort(
      //       (a, b) => parseInt(a._id) - parseInt(b._id)
      //     );
      //   })
      //   .catch((error) => {
      //     console.log("error : ", error);
      //     return error;
      //   });

      //code for getting basket directly from appbase.
      const appbaseRef = Appbase({
        app: AppbaseBasketApp,
        url: AppbaseAppUrl,
        credentials: AppbaseCredentials,
      });

      let id_Results = await appbaseRef
        .search({
          body: {
            query: {
              match_all: {},
            },
            sort: { _id: { order: "desc" } },
            stored_fields: [], // for getting only ids.
            size: 1,
          },
        })
        .then((response) => {
          console.log("Basket Found Successfully");
          console.log("response : ", response);
          // return response.hits.hits.sort(
          //   (a, b) => parseInt(a._id) - parseInt(b._id)
          // );

          return response.hits.hits;
        })
        .catch((error) => {
          console.log("error : ", error);
        });

      // lastID = typeof lastID;
      //console.log("idResults: ", id_Results);
      //
      if (id_Results.length !== 0) {
        let lastID = parseInt(id_Results[id_Results.length - 1]._id);
        // console.log("lastID: ", lastID);
        basketID = lastID + 1;
      } else {
        // let firstPart = initialID.slice(0, 2);
        // let lastPart = parseInt(initialID.slice(2)) + 1;
        // let newID = firstPart + lastPart;
        // basketID = newID;
        basketID = initialID + 1;
      }
      console.log("basketID: ", basketID);
    }

    // let { basketInputs, items } = this.state;
    // console.log(
    //   " state basketInputs: ",
    //   basketInputs,
    //   "\n state items: ",
    //   items
    // );
    // console.log("this.props.basketInputObj :", this.props.basketInputObj);
    // let { basketInputObj } = this.props;
    var contactInput = basketInputObj.contact.ContactId;
    var customerInput = basketInputObj.customer.CustomerId;

    // let user;

    // // console.log("items : ", this.state.items);
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    // }
    var updatedItemsArray;
    // console.log("basketInputs ", basketInputObj);

    // if (!contactInput && !customerInput) {
    //   this.props.toggleLoader({
    //     isLoading: false,
    //   });
    //   alert("Please enter customer or contact");
    //   return;
    // }

    if (!basketInputObj.desc) {
      this.props.toggleLoader({
        isLoading: false,
      });
      alert("Please enter description");
      return;
    }

    var contactName = basketInputObj.contact.DisplayName;
    // var contactEmail = basketInputObj.contact.ContactEmail;
    var customerName = basketInputObj.customer.Customer;
    var customerClass = basketInputObj.customer.CustomerClass;

    // this.props.toggleLoader({
    //   isLoading: true,
    // });
    // if (basketInputObj.orderNbr !== "New") {
    //   updatedItemsArray = items.slice().reverse();
    // } else {
    // updatedItemsArray = items;
    // }
    updatedItemsArray = cartItems.items;
    updatedItemsArray = updatedItemsArray.map((el) => {
      var productArr = {
        SerialNumber: el.SerialNumber,
        StyleNumber: el.StyleNumber,
        InternalNote: el.InternalNote,
        RetailPrice: el.RetailPrice,
        WholesalePrice: el.WholesalePrice,
        Quantity: el.quantity,
        PriceVisibility: el.priceVisibility,
        LinkVisibility: el.linkVisibility,
        ItemSelected: el.itemSelected,
        ProductType: el.productType,
        AssetOrMemo: el.assetOrmemo,
      };
      return productArr;
    });
    // console.log("items to save ", updatedItemsArray);
    // var products = JSON.stringify(updatedItemsArray).replace(/"/g, "'");
    var products = JSON.stringify(updatedItemsArray);

    // console.log("basketInputObj :", basketInputObj);
    var productObject = {
      BasketType: { value: basketInputObj.basketType },
      Contact: { value: contactInput === undefined ? null : contactInput },
      ContactName: { value: contactName === undefined ? null : contactName },
      // ContactEmail: { value: contactEmail === undefined ? null : contactEmail },
      Customer: { value: customerInput === undefined ? null : customerInput },
      CustomerName: {
        value: customerName === undefined ? null : customerName,
      },
      CustomerClass: {
        value: customerClass === undefined ? null : customerClass,
      },
      Description: {
        value: basketInputObj.desc === "" ? null : basketInputObj.desc,
      },
      IncludeRetailPrice: { value: basketInputObj.includeRetail },
      IncludeWholesalePrice: { value: basketInputObj.includeWholesale },
      InternalNotes: {
        value:
          basketInputObj.internalNotes === ""
            ? null
            : basketInputObj.internalNotes,
      },
      Occasion: {
        value:
          basketInputObj.occasion === "default"
            ? null
            : basketInputObj.occasion,
      },
      // OrderNbr: { value: basketInputObj.orderNbr },
      OrdersList: {
        value: products,
      },
      ActionType: {
        value: basketInputObj.orderNbr === "New" ? null : "Save",
      },
      UILoggedInUser: { value: this.state.userLoggedIn },

      // ...(basketInputObj.orderNbr === "New" && Object.keys(this.props.groups).length===0? {
      //   UILoggedInUser: { value: this.state.userLoggedIn },
      // } : {}),
      // ...(basketInputObj.orderNbr === "New" && Object.keys(this.props.groups).length!==0? {
      //   Email: { value: this.state.userLoggedIn },
      // }:{}),
      ...(basketInputObj.orderNbr === "New" && {
        CreatedAt: parseInt(dateTime),
      }),
      ...(basketInputObj.orderNbr !== "New" && {
        UpdatedAt: parseInt(dateTime),
      }),
      MakePrivate: { value: basketInputObj.makePrivate },
      Edit: { value: basketInputObj.edit },
      AssetOrMemo: {
        value:
          basketInputObj.assetOrmemo === "" ? null : basketInputObj.assetOrmemo,
      },
      // CreatedDate:
      //   basketInputObj.orderNbr === "New" ? parseInt(dateTime) : undefined,
      // UpdatedDate:
      //   basketInputObj.orderNbr !== "New" ? parseInt(dateTime) : undefined,
      // ...(type === "Delete" && { Status: "Deleted" }),
      Status: ["Deleted", "Archived", "Active"].includes(type)
        ? type
        : "Active",
    };
    // let token = this.props.tokenState.token;
    var payload = {
      data: {
        defaults: {
          // baseURL: basketBaseUrl,
          // token: token,
          index: AppbaseBasketApp,
        },
        id:
          basketInputObj.orderNbr !== "New"
            ? basketInputObj.orderNbr
            : basketID,
        type: ["Deleted", "Archived", "Active", "Update"].includes(type)
          ? // type === "Delete" ||
            // type === "Archived" ||
            // type === "Active" ||
            // type === "Update"
            "Update"
          : "New",
        inputs: productObject,
      },
    };
    // console.log("payload : ", payload);
    var resp = await axios
      // .post(ApiBaseUrl + "order", payload, {
      //   headers: {
      //     "x-api-key": ApiKey,
      //   },
      // })

      .post(appbaseBasketUrl + "basket", payload, {
        headers: {
          "x-api-key": basketApikey,
        },
      })
      .catch((error) => {
        toast.error("Error while saving basket !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        this.props.toggleLoader({
          isLoading: false,
        });
      });
    if (resp && resp.data.statusCode === 200) {
      // console.log("In if loop, resp: ", resp);
      this.props.setBasketFormInput({
        orderNbr: resp.data.data._id,
      });
      toast.success(
        `${
          ["Deleted", "Archived", "Active", "Update"].includes(type)
            ? // type === "Delete" || type === "Archived" || type === "Active"
              `Basket ${type} !`
            : "Basket saved !"
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        }
      );
      this.props.toggleLoader({
        isLoading: false,
      });
      return;
    }
    // else if (resp && JSON.parse(resp.data.body).errorCode === 401) {

    //   let token = await GetAuthToken();
    //   if (token) {
    //     this.props.setToken(token.access_token);
    //     await this.handleSave();
    //   } else {
    //     this.props.toggleLoader({
    //       isLoading: false,
    //     });
    //     return [];
    //   }
    // }
    else {
      // console.log("In else loop resp : ", resp);
      toast.error("Error while saving basket !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      this.props.toggleLoader({
        isLoading: false,
      });
    }
  }

  render() {
    console.log("-------before-------");
    let { showModal, items } = this.state;
    let { isLoading } = this.props.loaderActions;
    // console.log("basketApiKey: ", basketApikey);
    // console.log("items :", items);
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="basket-modal"
          centered="true"
          size="sm"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          {/* <LoadingOverlay active={isLoading} spinner text="Loading..."> */}
          <Modal.Header>
            <BasketHeader
              onModalHide={this.onModalHide}
              handleSave={this.handleSave}
              showPreviewModal={this.showPreviewModal}
              showPDFModal={this.showPDFModal}
              showChooseLayout={this.showChooseLayout}
              showExportModal={this.showExportModal}
              showBasketChangeModal={this.showBasketChangeModal}
              userLoggedIn={this.state.userLoggedIn}
              selectedItems={this.state.selectedItems}
              handleSetCover={this.handleSetCover}
              handleIncludeGIA={this.handleIncludeGIA}
              handleIncludeKTDShapes={this.handleIncludeKTDShapes}
              showAccuExportModal={this.showAccuExportModal}
              handleEmailTemplate={this.handleEmailTemplate}
              handleCoverModal={this.handleCoverModal}
            />
          </Modal.Header>
          <Modal.Body>
            <BasketForm
              items={items}
              showQuickAddBulkProductModal={this.showQuickAddBulkProductModal}
              handleSave={this.handleSave}
              userLoggedIn={this.state.userLoggedIn}
            />
            <BaksetItemsList
              items={items}
              showMoreModal={this.showMoreModal}
              selectedItems={this.state.selectedItems}
              handleSelectedItems={this.handleSelectedItems}
            />
          </Modal.Body>
          {/* </LoadingOverlay> */}
        </Modal>
        <MoreDetailsModal
          show={this.state.showMoreModal}
          hide={this.hideMoreModal}
          res={this.state.currentRes}
        />
        <PreviewEmailModal
          show={this.state.showPreviewModal}
          hide={this.hidePreviewModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          selectedItems={this.state.selectedItems}
          includeGIA={this.state.includeGIA}
          includeKTDShapes={this.state.includeKTDShapes}
          csvData={this.state.csvData}
          csvRenderData={this.state.csvRenderData}
          handleCsvData={this.handleCsvData}
          handleCsvRenderData={this.handleCsvRenderData}
          coverSelected={this.state.coverSelected}
          selectedShapes={this.state.selectedShapes}
          MTSInfo={this.state.MTSInfo}
          handleCheckboxChange={this.handleCheckboxChange}
          handleMTSCheckboxChange={this.handleMTSCheckboxChange}
        />
        <PDFModal
          show={this.state.showPDFModal}
          hide={this.hidePDFModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          selectedItems={this.state.selectedItems}
          includeGIA={this.state.includeGIA}
          allShapesSelected={this.state.allShapesSelected}
          selectedShapes={this.state.selectedShapes}
          MTSInfo={this.state.MTSInfo}
          handleCheckboxChange={this.handleCheckboxChange}
          handleMTSCheckboxChange={this.handleMTSCheckboxChange}
          includeKTDShapes={this.state.includeKTDShapes}
          coverSelected={this.state.coverSelected}
          type="Basket"
        />
        <ChooseLayoutModal
          show={this.state.showChooseLayout}
          hide={this.hideChooseLayout}
          setLayout={this.handleSetLayout}
          setCover={this.handleSetCover}
          showPreviewModal={this.showPreviewModal}
          showPDFModal={this.showPDFModal}
          goto={this.state.goto}
          showChooseLayout={this.showChooseLayout}
          coverType={this.state.coverType}
          includeGIA={this.state.includeGIA}
          handleIncludeGIA={this.handleIncludeGIA}
          includeKTDShapes={this.state.includeKTDShapes}
          handleIncludeKTDShapes={this.handleIncludeKTDShapes}
          selectedItems={this.state.selectedItems}
          covers={this.state.covers}
          handleSelectedCover={this.handleSelectedCover}
          coverCategories={this.state.coverCategories}
          coverSelected={this.state.coverSelected}
          selectedShapes={this.state.selectedShapes}
          MTSInfo={this.state.MTSInfo}
          handleCheckboxChange={this.handleCheckboxChange}
          handleMTSCheckboxChange={this.handleMTSCheckboxChange}
        />
        <ChooseExportModal
          show={this.state.showExportModal}
          hide={this.hideExportModal}
          selectedItems={this.state.selectedItems}
          showPreviewModal={this.showPreviewModal}
          handleCsvData={this.handleCsvData}
          handleCsvRenderData={this.handleCsvRenderData}
        />
        <BasketChangeModal
          show={this.state.showBasketChangeModal}
          hide={this.hideBasketChangeModal}
          handleSave={this.handleSave}
          goto={this.state.goto}
          showChooseLayout={this.showChooseLayout}
        />
        <QuickBulkAddProduct
          show={this.state.showQuickAddBulkProductModal}
          hide={this.hideQuickAddBulkProductModal}
        />
        <AccuExportModal
          show={this.state.showAccuExportModal}
          hide={this.hideAccuExportModal}
          link={this.state.accuExportLink}
        />
        <TemplateIndex
          show={this.state.showEmailTemplate}
          handleEmailTemplate={this.handleEmailTemplate}
        />
        <CoverIndex
          show={this.state.showCoverModal}
          handleCoverModal={this.handleCoverModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBasketFormInput,
      toggleLoader,
      setToken,
      updateItemSelected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
