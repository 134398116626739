import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { holdNotesFields } from "./holdNotesField";
import moment from "moment";

export default class HoldNotesArchievedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res: this.props.res,
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    let { hide } = this.props;
    this.setState(() => {
      hide && hide(null, false, "archivedHoldNotes");
    });
  }

  render() {
    let { show, res, holdNotesData } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => this.onModalHide()}
        animation={false}
        // dialogClassName="show-more_modal-dailog"
        className="hold_notes_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Hold Notes:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="hold_notes_container">
            {/* <table className="modal-table" width="100%"> */}
            {holdNotesData &&
              holdNotesData
                .filter((data) => data._source.IsArchived === "1")
                .filter((data) => ![null, undefined, ""].includes(data))
                .length !== 0 && (
                <Table>
                  <thead>
                    <tr>
                      {/* {holdNotesData &&
                    Object.keys(holdNotesData[0]._source).map((key) => {
                      return <th>{key}</th>;
                    })} */}
                      {holdNotesFields &&
                        holdNotesFields.map((fieldData) => {
                          return <th>{fieldData.label}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {holdNotesData &&
                  holdNotesData.map((data) => (
                    <tr>
                      {Object.values(data._source).map((value) => (
                        <td>{value}</td>
                      ))}
                    </tr>
                  ))} */}
                    {holdNotesData &&
                      holdNotesData
                        .filter((data) => data._source.IsArchived === "1")
                        .sort(
                          (a, b) =>
                            parseInt(
                              b._source.HoldDate.replace(/-/g, "").slice(0, 9)
                            ) -
                            parseInt(
                              a._source.HoldDate.replace(/-/g, "").slice(0, 9)
                            )
                        )
                        .map((data) => (
                          <tr>
                            {holdNotesFields &&
                              holdNotesFields.map((fieldData) => {
                                if (fieldData.field.includes("Date")) {
                                  return (
                                    <td>
                                      {" "}
                                      {moment(
                                        new Date(
                                          `${data._source[fieldData.field]}`
                                        )
                                      ).format("MM/DD/YYYY")}
                                    </td>
                                  );
                                }
                                if (fieldData.field === "IsHoldSold") {
                                  return (
                                    <td>
                                      {data._source[fieldData.field] === "0"
                                        ? "No"
                                        : "Yes"}
                                    </td>
                                  );
                                }
                                return <td>{data._source[fieldData.field]}</td>;
                              })}
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
