import React, { Component } from "react";
import {
  SingleDropdownList,
  MultiList
} from "@appbaseio/reactivesearch";

const item_order = [
  "FL",
  "IF",
  "VVS1",
  "VVS2",
  "VS1",
  "VS2",
  "SI1",
  "SI2",
  "SI3"
];

const item_order2 = [
"FL",
"IF",
"IF-VVS1",
"VVS1",
"IF-VVS2",
"VVS1-2",
"VVS2",
"IF-VS1",
"VVS1-VS1",
"VVS2-VS1",
"VS1",
"VS1-2",
"VVS1-VS2",
"VVS2-VS2",
"VS2",
"VS1-SI1",
"VS2-SI1",
"VVS2-SI1",
"XYZ SI",
"SI1",
"SI1-2",
"VS1-SI2",
"VS2-SI2",
"XYZ S1-2",
"XYZ SI2",
"SI2",
"SI2-3",
"SI3"
]

class DiamondClarityRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: '',
      endValue: '',
    };
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.mapOrder = this.mapOrder.bind(this);
    this.sortedArr = this.sortedArr.bind(this);
  }
  handleStartChange(value) {
    this.setState({
      startValue: value
    });
  }
  handleEndChange(value) {
    this.setState({
      endValue: value
    });
  }
  mapOrder(array, order, key) {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }
  sortedArr(array, order) {
    let arr = []
    for (let i = 0; i < order.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (order[i] === array[j].key) {
          arr.push(array[j])
        }
      }
    }
    return arr
  }
  render() {
    let { startValue, endValue } = this.state;
    let value;
    if (!startValue && !endValue) {
      value = null;
    } else if (startValue && !endValue) {
      value = item_order2.slice(item_order2.indexOf(startValue));
    } else if (!startValue && endValue) {
      value = item_order2.slice(0, item_order2.indexOf(endValue) + 1);
    } else {
      if (item_order2.indexOf(startValue) > item_order2.indexOf(endValue)) {
        value = item_order2.slice(
          item_order2.indexOf(endValue),
          item_order2.indexOf(startValue) + 1
        );
      } else {
        value = item_order2.slice(
          item_order2.indexOf(startValue),
          item_order2.indexOf(endValue) + 1
        );
      }
    }
    return (
      <div className="diamond-range form-field-wrap">
        <div className="color-input-dropdowns">
          <h2>Dia Clarity</h2>
          <SingleDropdownList
            componentId="DiamondStartCarat"
            className="start-range large-dropdown"
            dataField="StudsClarity.keyword"
            size={100}
            placeholder=""
            sortBy="count"
            showCount={false}
            showFilter={true}
            filterLabel="DiaStartClarity"
            onValueChange={value => this.handleStartChange(value)}
            transformData={list => {
              const ordered_array = this.sortedArr(list, item_order);
              return ordered_array
            }}
          />
          <span>-</span>
          <SingleDropdownList
            componentId="DiamondEndCarat"
            className="end-range large-dropdown"
            dataField="StudsClarity.keyword"
            size={100}
            placeholder=""
            sortBy="count"
            showCount={false}
            showFilter={true}
            filterLabel="DiaEndClarity"
            onValueChange={value => this.handleEndChange(value)}
            transformData={list => {
              const ordered_array = this.sortedArr(list, item_order);
              return ordered_array
            }}
          />
          <MultiList
            componentId="DiamondClarityRange"
            className="hide__multilist"
            dataField="StudsClarity.keyword"
            value={value}
            size={100}
            sortBy="asc"
            queryFormat="or"
            showSearch={false}
            showFilter={false}
            react={{
              or: ["DiamondStartCarat", "DiamondEndCarat"]
            }}
          />
        </div>
      </div>
    );
  }
}

export default DiamondClarityRange;
