import React from "react";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import { SortableElement, sortableHandle } from "react-sortable-hoc";
import { toast } from "react-toastify";
import ShowMore from "react-show-more";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import moment from "moment";

const DragHandle = sortableHandle(() => (
  <img
    src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/drag-reorder.png"
    className="drag__icon"
    alt="drag n drop"
  />
));

const showWebImage = (img) => {
  var src =
    "https://cdn4.kwiat.com/source-images/web/altviews/" +
    img.replace(/\.[^.]*$/, "") +
    "-altview@2x.jpg";
  return src;
};
const showimage = (image) => {
  var str, img;
  if (image && image != null) {
    let searchimage;
    searchimage = image;
    str = searchimage.split("\\");
    searchimage = str[str.length - 1].replace(/icon/g, "search");
    searchimage = searchimage.replace(/Icon/g, "search");
    img = "https://cdn.kwiat.com/source-images/search/" + searchimage;
  } else {
    img = "";
  }
  return img;
};
const thumbnailImage = (res) => {
  var src = false;
  if (
    res.SerialVideoLink &&
    res.SerialVideoLink.includes("/v360/") &&
    res.IconImageName &&
    res.IconImageName.includes("still")
  ) {
    var productId = res.SerialVideoLink.match("imaged/(.*)/")[1];
    src = `https://cdn.kwiat.com/kwiat/v360/imaged/${productId}/still.jpg`;
  } else if (res.WebImage1) {
    src = showWebImage(res.WebImage1);
  } else if (res.IconImageName) {
    src = showimage(res.IconImageName);
  }
  return src;
};
const showDiaImage = (image) => {
  var img, str;
  if (image && image != null) {
    img = image;
    str = img.split("=");
    if (str[1] && str[1].trim() === "") {
      img = "";
      str = "";
    }
  } else {
    img = "";
    str = "";
  }
  return img;
};
// const diaIcon = (shape) => {
//   var img;
//   if (shape && shape != null) {
//     img =
//       "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
//       shape +
//       ".svg";
//   }
//   return img;
// };
const diaIcon = (data) => {
  var img;
  if (data && data != null) {
    img = data;
  }
  return img;
};

const SortableItem = SortableElement((props) => {
  let {
    value: el,
    handleQtyChange,
    removeFromCart,
    handleMoreDetails,
    handleKeyDown,
    handlePriceEditChange,
    handleInputBlur,
    handleTogglePriceInput,
    handlePriceVisibility,
    handleLinkVisibility,
    handleInternalNotes,
    customerClass,
    contact,
    customer,
    showZoomImage,
    showMoreModal,
    isValueEmpty,
    warehouse,
    i,
    handleSelectedItems,
    handleAssetOrMemo,
    basketInputObj,
    handleHoldNoteArchive,
    handleHoldNotesModals,
    handleItemHoldNoteUpdate,
    handleItemRefresh,
    handleHoldNoteEditModal,
  } = props;

  // console.log("props: ", props);

  var idForCollapse = `${
    el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
  }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
    el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
  }-${el.priceVisibility.replaceAll(/\s/g, "")}-${el.linkVisibility.replaceAll(
    /\s/g,
    ""
  )}`;

  return (
    <div className="basket__items__list--mobile">
      <div className="es__basket__product__warehouse__value">
        <input
          type="checkbox"
          checked={el.itemSelected ? el.itemSelected : false}
          onChange={(e) => handleSelectedItems(e, el)}
        />
        <label>
          {/* {" "}
          {warehouse.map((value) =>
            value.InventoryID === el.InventoryID &&
            value.SerialNumber === el.SerialNumber
              ? `${isValueEmpty(value.Warehouse)}
                                  ${
                                    value.Warehouse !== value.SerialStatus &&
                                    isValueEmpty(value.Warehouse) &&
                                    isValueEmpty(value.SerialStatus)
                                      ? "/"
                                      : ""
                                  }
                                    ${
                                      value.IsVirtual === "1"
                                        ? "Virtual - "
                                        : ""
                                    }
                                    ${
                                      value.IsPhantom === "1"
                                        ? "Phantom - "
                                        : ""
                                    }
                                    ${
                                      value.Warehouse !== value.SerialStatus
                                        ? isValueEmpty(value.SerialStatus)
                                        : ""
                                    }`
              : ``
          )} */}
          {el.SerialNumber
            ? isValueEmpty(el.Warehouse) && el.Warehouse === "MEMO"
              ? `${el.Warehouse} - ${el.StatusCustomer}`
              : el.Warehouse
            : ""}
          {el.SerialNumber
            ? el.Warehouse !== el.SerialStatus &&
              isValueEmpty(el.Warehouse) &&
              isValueEmpty(el.SerialStatus)
              ? "/"
              : ""
            : ""}
          {el.SerialNumber ? (el.IsVirtual === "1" ? "Virtual - " : "") : ""}
          {el.SerialNumber ? (el.IsPhantom === "1" ? "Phantom - " : "") : ""}
          {el.SerialNumber
            ? el.Warehouse !== el.SerialStatus
              ? isValueEmpty(el.SerialStatus)
              : ""
            : ""}
        </label>
      </div>
      <div className="es__basket__product">
        <div className="product__actions__container">
          {/* <span className="drag__class">
            <DragHandle />
          </span>
          <div className="item__quantity__info">
            <span className="quantity__title">QTY</span>
            <input
              type="number"
              min="1"
              value={el.quantity}
              onFocus={(e) => e.target.select()}
              onChange={(e) => handleQtyChange(el, e.target.value)}
            />
          </div> */}
          <span className="product_no">{i < 9 ? `0${i + 1}` : i + 1}</span>
          <span className="remove__product">
            <img
              src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/remove-from-basket.png"
              alt="remove product"
              className="remove__icon"
              onClick={() => {
                removeFromCart(el);
                toast.success("Removed from basket !", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                  pauseOnHover: false,
                });
              }}
            />
          </span>
        </div>
        <div className="product__image__box">
          <h5 className="product-info_box_heading">
            <span>{el.SerialNumber}</span>
          </h5>
          <div className="item__thumbnail__box">
            {el.productType === "J" || el.productType === "G" ? (
              thumbnailImage(el) ? (
                <img
                  className="product-thumbnail"
                  src={thumbnailImage(el)}
                  onClick={() => showZoomImage(el, "onImage")}
                  alt="product"
                />
              ) : (
                <ReactImageFallback
                  fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                  alt=""
                  className="product-thumbnail"
                />
              )
            ) : el.IconImageFileID ? (
              <img
                className="product-image-icon"
                src={showDiaImage(el.IconImageFileID)}
                alt="product"
              />
            ) : (
              // <img
              //   className="dia-image-icon"
              //   src={diaIcon(el.Shape)}
              //   alt="product"
              // />
              <img
                className="dia-image-icon"
                src={diaIcon(el.search_dia_image_url)}
                alt="product"
              />
            )}
          </div>
        </div>
        <div className="product__info__container">
          <div className="product-info_box">
            <h5 className="product-stylenumber_heading">
              <span>{el.StyleNumber}</span>
            </h5>
            <div className="product__details__container">
              <table className="es__mobile-product-details-table">
                <tbody>
                  <tr>
                    <td>
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        {el.Maker && el.Maker !== null ? `${el.Maker}` : ""}
                      </ShowMore>
                    </td>
                    <td>
                      {el.RetailPrice && el.RetailPrice != null
                        ? currencyFormatter.format(`${el.RetailPrice}`, {
                            code: "USD",
                            precision: 0,
                          })
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {el.RetailPrice && el.RetailPrice != null
                          ? "Retail Price"
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        {el.Metal && el.Metal !== null ? `${el.Metal}` : ""}
                      </ShowMore>
                    </td>
                    <td>
                      {el.RetailPriceCode}
                      <span className="es__mobile-product-details-table__label">
                        {el.RetailPriceCode && el.RetailPriceCode != null
                          ? "Code"
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {el.DiamondCarats && el.DiamondCarats !== null
                        ? `${el.DiamondCarats}`
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {el.DiamondCarats && el.DiamondCarats != null
                          ? "DIA-CTS"
                          : ""}
                      </span>
                    </td>
                    <td>
                      {el.SerialStatus && el.SerialStatus !== null
                        ? ` ${el.SerialStatus}`
                        : ""}
                      <span className="es__mobile-product-details-table__label">
                        {el.SerialStatus && el.SerialStatus != null
                          ? "Location"
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        {el.Color && el.Color != null ? `${el.Color}` : ""}
                        {el.Clarity && el.Clarity != null
                          ? ` / ${el.Clarity}`
                          : ""}
                      </ShowMore>
                    </td>
                    <td>
                      <button
                        className="btn btn-default align-self-end collapsed"
                        data-toggle="collapse"
                        data-target={`#product__collapsed__more-details-${idForCollapse}`}
                        onClick={(e) => handleMoreDetails(e)}
                      >
                        +
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="clearfix collapse"
        id={`product__collapsed__more-details-${idForCollapse}`}
      >
        <div className="details__divider" />
        <div className="more__details__container">
          <div className="basic__details">
            <table>
              <tbody>
                <tr>
                  <td>{el.Maker}</td>
                </tr>
                <tr>
                  <td>{el.Period}</td>
                </tr>
                <tr>
                  <td>
                    {el.Length}
                    {el.BangleSize}
                    {el.Diameter}
                    {el.HoopDiameter}
                  </td>
                </tr>
                <tr>
                  <td>{el.WidthOD}</td>
                  <td>
                    <span
                      className="show-more"
                      onClick={() => showMoreModal(el)}
                    >
                      +
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="holding-details pos-relative mobile-view">
            <div className="product-info_box diamonds-status-pricing-hold-info_box">
              <h5 className="product-info_box_heading product-light_title_color">
                <span
                // onClick={() =>
                //   this.props.toggleIframeModal({
                //     show: true,
                //     url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${el.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                //   })
                // }
                >
                  Hold Notes
                </span>
              </h5>
              {basketInputObj.holdNotesAccess && (
                <div class="hold_notes_actions">
                  {!el._id.toString().includes("JOB") && el.HoldNoteID && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="tooltip" id="Edit">
                          Edit
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        onClick={() => {
                          handleHoldNoteEditModal(true);
                          handleHoldNotesModals(
                            "",
                            true,
                            "createHoldNotes",
                            el
                          );
                        }}
                      >
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </OverlayTrigger>
                  )}
                  {!el._id.toString().includes("JOB") && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="tooltip" id="Create">
                          Create
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        onClick={() =>
                          handleHoldNotesModals(
                            el.InventoryID.toString() +
                              el.SerialNumber.toString(),
                            true,
                            "createHoldNotes",
                            el
                          )
                        }
                      >
                        +
                      </span>
                    </OverlayTrigger>
                  )}
                  {el.HoldNoteID && !el._id.toString().includes("JOB") && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="tooltip" id="Archive">
                          Archive
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span onClick={() => handleHoldNoteArchive(el)}>x</span>
                    </OverlayTrigger>
                  )}
                  {!el._id.toString().includes("JOB") && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="tooltip" id="View">
                          View
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        onClick={(e) =>
                          handleHoldNotesModals(
                            el.InventoryID.toString() +
                              el.SerialNumber.toString(),
                            true,
                            "archivedHoldNotes",
                            ""
                          )
                        }
                      >
                        <i class="fa fa-history" aria-hidden="true"></i>
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              )}
              <div className="product-info_background">
                <section className="responsive-hold-notes">
                  <div className="product-details_lcol">
                    <div className="unknown product-details-label">
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        <span className="entered-by">
                          {isValueEmpty(el.HoldEnteredByName)}
                        </span>
                      </ShowMore>
                    </div>
                  </div>
                  <div className="product-details_mcol">
                    <div className="unknown product-details-label">
                      <ShowMore
                        lines={1}
                        more="&#43;"
                        less="&#8722;"
                        anchorClass=""
                      >
                        <span className="customer-name">
                          {isValueEmpty(el.HoldCustomerName)}
                        </span>
                      </ShowMore>
                    </div>
                  </div>
                </section>
                <section className="responsive-hold-notes">
                  <div className="product-details_lcol">
                    <div className="unknown product-details-label">
                      <span className="hold-date">
                        {isValueEmpty(el.HoldDate)
                          ? moment(new Date(`${el.HoldDate}`)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </span>
                      <span className="product-details-view-label">
                        {isValueEmpty(el.HoldDate) ? " Hold Date" : ""}
                      </span>
                    </div>
                  </div>
                  <div className="product-details_mcol">
                    <div className="unknown product-details-label">
                      <span className="release-date">
                        {isValueEmpty(el.ReleaseDate)
                          ? moment(new Date(`${el.ReleaseDate}`)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </span>
                      <span className="product-details-view-label">
                        {isValueEmpty(el.ReleaseDate) ? " Release Date" : ""}
                      </span>
                    </div>
                  </div>
                </section>
                <div className="holding-description">
                  <ShowMore
                    lines={3}
                    more="&#43;"
                    less="&#8722;"
                    anchorClass="holding-description_accordion"
                  >
                    <span className="holding-con">
                      {isValueEmpty(el.HoldText)}
                    </span>
                  </ShowMore>
                </div>
                {el.IsHoldSold === "1" && (
                  <div className="is_hold_sold">SOLD</div>
                )}
              </div>
            </div>
          </div>
          <div className="pricing__internal-note__grouped">
            <div className="price__section">
              <h5 className="box__heading">
                <span>PRICING</span>
              </h5>
              <table width="100%">
                <tbody>
                  <tr>
                    <td className="price__value">
                      <input
                        className="basket__item__edit"
                        type="number"
                        style={{ display: "none" }}
                        value={parseFloat(el.RetailPrice).toFixed() || 0}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => handlePriceEditChange(e, "retail", el)}
                        onBlur={(e) => handleInputBlur(e)}
                      />
                      <p>
                        {currencyFormatter.format(`${el.RetailPrice}`, {
                          code: "USD",
                          precision: 0,
                        })}
                      </p>
                    </td>
                    <td rowSpan="2" className="edit__price__icon">
                      {!customer.Customer && !customer.CustomerClass ? (
                        <img
                          className="edit__price"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                          alt="price edit"
                          onClick={(e) => handleTogglePriceInput(e, "retail")}
                        />
                      ) : customerClass === "RETAIL" ||
                        customerClass === "TRADE" ||
                        (contact.ContactId && customerClass !== "WHOLESALE") ||
                        customerClass === "WHOLESALE" ? (
                        <img
                          className="edit__price"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                          alt="price edit"
                          onClick={(e) => handleTogglePriceInput(e, "retail")}
                        />
                      ) : (
                        <img
                          className="edit__price"
                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                          alt="price edit"
                          style={{ opacity: "0.3" }}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="price__label">Retail</td>
                  </tr>
                  {basketInputObj.showWholesale && (
                    <tr>
                      <td className="price__value">
                        <input
                          className="basket__item__edit"
                          type="number"
                          value={parseFloat(el.WholesalePrice).toFixed() || 0}
                          style={{ display: "none" }}
                          onKeyDown={(e) => handleKeyDown(e)}
                          onChange={(e) =>
                            handlePriceEditChange(e, "wholesale", el)
                          }
                          onBlur={(e) => handleInputBlur(e)}
                        />
                        <p>
                          {(el.WholesalePrice &&
                            currencyFormatter.format(`${el.WholesalePrice}`, {
                              code: "USD",
                              precision: 0,
                            })) ||
                            "$0"}
                        </p>
                      </td>
                      <td rowSpan="2" className="edit__price__icon">
                        {!customer.Customer && !customer.CustomerClass ? (
                          <img
                            className="edit__price"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                            alt="price edit"
                            onClick={(e) =>
                              handleTogglePriceInput(e, "wholesale")
                            }
                          />
                        ) : customerClass === "WHOLESALE" ? (
                          <img
                            className="edit__price"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                            alt="price edit"
                            onClick={(e) =>
                              handleTogglePriceInput(e, "wholesale")
                            }
                          />
                        ) : (
                          <img
                            className="edit__price"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                            alt="price edit"
                            style={{ opacity: "0.3" }}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                  {basketInputObj.showWholesale && (
                    <tr>
                      <td className="price__label">Wholesale</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {customerClass && customerClass !== "RETAIL" ? (
                <select
                  value={el.assetOrmemo || ""}
                  onChange={(e) => handleAssetOrMemo(e, el)}
                >
                  <option value="">Asset/Memo</option>
                  <option value="A">Asset</option>
                  <option value="M">Memo</option>
                </select>
              ) : (
                ``
              )}
              {basketInputObj.showWholesale && (
                <select
                  id="price__visibility__select"
                  value={el.priceVisibility || "Default"}
                  onChange={(e) => handlePriceVisibility(e, el)}
                >
                  <option value="Default">Price</option>
                  <option value="Hide Retail Price">Hide Retail</option>
                  <option value="Hide Wholesale Price">Hide Wholesale</option>
                  <option value="Hide Both">Hide Both</option>
                </select>
              )}
              <select
                // id="price__visibility__select"
                value={el.linkVisibility || "Default"}
                onChange={(e) => handleLinkVisibility(e, el)}
              >
                <option value="Default">Link</option>
                <option value="Hide Link">Hide Links</option>
                {/* <option value="Hide Wholesale Price">
                                Hide Wholesale Price
                              </option> */}
                {/* <option value="Hide Both">Hide Both</option> */}
              </select>
            </div>
            <div className="internal-note__section">
              <textarea
                placeholder="Item Level Note Here..."
                value={el.InternalNote}
                onChange={(e) => handleInternalNotes(e, el)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SortableItem;
