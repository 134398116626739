// Action types per module
export const TOGGLE_IFRAME_MODAL = "TOGGLE_IFRAME_MODAL";
export const SETAPPTYPE = "SETAPPTYPE";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_INTERNAL_NOTES = "UPDATE_INTERNAL_NOTES";
export const UPDATE_PRICE_VISIBILITY = "UPDATE_PRICE_VISIBILITY";
export const UPDATE_LINK_VISIBILITY = "UPDATE_LINK_VISIBILITY";
export const UPDATE_ITEM_SELECTED = "UPDATE_ITEM_SELECTED";
export const BASKET_FORM_INFO = "BASKET_FORM_INFO";
export const RESET_STATE = "RESET_STATE";
export const OPEN_BASKET_ITEMS = "OPEN_BASKET_ITEMS";
export const TOGGLE_BASKET_LOADER = "TOGGLE_BASKET_LOADER";
export const SETTOKEN = "SETTOKEN";
export const SETSORTOPTION = "SETSORTOPTION";
export const SET_ASSET_OR_MEMO = "SET_ASSET_OR_MEMO";
export const SET_GROUPS = "SET_GROUPS";
export const SET_EMP_DATA = "SET_EMP_DATA";
export const SET_FEEDS_HOLD_DATA = "SET_FEEDS_DATA";
export const SET_JEWELRY_HOLD_DATA = "SET_JEWELRYHOLD_DATA";
