import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../components/MultiDropdownListWithFocus";

class SubCollection extends Component {
  render() {
    return (
      <div className="item-subcollection">
        <MultiDropdownList
          className="form-field"
          title="SubCollection"
          componentId="SubCollection"
          dataField={"SubCollection.keyword"}
          react={{ and: ["Collection"] }}
          size={1000}
          showCount={false}
          showSearch={true}
          // showClear={true && !this.props.showFilteredData === ""}
          // searchPlaceholder={!this.props.showFilteredData && ""}
          sortBy="asc"
          renderListItem={(label) => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(SubCollection);
