import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./configureStore";
import IframeModal from "./components/IframeModal";
import "jquery/src/jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-theme.css";
import "react-multi-email/style.css";
import "./styles/index.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import App from "./App";
import "font-awesome/css/font-awesome.css";
// import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

import { unregister } from "./registerServiceWorker";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="AppContainer">
        <App />
        <IframeModal />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
unregister();
