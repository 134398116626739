import React, { Component } from "react";
import { MultiList } from "@appbaseio/reactivesearch";

class IsSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: ["0"]
    };
  }

  componentDidMount() {
    if (this.props?.keywordSearchSignal === "ACTIVE") {
      this.setState({ checkedValues: ["0", "1"] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.keywordSearchSignal !== this.props?.keywordSearchSignal) {
      if (this.props?.keywordSearchSignal === "ACTIVE") {
        this.setState({ checkedValues: ["0", "1"] });
      } else {
        this.setState({ checkedValues: ["0"] });
      }
    }
  }

  handleValueChange = (value) => {
    this.setState({ checkedValues: value });
    if (value.includes("1")) {
      this.props.handleSoldSignal(true);
    } else {
      this.props.handleSoldSignal(false);
    }
  };

  render() {
    const { checkedValues } = this.state;

    return (
      <div className="toggle__filters">
        <MultiList
          componentId="IncludeSold"
          dataField="IsSold.keyword"
          value={checkedValues}
          queryFormat="or"
          showFilter={true}
          showSearch={false}
          sortBy="asc"
          render={({ data, handleChange }) => {
            const checkedFlag = checkedValues.includes("1")
            if (data.length === 1) {
              return (
                <ul>
                  <li />
                  <li>
                    <input
                      type="checkbox"
                      value="1"
                      onChange={(e) => {
                        handleChange(e);
                        this.handleValueChange(e.target.checked ? ["0", "1"] : ["0"]);
                      }}
                      checked={checkedFlag}
                    />
                    <span className="toggle--label"> + Sold</span>
                  </li>
                </ul>
              );
            } else {
              return (
                <ul>
                  {data.map((item) => (
                    <li key={item.key}>
                      <input
                        type="checkbox"
                        value={item.key}
                        onChange={(e) => {
                          handleChange(e);
                          const newValue = e.target.checked
                            ? [...checkedValues, item.key]
                            : checkedValues.filter((val) => val !== item.key);
                          this.handleValueChange(newValue);
                        }}
                        checked={checkedFlag}
                      />
                      <span className="toggle--label"> + Sold</span>
                    </li>
                  ))}
                </ul>
              );
            }
          }}
        />
      </div>
    );
  }
}

export default IsSold;