import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import currencyFormatter from "currency-formatter";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleIframeModal } from "../actions/index";

import { BaseURL } from "../utils/constants";

class SoldModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }
  render() {
    let { showModal } = this.state;
    let { result, checked, access } = this.props;
    console.log("access", access);
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="stock-popup-modal product"
          centered="true"
          size="sm"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton className="modal-header-con">
            <Modal.Title>Serial # {result.SerialNumber}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="product-sold_details pos-relative">
              <div className="product-info_box">
                <h5 className="product-info_box_heading product-light_title_color stock-modal-info_box_heading">
                  <span>Sold Details</span>
                </h5>
                <div className="product-info_background">
                  <section>
                    <div className="product-details_lcol">
                      <div
                        className={
                          result.SoldDate && result.SoldDate != null
                            ? "known product-details-label"
                            : "unknown product-details-label"
                        }
                      >
                        <span className="sold-date product-details_line-break ">
                          {result.SoldDate && result.SoldDate != null
                            ? moment(new Date(`${result.SoldDate}`)).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </span>
                        <span className="product-details-view-label">Date</span>
                      </div>
                    </div>
                    <div className="product-details_mcol">
                      {access === "advanced" ? (
                        <div
                          className={
                            result.SoldRefNumber && result.SoldRefNumber != null
                              ? "known product-details-label"
                              : "unknown product-details-label"
                          }
                        >
                          <span className="pricing-method product-details_line-break">
                            {checked === true
                              ? result.SoldDiscountCode
                              : result.SoldDiscount &&
                                result.SoldDiscount != null
                              ? `${Number(result.SoldDiscount).toFixed(2)} %`
                              : ""}
                          </span>
                          <span className="product-details-view-label">
                            Discount Percent
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </section>
                  <section>
                    <div className="product-details_lcol">
                      <div className="unknown product-details-label ">
                        <span
                          className="sold-customer product-details_line-break iframe-link"
                          onClick={() =>
                            this.props.toggleIframeModal({
                              show: true,
                              url: `${BaseURL}/pages/kw/kw501003.aspx?PopupPanel=On&AcctCD=${
                                result.SoldCustomerID
                              }`
                            })
                          }
                        >
                          {result.SoldCustomer && result.SoldCustomer != null
                            ? `${result.SoldCustomer}`
                            : ""}
                        </span>
                        <span className="product-details-view-label">
                          Customer
                        </span>
                      </div>
                    </div>
                    <div className="product-details_mcol">
                      {access === "advanced" ? (
                        <div className="unknown product-details-label">
                          <span className="pricing-method product-details_line-break">
                            {checked === true
                              ? result.SoldMarginCode
                              : result.SoldMargin && result.SoldMargin != null
                              ? currencyFormatter.format(
                                  `${result.SoldMargin}`,
                                  {
                                    code: "USD",
                                    precision: 0
                                  }
                                )
                              : ""}
                          </span>
                          <span className="product-details-view-label">
                            Total Profit
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </section>
                  {/* 3rd section */}
                  <section>
                    <div className="product-details_lcol">
                      <div className="unknown product-details-label ">
                        <span className="in-stock-cost product-details_line-break">
                          {checked === true
                            ? result.SoldPriceCode
                            : result.SoldPrice && result.SoldPrice != null
                            ? currencyFormatter.format(`${result.SoldPrice}`, {
                                code: "USD",
                                precision: 0
                              })
                            : ""}
                        </span>
                        <span className="product-details-view-label">
                          Sold Price
                        </span>
                      </div>
                    </div>
                    <div className="product-details_mcol">
                      {access === "advanced" ? (
                        <div className="unknown product-details-label">
                          <span className="sold-customer product-details_line-break">
                            {result.ProfitMargin && result.ProfitMargin !== null
                              ? `${Number(result.ProfitMargin * 100).toFixed(2)} %`
                              : ""}
                          </span>
                          <span className="product-details-view-label">
                            Profit Margin
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </section>
                  {/* 4th section */}
                  <section>
                    <div className="product-details_lcol">
                      <div className="unknown product-details-label ">
                        <span
                          className="sold-ref-number product-details_line-break iframe-link"
                          onClick={() =>
                            this.props.toggleIframeModal({
                              show: true,
                              url: `${BaseURL}/pages/ar/ar301000.aspx?PopupPanel=On&DocType=INV&RefNbr=${
                                result.SoldRefNumber
                              }`
                            })
                          }
                        >
                          {result.SoldRefNumber && result.SoldRefNumber != null
                            ? `${result.SoldRefNumber}`
                            : ""}
                        </span>
                        <span className="product-details-view-label">
                          Invoice
                        </span>
                      </div>
                    </div>
                    <div className="product-details_mcol">
                      <div className="unknown product-details-label" />
                    </div>
                  </section>
                </div>
              </div>
              <div className="see-more">
                {/*                <Button className="see-more-btn">View All Details</Button> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleIframeModal
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(SoldModal);
