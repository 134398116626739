import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Modal, Alert } from "react-bootstrap";
import Appbase from "appbase-js";
import LoadingOverlay from "react-loading-overlay";
import { addToCart, toggleLoader } from "../../actions/index";
import {
  AppbaseCredentials,
  AppbaseAppUrl,
  JewelrySerialApp,
  JewelryStyleApp,
  DiamondSerialApp,
  GemstoneSerialApp,
} from "../../utils/constants";

const mapStateToProps = (state) => {
  return {
    loaderActions: state.loaderActions,
    basketInputObj: state.basketInputChange,
  };
};

class QuickBulkAddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOption: "serial",
      items: [],
      searchSuggestions: [],
      // searchField:["SerialNumber"],
      unAddedItems: [],
      itemSelected: [],
      productType: "",
      searchApp: "Jewelry Serial",
      app: JewelrySerialApp,
      showAlert: false,
    };
    this.handleSearchOption = this.handleSearchOption.bind(this);
    this.handleSelectedApp = this.handleSelectedApp.bind(this);
    this.handleBulkAdd = this.handleBulkAdd.bind(this);
    this.onModalHide = this.onModalHide.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSelectedApp(e) {
    let { searchOption } = this.state;
    let appSelected = e.target.value;
    if (appSelected === "Jewelry Serial") {
      //   console.log("Jewelry");
      this.setState({
        app: JewelrySerialApp,
        searchApp: appSelected,
        searchOption: "serial",
      });
    }
    if (appSelected === "Jewelry Style") {
      //   console.log("Jewelry");
      this.setState({
        app: JewelryStyleApp,
        searchApp: appSelected,
        searchOption: "style",
      });
    }
    if (appSelected === "Diamonds") {
      //   console.log("Diamonds");
      this.setState({
        app: DiamondSerialApp,
        searchApp: appSelected,
        searchOption: "serial",
      });
    }
    if (appSelected === "Gemstones") {
      //   console.log("Gemstones");
      this.setState({
        app: GemstoneSerialApp,
        searchApp: appSelected,
        searchOption: "serial",
      });
    }
  }

  handleSearchOption(e) {
    let value = e.target.value;
    // console.log("searchOption: ", value);
    // if (value === "serial" && this.state.searchApp === "Jewelry Serial") {
    //   this.setState({
    //     searchOption: value,
    //     app: JewelrySerialApp,
    //   });
    // } else if (value === "style" && this.state.searchApp === "Jewelry Style") {
    //   this.setState({
    //     searchOption: value,
    //     app: JewelrySerialApp,
    //   });
    // } else {
    this.setState({
      searchOption: value,
      // app: JewelrySerialApp,
    });
    // }
  }

  async fetchProducts({ app, query, searchField }) {
    // console.log("Query: ", query);
    // console.log("serachField: ", searchField);
    // console.log("app: ", app);
    var serialQuery = [
      {},
      {
        query: {
          bool: {
            must: [
              {
                term: {
                  "SerialNumber.keyword": query,
                },
              },
              {
                match: {
                  ItemStatus: "Active",
                  // searchField: query,
                },
              },
            ],
          },
        },
      },
      // {},
      // {
      //   query: {
      //     // bool: {
      //     //   must: [
      //     //     {
      //     //       // bool: {
      //     //       //   should: [
      //     //       //     {
      //     //       //       match: {
      //     //       //         // ItemStatus: "Active",
      //     //       //         SerialNumber: query,
      //     //       //       },
      //     //       //     },
      //     //       //     // {
      //     //       //     //   multi_match: {
      //     //       //     //     query: query,
      //     //       //     //     fields: searchField,
      //     //       //     //     // type: "phrase_prefix",
      //     //       //     //     // operator: "and",
      //     //       //     //   },
      //     //       //     // },
      //     //       //   ],
      //     //       //   // minimum_should_match: "1",
      //     //       // },
      //     //       match: {
      //     //         SerialNumber: query,
      //     //         // searchField: query,
      //     //       },
      //     //     },
      //     //     {
      //     match: {
      //       ItemStatus: "Active",
      //       // searchField: query,
      //     },
      //     //     },
      //     //     // {
      //     //     //   match: {
      //     //     //     // ItemStatus: "Active",
      //     //     //     searchField: query,
      //     //     //   },
      //     //     // },
      //     //   ],
      //     // },
      //   },
      //   // size: 15,
      // },
    ];

    var styleQuery = [
      {},
      {
        query: {
          term: {
            "StyleNumber.keyword": query,
          },
          // bool: {
          //   must: [
          //     {
          //       bool: {
          //         should: [
          //           // {
          //           //   multi_match: {
          //           //     query: query,
          //           //     fields: searchField,
          //           //     // type: "phrase_prefix",
          //           //     // operator: "and",
          //           //   },
          //           // },
          //           {
          //             match: {
          //               StyleNumber: query,
          //             },
          //           },
          //         ],
          //         // minimum_should_match: "1",
          //       },
          //     },
          //     // {
          //     //   match: {
          //     //     ItemStatus: "Active",
          //     //   },
          //     // },
          //   ],
          // },
        },
        // size: 15,
      },
    ];
    // console.log("serachField: ", searchField);
    var appbase = Appbase({
      url: AppbaseAppUrl,
      app: app,
      credentials: AppbaseCredentials,
    });
    return await appbase
      .msearch({
        type: "_doc",
        body:
          JSON.stringify(searchField) === JSON.stringify(["SerialNumber"])
            ? serialQuery
            : styleQuery,
      })
      .then((response) => {
        return response.responses[0].hits.hits;
      })
      .catch(() => console.log("error while appbase fetch"));
  }

  async handleProducts(val) {
    // console.log("val : ", val);
    let { searchOption, app, itemSelected, searchApp } = this.state;
    var appFlag, searchField, individualApp;
    // , app;
    if (val === "") {
      return;
    }
    // if (val.startsWith("D")) {
    //   appFlag = "D";
    //   searchField = ["SerialNumber"];
    //   individualApp = DiamondSerialApp;
    // this.setState({
    //   productType: "D",
    // });
    // } else if (val.startsWith("G")) {
    //   appFlag = "G";
    //   searchField = ["SerialNumber"];
    //   individualApp = GemstoneSerialApp;
    //   this.setState({
    //     productType: "G",
    //   });
    // } else {
    //   appFlag = "J";
    //   // console.log("searchOption: ", searchOption, "\n searchApp: ", searchApp);
    //   if (searchOption === "serial" && searchApp === "Jewelry Serial") {
    //     searchField = ["SerialNumber"];
    //     individualApp = JewelrySerialApp;
    //     // this.setState({ app: JewelrySerialApp });
    //   } else {
    //     searchField = ["StyleNumber"];
    //     individualApp = JewelryStyleApp;
    //     // this.setState({ app: JewelrySerialApp });
    //   }
    //   this.setState({
    //     productType: "J",
    //   });
    // }

    if (
      app === JewelrySerialApp ||
      app === DiamondSerialApp ||
      app === GemstoneSerialApp
    ) {
      searchField = ["SerialNumber"];
      if (app === JewelrySerialApp)
        this.setState({
          productType: "J",
        });
      if (app === DiamondSerialApp)
        this.setState({
          productType: "D",
        });
      if (app === GemstoneSerialApp)
        this.setState({
          productType: "G",
        });
    } else if ((app = JewelryStyleApp)) {
      searchField = ["StyleNumber"];
      this.setState({
        productType: "J",
      });
    }

    // if (individualApp === app) {
    var res = await this.fetchProducts({
      app,
      searchField,
      query: val,
    });
    //   console.log("res: ", res[0]);
    if (res && res.length) {
      // console.log("Inside if res");
      // console.log("prodctType : ", this.state.productType);
      // // this.setState({
      // //   searchSuggestions: res,
      // // });
      // // if (res.length === 1) {
      // this.setState((prevState) => ({
      //   itemSelected: [...prevState.itemSelected, ...[res]],
      // }));
      // // }
      return res[0];
    } else {
      let errorMsg = "Appbase fetching products error !";
      // if (!res.length) {
      errorMsg = "No products found !";
      // }
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return val;
    }
    // } else {
    //   let errorMsg = "The product is not of same type";
    //   toast.error(errorMsg, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //     pauseOnHover: false,
    //   });
    //   return val;
    // }
  }

  async handleBulkAdd() {
    let { items, productType, unAddedItems } = this.state;
    let bulkItems = document.querySelector("#bulk_product_values").value;
    // console.log("bulkItems :", bulkItems);
    // console.log("bulkItems type :", typeof bulkItems);
    if (bulkItems === "") {
      toast.error("Add atleast one product", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
      return;
    } else {
      bulkItems = bulkItems.replace(/\r\n/g, "\n").split("\n");
      // console.log("bulkItems :", bulkItems);
      if (bulkItems[bulkItems.length - 1] === "") {
        bulkItems.pop();
      }
      // this.setState((prevState) => ({
      //   items: [...prevState.items, ...bulkItems],
      // }));
      // console.log("this.state.items :", this.state.items);
    }

    let productArray = [];
    let remainingItems = [];
    // console.log("this.state.items :", this.state.items);
    // if (items.length === 0) {
    //   let errorMsg = "No products added";
    //   toast.error(errorMsg, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //     pauseOnHover: false,
    //   });
    //   return;
    // }
    this.props.toggleLoader({
      isLoading: true,
    });
    // this.setState({ itemSelected: items });
    // let prevItems = this.state.itemSelected;
    let currItems = bulkItems;
    // if (JSON.stringify(currItems) !== JSON.stringify(prevItems)) {
    //   currItems = currItems.filter((item) => !prevItems.includes(item));
    //   console.log("currItems : ", currItems);
    //   if (currItems.length === 0 || (currItems.length === 1 && !currItems[0])) {
    //     if (unAddedItems.length !== 0) {
    //       currItems = unAddedItems;
    //     } else {
    //       this.props.toggleLoader({
    //         isLoading: false,
    //       });
    //       toast.error("No products add", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 2000,
    //         pauseOnHover: false,
    //       });
    //       return;
    //     }
    //   } else {
    //     if (unAddedItems.length !== 0) {
    //       currItems = currItems.concat(unAddedItems);
    //       console.log("currItems : ", currItems);
    //     }
    //   }

    //   // console.log("differences: ", currItems);
    //   // this.setState({ items: difference });
    // } else if (unAddedItems.length !== 0) {
    //   currItems = unAddedItems;
    // } else {
    //   this.props.toggleLoader({
    //     isLoading: false,
    //   });
    //   toast.error("No products add", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //     pauseOnHover: false,
    //   });
    //   return;
    // }

    // console.log("items length: ", items.length);
    // console.log("items: ", items);
    // if (currItems.length === 1 && !currItems[0]) {
    //   this.props.toggleLoader({
    //     isLoading: false,
    //   });
    //   toast.error("No products add", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //     pauseOnHover: false,
    //   });
    //   return;
    // }
    for (let i = 0; i < currItems.length; i++) {
      let data = await this.handleProducts(currItems[i]);
      // console.log("type of data: ", typeof data);
      // console.log("data: ", data);
      if (data && typeof data === "object") {
        // console.log("data: ", data);
        await this.props.addToCart({
          product: { ...data["_source"], _id: data["_id"] },
          productType: this.state.productType,
          assetOrmemo: this.props.basketInputObj.assetOrmemo,
        });
        productArray.push(data);
      } else {
        remainingItems.push(data);
      }
    }
    // this.setState({ itemSelected: items });
    this.setState({ unAddedItems: remainingItems });
    // this.setState({ items: [] });
    // console.log("remainingItems", remainingItems);
    // console.log("productArray", productArray);
    productArray = [];
    await this.props.toggleLoader({
      isLoading: false,
    });
    if (remainingItems.length !== 0 && remainingItems[0])
      this.setState({ showAlert: true });
  }

  handleInputChange(e) {
    console.log("value : ", e.target.value);
    // if (e.target.value === "") {
    //   console.log("Empty value");
    // }
    let bulkItems = e.target.value.replace(/\r\n/g, "\n").split("\n");
    if (bulkItems[bulkItems.length - 1] === "") {
      bulkItems.pop();
    }
    // console.log("bulkItems : ", bulkItems);
    // console.log("type : ", typeof bulkItems);
    this.setState((prevState) => ({
      items: bulkItems.length === 0 ? [] : [...prevState.items, ...bulkItems],
    }));
    // console.log("this.state.items :", this.state.items);
  }

  onModalHide() {
    let { hide } = this.props;
    this.setState(() => {
      hide && hide();
    });
    this.setState({
      searchOption: "serial",
      items: [],
      searchSuggestions: [],
      // searchField:["SerialNumber"],
      unAddedItems: [],
      itemSelected: [],
      productType: "",
      searchApp: "Jewelry Serial",
      app: JewelrySerialApp,
      showAlert: false,
    });
  }

  getValue() {
    let value = document.querySelector("#bulk_product_values").value;
    // console.log("value :", value);
    // console.log("value type :", typeof value);
  }

  render() {
    let { show } = this.props;
    let { isLoading } = this.props.loaderActions;
    return (
      <div>
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="show_bulk_add_modal"
          centered="true"
          size="sm"
          show={show}
          onHide={() => this.onModalHide()}
        >
          {/* <LoadingOverlay active={isLoading} spinner text="Loading..."> */}
          <Modal.Header closeButton> Import Bulk Produts: </Modal.Header>
          <Modal.Body>
            <div className="import_bulk_product_wrapper">
              {/* <select onChange={this.handleSearchOption}>
                  <option value="serial">Serial</option>
                  <option value="style">Style</option>
                </select> */}
              <select onChange={this.handleSelectedApp}>
                <option value="Jewelry Serial">Jewelry Serial</option>
                <option value="Jewelry Style">Jewelry Style</option>
                <option value="Diamonds">Diamonds</option>
                <option value="Gemstones">Gemstones</option>
              </select>
              <textarea
                id="bulk_product_values"
                // onChange={this.getValue.bind(this)}
                // onChange={this.handleInputChange}
                // value={this.state.items}
              />
              <button onClick={this.handleBulkAdd}>Import</button>
            </div>
          </Modal.Body>
          {/* </LoadingOverlay> */}
        </Modal>
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="show_alert_modal"
          centered="true"
          size="sm"
          show={this.state.showAlert}
          onHide={() => this.setState({ showAlert: false })}
        >
          <Modal.Header closeButton>
            {this.state.unAddedItems.length} item
            {this.state.unAddedItems.length === 1 ? `` : `s`} were not added :
          </Modal.Header>
          <Modal.Body>
            <ul>
              {this.state.unAddedItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
            {/* <button >OK</button> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
      toggleLoader,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickBulkAddProduct);
