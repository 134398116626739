import React from "react";
import { Modal } from "react-bootstrap";
import { BaseURL, basketBaseUrl } from "../../utils/constants";

export default function AccuExportModal(props) {
  const { link, show, hide } = props;
  //   console.log("Link: ", link);
  return (
    <Modal
      animation={false}
      autoFocus={false}
      enforceFocus={false}
      className="show_alert_modal"
      centered="true"
      size="sm"
      show={show}
      onHide={() => hide()}
    >
      <Modal.Header closeButton>Accumatica Export Details.</Modal.Header>
      <Modal.Body>
        <div>
          Your basket exported to Acumatica: #{link}.
          <a
            href={
              BaseURL + `/Main?ScreenId=SO301000&OrderType=TS&OrderNbr=${link}`
              // `https://dev.kwiat.com/Kwiat/Main?ScreenId=SO301000&OrderType=TS&OrderNbr=${link}`
            }
            target="_blank"
          >
            {" "}
            Click here{" "}
          </a>
          to access.
        </div>
      </Modal.Body>
    </Modal>
  );
}
