const jewelrySerialRemovedFields = [
  "_index",
  "_type",
  "_id",
  "_score",
  "sort",
  "UsrMountedStockNbr",
  "LargeImageName",
  "RetailPriceCode",
  "InventoryID",
  "LastModifiedByID",
  "MemoOutCustomerID",
  "MetalNote",
  "WholesalePriceCode",
  "AdjustmentNbr",
  "ReleaseDate",
  "kwesjewelryserialsearch_lastModifiedDateTime",
  "PricingDate",
  "IsMounted",
  "HoldDate",
  "Refnoteid",
  "StatusRefNbr",
  "HoldCustomerName",
  "SearchType",
  "Rtvdate",
  "Siteid",
  "JewLastModifiedDateTime",
  "WholeSaleMarkup",
  "StatusDate",
  "QtyInTransit",
  "CreatedByScreenID",
  "StatusAmount",
  "StatusCustomer",
  "Invid",
  "QtyOnHand",
  "MountingWholesale",
  "HoldText",
  "CreatedByID",
  "StatusCustomerID",
  "CreatedDateTime",
  "Warehouse",
  "IsVirtual",
  "LastModifiedByScreenID",
  "StatusRefType",
  "MemoOutCustomer",
  "IsCom",
  "IsRtv",
  "IsPhantom",
  "Location",
  "InStockCostCode",
  "InStockCost",
  "ConsignmentItem",
  "WholesaleMarkup",
  "InStockDate",
  "RetailMarkup",
  "VendorID",
  "Vendor",
  "TotalMarkup",
  "VendorRefNbr",
  "OwnCost",
  "PurchaseRefNumber",
  "ConsignmentCost",
  "PricingMode",
  "StyleStatus",
  "StyleStatusDate",
  "InventoryCategory",
  "SoldDate",
  "SoldRefNumber",
  "SoldDiscountCode",
  "SoldDiscount",
  "SoldCustomerID",
  "SoldCustomer",
  "SoldMarginCode",
  "SoldMargin",
  "SoldPriceCode",
  "SoldPrice",
  "ProfitMargin",
  "IconImageName",
  "IconImageFileID",
  "LargeImageFileID"
];
const jewelryStyleRemovedFields = [
  "_index",
  "_type",
  "_id",
  "_score",
  "sort",
  "UsrMountedStockNbr",
  "LargeImageName",
  "RetailPriceCode",
  "StyleVideoLink",
  "LastModifiedDateTime",
  "InventoryID",
  "LastModifiedByID",
  "ChildPricingMethod",
  "MemoOutCustomerID",
  "MetalNote",
  "WholesalePriceCode",
  "AdjustmentNbr",
  "ReleaseDate",
  "kwesjewelryserialsearch_lastModifiedDateTime",
  "PricingDate",
  "IsMounted",
  "HoldDate",
  "Refnoteid",
  "StatusRefNbr",
  "HoldCustomerName",
  "SearchType",
  "Rtvdate",
  "Siteid",
  "JewLastModifiedDateTime",
  "WholeSaleMarkup",
  "StatusDate",
  "QtyInTransit",
  "CreatedByScreenID",
  "StatusAmount",
  "StatusCustomer",
  "Invid",
  "QtyOnHand",
  "MountingWholesale",
  "HoldText",
  "CreatedByID",
  "StatusCustomerID",
  "CreatedDateTime",
  "InventoryDBID",
  "Warehouse",
  "IsVirtual",
  "LastModifiedByScreenID",
  "StatusRefType",
  "MemoOutCustomer",
  "IsCom",
  "IsRtv",
  "IsPhantom",
  "Location",
  "InStockCostCode",
  "InStockCost",
  "ConsignmentItem",
  "WholesaleMarkup",
  "InStockDate",
  "RetailMarkup",
  "VendorID",
  "Vendor",
  "TotalMarkup",
  "VendorRefNbr",
  "OwnCost",
  "PurchaseRefNumber",
  "ConsignmentCost",
  "PricingMode",
  "StyleStatus",
  "StyleStatusDate",
  "InventoryCategory",
  "SoldDate",
  "SoldRefNumber",
  "SoldDiscountCode",
  "SoldDiscount",
  "SoldCustomerID",
  "SoldCustomer",
  "SoldMarginCode",
  "SoldMargin",
  "SoldPriceCode",
  "SoldPrice",
  "ProfitMargin",
  "IconImageName",
  "IconImageFileID",
  "LargeImageFileID"
];
const diamondDataExport = [
  "_index",
  "_type",
  "_id",
  "_score",
  "sort",
  "DiamondSizeCategory",
  "LargeImageName",
  "RetailPriceCode",
  "ConsignmentCost",
  "RefNoteID",
  "InventoryID",
  "OwnCost",
  "PlanType",
  "AdjustmentNbr",
  "WholesalePricePerCarat",
  "ReleaseDate",
  "PricingDate",
  "HoldDate",
  "Description",
  "StatusRefNbr",
  "HoldCustomerName",
  "Rtvdate",
  "Siteid",
  "WholeSaleMarkup",
  "StatusDate",
  "QtyInTransit",
  "StatusAmount",
  "StatusCustomer",
  "Invid",
  "DiamondColorRange_New",
  "QtyOnHand",
  "RTVInitial",
  "HoldText",
  "kwesdiamondserialsearch_lastModifiedDateTime",
  "StatusCustomerID",
  "HoldBy",
  "IsVirtual",
  "StatusRefType",
  "DiamondClarityRange_New",
  "MemoOutCustomer",
  "IsRtv",
  "GIAReportDate",
  "InventoryCategory",
  "IsPhantom",
  "DiamondCut",
  "SoldDate",
  "SoldRefNumber",
  "SoldDiscountCode",
  "SoldDiscount",
  "SoldCustomerID",
  "SoldCustomer",
  "SoldMarginCode",
  "SoldMargin",
  "SoldPriceCode",
  "SoldPrice",
  "ProfitMargin",
  "RapListPerCaratCode",
  "RapListPerCarat",
  "ConsignmentItem",
  "InStockCostCode",
  "InStockCost",
  "InStockRelToList",
  "InStockDate",
  "InStockCostPerCaratCode",
  "InStockCostPerCarat",
  "VendorID",
  "Vendor",
  "WholesaleRelToList",
  "VendorRefNbr",
  "WholesalePerCaratCode",
  "WholesalePerCarat",
  "WholesaleMarkup",
  "PurchaseRefNumber",
  "RetailRelToList",
  "PricingMode",
  "RetailPricePerCarat",
  "RetailMarkup",
  "InternalUseComments",
  "IconImageName",
  "IconImageFileID",
  "LargeImageFileID"
];
const gemstoneDataExport = [
  "_index",
  "_type",
  "_id",
  "_score",
  "sort",
  "LargeImageName",
  "RetailPriceCode",
  "ConsignmentCost",
  "InventoryID",
  "OwnCost",
  "kwesgemstoneserialsearch_lastModifiedDateTime",
  "MemoOutCustomerID",
  "WholesalePriceCode",
  "AdjustmentNbr",
  "ReleaseDate",
  "IsSold",
  "PricingDate",
  "HoldDate",
  "RetailPricePerCarat",
  "Refnoteid",
  "StatusRefNbr",
  "HoldCustomerName",
  "Rtvdate",
  "Siteid",
  "StatusDate",
  "QtyInTransit",
  "StatusAmount",
  "StatusCustomer",
  "Invid",
  "QtyOnHand",
  "HoldText",
  "StatusCustomerID",
  "HoldBy",
  "Warehouse",
  "IsVirtual",
  "StatusRefType",
  "MemoOutCustomer",
  "WholesalePricePerCaratt",
  "IsRtv",
  "IsPhantom",
  "SoldDate",
  "SoldRefNumber",
  "SoldDiscountCode",
  "SoldDiscount",
  "SoldCustomerID",
  "SoldCustomer",
  "SoldMarginCode",
  "SoldMargin",
  "SoldPriceCode",
  "SoldPrice",
  "ProfitMargin",
  "InStockCostCode",
  "InStockCost",
  "ConsignmentItem",
  "InStockCostPerCaratCode",
  "InStockCostPerCarat",
  "InStockDate",
  "WholesaleRelToList",
  "VendorID",
  "Vendor",
  "WholesalePerCaratCode",
  "WholesalePerCarat",
  "WholesaleMarkup",
  "VendorRefNbr",
  "RetailRelToList",
  "PurchaseRefNumber",
  "RetailMarkup",
  "PricingMode",
  "InternalUseComments",
  "IconImageName",
  "IconImageFileID",
  "LargeImageFileID"
];

export {
  jewelrySerialRemovedFields,
  jewelryStyleRemovedFields,
  diamondDataExport,
  gemstoneDataExport
};