import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

class SerialNumber extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (!value) {
      this.props.serialSearchChanged("NOT_ACTIVE");
      return;
    }
    this.props.serialSearchChanged("ACTIVE");
  }
  handleSelected(value) {
    this.props.serialSearchSelected(true);
  }

  render() {
    let { appType, data } = this.props;
    return (
      <div className="diamond-serial-number form-field-wrap">
        <DataSearch
          title={
            appType && appType === "customer" ? "Serial Number" : "Serial #"
          }
          className="form-field"
          showClear
          debounce={250}
          placeholder={
            appType && appType === "customer"
              ? "Start Search..."
              : "Enter Serial"
          }
          dataField={data}
          componentId="SerialSearch"
          onValueChange={(value) => this.handleChange(value)}
          onValueSelected={(value) => this.handleSelected(value)}
          iconPosition={appType && appType === "customer" ? "right" : "left"}
        />
      </div>
    );
  }
}
export default SerialNumber;
