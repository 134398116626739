import React, { Component } from "react"; 
import currencyFormatter from "currency-formatter";
import ShowMore from "react-show-more";
import { bindActionCreators } from "redux";
import { toggleIframeModal } from "../../actions/index";
import { connect } from "react-redux";

class MobileProductDetails extends Component {
  render() {
    let { res } = this.props;
    return (
      <div className="es__mobile-product">
        <table className="es__mobile-product-details-table">
          <tbody>
            <tr>
              <td>{res.ShapeDisplay && res.ShapeDisplay !== null ? `${res.ShapeDisplay}` : ""}</td>
              <td>
                {res.RetailPrice && res.RetailPrice != null
                  ? currencyFormatter.format(`${res.RetailPrice}`, {
                      code: "USD",
                      precision: 0
                    })
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPrice && res.RetailPrice != null
                    ? "Retail"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.DiamondCaratWeight && res.DiamondCaratWeight !== null
                  ? `${Number(res.DiamondCaratWeight).toFixed(2)}cts`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.DiamondCaratWeight && res.DiamondCaratWeight != null
                    ? "WT."
                    : ""}
                </span>
              </td>
              <td>
                {res.RetailPriceCode}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPriceCode && res.RetailPriceCode != null
                    ? "Code"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
              { res.IsRough === "1" && res.CertReceived === "0" ? (
                    <span>
                      {res.MTSDiamondColorRange && res.MTSDiamondColorRange !== null
                        ? `${res.MTSDiamondColorRange}/`
                        : ""}
                      {res.MTSDiamondClarityRange && res.MTSDiamondClarityRange !== null
                        ? `${res.MTSDiamondClarityRange}`
                        : ""}
                    </span>
                  ) : (
                    <span>
                      {res.DiamondColorRange && res.DiamondColorRange !== null
                        ? `${res.DiamondColorRange}/`
                        : `${res.FancyColorGIA}/`}
                      {res.DiamondClarityRange && res.DiamondClarityRange !== null
                        ? `${res.DiamondClarityRange}`
                        : ""}
                    </span>
                  )
               }
              </td>
              <td>
                {res.IsVirtual === "1" ? "Virtual - " : ""}
                {res.IsPhantom === "1" ? "Phantom - " : ""}
                {res.SerialStatus && res.SerialStatus !== null
                  ? ` ${res.SerialStatus}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.SerialStatus && res.SerialStatus != null ? "Status" : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.StoneRatio && res.StoneRatio !== null
                  ? `${res.StoneRatio}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.StoneRatio && res.StoneRatio != null
                    ? "Stone Ratio"
                    : ""}
                </span>
              </td>
              <td className="es__product-details__data-row">
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.StatusCustomer && res.StatusCustomer !== null
                    ? `${res.StatusCustomer}`
                    : ""}
                </ShowMore>
              </td>
            </tr>
            <tr>
              <td className="gia-pdf-data">
                <ShowMore
                  lines={1}
                  more="&#43;"
                  less="&#8722;"
                  anchorClass=""
                >
                  {res.GradingLab && res.GradingLab != null
                    ? `${res.GradingLab} -  ${res.LabReportNbr || ""}`
                    : ""}
                </ShowMore>
                <span>
                  {res.Giapdfurl ? (
                    <img
                      src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                      alt="icon"
                      className="pdf-icon cursor-pointer"
                      onClick={() =>
                        this.props.toggleIframeModal({
                          show: true,
                          url: res.Giapdfurl
                        })
                      }
                    />
                  ) : (
                    ""
                  )}
                </span>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-default align-self-end"
                  onClick={() => this.props.handleProductModal(res)}
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleIframeModal
    },
    dispatch
  );
};
// export default MobileProductDetails;
export default connect(
  null,
  mapDispatchToProps
)(MobileProductDetails);
