import React from 'react';
import CenterStone from "./CenterStone";
import CenterSize from "./CenterSize";
import CenterShape from "./CenterShape";
import CenterColor from "./CenterColor";
import CenterClarity from "./CenterClarity";
import CenterOrigin from "./CenterOrigin";
import CenterEnhancement from "./CenterEnhancement";
import RingSize from './RingSize';
import JewelrySize from './JewelrySize';
import WRShape from "./WRShape";
import WRSetting from "./WRSetting";
import EternPart from "./EternPart";

class AdvancedSearch extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <div className="es__advanced-search">
        <div className="col-sm-6 es__filter-columns">
          <h5 className="advanced-search-heading">
            Engagement Rings - Center Diamond Details
          </h5>
          <div className="advanced-search-border">
            <div className="engagement-rings-lcol">
              <CenterStone />
              <CenterShape />
              <CenterSize />
            </div>
            <div className="engagement-rings-rcol">
              <CenterColor />
              <CenterClarity />
              <CenterOrigin />
              <CenterEnhancement />
            </div>
          </div>
        </div>
        <div className="col-sm-3 es__filter-columns">
          <h5 className="advanced-search-heading">Wedding Rings</h5>
          <div className="advanced-search-border wr-advanced">
            <WRShape data={data.wrshape} />
            <div className="grouped-fields">
              <div className="grouped-fields__wr">
                <WRSetting />
              </div>
              <div className="grouped-fields__eternpart">
                <EternPart />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 es__filter-columns">
          <h5 className="advanced-search-heading">Jewelry</h5>
          <div className="advanced-search-border jewelry-advanced-fields">
            <RingSize />
            <JewelrySize />
          </div>
        </div>
      </div>
    );
  }
}

export default AdvancedSearch;