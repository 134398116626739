import React, { Component } from "react";
import AppRouter from "./AppRouter";
import $ from "jquery";
import { HashRouter as Router } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { connect } from "react-redux";
import {
  setAppType,
  setGroups,
  setBasketFormInput,
  setEmployeeData,
  setFeedsHoldData,
  setJewelryHoldData
} from "./actions/index";
import { ToastContainer } from "react-toastify";
import "./styles/original.css";
import "./styles/customer.css";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import { bindActionCreators } from "redux";
import axios from "axios";
import {
  AppbaseAppUrl,
  AppbaseEmployeeApp,
  AppbaseCredentials,
  AppbaseAttributesApp
} from "./utils/constants";
import Appbase from "appbase-js";
import { fetchApiData } from "./components/holdNotesApiData";
const mapStateToProps = state => {
  return {
    // cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    loaderActions: state.loaderActions,
    tokenState: state.tokenState,
    groups: state.SetGroups.groups
  };
};

const appName = "original";
// const appName = "customer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleGetGroups = this.handleGetGroups.bind(this);
    this.getUserEmail = this.getUserEmail.bind(this);
  }
  async componentDidMount() {
    let url = window.location.href;

    if (appName === "original") {
      document.body.className += ` original`;
      this.props.setAppType("original");
    } else {
      document.body.className += ` customer`;
      this.props.setAppType("customer");
    }

    if (url.includes("minetoshine.vip")) {
      document.body.className += ` customer`;
      this.props.setAppType("customer");
    }

    window.addEventListener("message", function(e) {
      if (window.parent.postMessage && isMobileOnly) {
        var height = document.getElementById("root").clientHeight;
        var postData = {
          height: height,
          flag: true
        };
        window.parent.postMessage(postData, "*");
      }
    });
    // console.log("url: ", url);
    if (url.includes("kim.kwiat.com")) {
      // document.getElementsByClassName("AppContainer")[0].style.marginTop =
      //   -45 + "px";
      // // document.getElementsByClassName("es__basket-icon")[0].style.top =
      // //   45 + "px";
      // const scrollDemo = document.querySelector("body.original");
      // this.timer = setInterval(() => {
      //   if (scrollDemo.scrollTop === 0) {
      //     document.getElementsByClassName("es__basket-icon")[0].style.top =
      //       10 + "px";
      //   } else {
      //     document.getElementsByClassName("es__basket-icon")[0].style.top =
      //       105 + "px";
      //   }
      // }, 500);
      document.getElementsByClassName("qp-tool-bar-wrapper")[0].style.display =
        "none";
      document.getElementsByClassName(
        "qp-tool-bar-wrapper"
      )[0].style.minHeight = "0px";
    }
    if (!url.includes("kim.kwiat.com")) {
      await this.handleGetGroups();
      let groups = this.props.groups.groups;
      // console.log("groups :", groups);
      if (groups) {
        let accessGroup = groups.filter(group =>
          [
            "227b908c-faec-4169-a1f0-a45e217725e5",
            "1dd4718d-e6a6-4d12-b536-d9f0d6025a51",
            "6e9595ab-6424-43e7-a367-91248b278798"
          ].includes(group.id)
        );
        // console.log({ accessGroup });
        if (accessGroup.length !== 0) {
          this.props.setBasketFormInput({
            access: "advanced",
            dwebbNoDefault: true
          });
        }
        groups.map(group => {
          if (group.id !== "5036fc5b-9077-4513-8296-47032fc363a9") {
            // this.props.setBasketFormInput({ holdNotesAccess: true });
          } else {
            this.props.setBasketFormInput({
              holdNotesAccess: false,
              dwebbDefault: true
            });
          }
        });
      }
    }
    let empData = await fetchApiData(AppbaseEmployeeApp);
    const loggedUser = JSON.parse(sessionStorage.getItem('reduxState'))
    const loggedUserEmail = loggedUser?.basketInputChange?.user?.Email
    empData.forEach((item)=>{
      if(item?._source?.Email === loggedUserEmail && item?._source?.bulkHoldNotes == "1"){
        this.props.setBasketFormInput({
          holdNotesAccess: true
        });
      }
    })
    await this.getUserEmail();
    $(document).on("keydown", function(event) {
      // console.log("enter key event ");
      return event.key != "Enter";
    });
    let empResponse = await fetchApiData(AppbaseEmployeeApp);
    // console.log({ empResponse });
    if (empResponse) {
      // this.setState({ employeeData: empResponse });
      this.props.setEmployeeData(empResponse);
    }
    // let custResponse = await fetchApiData(AppbaseCustomerApp);
    // if (custResponse) {
    //   this.setState({ customers: custResponse });
    // }
    let feedResponse = await fetchApiData(AppbaseAttributesApp, "feed");
    // console.log({ feedResponse });
    if (feedResponse) {
      // this.setState({ feedRestrictionsData: feedResponse });
      this.props.setFeedsHoldData(feedResponse);
    }
    let jewelryRespone = await fetchApiData(AppbaseAttributesApp, "jewelry");
    // console.log({ jewelryRespone });
    if (jewelryRespone) {
      // this.setState({ jewelryRestrictionData: jewelryRespone });
      this.props.setJewelryHoldData(jewelryRespone);
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      ["", undefined, null].includes(this.props.basketInputObj.user) ||
      ["", undefined, null].includes(nextProps.basketInputObj.user)
    ) {
      await this.getUserEmail();
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  async handleGetGroups() {
    let res = await axios.get(`/api/index`, { credentials: "include" });

    // console.log("res: ", res);
    if (res.data.response.status === 200) {
      let groupData = res.data.data;
      this.props.setGroups({ groups: groupData });
    } else {
      console.log("Error in fetching details");
    }
  }

  async getUserEmail() {
    let userEmail;
    if (this.props.groups.email) {
      userEmail = this.props.groups.email;
    } else if (window.parent.document.getElementById("btnUserMenu")) {
      // userEmail = window.parent.document
      //   .getElementById("btnUserMenu")
      //   .querySelector(".user-name").textContent;
      userEmail = window.parent.document
        .getElementById("userMenu")
        .querySelector("li.menu-form > div.inline > span:nth-child(3)")
        .textContent;
    } else {
      userEmail = null;
      // userEmail = "sahil@kwiat.com";
      // userEmail = "megan@kwiat.com";
      // userEmail = "nagaraj@kwiat.com";
      // userEmail = "josh@kwiat.com";
      // userEmail = "cory@kwiat.com";
    }
    // console.log("userEmail: ", userEmail);

    const appbaseRef = Appbase({
      app: AppbaseEmployeeApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials
    });
    if (userEmail) {
      await appbaseRef
        .search({
          body: {
            query: {
              match_phrase: {
                Email: {
                  query: userEmail
                }
              }
            },
            size: 10000,
            // stored_fields: ["Email"], // for getting only ids
            _source: [
              "FirstName",
              "LastName",
              "Email",
              "BasketEmail",
              "EmployeeLogin",
              "Title",
              "Phone1",
              "AddressLine1",
              "AddressLine2",
              "City",
              "State",
              "PostalCode",
              "BasketBccEmail",
              "BasketCcEmail",
              "CRMBccEmail"
            ]
          }
        })
        .then(response => {
          console.log("User Email Found Successfully");
          // console.log("response : ", response.hits.hits[0]._source);
          this.props.setBasketFormInput({
            user: response.hits.hits[0]._source
          });
          // console.log("Basket form data: ", this.props.basketInputObj);
          // result = { statusCode: 200, data: response };
        })
        .catch(error => {
          console.log("User Email Error");

          // console.log("error : ", error);
          // return;
          // result = { statusCode: 500, data: error };
        });
      // let payload = {
      //   data: {
      //     defaults: {
      //       baseURL: basketBaseUrl,
      //       token: token,
      //     },
      //     inputs: {
      //       EmployeeLogin: {
      //         value: this.props.basketInputObj.user.EmployeeLogin,
      //       },
      //     },
      //   },
      // };
      // // console.log("payload: ", payload);
      // await axios
      //   .post(appbaseBasketUrl + "user-signature", payload, {
      //     headers: {
      //       "x-api-key": basketApikey,
      //     },
      //   })
      //   .then((res) => {
      //     // console.log("resp : ", res);
      //     if (
      //       res.data.statusCode === 200 &&
      //       res.data.body.KWMailSignature.value
      //     ) {
      //       console.log("User signature found.");
      //       this.props.setBasketFormInput({
      //         user: {
      //           ...this.props.basketInputObj.user,
      //           Signature: res.data.body.KWMailSignature.value,
      //         },
      //       });
      //     } else {
      //       console.log("response is empty");
      //     }
      //     // console.log("Basket form data: ", this.props.basketInputObj);
      //     // return JSON.parse(res.data.body);
      //     // return res;
      //   })
      //   .catch((err) => {
      //     // console.log("error : ", err);
      //     console.log("User Signature error.", err);
      //     // return;
      //   });
    } else {
      console.log("No user");
    }
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   let userDetails = window.parent.document
    //     .getElementById("userMenu")
    //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
    //   console.log("userDetails: ", userDetails);
    // }
  }
  render() {
    let { isLoading } = this.props.loaderActions;
    return (
      <div className="App">
        <LoadingOverlay active={isLoading} spinner text="Loading...">
          <Router>
            <AppRouter />
          </Router>
          <ToastContainer hideProgressBar={true} />
        </LoadingOverlay>
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setAppType: (type) => dispatch(setAppType(type)),
//     setGroups:(group)=>dispatch(setGroups(group)),
//   };
// };
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setAppType,
      setBasketFormInput,
      setGroups,
      setEmployeeData,
      setFeedsHoldData,
      setJewelryHoldData
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
