import React, { Component } from "react";
import { sortableContainer, arrayMove } from "react-sortable-hoc";
import { Modal, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import SortableItem from "./SortableItem";
import Appbase from "appbase-js";
import isEmpty from "lodash/isEmpty";
import {
  removeFromCart,
  updateQuantity,
  updateInternalNotes,
  updatePriceVisibility,
  updateLinkVisibility,
  updateItemSelected,
  openCartItems,
  toggleIframeModal,
  setSortOption,
  toggleLoader,
  setAssetOrMemo,
} from "../../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ImageModal from "../../ImageModal";
import SortItems from "../SortItems";
import MoreDetailsModal from "../../../jewelry-search/components/MoreDetailsModal";

import {
  AppbaseAppUrl,
  AppbaseCredentials,
  DiamondSerialApp,
  GemstoneSerialApp,
  JewelrySerialApp,
  JewelryStyleApp,
  BaseURL,
  AppbaseHoldNotesApp,
  holdNoteApi,
  holdNoteApiKey,
} from "../../../utils/constants";
import HoldNoteCreateModal from "../../HoldNoteCreateModal";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import HoldNotesArchievedModal from "../../HoldNotesArchievedModal";
import cx from "classnames";
import moment from "moment";
import axios from "axios";
import { getHoldNotesData } from "../../holdNotesApiData";
import { getItemFromAppBase, getUniqueItemWithQty } from "../ReadAndOpenBasket";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions.items,
    basketInputObj: state.basketInputChange,
    sortOption: state.sortOption.basketSortOption,
    loaderActions: state.loaderActions,
    apiData: state.apiData,
  };
};

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class MobileBasketItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.cartItems,
      customerClass: props.basketInputObj.customer.CustomerClass || "",
      contact: props.basketInputObj.contact,
      customer: props.basketInputObj.customer,
      collapsedProductId: "",
      showImageModal: false,
      zoomImage: "",
      showMoreModal: false,
      currentRes: "",
      warehouse: [],
      showPriceCheckModal: false,
      differenceItemPrices: [],
      showHoldNotesModal: false,
      showHoldNotesEditModal: false,
      holdNotesData: "",
      showHoldNoteCreateModal: false,
      customers: "",
      feedRestrictionsData: this.props.apiData.feedsData,
      jewelryRestrictionData: this.props.apiData.jewelryHoldData,
      employeeData: this.props.apiData.empData,
      holdNoteItem: "",
    };

    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.handlePriceVisibility = this.handlePriceVisibility.bind(this);
    this.handleLinkVisibility = this.handleLinkVisibility.bind(this);
    this.handleTogglePriceInput = this.handleTogglePriceInput.bind(this);
    this.handlePriceEditChange = this.handlePriceEditChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInternalNotes = this.handleInternalNotes.bind(this);
    this.handleMoreDetails = this.handleMoreDetails.bind(this);
    this.showZoomImage = this.showZoomImage.bind(this);
    this.hideImageModal = this.hideImageModal.bind(this);
    this.showMoreModal = this.showMoreModal.bind(this);
    this.hideMoreModal = this.hideMoreModal.bind(this);
    this.getWarehouseValue = this.getWarehouseValue.bind(this);
    this.bindisValueEmpty = this.isValueEmpty.bind(this);
    this.handlePriceCheck = this.handlePriceCheck.bind(this);
    this.showPriceCheckModal = this.showPriceCheckModal.bind(this);
    this.hidePriceCheckModal = this.hidePriceCheckModal.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleAllItems = this.handleAllItems.bind(this);
    this.handleAssetOrMemo = this.handleAssetOrMemo.bind(this);
    this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
    this.handleItemRefresh = this.handleItemRefresh.bind(this);
    this.handleHoldNoteEditModal = this.handleHoldNoteEditModal.bind(this);
    // this.getAppbaseWarehouse = this.getAppbaseWarehouse.bind(this);
  }
  componentDidMount() {
    // if (this.props.items !== this.state.data) {
    // this.setState({ warehouse: [] });
    // for (let i = 0; i < this.state.items.length; i++) {
    //   this.getWarehouseValue(this.state.items[i]);
    // }
    // }
  }
  componentWillReceiveProps(nextProps) {
    // console.log("nextProps :", nextProps);
    if (this.state.items !== nextProps.cartItems) {
      this.setState({
        items: nextProps.cartItems,
        // warehouse: [],
      });
      // for (let i = 0; i < nextProps.items.length; i++) {
      //   this.getWarehouseValue(nextProps.items[i]);
      // }
    }
    // if (this.state.items.length !== nextProps.cartItems.length) {
    //   this.setState({ warehouse: [] });
    //   for (let i = 0; i < nextProps.cartItems.length; i++) {
    //     this.getWarehouseValue(nextProps.cartItems[i]);
    //   }
    // }
    if (
      this.state.customerClass !==
        nextProps.basketInputObj.customer.CustomerClass ||
      this.state.contact !== nextProps.basketInputObj.contact
    ) {
      this.setState({
        customerClass: nextProps.basketInputObj.customer.CustomerClass,
        contact: nextProps.basketInputObj.contact,
        customer: nextProps.basketInputObj.customer,
      });
    }
  }

  handleHoldNoteEditModal(value) {
    this.setState({
      showHoldNotesEditModal: value,
    });
  }

  async handleItemRefresh() {
    let items = this.state.items;
    this.props.toggleLoader({
      isLoading: true,
    });
    items = items.map((el) => {
      var productArr = {
        SerialNumber: el.SerialNumber,
        StyleNumber: el.StyleNumber,
        InternalNote: el.InternalNote,
        RetailPrice: el.RetailPrice,
        WholesalePrice: el.WholesalePrice,
        Quantity: el.quantity,
        PriceVisibility: el.priceVisibility,
        LinkVisibility: el.linkVisibility,
        ItemSelected: el.itemSelected,
        ProductType: el.productType,
        AssetOrMemo: el.assetOrmemo,
      };
      return productArr;
    });

    let uniqueArrayFromBasket = getUniqueItemWithQty(items);
    // console.log("uniqueArrayFromBasket: ", uniqueArrayFromBasket);
    var fromAppbaseCall = [];
    for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
      var res = await getItemFromAppBase({
        item: uniqueArrayFromBasket[i],
        searchOption: "mixed",
      });
      // console.log("res: ", res);
      if (typeof res[0]["_source"] !== "object") {
        toast.error("Appbase fetching products error !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        continue;
      }
      let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
      fromAppbaseCall.push({
        ...omitted,
        RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
        WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
        quantity: uniqueArrayFromBasket[i].Quantity,
        InternalNote: uniqueArrayFromBasket[i].InternalNote,
        priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
        productType: uniqueArrayFromBasket[i].ProductType,
        linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
          ? uniqueArrayFromBasket[i].LinkVisibility
          : "Default",
        itemSelected: uniqueArrayFromBasket[i].ItemSelected
          ? uniqueArrayFromBasket[i].ItemSelected
          : true,
        LineNbr: uniqueArrayFromBasket[i].LineNbr,
        assetOrmemo:
          uniqueArrayFromBasket[i].AssetOrMemo &&
          uniqueArrayFromBasket[i].AssetOrMemo !== true &&
          uniqueArrayFromBasket[i].AssetOrMemo !== false
            ? uniqueArrayFromBasket[i].AssetOrMemo
            : true,
      });
    }
    this.props.openCartItems(fromAppbaseCall);
    this.props.toggleLoader({
      isLoading: false,
    });
  }

  handleItemHoldNoteUpdate(res, items) {
    // console.log("Inside item update");
    // console.log({ res }, { items });
    if (!items) {
      // this.setState((prevState) => ({
      //   data: prevState.data.map((item) => {
      //     if (item.SerialNumber === res.SerialNumber) {
      //       return res;
      //     } else {
      //       return item;
      //     }
      //   }),
      // }));
      let data = this.state.items.map((item) => {
        if (item.SerialNumber === res.SerialNumber) {
          return res;
        } else {
          return item;
        }
      });
      this.props.openCartItems(data);
    } else {
      let data = this.state.items.map((basketItem) => {
        let result = items.find(
          (item) => item.SerialNumber === basketItem.SerialNumber
        );
        if (result) {
          return { ...basketItem, ...res };
        } else {
          return basketItem;
        }
      });
      this.props.openCartItems(data);
      // console.log({ data });
      // this.setState((prevState) => ({
      //   data: prevState.data.map((basketItem) => {
      //     let result = items.find(
      //       (item) => item.SerialNumber === basketItem.SerialNumber
      //     );
      //     if (result) {
      //       return { ...basketItem, ...res };
      //     } else {
      //       return basketItem;
      //     }
      //   }),
      // }));
    }
    // this.getProduct(this.state.results);
  }

  async handleHoldNoteArchive(res) {
    let result = window.confirm("Confirm Hold Note Archieve ?");
    if (result) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
        },
      };
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // this.props.toggleLoader({
          //   isLoading: false,
          // });
        });
      // console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        this.props.toggleLoader({
          isLoading: false,
        });
        this.setState((prevState) => ({
          items: prevState.items.map((item) => {
            if (item.SerialNumber === res.SerialNumber) {
              return {
                ...item,
                // HoldBy: null,
                HoldCustomerId: null,
                HoldCustomerName: null,
                HoldDate: null,
                HoldEnteredByID: null,
                HoldEnteredByName: null,
                HoldEnteredForID: null,
                HoldEnteredForName: null,
                HoldNoteID: null,
                HoldNoteNbr: null,
                HoldText: null,
                ReleaseDate: null,
                IsHoldSold: null,
              };
            } else {
              return item;
            }
          }),
        }));
      } else {
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }

  async handleHoldNotesModals(id, value, type, res) {
    let { items } = this.state;
    this.setState({ holdNoteItem: res });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      this.setState({ holdNotesData: response });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    this.setState({
      ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
      ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
      ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
      ...(!value
        ? { showHoldNotesEditModal: false, holdNotesData: "", holdNoteItem: "" }
        : {}),
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  }

  handleAllItems(e) {
    let { data } = this.state;
    data.map((item) =>
      this.props.updateItemSelected({
        item: item,
        selected: e.target.checked,
      })
    );
  }

  showPriceCheckModal() {
    this.setState({ showPriceCheckModal: true });
  }

  hidePriceCheckModal() {
    this.setState({ showPriceCheckModal: false });
  }

  async fetchProducts({ app, query, searchField }) {
    // console.log("Query: ", query);
    // console.log("serachField: ", searchField);
    // console.log("app: ", app);
    var serialQuery = [
      {},
      {
        query: {
          bool: {
            must: [
              {
                term: {
                  "SerialNumber.keyword": query,
                },
              },
              {
                match: {
                  ItemStatus: "Active",
                  // searchField: query,
                },
              },
            ],
          },
        },
      },
      // {},
      // {
      //   query: {
      //     // bool: {
      //     //   must: [
      //     //     {
      //     //       // bool: {
      //     //       //   should: [
      //     //       //     {
      //     //       //       match: {
      //     //       //         // ItemStatus: "Active",
      //     //       //         SerialNumber: query,
      //     //       //       },
      //     //       //     },
      //     //       //     // {
      //     //       //     //   multi_match: {
      //     //       //     //     query: query,
      //     //       //     //     fields: searchField,
      //     //       //     //     // type: "phrase_prefix",
      //     //       //     //     // operator: "and",
      //     //       //     //   },
      //     //       //     // },
      //     //       //   ],
      //     //       //   // minimum_should_match: "1",
      //     //       // },
      //     //       match: {
      //     //         SerialNumber: query,
      //     //         // searchField: query,
      //     //       },
      //     //     },
      //     //     {
      //     match: {
      //       ItemStatus: "Active",
      //       // searchField: query,
      //     },
      //     //     },
      //     //     // {
      //     //     //   match: {
      //     //     //     // ItemStatus: "Active",
      //     //     //     searchField: query,
      //     //     //   },
      //     //     // },
      //     //   ],
      //     // },
      //   },
      //   // size: 15,
      // },
    ];

    var styleQuery = [
      {},
      {
        query: {
          term: {
            "StyleNumber.keyword": query,
          },
          // bool: {
          //   must: [
          //     {
          //       bool: {
          //         should: [
          //           // {
          //           //   multi_match: {
          //           //     query: query,
          //           //     fields: searchField,
          //           //     // type: "phrase_prefix",
          //           //     // operator: "and",
          //           //   },
          //           // },
          //           {
          //             match: {
          //               StyleNumber: query,
          //             },
          //           },
          //         ],
          //         // minimum_should_match: "1",
          //       },
          //     },
          //     // {
          //     //   match: {
          //     //     ItemStatus: "Active",
          //     //   },
          //     // },
          //   ],
          // },
        },
        // size: 15,
      },
    ];
    // console.log("serachField: ", searchField);
    var appbase = Appbase({
      url: AppbaseAppUrl,
      app: app,
      credentials: AppbaseCredentials,
    });
    return await appbase
      .msearch({
        type: "_doc",
        body:
          JSON.stringify(searchField) === JSON.stringify(["SerialNumber"])
            ? serialQuery
            : styleQuery,
      })
      .then((response) => {
        return response.responses[0].hits.hits;
      })
      .catch(() => console.log("error while appbase fetch"));
  }

  async handlePriceCheck() {
    // console.log("handlePriceCheck");
    let basketItems = JSON.parse(JSON.stringify(this.props.cartItems));
    // console.log("basketItems :", basketItems);
    this.props.toggleLoader({
      isLoading: true,
    });
    // let apiItemData = [];
    let apiItemData = await Promise.all(
      basketItems.map(async (el) => {
        if (el.SerialNumber) {
          let app =
            el.productType === "J"
              ? JewelrySerialApp
              : el.productType === "D"
              ? DiamondSerialApp
              : GemstoneSerialApp;
          let query = el.SerialNumber;
          let searchField = ["SerialNumber"];
          var res = await this.fetchProducts({
            app,
            searchField,
            query,
          });

          // console.log(
          //   "Serial: ",
          //   query,
          //   "\n App: ",
          //   app,
          //   "\n SearchField: ",
          //   searchField
          // );
          // console.log("Serial res: ", res);
          if (res && res.length) {
            // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
            // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
            // console.log(
            //   "res.RetailPrice :",
            //   parseInt(res[0]._source.RetailPrice)
            // );
            // console.log(
            //   "res.WholesalePrice :",
            //   parseInt(res[0]._source.WholesalePrice)
            // );

            // return (
            //   el.RetailPrice !== res[0]._source.RetailPrice ||
            //   el.WholesalePrice !== res[0]._source.WholesalePrice
            // );
            if (
              el.RetailPrice &&
              ![0, null, "NaN"].includes(parseInt(el.RetailPrice)) &&
              res[0]._source.RetailPrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.RetailPrice)
              ) &&
              el.WholesalePrice &&
              ![0, null, "NaN"].includes(parseInt(el.WholesalePrice)) &&
              res[0]._source.WholesalePrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.WholesalePrice)
              ) &&
              (parseInt(el.RetailPrice) !==
                parseInt(res[0]._source.RetailPrice) ||
                parseInt(el.WholesalePrice) !==
                  parseInt(res[0]._source.WholesalePrice))
            ) {
              // console.log(
              //   "Serial: ",
              //   query,
              //   "\n App: ",
              //   app,
              //   "\n SearchField: ",
              //   searchField
              // );
              // console.log("Inside if price not equal loop");
              // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
              // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
              // console.log(
              //   "res.RetailPrice :",
              //   parseInt(res[0]._source.RetailPrice)
              // );
              // console.log(
              //   "res.WholesalePrice :",
              //   parseInt(res[0]._source.WholesalePrice)
              // );
              return res[0]._source;
            }
          }
        } else {
          let app = JewelryStyleApp;
          let query = el.StyleNumber;
          let searchField = ["StyleNumber"];
          let res = await this.fetchProducts({
            app,
            searchField,
            query,
          });
          // console.log("Style: ", query);
          // console.log("style res:", res);
          // console.log(
          //   "Style: ",
          //   query,
          //   "\n App: ",
          //   app,
          //   "\n SearchField: ",
          //   searchField
          // );
          // console.log("Serial res: ", res);
          if (res && res.length) {
            // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
            // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
            // console.log(
            //   "res.RetailPrice :",
            //   parseInt(res[0]._source.RetailPrice)
            // );
            // console.log(
            //   "res.WholesalePrice :",
            //   parseInt(res[0]._source.WholesalePrice)
            // );
            // return (
            //   el.RetailPrice !== res[0]._source.RetailPrice ||
            //   el.WholesalePrice !== res[0]._source.WholesalePrice
            // );
            if (
              el.RetailPrice &&
              ![0, null, "NaN"].includes(parseInt(el.RetailPrice)) &&
              res[0]._source.RetailPrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.RetailPrice)
              ) &&
              el.WholesalePrice &&
              ![0, null, "NaN"].includes(parseInt(el.WholesalePrice)) &&
              res[0]._source.WholesalePrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.WholesalePrice)
              ) &&
              (parseInt(el.RetailPrice) !==
                parseInt(res[0]._source.RetailPrice) ||
                parseInt(el.WholesalePrice) !==
                  parseInt(res[0]._source.WholesalePrice))
            ) {
              // console.log("Style: ", query);
              // console.log("style res:", res);
              // console.log(
              //   "Style: ",
              //   query,
              //   "\n App: ",
              //   app,
              //   "\n SearchField: ",
              //   searchField
              // );
              // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
              // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
              // console.log(
              //   "res.RetailPrice :",
              //   parseInt(res[0]._source.RetailPrice)
              // );
              // console.log(
              //   "res.WholesalePrice :",
              //   parseInt(res[0]._source.WholesalePrice)
              // );
              // console.log("Inside if price not equal loop");
              return res[0]._source;
            }
          }
        }
      })
    );
    // console.log("apiItemData before filter :", apiItemData);
    apiItemData = apiItemData.filter((value) => value !== undefined);
    // console.log("apiItemData :", apiItemData);
    this.setState({ differenceItemPrices: apiItemData });
    this.props.toggleLoader({
      isLoading: false,
    });
    if (apiItemData && apiItemData.length) {
      this.showPriceCheckModal();
    } else {
      toast.success("No differences found!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
      });
    }

    // for (let i = 0; i < basketItems.length; i++) {
    //   let el = basketItems[i];
    //   if (el.SerialNumber) {
    //     let app =
    //       el.productType === "J"
    //         ? JewelrySerialApp
    //         : el.productType === "D"
    //         ? DiamondSerialApp
    //         : GemstoneSerialApp;
    //     let query = el.SerialNumber;
    //     let searchField = ["SerialNumber"];
    //     var res = await this.fetchProducts({
    //       app,
    //       searchField,
    //       query,
    //     });
    //   } else {
    //     let app = JewelryStyleApp;
    //     let query = el.StyleNumber;
    //     let searchField = ["StyleNumber"];
    //     let res = this.fetchProducts({
    //       app,
    //       searchField,
    //       query,
    //     });
    //   }
    // }
  }

  handlePriceChange(res) {
    let { items } = this.state;
    console.log("items :", items);
    console.log("res", res);
    let updatedItems = data.map((el) => {
      if (res.SerialNumber && res.SerialNumber === el.SerialNumber) {
        el.RetailPrice = res.RetailPrice;
        el.WholesalePrice = res.WholesalePrice;
        return el;
      }
      if (res.StyleNumber && res.StyleNumber === el.StyleNumber) {
        el.RetailPrice = res.RetailPrice;
        el.WholesalePrice = res.WholesalePrice;
        return el;
      }
      return el;
    });
    console.log("updatedItems: ", updatedItems);
    this.props.openCartItems(updatedItems);
    this.setState((prevState) => ({
      differenceItemPrices: prevState.differenceItemPrices.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item)
      ),
    }));
    toast.success("Price Updated!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
      pauseOnHover: false,
    });
  }

  handleAllItems(e) {
    let { items } = this.state;
    items.map((item) =>
      this.props.updateItemSelected({
        item: item,
        selected: e.target.checked,
      })
    );
  }

  isValueEmpty(res) {
    let result = "";
    if (!isEmpty(res) && res !== "NaN") {
      result = res;
    }
    return result;
  }
  async getWarehouseValue(item) {
    // console.log("item: ", item);
    var _id;
    var app;
    var res;
    if (item.productType === "D" && item.SerialNumber && item.InventoryID) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = DiamondSerialApp;
    } else if (
      item.productType === "G" &&
      item.SerialNumber &&
      item.InventoryID
    ) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = GemstoneSerialApp;
    } else if (
      item.productType === "J" &&
      item.SerialNumber &&
      item.InventoryID
    ) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = JewelrySerialApp;
    } else {
      console.log("No state warehouse update");
      return ` `;
    }

    var appbase = Appbase({
      url: AppbaseAppUrl,
      app: app,
      credentials: AppbaseCredentials,
    });
    await appbase
      .get({
        type: "_doc",
        id: _id,
      })
      .then((response) => {
        // console.log(" warehouse: ", response._source);
        this.setState((prevState) => ({
          warehouse: [
            ...prevState.warehouse,
            {
              InventoryID: response._source.InventoryID,
              SerialNumber: response._source.SerialNumber,
              Warehouse: response._source.Warehouse,
              SerialStatus: response._source.SerialStatus,
              IsVirtual: response._source.IsVirtual,
              IsPhantom: response._source.IsPhantom,
            },
          ],
        }));
      })
      .catch((error) => {
        // console.log("error while appbase fetch", error);
        // return ``;
      });

    // console.log("res: ", res);
    // return <span>{res}</span>;
  }
  showMoreModal(res) {
    // console.log("Inside show More Modal");
    this.setState({
      currentRes: res,
      showMoreModal: true,
    });
  }
  hideMoreModal() {
    this.setState({
      showMoreModal: false,
    });
  }
  handleQtyChange(el, qty) {
    this.props.updateQuantity({
      item: el,
      qty: qty,
    });
  }
  handleInternalNotes(e, el) {
    this.props.updateInternalNotes({
      item: el,
      note: e.target.value,
    });
  }
  handlePriceVisibility(e, el) {
    this.props.updatePriceVisibility({
      item: el,
      visibility: e.target.value,
    });
    let newId = `#product__collapsed__more-details-${
      el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
    }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
      el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
    }-${el.priceVisibility.replaceAll(
      /\s/g,
      ""
    )}-${el.linkVisibility.replaceAll(/\s/g, "")}`;
    // console.log("newID", newId);
    this.setState({
      collapsedProductId: newId,
    });
  }
  handleAssetOrMemo(e, el) {
    this.props.setAssetOrMemo({
      item: el,
      value: e.target.value,
    });
    let newId = `#product__collapsed__more-details-${
      el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
    }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
      el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
    }-${el.priceVisibility.replaceAll(
      /\s/g,
      ""
    )}-${el.linkVisibility.replaceAll(/\s/g, "")}`;
    // console.log("newID", newId);
    this.setState({
      collapsedProductId: newId,
    });
  }
  handleLinkVisibility(e, el) {
    this.props.updateLinkVisibility({
      item: el,
      visibility: e.target.value,
    });
    let newId = `#product__collapsed__more-details-${
      el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
    }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
      el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
    }-${el.priceVisibility.replaceAll(
      /\s/g,
      ""
    )}-${el.linkVisibility.replaceAll(/\s/g, "")}`;
    // console.log("newID", newId);
    this.setState({
      collapsedProductId: newId,
    });
  }
  handleTogglePriceInput(e) {
    var inputNode =
      e.currentTarget.parentElement.parentElement.querySelector("input");
    var valueNode =
      e.currentTarget.parentElement.parentElement.querySelector("p");
    if (inputNode.style.display === "none") {
      inputNode.style.display = "block";
      inputNode.focus();
    } else {
      inputNode.style.display = "none";
    }
    if (valueNode.style.display === "none") {
      valueNode.style.display = "block";
    } else {
      valueNode.style.display = "none";
    }
  }

  handleInputBlur(e) {
    var valueNode =
      e.currentTarget.parentElement.parentElement.querySelector("p");
    e.currentTarget.style.display = "none";
    valueNode.style.display = "block";
  }
  handleKeyDown(e) {
    if (e.key === ".") {
      e.preventDefault();
      return;
    }
  }
  handlePriceEditChange(e, priceCheck, itemToEdit) {
    let { items } = this.state;
    if (priceCheck === "retail") {
      let updatedItems = items.map((el) => {
        if (JSON.stringify(el) === JSON.stringify(itemToEdit)) {
          el.RetailPrice =
            parseFloat(e.target.value).toFixed(2).toString() || "0";
          return el;
        } else {
          return el;
        }
      });
      this.props.openCartItems(updatedItems);
    } else {
      let updatedItems = items.map((el) => {
        if (JSON.stringify(el) === JSON.stringify(itemToEdit)) {
          el.WholesalePrice =
            parseFloat(e.target.value).toFixed(2).toString() || "0";
          return el;
        } else {
          return el;
        }
      });
      this.props.openCartItems(updatedItems);
    }
  }
  handleMoreDetails(e) {
    let { collapsedProductId } = this.state;
    if (e.target.getAttribute("aria-expanded") === "true") {
      e.target.innerHTML = "-";
    } else {
      e.target.innerHTML = "+";
    }
    if (collapsedProductId) {
      var collapseNode = document.querySelector(collapsedProductId);
      var prevNode = document.querySelector(
        "[data-target='" + collapsedProductId + "']"
      );
      if (collapseNode) {
        if (collapseNode.classList.contains("in")) {
          collapseNode.classList.remove("in");
        }
        prevNode.innerHTML = "+";
      }
    }
    this.setState({
      collapsedProductId: e.target.getAttribute("data-target"),
    });
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    var sorted;
    sorted = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({
      items: sorted,
    });
    this.props.openCartItems(sorted);
    this.props.setSortOption([]);
  };

  showZoomImage(res, eventPosition) {
    function showWebImage(img) {
      var src = "https://cdn4.kwiat.com/source-images/web/original/" + img;
      return src;
    }
    function showimage(image) {
      let img,
        str = "";
      if (image && image != null) {
        let searchimage;
        searchimage = image;
        str = searchimage.split("\\");
        searchimage = str[str.length - 1];
        img = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      } else {
        img = "";
      }
      return img;
    }
    const webImgName = (img) => img.replace(/ /g, "");
    const largeImgName = (img) => {
      var str = img.split("\\");
      return str[str.length - 1];
    };

    var imgArr = [];
    if (eventPosition === "onWebImageIcon") {
      imgArr.push({
        original: showWebImage(res.WebImage1),
        thumbnail: showWebImage(res.WebImage1),
        imgName: webImgName(res.WebImage1),
      });
    } else {
      if (res.LargeImageName) {
        imgArr.push({
          original: showimage(res.LargeImageName),
          thumbnail: showimage(res.LargeImageName),
          imgName: largeImgName(res.LargeImageName),
        });
      }
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field]),
          });
        }
      }
    }
    this.setState({
      showImageModal: true,
      zoomImage: imgArr,
    });
  }
  hideImageModal() {
    this.setState({
      showImageModal: false,
    });
  }

  render() {
    const {
      items,
      customerClass,
      contact,
      showImageModal,
      zoomImage,
      customer,
    } = this.state;
    // console.log("props", this.props);
    return (
      <div className="mobile__basket__grouped">
        {items.length > 0 && (
          <div className="mobile__sort__select">
            <div className="basket_customer_class">
              {this.props.basketInputObj.customer.CustomerClass}
            </div>
            <SortItems />
          </div>
        )}
        <SortableContainer
          onSortEnd={this.onSortEnd}
          useDragHandle
          helperClass="sortableHelperMobile"
        >
          <div className="mobile_basket_select_all">
            <div className="select_all">
              <input
                type="checkbox"
                id="Mobile-Select-All-Items"
                checked={this.props.selectedItems.length ? true : false}
                onChange={(e) => this.handleAllItems(e)}
              />
              <label htmlFor="Mobile-Select-All-Items">Select All</label>
            </div>
            {this.props.basketInputObj.holdNotesAccess &&
              items &&
              items.length !== 0 && (
                <button
                  onClick={() =>
                    this.handleHoldNotesModals(
                      // res.InventoryID.toString() +
                      //   res.SerialNumber.toString(),
                      "",
                      true,
                      "createHoldNotes"
                    )
                  }
                >
                  Bulk Hold Notes
                </button>
              )}
            <button className="btn" onClick={this.handlePriceCheck}>
              Check Price
            </button>
          </div>
          {items.map((el, index) => {
            // console.log("index: ", index);
            var idForSort = `${
              el.SerialNumber ? el.SerialNumber.replaceAll(/\s/g, "") : ""
            }-${el.StyleNumber ? el.StyleNumber.replaceAll(/\s/g, "") : ""}-${
              el.InternalNote ? el.InternalNote.replaceAll(/\s/g, "") : ""
            }-${el.priceVisibility.replaceAll(/\s/g, "")}-${parseFloat(
              el.RetailPrice
            )}`;

            return (
              <SortableItem
                key={`item-${idForSort}`}
                i={index}
                value={el}
                customerClass={customerClass}
                contact={contact}
                customer={customer}
                handleQtyChange={(el, qty) => this.handleQtyChange(el, qty)}
                removeFromCart={(el) => this.props.removeFromCart(el)}
                handleMoreDetails={(e) => this.handleMoreDetails(e)}
                handleKeyDown={(e) => this.handleKeyDown(e)}
                handlePriceEditChange={(e, priceCheck, itemToEdit) =>
                  this.handlePriceEditChange(e, priceCheck, itemToEdit)
                }
                handleInputBlur={(e) => this.handleInputBlur(e)}
                handleTogglePriceInput={(e) => this.handleTogglePriceInput(e)}
                handlePriceVisibility={(e, el) =>
                  this.handlePriceVisibility(e, el)
                }
                handleLinkVisibility={(e, el) =>
                  this.handleLinkVisibility(e, el)
                }
                handleInternalNotes={(e, el) => this.handleInternalNotes(e, el)}
                showZoomImage={(res, eventPosition) =>
                  this.showZoomImage(res, eventPosition)
                }
                showMoreModal={this.showMoreModal}
                warehouse={this.state.warehouse}
                isValueEmpty={this.isValueEmpty}
                handleSelectedItems={this.props.handleSelectedItems}
                selectedItems={this.props.selectedItems}
                handleAssetOrMemo={this.handleAssetOrMemo}
                basketInputObj={this.props.basketInputObj}
                handleHoldNoteArchive={this.handleHoldNoteArchive}
                handleHoldNotesModals={this.handleHoldNotesModals}
                handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
                handleItemRefresh={this.handleItemRefresh}
                handleHoldNoteEditModal={this.handleHoldNoteEditModal}
              />
            );
          })}
        </SortableContainer>
        {showImageModal && zoomImage !== null && (
          <ImageModal
            show={showImageModal}
            callback={this.hideImageModal}
            image={zoomImage}
          />
        )}
        <MoreDetailsModal
          show={this.state.showMoreModal}
          hide={this.hideMoreModal}
          res={this.state.currentRes}
        />
        <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
          basketItems={this.state.items.filter(
            (item) => item.itemSelected === true
          )}
          handleItemRefresh={this.handleItemRefresh}
        />
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="show_alert_modal"
          centered="true"
          size="sm"
          show={this.state.showPriceCheckModal}
          onHide={() => this.hidePriceCheckModal()}
        >
          <Modal.Header closeButton>
            {this.state.differenceItemPrices.length} item(s) have price
            differences:
          </Modal.Header>
          <Modal.Body>
            <table className="price_check_table">
              <thead>
                <tr>
                  <th>Serial/Style</th>
                  <th>Current Pricing</th>
                  <th>Basket Pricing</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.showPriceCheckModal &&
                  this.state.differenceItemPrices.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.SerialNumber
                          ? item.SerialNumber
                          : item.StyleNumber}
                      </td>
                      <td>
                        {(item.WholesalePrice &&
                          currencyFormatter.format(`${item.WholesalePrice}`, {
                            code: "USD",
                            precision: 0,
                          })) ||
                          "$0"}{" "}
                        /{" "}
                        {(item.RetailPrice &&
                          currencyFormatter.format(`${item.RetailPrice}`, {
                            code: "USD",
                            precision: 0,
                          })) ||
                          "$0"}
                      </td>
                      {/* <td>{`${
                      item.WholesalePrice !== null ? item.WholesalePrice : 0
                    } / ${
                      item.RetailPrice !== null ? item.RetailPrice : 0
                    }`}</td> */}
                      <td>
                        {/* {console.log(
                        "value :",
                        this.state.data.filter((el) => {
                          if (
                            item.SerialNumber &&
                            item.SerialNumber === el.SerialNumber
                          )
                            return el;

                          if (
                            item.StyleNumber &&
                            item.StyleNumber === el.StyleNumber
                          )
                            return el;
                        })[0].WholesalePrice
                      )} */}
                        {`${
                          this.state.data.filter((el) => {
                            if (
                              item.SerialNumber &&
                              item.SerialNumber === el.SerialNumber
                            )
                              return el;

                            if (
                              item.StyleNumber &&
                              item.StyleNumber === el.StyleNumber
                            )
                              return el;
                          })[0].WholesalePrice !== null
                            ? currencyFormatter.format(
                                this.state.data.filter((el) => {
                                  if (
                                    item.SerialNumber &&
                                    item.SerialNumber === el.SerialNumber
                                  )
                                    return el;

                                  if (
                                    item.StyleNumber &&
                                    item.StyleNumber === el.StyleNumber
                                  )
                                    return el;
                                })[0].WholesalePrice,
                                {
                                  code: "USD",
                                  precision: 0,
                                }
                              )
                            : `$0`
                        } /
                        ${
                          this.state.data.filter((el) => {
                            if (
                              item.SerialNumber &&
                              item.SerialNumber === el.SerialNumber
                            )
                              return el;

                            if (
                              item.StyleNumber &&
                              item.StyleNumber === el.StyleNumber
                            )
                              return el;
                          })[0].RetailPrice !== null
                            ? currencyFormatter.format(
                                this.state.data.filter((el) => {
                                  if (
                                    item.SerialNumber &&
                                    item.SerialNumber === el.SerialNumber
                                  )
                                    return el;

                                  if (
                                    item.StyleNumber &&
                                    item.StyleNumber === el.StyleNumber
                                  )
                                    return el;
                                })[0].RetailPrice,
                                {
                                  code: "USD",
                                  precision: 0,
                                }
                              )
                            : `$0`
                        }`}
                      </td>
                      <td>
                        <button onClick={() => this.handlePriceChange(item)}>
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan="4">
                    <button
                      className="align-self-center"
                      onClick={this.hidePriceCheckModal}
                    >
                      OK
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <div className="price_check_wrapper">
              {this.state.differenceItemPrices.map((item, i) => (
                <div className="price_check_item_wrapper" key={i}>
                  <label className="price_check_item">
                    {item.SerialNumber ? item.SerialNumber : item.StyleNumber}
                  </label>
                  <label>{item}</label>
                  <button onClick={() => this.handlePriceChange(item)}>
                    Update
                  </button>
                  <button>Ignore</button>
                  <br />
                </div>
              ))}
              <button
                className="align-self-center"
                onClick={this.hidePriceCheckModal}
              >
                OK
              </button>
            </div> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeFromCart,
      updateQuantity,
      updateInternalNotes,
      updatePriceVisibility,
      updateLinkVisibility,
      updateItemSelected,
      openCartItems,
      toggleIframeModal,
      setSortOption,
      toggleLoader,
      setAssetOrMemo,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileBasketItemsList);
