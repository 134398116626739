import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import ReactImageFallback from "react-image-fallback";
import { Modal, Alert, Button } from "react-bootstrap";
import currencyFormatter from "currency-formatter";
import ShowMore from "react-show-more";
import Appbase from "appbase-js";
import isEmpty from "lodash/isEmpty";
import { isMobileOnly } from "react-device-detect";
import {
  removeFromCart,
  updateQuantity,
  updateInternalNotes,
  updatePriceVisibility,
  updateLinkVisibility,
  updateItemSelected,
  openCartItems,
  toggleIframeModal,
  setSortOption,
  toggleLoader,
  setAssetOrMemo,
} from "../../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// import SortBasketItems from "../SortBasketItems";
import ItemsCondensedMode from "./ItemsCondensedMode";
import ImageModal from "../../ImageModal";
import SortItems from "../SortItems";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  DiamondSerialApp,
  GemstoneSerialApp,
  JewelrySerialApp,
  JewelryStyleApp,
  BaseURL,
  AppbaseHoldNotesApp,
  holdNoteApi,
  holdNoteApiKey,
} from "../../../utils/constants";
import HoldNoteCreateModal from "../../HoldNoteCreateModal";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import HoldNotesArchievedModal from "../../HoldNotesArchievedModal";
import cx from "classnames";
import moment from "moment";
import axios from "axios";
import { getHoldNotesData } from "../../holdNotesApiData";
import { getItemFromAppBase, getUniqueItemWithQty } from "../ReadAndOpenBasket";
import JewelryStockModal from "../../JewelryStockModal";
import DiamondStockModal from "../../DiamondStockModal";
import GemstoneStockModal from "../../GemstoneStockModal";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
    cartItems: state.cartActions,
    loaderActions: state.loaderActions,
    apiData: state.apiData,
  };
};

class BaksetItemsList extends Component {
  constructor(props) {
    super(props);
    const data = props.items;

    this.state = {
      access: this.props.basketInputObj.access,
      data,
      hideDetails: false,
      customerClass: props.basketInputObj.customer.CustomerClass || "",
      contact: props.basketInputObj.contact,
      customer: props.basketInputObj.customer,
      showImageModal: false,
      zoomImage: "",
      warehouse: [],
      showPriceCheckModal: false,
      differenceItemPrices: [],
      showHoldNotesModal: false,
      showHoldNotesEditModal: false,
      holdNotesData: "",
      showHoldNoteCreateModal: false,
      customers: "",
      feedRestrictionsData: this.props.apiData.feedsData,
      jewelryRestrictionData: this.props.apiData.jewelryHoldData,
      employeeData: this.props.apiData.empData,
      holdNoteItem: "",
      showStockPopUpModal: false,
      checked: this.props.basketInputObj.showCodeChecked,
      archivePopUp: false,
      blankMTSCustomer: false,
      bulkNoteClicked: false,
      itemToArchive: null
    };

    this.handlePriceVisibility = this.handlePriceVisibility.bind(this);
    this.handleLinkVisibility = this.handleLinkVisibility.bind(this);
    this.handleHideDetails = this.handleHideDetails.bind(this);
    this.handleTogglePriceInput = this.handleTogglePriceInput.bind(this);
    this.handlePriceEditChange = this.handlePriceEditChange.bind(this);
    this.handleInternalNotes = this.handleInternalNotes.bind(this);
    this.showZoomImage = this.showZoomImage.bind(this);
    this.hideImageModal = this.hideImageModal.bind(this);
    this.getWarehouseValue = this.getWarehouseValue.bind(this);
    this.bindisValueEmpty = this.isValueEmpty.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handlePriceCheck = this.handlePriceCheck.bind(this);
    this.showPriceCheckModal = this.showPriceCheckModal.bind(this);
    this.hidePriceCheckModal = this.hidePriceCheckModal.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleAllItems = this.handleAllItems.bind(this);
    this.handleAssetOrMemo = this.handleAssetOrMemo.bind(this);
    this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
    this.handleItemRefresh = this.handleItemRefresh.bind(this);
    this.handleStockPopUpModal = this.handleStockPopUpModal.bind(this);
    // this.getAppbaseWarehouse = this.getAppbaseWarehouse.bind(this);
  }

  componentDidMount() {
    // console.log("Inside Component did mount");
    // console.log("prevState: ", prevState);
    // console.log("state: ", this.state);
    // if (this.props.items !== this.state.data) {
    // this.setState({ warehouse: [] });
    // for (let i = 0; i < this.state.data.length; i++) {
    //   this.getWarehouseValue(this.state.data[i]);
    // }
    // }
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.items.length !== nextProps.items.length) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        data: nextProps.items,
        // checked: nextProps.basketInputObj.showCodeChecked
        // warehouse: [],
      });
      // for (let i = 0; i < nextProps.items.length; i++) {
      //   this.getWarehouseValue(nextProps.items[i]);
      // }
    }
    // if (this.props.items.length !== nextProps.items.length) {
    //   // this.setState({ warehouse: [] });
    //   let newlyAddedItems = nextProps.items.filter(
    //     (newItem, i) => !this.props.items.includes(newItem)
    //   );
    //   // console.log("newlyAdded Item: ", newlyAddedItems);
    //   for (let i = 0; i < newlyAddedItems; i++) {
    //     this.getWarehouseValue(newlyAddedItems[i]);
    //   }
    // }
    if (
      this.state.customerClass !==
      nextProps.basketInputObj.customer.CustomerClass ||
      this.state.contact !== nextProps.basketInputObj.contact ||
      this.state.customer !== nextProps.basketInputObj.customer
    ) {
      this.setState({
        customerClass: nextProps.basketInputObj.customer.CustomerClass,
        contact: nextProps.basketInputObj.contact,
        customer: nextProps.basketInputObj.customer,
      });
    }
  }

  handleStockPopUpModal(value, item) {
    let { access } = this.state;
    if (access !== "advanced") {
      return;
    }
    this.setState({
      holdNoteItem: item ? item : "",
      showStockPopUpModal: value ? value : false,
    });
  }

  async handleItemRefresh() {
    let items = this.state.data;
    this.props.toggleLoader({
      isLoading: true,
    });
    items = items.map((el) => {
      var productArr = {
        SerialNumber: el.SerialNumber,
        StyleNumber: el.StyleNumber,
        InternalNote: el.InternalNote,
        RetailPrice: el.RetailPrice,
        WholesalePrice: el.WholesalePrice,
        Quantity: el.quantity,
        PriceVisibility: el.priceVisibility,
        LinkVisibility: el.linkVisibility,
        ItemSelected: el.itemSelected,
        ProductType: el.productType,
        AssetOrMemo: el.assetOrmemo,
      };
      return productArr;
    });

    let uniqueArrayFromBasket = getUniqueItemWithQty(items);
    // console.log("uniqueArrayFromBasket: ", uniqueArrayFromBasket);
    var fromAppbaseCall = [];
    for (var i = 0; i < uniqueArrayFromBasket.length; i++) {
      var res = await getItemFromAppBase({
        item: uniqueArrayFromBasket[i],
        searchOption: "mixed",
      });
      // console.log("res: ", res);
      if (typeof res[0]["_source"] !== "object") {
        toast.error("Appbase fetching products error !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
        });
        continue;
      }
      let { RetailPrice, WholesalePrice, ...omitted } = res[0]["_source"];
      fromAppbaseCall.push({
        ...omitted,
        RetailPrice: uniqueArrayFromBasket[i].RetailPrice,
        WholesalePrice: uniqueArrayFromBasket[i].WholesalePrice,
        quantity: uniqueArrayFromBasket[i].Quantity,
        InternalNote: uniqueArrayFromBasket[i].InternalNote,
        priceVisibility: uniqueArrayFromBasket[i].PriceVisibility,
        productType: uniqueArrayFromBasket[i].ProductType,
        linkVisibility: uniqueArrayFromBasket[i].LinkVisibility
          ? uniqueArrayFromBasket[i].LinkVisibility
          : "Default",
        itemSelected: uniqueArrayFromBasket[i].ItemSelected
          ? uniqueArrayFromBasket[i].ItemSelected
          : true,
        LineNbr: uniqueArrayFromBasket[i].LineNbr,
        assetOrmemo:
          uniqueArrayFromBasket[i].AssetOrMemo &&
            uniqueArrayFromBasket[i].AssetOrMemo !== true &&
            uniqueArrayFromBasket[i].AssetOrMemo !== false
            ? uniqueArrayFromBasket[i].AssetOrMemo
            : true,
      });
    }
    this.props.openCartItems(fromAppbaseCall);
    this.props.toggleLoader({
      isLoading: false,
    });
  }

  handleItemHoldNoteUpdate(res, items) {
    console.log("Inside item update");
    // console.log({ res }, { items });
    if (!items) {
      // this.setState((prevState) => ({
      //   data: prevState.data.map((item) => {
      //     if (item.SerialNumber === res.SerialNumber) {
      //       return res;
      //     } else {
      //       return item;
      //     }
      //   }),
      // }));
      let data = this.state.data.map((item) => {
        if (item.SerialNumber === res.SerialNumber) {
          return res;
        } else {
          return item;
        }
      });
      this.props.openCartItems(data);
    } else {
      let data = this.state.data.map((basketItem) => {
        let result = items.find(
          (item) => item.SerialNumber === basketItem.SerialNumber
        );
        if (result) {
          return { ...basketItem, ...res };
        } else {
          return basketItem;
        }
      });
      this.props.openCartItems(data);
      // console.log({ data });
      // this.setState((prevState) => ({
      //   data: prevState.data.map((basketItem) => {
      //     let result = items.find(
      //       (item) => item.SerialNumber === basketItem.SerialNumber
      //     );
      //     if (result) {
      //       return { ...basketItem, ...res };
      //     } else {
      //       return basketItem;
      //     }
      //   }),
      // }));
    }
    // this.getProduct(this.state.results);
  }

  async handleHoldNoteArchive(res) {
    //let result = window.confirm("Confirm Hold Note Archieve ?");
    if (true) {
      this.props.toggleLoader({
        isLoading: true,
      });
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
          // blankMTSCustomer: this.state.blankMTSCustomer,
        },
      };
      if (res?.MTSCustomer) {
        payload.data.blankMTSCustomer =  this.state.blankMTSCustomer;
      }else if(res?.MTSCustomerExists === "Yes" ){
        payload.data.blankMTSCustomer =  this.state.blankMTSCustomer;
      }
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // this.props.toggleLoader({
          //   isLoading: false,
          // });
        });
      console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        this.props.toggleLoader({
          isLoading: false,
        });
        this.setState((prevState) => ({
          data: prevState.data.map((item) => {
            if (item.SerialNumber === res.SerialNumber) {
              return {
                ...item,
                // HoldBy: null,
                HoldCustomerId: null,
                HoldCustomerName: null,
                HoldDate: null,
                HoldEnteredByID: null,
                HoldEnteredByName: null,
                HoldEnteredForID: null,
                HoldEnteredForName: null,
                HoldNoteID: null,
                HoldNoteNbr: null,
                HoldText: null,
                ReleaseDate: null,
                IsHoldSold: null,
              };
            } else {
              return item;
            }
          }),
        }));
      } else {
        this.props.toggleLoader({
          isLoading: false,
        });
      }
    }
  }

  async handleHoldNotesModals(id, value, type, res) {
    let { data } = this.state;
    this.setState({ holdNoteItem: res });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      this.setState({ holdNotesData: response });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    this.setState({
      ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
      ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
      ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
      ...(!value
        ? { showHoldNotesEditModal: false, holdNotesData: "", holdNoteItem: "" }
        : {}),
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  }

  handleAllItems(e) {
    let { data } = this.state;
    data.map((item) =>
      this.props.updateItemSelected({
        item: item,
        selected: e.target.checked,
      })
    );
  }

  showPriceCheckModal() {
    this.setState({ showPriceCheckModal: true });
  }

  hidePriceCheckModal() {
    this.setState({ showPriceCheckModal: false });
  }

  async fetchProducts({ app, query, searchField }) {
    // console.log("Query: ", query);
    // console.log("serachField: ", searchField);
    // console.log("app: ", app);
    var serialQuery = [
      {},
      {
        query: {
          bool: {
            must: [
              {
                term: {
                  "SerialNumber.keyword": query,
                },
              },
              {
                match: {
                  ItemStatus: "Active",
                  // searchField: query,
                },
              },
            ],
          },
        },
      },
      // {},
      // {
      //   query: {
      //     // bool: {
      //     //   must: [
      //     //     {
      //     //       // bool: {
      //     //       //   should: [
      //     //       //     {
      //     //       //       match: {
      //     //       //         // ItemStatus: "Active",
      //     //       //         SerialNumber: query,
      //     //       //       },
      //     //       //     },
      //     //       //     // {
      //     //       //     //   multi_match: {
      //     //       //     //     query: query,
      //     //       //     //     fields: searchField,
      //     //       //     //     // type: "phrase_prefix",
      //     //       //     //     // operator: "and",
      //     //       //     //   },
      //     //       //     // },
      //     //       //   ],
      //     //       //   // minimum_should_match: "1",
      //     //       // },
      //     //       match: {
      //     //         SerialNumber: query,
      //     //         // searchField: query,
      //     //       },
      //     //     },
      //     //     {
      //     match: {
      //       ItemStatus: "Active",
      //       // searchField: query,
      //     },
      //     //     },
      //     //     // {
      //     //     //   match: {
      //     //     //     // ItemStatus: "Active",
      //     //     //     searchField: query,
      //     //     //   },
      //     //     // },
      //     //   ],
      //     // },
      //   },
      //   // size: 15,
      // },
    ];

    var styleQuery = [
      {},
      {
        query: {
          term: {
            "StyleNumber.keyword": query,
          },
          // bool: {
          //   must: [
          //     {
          //       bool: {
          //         should: [
          //           // {
          //           //   multi_match: {
          //           //     query: query,
          //           //     fields: searchField,
          //           //     // type: "phrase_prefix",
          //           //     // operator: "and",
          //           //   },
          //           // },
          //           {
          //             match: {
          //               StyleNumber: query,
          //             },
          //           },
          //         ],
          //         // minimum_should_match: "1",
          //       },
          //     },
          //     // {
          //     //   match: {
          //     //     ItemStatus: "Active",
          //     //   },
          //     // },
          //   ],
          // },
        },
        // size: 15,
      },
    ];
    // console.log("serachField: ", searchField);
    var appbase = Appbase({
      url: AppbaseAppUrl,
      app: app,
      credentials: AppbaseCredentials,
    });
    return await appbase
      .msearch({
        type: "_doc",
        body:
          JSON.stringify(searchField) === JSON.stringify(["SerialNumber"])
            ? serialQuery
            : styleQuery,
      })
      .then((response) => {
        return response.responses[0].hits.hits;
      })
      .catch(() => console.log("error while appbase fetch"));
  }

  async handlePriceCheck() {
    // console.log("handlePriceCheck");
    let basketItems = JSON.parse(JSON.stringify(this.props.cartItems.items));
    // console.log("basketItems :", basketItems);
    this.props.toggleLoader({
      isLoading: true,
    });
    // let apiItemData = [];
    let apiItemData = await Promise.all(
      basketItems.map(async (el) => {
        if (el.SerialNumber) {
          let app =
            el.productType === "J"
              ? JewelrySerialApp
              : el.productType === "D"
                ? DiamondSerialApp
                : GemstoneSerialApp;
          let query = el.SerialNumber;
          let searchField = ["SerialNumber"];
          var res = await this.fetchProducts({
            app,
            searchField,
            query,
          });

          // console.log(
          //   "Serial: ",
          //   query,
          //   "\n App: ",
          //   app,
          //   "\n SearchField: ",
          //   searchField
          // );
          // console.log("Serial res: ", res);
          if (res && res.length) {
            // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
            // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
            // console.log(
            //   "res.RetailPrice :",
            //   parseInt(res[0]._source.RetailPrice)
            // );
            // console.log(
            //   "res.WholesalePrice :",
            //   parseInt(res[0]._source.WholesalePrice)
            // );

            // return (
            //   el.RetailPrice !== res[0]._source.RetailPrice ||
            //   el.WholesalePrice !== res[0]._source.WholesalePrice
            // );
            if (
              el.RetailPrice &&
              ![0, null, "NaN"].includes(parseInt(el.RetailPrice)) &&
              res[0]._source.RetailPrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.RetailPrice)
              ) &&
              el.WholesalePrice &&
              ![0, null, "NaN"].includes(parseInt(el.WholesalePrice)) &&
              res[0]._source.WholesalePrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.WholesalePrice)
              ) &&
              (parseInt(el.RetailPrice) !==
                parseInt(res[0]._source.RetailPrice) ||
                parseInt(el.WholesalePrice) !==
                parseInt(res[0]._source.WholesalePrice))
            ) {
              // console.log(
              //   "Serial: ",
              //   query,
              //   "\n App: ",
              //   app,
              //   "\n SearchField: ",
              //   searchField
              // );
              // console.log("Inside if price not equal loop");
              // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
              // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
              // console.log(
              //   "res.RetailPrice :",
              //   parseInt(res[0]._source.RetailPrice)
              // );
              // console.log(
              //   "res.WholesalePrice :",
              //   parseInt(res[0]._source.WholesalePrice)
              // );
              return res[0]._source;
            }
          }
        } else {
          let app = JewelryStyleApp;
          let query = el.StyleNumber;
          let searchField = ["StyleNumber"];
          let res = await this.fetchProducts({
            app,
            searchField,
            query,
          });
          // console.log("Style: ", query);
          // console.log("style res:", res);
          // console.log(
          //   "Style: ",
          //   query,
          //   "\n App: ",
          //   app,
          //   "\n SearchField: ",
          //   searchField
          // );
          // console.log("Serial res: ", res);
          if (res && res.length) {
            // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
            // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
            // console.log(
            //   "res.RetailPrice :",
            //   parseInt(res[0]._source.RetailPrice)
            // );
            // console.log(
            //   "res.WholesalePrice :",
            //   parseInt(res[0]._source.WholesalePrice)
            // );
            // return (
            //   el.RetailPrice !== res[0]._source.RetailPrice ||
            //   el.WholesalePrice !== res[0]._source.WholesalePrice
            // );
            if (
              el.RetailPrice &&
              ![0, null, "NaN"].includes(parseInt(el.RetailPrice)) &&
              res[0]._source.RetailPrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.RetailPrice)
              ) &&
              el.WholesalePrice &&
              ![0, null, "NaN"].includes(parseInt(el.WholesalePrice)) &&
              res[0]._source.WholesalePrice &&
              ![0, null, "NaN"].includes(
                parseInt(res[0]._source.WholesalePrice)
              ) &&
              (parseInt(el.RetailPrice) !==
                parseInt(res[0]._source.RetailPrice) ||
                parseInt(el.WholesalePrice) !==
                parseInt(res[0]._source.WholesalePrice))
            ) {
              // console.log("Style: ", query);
              // console.log("style res:", res);
              // console.log(
              //   "Style: ",
              //   query,
              //   "\n App: ",
              //   app,
              //   "\n SearchField: ",
              //   searchField
              // );
              // console.log("el.RetailPrice :", parseInt(el.RetailPrice));
              // console.log("el.WholesalePrice :", parseInt(el.WholesalePrice));
              // console.log(
              //   "res.RetailPrice :",
              //   parseInt(res[0]._source.RetailPrice)
              // );
              // console.log(
              //   "res.WholesalePrice :",
              //   parseInt(res[0]._source.WholesalePrice)
              // );
              // console.log("Inside if price not equal loop");
              return res[0]._source;
            }
          }
        }
      })
    );
    // console.log("apiItemData before filter :", apiItemData);
    apiItemData = apiItemData.filter((value) => value !== undefined);
    // console.log("apiItemData :", apiItemData);
    this.setState({ differenceItemPrices: apiItemData });
    this.props.toggleLoader({
      isLoading: false,
    });
    if (apiItemData && apiItemData.length) {
      this.showPriceCheckModal();
    } else {
      toast.success("No differences found!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
      });
    }

    // for (let i = 0; i < basketItems.length; i++) {
    //   let el = basketItems[i];
    //   if (el.SerialNumber) {
    //     let app =
    //       el.productType === "J"
    //         ? JewelrySerialApp
    //         : el.productType === "D"
    //         ? DiamondSerialApp
    //         : GemstoneSerialApp;
    //     let query = el.SerialNumber;
    //     let searchField = ["SerialNumber"];
    //     var res = await this.fetchProducts({
    //       app,
    //       searchField,
    //       query,
    //     });
    //   } else {
    //     let app = JewelryStyleApp;
    //     let query = el.StyleNumber;
    //     let searchField = ["StyleNumber"];
    //     let res = this.fetchProducts({
    //       app,
    //       searchField,
    //       query,
    //     });
    //   }
    // }
  }

  isValueEmpty(res) {
    let result = "";
    if (!isEmpty(res) && res !== "NaN") {
      result = res;
    }
    // console.log("result :", result);
    return result;
  }

  async getWarehouseValue(item) {
    // console.log("item: ", item);
    console;
    var _id;
    var app;
    var res;
    if (item.productType === "D" && item.SerialNumber && item.InventoryID) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = DiamondSerialApp;
    } else if (
      item.productType === "G" &&
      item.SerialNumber &&
      item.InventoryID
    ) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = GemstoneSerialApp;
    } else if (
      item.productType === "J" &&
      item.SerialNumber &&
      item.InventoryID
    ) {
      _id = item.InventoryID.toString() + item.SerialNumber.toString();
      // console.log("_id: ", _id);
      app = JewelrySerialApp;
    } else {
      console.log("No state warehouse update");
      return ` `;
    }

    var appbase = Appbase({
      url: AppbaseAppUrl,
      app: app,
      credentials: AppbaseCredentials,
    });
    await appbase
      .get({
        type: "_doc",
        id: _id,
      })
      .then((response) => {
        // console.log(" warehouse: ", response._source);
        this.setState((prevState) => ({
          warehouse: [
            ...prevState.warehouse,
            {
              InventoryID: response._source.InventoryID,
              SerialNumber: response._source.SerialNumber,
              Warehouse: response._source.Warehouse,
              SerialStatus: response._source.SerialStatus,
              IsVirtual: response._source.IsVirtual,
              IsPhantom: response._source.IsPhantom,
            },
          ],
        }));
      })
      .catch((error) => {
        // console.log("error while appbase fetch", error);
        // return ``;
      });

    // console.log("res: ", res);
    // return <span>{res}</span>;
  }

  handleMouseEnter(e) {
    if (isMobileOnly) {
      return;
    }
    e.preventDefault();
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    // console.log("share node : ", shareNode);
    serialNode.classList.add(
      "product-index_container",
      "pos-relative",
      "serial-hovered"
    );
    shareNode.classList.add(
      "pos-relative",
      "es__bordered-box",
      "share-hovered"
    );
  }
  handleMouseLeave(e) {
    if (isMobileOnly) {
      return;
    }
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    serialNode.classList.remove("serial-hovered");
    shareNode.classList.remove("share-hovered");
  }

  handleQtyChange(el, qty) {
    this.props.updateQuantity({
      item: el,
      qty: qty,
    });
  }
  handleInternalNotes(el, e) {
    this.props.updateInternalNotes({
      item: el,
      note: e.target.value,
    });
  }
  handlePriceVisibility(e, el) {
    this.props.updatePriceVisibility({
      item: el,
      visibility: e.target.value,
    });
  }
  handleLinkVisibility(e, el) {
    this.props.updateLinkVisibility({
      item: el,
      visibility: e.target.value,
    });
  }
  handleAssetOrMemo(e, el) {
    this.props.setAssetOrMemo({
      item: el,
      value: e.target.value,
    });
  }
  showWebImage(img) {
    var src =
      "https://cdn4.kwiat.com/source-images/web/altviews/" +
      img.replace(/\.[^.]*$/, "") +
      "-altview@2x.jpg";
    return src;
  }
  showimage(image) {
    var str, img;
    if (image && image != null) {
      let searchimage;
      searchimage = image;
      str = searchimage.split("\\");
      searchimage = str[str.length - 1].replace(/icon/g, "search");
      searchimage = searchimage.replace(/Icon/g, "search");
      img = "https://cdn.kwiat.com/source-images/search/" + searchimage;
    } else {
      img = "";
    }
    return img;
  }
  thumbnailImage(res) {
    var src = false;
    if (
      res.SerialVideoLink &&
      res.SerialVideoLink.includes("/v360/") &&
      res.IconImageName &&
      res.IconImageName.includes("still")
    ) {
      var productId = res.SerialVideoLink.match("imaged/(.*)/")[1];
      src = `https://cdn.kwiat.com/kwiat/v360/imaged/${productId}/still.jpg`;
    } else if (res.WebImage1) {
      src = this.showWebImage(res.WebImage1);
    } else if (res.IconImageName) {
      src = this.showimage(res.IconImageName);
    }
    return src;
  }
  showZoomImage(res, eventPosition) {
    function showWebImage(img) {
      var src = "https://cdn4.kwiat.com/source-images/web/original/" + img;
      return src;
    }
    function showimage(image) {
      let img,
        str = "";
      if (image && image != null) {
        let searchimage;
        searchimage = image;
        str = searchimage.split("\\");
        searchimage = str[str.length - 1];
        img = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      } else {
        img = "";
      }
      return img;
    }
    const webImgName = (img) => img.replace(/ /g, "");
    const largeImgName = (img) => {
      var str = img.split("\\");
      return str[str.length - 1];
    };

    var imgArr = [];
    if (eventPosition === "onWebImageIcon") {
      imgArr.push({
        original: showWebImage(res.WebImage1),
        thumbnail: showWebImage(res.WebImage1),
        imgName: webImgName(res.WebImage1),
      });
    } else {
      if (res.LargeImageName) {
        imgArr.push({
          original: showimage(res.LargeImageName),
          thumbnail: showimage(res.LargeImageName),
          imgName: largeImgName(res.LargeImageName),
        });
      }
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field]),
          });
        }
      }
    }
    this.setState({
      showImageModal: true,
      zoomImage: imgArr,
    });
  }
  hideImageModal() {
    this.setState({
      showImageModal: false,
    });
  }
  handleHideDetails() {
    this.setState({
      hideDetails: !this.state.hideDetails,
    });
  }

  handleTogglePriceInput(e) {
    var inputNode = e.currentTarget.parentElement.parentElement.querySelector(
      "input"
    );
    var valueNode = e.currentTarget.parentElement.parentElement.querySelector(
      "p"
    );
    if (inputNode.style.display === "none") {
      inputNode.style.display = "block";
      inputNode.focus();
    } else {
      inputNode.style.display = "none";
    }
    if (valueNode.style.display === "none") {
      valueNode.style.display = "block";
    } else {
      valueNode.style.display = "none";
    }
  }

  handleInputBlur(e) {
    var valueNode = e.currentTarget.parentElement.parentElement.querySelector(
      "p"
    );
    e.currentTarget.style.display = "none";
    valueNode.style.display = "block";
  }
  handleKeyDown(e) {
    if (e.key === ".") {
      e.preventDefault();
      return;
    }
  }

  handlePriceChange(item) {
    let { data } = this.state;
    console.log("data :", data);
    console.log("item", item);
    let updatedItems = data.map((el) => {
      if (item.SerialNumber && item.SerialNumber === el.SerialNumber) {
        el.RetailPrice = item.RetailPrice;
        el.WholesalePrice = item.WholesalePrice;
        return el;
      }
      if (item.StyleNumber && item.StyleNumber === el.StyleNumber) {
        el.RetailPrice = item.RetailPrice;
        el.WholesalePrice = item.WholesalePrice;
        return el;
      }
      return el;
    });
    console.log("updatedItems: ", updatedItems);
    this.props.openCartItems(updatedItems);
    this.setState((prevState) => ({
      differenceItemPrices: prevState.differenceItemPrices.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(item)
      ),
    }));
    toast.success("Price Updated!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1500,
      pauseOnHover: false,
    });
  }

  handlePriceEditChange(e, priceCheck, itemToEdit) {
    let { data } = this.state;
    if (priceCheck === "retail") {
      let updatedItems = data.map((el) => {
        if (JSON.stringify(el) === JSON.stringify(itemToEdit)) {
          el.RetailPrice =
            parseFloat(e.target.value)
              .toFixed(2)
              .toString() || "0";
          return el;
        } else {
          return el;
        }
      });
      this.props.openCartItems(updatedItems);
    } else {
      let updatedItems = data.map((el) => {
        if (JSON.stringify(el) === JSON.stringify(itemToEdit)) {
          el.WholesalePrice =
            parseFloat(e.target.value)
              .toFixed(2)
              .toString() || "0";
          return el;
        } else {
          return el;
        }
      });
      this.props.openCartItems(updatedItems);
    }
  }
  showDiaImage(image) {
    var img, str;
    if (image && image != null) {
      img = image;
      str = img.split("=");
      if (str[1] && str[1].trim() === "") {
        img = "";
        str = "";
      }
    } else {
      img = "";
      str = "";
    }
    return img;
  }
  // diaIcon(shape) {
  //   var img;
  //   if (shape && shape != null) {
  //     img =
  //       "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
  //       shape +
  //       ".svg";
  //   }
  //   return img;
  // }
  diaIcon(data) {
    var img;
    if (data && data != null) {
      img = data;
    }
    return img;
  }

  render() {
    let that = this;
    const {
      data,
      hideDetails,
      customerClass,
      contact,
      customer,
      showImageModal,
      zoomImage,
      warehouse,
      holdNoteItem,
      showStockPopUpModal,
      checked,
    } = that.state;
    let { selectedItems } = this.props;
    // console.log("selected Items", selectedItems);
    // console.log("data", data);
    // console.log("warehouse: ", warehouse);
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
        that.props.openCartItems(data);
        that.props.setSortOption([]);
      },
      nodeSelector: ".drag__selector",
      handleSelector: ".drag__class",
      lineClassName: "drag__line",
    };

    return (
      <div className="basket__items__container">
        {data.length > 0 && (
          <div className="toggle__item__desc">
            <SortItems />
            <div
              className="select_all_items"
            // style={
            //   this.props.basketInputObj.customer.CustomerClass
            //     ? { marginLeft: "-20%" }
            //     : { marginLeft: "-27%" }
            // }
            >
              <input
                type="checkbox"
                id="Select-All-Items"
                checked={
                  data.filter((item) => item.itemSelected === false).length
                    ? false
                    : true
                }
                onChange={(e) => this.handleAllItems(e)}
              />
              <label htmlFor="Select-All-Items">Select All</label>
            </div>
            <div className="basket_customer_class">
              {this.props.basketInputObj.customer.CustomerClass}
            </div>
            <div
              className="basket_check_price"
            // style={
            //   this.props.basketInputObj.customer.CustomerClass
            //     ? { marginRight: "-18%" }
            //     : { marginRight: "-25%" }
            // }
            >
              {/* holdNotesAccess */}
              {this.props.basketInputObj.holdNotesAccess &&
                data &&
                data.length !== 0 && (
                  <button
                    className="btn"
                    onClick={() =>{
                      this.setState((prev)=>{
                        return {...prev, bulkNoteClicked: true}
                      })
                      this.handleHoldNotesModals(
                        // res.InventoryID.toString() +
                        //   res.SerialNumber.toString(),
                        "",
                        true,
                        "createHoldNotes"
                      )
                      }
                    }
                  >
                    Bulk Hold Notes
                  </button>
                )}
              <button className="btn" onClick={this.handlePriceCheck}>
                Check Price
              </button>
            </div>
            <div className="sort__less-details">
              <button className="btn" onClick={this.handleHideDetails}>
                {hideDetails ? "Show Details" : "Hide Details"}
              </button>
            </div>
          </div>
        )}
        <div className="items__container">
          <div className="basket__items__grouped">
            {hideDetails ? (
              <ItemsCondensedMode
                thumbnailImage={(el) => this.thumbnailImage(el)}
              />
            ) : (
              <ReactDragListView {...dragProps}>
                <div className="more__details__container">
                  {data.map((el, i) => {
                    // console.log("el : ", el);
                    return (
                      <div
                        className="basket__items__list drag__selector"
                        key={i}
                        onMouseEnter={(e) => this.handleMouseEnter(e)}
                        onMouseLeave={(e) => this.handleMouseLeave(e)}
                      >
                        {/* <input
                          type="checkbox"
                          checked={el.itemSelected ? el.itemSelected : false}
                          onChange={(e) =>
                            this.props.handleSelectedItems(e, el)
                          }
                        /> */}
                        <div className="basket__item__header">
                          <span className="drag__class">
                            <img
                              src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/drag-reorder.png"
                              alt="drag n drop"
                            />
                          </span>
                          <input
                            type="checkbox"
                            checked={el.itemSelected ? el.itemSelected : false}
                            onChange={(e) =>
                              this.props.handleSelectedItems(e, el)
                            }
                          />
                          <div className="item__serial-style__number">
                            {el.SerialNumber ? (
                              <h5>
                                <span
                                  className="item__serial__number iframe-link"
                                  onClick={() =>
                                    this.props.toggleIframeModal({
                                      show: true,
                                      url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${el.StyleNumber}&LotSerialNbr=${el.SerialNumber}`,
                                    })
                                  }
                                >
                                  {el.SerialNumber}
                                </span>
                                <span className="serial__style__interval">
                                  |
                                </span>
                                <span
                                  className="item__style__number iframe-link"
                                  onClick={() =>
                                    this.props.toggleIframeModal({
                                      show: true,
                                      url: `${BaseURL}/pages/in/in202500.aspx?PopupPanel=On&InventoryCD=${el.StyleNumber}`,
                                    })
                                  }
                                >
                                  {el.StyleNumber}
                                </span>
                              </h5>
                            ) : (
                              <h5>
                                <span
                                  className="item__style__number iframe-link"
                                  onClick={() =>
                                    this.props.toggleIframeModal({
                                      show: true,
                                      url: `${BaseURL}/pages/in/in202500.aspx?PopupPanel=On&InventoryCD=${el.StyleNumber}`,
                                    })
                                  }
                                >
                                  {el.StyleNumber}
                                </span>
                              </h5>
                            )}
                          </div>
                          <div className="item_warehouse_value">
                            <h5>
                              <span className="item_warehouse_value">
                                {/* {warehouse.map((value) =>
                                  value.InventoryID === el.InventoryID &&
                                  value.SerialNumber === el.SerialNumber
                                    ? 
                                    `${this.isValueEmpty(value.Warehouse)}
                                  ${
                                    value.Warehouse !== value.SerialStatus &&
                                    this.isValueEmpty(value.Warehouse) &&
                                    this.isValueEmpty(value.SerialStatus)
                                      ? "/"
                                      : ""
                                  }
                                    ${
                                      value.IsVirtual === "1"
                                        ? "Virtual - "
                                        : ""
                                    }
                                    ${
                                      value.IsPhantom === "1"
                                        ? "Phantom - "
                                        : ""
                                    }
                                    ${
                                      value.Warehouse !== value.SerialStatus
                                        ? this.isValueEmpty(value.SerialStatus)
                                        : ""
                                    }`
                                    : ``
                                )} */}

                                {el.SerialNumber
                                  ? this.isValueEmpty(el.Warehouse) &&
                                    el.Warehouse === "MEMO"
                                    ? `${el.Warehouse} - ${el.StatusCustomer}`
                                    : el.Warehouse
                                  : ""}
                                {el.SerialNumber
                                  ? el.Warehouse !== el.SerialStatus &&
                                    this.isValueEmpty(el.Warehouse) &&
                                    this.isValueEmpty(el.SerialStatus)
                                    ? "/"
                                    : ""
                                  : ""}
                                {el.SerialNumber
                                  ? el.IsVirtual === "1"
                                    ? "Virtual - "
                                    : ""
                                  : ""}
                                {el.SerialNumber
                                  ? el.IsPhantom === "1"
                                    ? "Phantom - "
                                    : ""
                                  : ""}
                                {el.SerialNumber
                                  ? el.Warehouse !== el.SerialStatus
                                    ? this.isValueEmpty(el.SerialStatus)
                                    : ""
                                  : ""}
                              </span>
                            </h5>
                          </div>
                          <div className="item__internal-note__input">
                            <input
                              type="text"
                              placeholder="Item Level Note Here..."
                              value={el.InternalNote}
                              onChange={(e) => this.handleInternalNotes(el, e)}
                            />
                          </div>
                        </div>
                        <div className="basket__item__box-wrapper">
                          <div className="item__quantity__info">
                            {/* <span className="quantity__title">QTY</span>
                            <input
                              type="number"
                              min="1"
                              value={el.quantity}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) =>
                                this.handleQtyChange(el, e.target.value)
                              }
                            /> */}
                            <div className="product-index_container pos-relative">
                              {i < 9 ? `0${i + 1}` : i + 1}
                            </div>
                            <div className="es__product__hovered pos-relative es__bordered-box">
                              <img
                                src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/remove-from-basket.png"
                                alt="remove product"
                                onClick={() => {
                                  this.props.removeFromCart(el);
                                  toast.success("Removed from basket !", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                    pauseOnHover: false,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="item__thumbnail__box">
                            {el.productType === "J" ||
                              el.productType === "G" ? (
                              this.thumbnailImage(el) ? (
                                <img
                                  className="product-thumbnail"
                                  src={this.thumbnailImage(el)}
                                  onClick={() =>
                                    this.showZoomImage(el, "onImage")
                                  }
                                  alt="product"
                                />
                              ) : (
                                <ReactImageFallback
                                  fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                                  alt=""
                                  className="product-thumbnail"
                                />
                              )
                            ) : el.IconImageFileID ? (
                              <img
                                className="product-image-icon"
                                src={this.showDiaImage(el.IconImageFileID)}
                                alt="product"
                              />
                            ) : (
                              // <img
                              //   className="dia-image-icon"
                              //   src={this.diaIcon(el.Shape)}
                              //   alt="product"
                              // />
                              <img
                                className="dia-image-icon"
                                src={this.diaIcon(el.search_dia_image_url)}
                                alt="product"
                              />
                            )}
                          </div>
                          <div
                            className="item__description__box"
                            style={
                              (this.props.basketInputObj.customer
                                .CustomerClass &&
                                this.props.basketInputObj.customer
                                  .CustomerClass === "RETAIL") ||
                                !this.props.basketInputObj.customer.CustomerClass
                                ? { width: "67%" }
                                : {}
                            }
                          >
                            <h5 className="product-stylenumber_heading product-dark_title_color">
                              <div>
                                <span
                                  className="iframe-link"
                                // onClick={() =>
                                //   this.props.toggleIframeModal({
                                //     show: true,
                                //     url: `${BaseURL}/pages/in/in202500.aspx?PopupPanel=On&InventoryCD=${res.StyleNumber}`,
                                //   })
                                // }
                                >
                                  {/* {isValueEmpty(res.StyleNumber)} */}
                                  {el.StyleNumber ? el.StyleNumber : ""}
                                </span>
                                {/* {res.IsTiara === "1" || res.IsKWCushion === "1" ? (
                          <span className="es__istiara" />
                        ) : (
                          ""
                        )}
                        {res.IsFLRound === "1" ? (
                          <span className="es__isfl" />
                        ) : (
                          ""
                        )}
                        {res.IsFLCushion === "1" ? (
                          <span className="es__isfl" />
                        ) : (
                          ""
                        )}
                        {isValueEmpty(res.EditorialVideo) ? (
                          <img
                            onClick={() =>
                              this.props.toggleIframeModal({
                                show: true,
                                url: "",
                                editorial: res.EditorialVideo,
                              })
                            }
                            className="iframe-link"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                            height="14px"
                            width="20px"
                            alt="product"
                          />
                        ) : (
                          ""
                        )}
                        {isValueEmpty(res.StyleVideoLink) ? (
                          <img
                            onClick={() =>
                              this.props.toggleIframeModal({
                                show: true,
                                url: res.StyleVideoLink,
                                editorial: "",
                              })
                            }
                            className="iframe-link"
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                            height="14px"
                            width="20px"
                            alt="product"
                          />
                        ) : (
                          ""
                        )}
                        {res.VendorRefDisplay && (
                          <span className="iframe-link">
                            ({res.VendorRefDisplay})
                          </span>
                        )}

                        {res.FileIdNames && (
                          <img
                            src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                            height="14px"
                            width="20px"
                            onClick={() => this.handleShowFileIdNames(res)}
                          />
                        )} */}
                              </div>
                              {el.KTDpdfurl !== "" && (
                                <div style={{ marginLeft: "auto" }}>
                                  <span>
                                    {el.KTDpdfurl && (
                                      <img
                                        src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/ktd-icon.png"
                                        height="16px"
                                        width="33px"
                                        onClick={() =>
                                          this.props.toggleIframeModal({
                                            show: true,
                                            url: el.KTDpdfurl,
                                          })
                                        }
                                      />
                                    )}
                                  </span>
                                </div>
                              )}
                              {el.IsMTS === "1" && (
                                <span>
                                  <img
                                    src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/mts-icon.png"
                                    height="16px"
                                    width="33px"
                                    style={{ cursor: "inherit" }}
                                  />
                                </span>
                              )}
                            </h5>
                            <table width="100%">
                              <tbody>
                                {el.productType === "J" ? (
                                  <tr>
                                    <td
                                      colSpan="4"
                                      className="item__description"
                                    >
                                      {el.ShortDescription
                                        ? `${el.ShortDescription}`
                                        : `${el.Description}`}
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td>
                                      {el.ShapeDisplay}
                                      {el.productType === "G" &&
                                        ` - ${el.GemstoneType}`}
                                    </td>
                                    <td>
                                      {el.productType === "D" &&
                                        el.Polish &&
                                        el.Symmetry &&
                                        `${el.Polish}/${el.Symmetry}`}
                                    </td>
                                    <td
                                      className={
                                        el.Giapdfurl
                                          ? "lab__report--container iframe-link"
                                          : "lab__report--container"
                                      }
                                      onClick={() =>
                                        this.props.toggleIframeModal({
                                          show: true,
                                          url: el.Giapdfurl,
                                        })
                                      }
                                    >
                                      <span>
                                        {el.productType === "D"
                                          ? `${el.GradingLab
                                            ? el.GradingLab + " - "
                                            : ""
                                          } ${el.LabReportNbr
                                            ? el.LabReportNbr
                                            : ""
                                          }`
                                          : el.GradingLab}
                                      </span>
                                      {el.Giapdfurl ? (
                                        <img
                                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                          alt="icon"
                                          className="pdf-icon cursor-pointer"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    {el.productType === "J"
                                      ? el.ItemSubtype
                                      : el.productType === "D" &&
                                        el.DiamondCaratWeight
                                        ? `${Number(
                                          el.DiamondCaratWeight
                                        ).toFixed(2)}cts`
                                        : `${Number(el.CaratWeight).toFixed(
                                          2
                                        )}cts`}
                                  </td>
                                  <td>
                                    {el.productType === "J" ? (
                                      <span className="">
                                        {el.DiamondCarats
                                          ? el.DiamondCarats + " cts dia"
                                          : ``}
                                      </span>
                                    ) : el.productType === "D" &&
                                      el.StoneFluoresence &&
                                      el.StoneFluoresenceColor ? (
                                      `${el.StoneFluoresence || ""}
                                        ${el.StoneFluoresenceColor || ""}`
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {el.productType === "J"
                                      ? el.Maker
                                      : el.productType === "G" && (
                                        <span
                                          className={
                                            el.Giapdfurl
                                              ? "lab__report--container iframe-link"
                                              : "lab__report--container"
                                          }
                                          onClick={() =>
                                            this.props.toggleIframeModal({
                                              show: true,
                                              url: el.Giapdfurl,
                                            })
                                          }
                                        >
                                          {el.LabReportNbr}
                                          {el.Giapdfurl ? (
                                            <img
                                              src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                              alt="icon"
                                              className="pdf-icon cursor-pointer"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      )}
                                  </td>
                                  <td>{el.RingSize}</td>
                                </tr>
                                <tr>
                                  <td>
                                    {el.productType === "J"
                                      ? el.Collection
                                      : el.CountryOfOrigin}
                                    {el.productType === "D" &&
                                      (el.IsRough === "0" || el.CertReceived === "1" )
                                      ? `${el.DiamondColorRange
                                        ? el.DiamondColorRange + "/"
                                        : el.FancyColorGIA || ""
                                      }${el.DiamondClarityRange || ""}
                                        `
                                      : `${el.MTSDiamondColorRange
                                        ? el.MTSDiamondColorRange + "/"
                                        : ""
                                      }${el.MTSDiamondClarityRange || ""}
                                    `}
                                    {/*{el.productType === "J" ? el.Collection: `${el.CountryOfOrigin}`}*/}
                                  </td>
                                  <td>
                                    {el.Color ? el.Color + " / " : ""}
                                    {el.Clarity}
                                  </td>
                                  <td>{el.Period}</td>
                                  <td>
                                    {el.Length}
                                    {el.BangleSize}
                                    {el.Diameter}
                                    {el.HoopDiameter}
                                  </td>

                                  <td />
                                </tr>
                                {/* keep commented code<tr>
                                  <td>
                                    {el.productType === "J"
                                      ? el.Metal
                                      : el.productType === "D"
                                      ? el.DiamondCut
                                      : ""}
                                  </td>
                                  <td>
                                    {el.DiamondDetails || ""}
                                    {el.DiamondDetails && el.ColorComment
                                      ? " & "
                                      : ""}
                                    {el.ColorComment || ""}
                                  </td>
                                  <td />
                                  <td>{el.WidthOD}</td>
                                  <td>
                                    <span
                                      className="show-more"
                                      onClick={() => {
                                        this.props.showMoreModal(el);
                                      }}
                                    >
                                      +
                                    </span>
                                  </td>
                                    </tr>*/}
                                <tr>
                                  <td
                                    className={
                                      el.GCalpdfurl
                                        ? "lab__report--container iframe-link"
                                        : "lab__report--container"
                                    }
                                    onClick={() =>
                                      this.props.toggleIframeModal({
                                        show: true,
                                        url: el.GCalpdfurl,
                                      })
                                    }
                                  >
                                    <span>
                                      {el.productType === "D"
                                        ? `${el.GCalNbr ? "Gcal" + " - " : ""
                                        } ${el.GCalNbr ? el.GCalNbr : ""}`
                                        : el.GCalNbr}
                                    </span>
                                    {el.GCalpdfurl ? (
                                      <img
                                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/pdf.svg"
                                        alt="icon"
                                        className="pdf-icon cursor-pointer"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="holding-details pos-relative mobile-view">
                            <div className="product-info_box diamonds-status-pricing-hold-info_box">
                              <h5 className="product-info_box_heading product-light_title_color">
                                <span
                                // onClick={() =>
                                //   this.props.toggleIframeModal({
                                //     show: true,
                                //     url: `${BaseURL}/pages/kw/kw801004.aspx?PopupPanel=On&InventoryID=${el.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
                                //   })
                                // }
                                >
                                  Hold Notes
                                </span>
                              </h5>
                              {/* holdNotesAccess */}
                              {this.props.basketInputObj.holdNotesAccess && (
                                <div class="hold_notes_actions">
                                  {!el._id.toString().includes("JOB") &&
                                    el.HoldNoteID && (
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip
                                            className="tooltip"
                                            id="Edit"
                                          >
                                            Edit
                                          </Tooltip>
                                        }
                                        placement="top"
                                      >
                                        <span
                                          onClick={() => {
                                            this.setState({
                                              showHoldNotesEditModal: true,
                                            });
                                            this.handleHoldNotesModals(
                                              "",
                                              true,
                                              "createHoldNotes",
                                              el
                                            );
                                          }}
                                        >
                                          <i
                                            class="fa fa-pencil-square-o"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  {!el._id.toString().includes("JOB") && (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip
                                          className="tooltip"
                                          id="Create"
                                        >
                                          Create
                                        </Tooltip>
                                      }
                                      placement="top"
                                    >
                                      <span
                                        onClick={() =>
                                          this.handleHoldNotesModals(
                                            el.InventoryID.toString() +
                                            el.SerialNumber.toString(),
                                            true,
                                            "createHoldNotes",
                                            el
                                          )
                                        }
                                      >
                                        +
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                  {el.HoldNoteID &&
                                    !el._id.toString().includes("JOB") && (
                                      <>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip
                                              className="tooltip"
                                              id="Archive"
                                            >
                                              Archive
                                            </Tooltip>
                                          }
                                          placement="top"
                                        >
                                          <span
                                            onClick={() => {
                                              if (el?.index === "kwfl-acumatica-catalog-v7-prod-diamondserial2") {
                                                if (el?.MTSCustomer) {
                                                  this.setState({
                                                    ...this.state,
                                                    archivePopUp: true,
                                                    itemToArchive: el
                                                  });
                                                } else {
                                                  this.handleHoldNoteArchive(el)
                                                }
                                              }else if (el?.index === "kwfl-acumatica-catalog-v7-prod-jewelryserial2") {
                                                if (el?.MTSCustomerExists === "Yes") {
                                                  this.setState({
                                                    ...this.state,
                                                    archivePopUp: true,
                                                    itemToArchive: el
                                                  });
                                                } else {
                                                  this.handleHoldNoteArchive(el)
                                                }
                                              }
                                              else{
                                                this.setState({
                                                  ...this.state,
                                                  archivePopUp: true,
                                                  itemToArchive: el
                                                });
                                              }
                                              //this.handleHoldNoteArchive(el)
                                            }}
                                          >
                                            x
                                          </span>
                                        </OverlayTrigger>
                                        <Modal
                                          show={this.state.archivePopUp}
                                          onHide={() => { }}
                                          animation={false}
                                          // dialogClassName="show-more_modal-dailog"
                                          className="hold_notes_create_modal"
                                          aria-labelledby="contained-modal-title-vcenter"
                                          style={{
                                            position: "fixed",
                                            left: "20%",
                                            top: "20%",
                                            width: "60%",
                                          }}
                                        >
                                          <Modal.Body>
                                            <div>
                                              <p>
                                                Do you want to blank out MTS
                                                Customer ?
                                              </p>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "end",
                                                  alignItems: "end",
                                                }}
                                              >
                                                <Button
                                                  variant="secondary"
                                                  style={{ marginRight: "2vw" }}
                                                  onClick={() => {
                                                    this.setState(
                                                      {
                                                        ...this.state,
                                                        archivePopUp: false,
                                                        blankMTSCustomer: false,
                                                      },
                                                      () => {
                                                        if (this.state.itemToArchive)
                                                          this.handleHoldNoteArchive(
                                                            this.state.itemToArchive
                                                          );
                                                      }
                                                    );
                                                  }}
                                                >
                                                  NO
                                                </Button>
                                                <Button
                                                  variant="secondary"
                                                  onClick={() => {
                                                    this.setState(
                                                      {
                                                        ...this.state,
                                                        archivePopUp: false,
                                                        blankMTSCustomer: true,
                                                      },
                                                      () => {
                                                        if (this.state.itemToArchive)
                                                          this.handleHoldNoteArchive(
                                                            this.state.itemToArchive
                                                          );
                                                      }
                                                    );
                                                  }}
                                                >
                                                  YES
                                                </Button>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </>
                                    )}
                                  {!el._id.toString().includes("JOB") && (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip className="tooltip" id="View">
                                          View
                                        </Tooltip>
                                      }
                                      placement="top"
                                    >
                                      <span
                                        onClick={(e) =>
                                          this.handleHoldNotesModals(
                                            el.InventoryID.toString() +
                                            el.SerialNumber.toString(),
                                            true,
                                            "archivedHoldNotes",
                                            ""
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-history"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              )}
                              <div className="product-info_background">
                                <section className="responsive-hold-notes">
                                  <div className="product-details_lcol">
                                    <div className="unknown product-details-label">
                                      <ShowMore
                                        lines={1}
                                        more="&#43;"
                                        less="&#8722;"
                                        anchorClass=""
                                      >
                                        <span className="entered-by">
                                          {this.isValueEmpty(
                                            el.HoldEnteredByName
                                          )}
                                        </span>
                                      </ShowMore>
                                    </div>
                                  </div>
                                  <div className="product-details_mcol">
                                    <div className="unknown product-details-label">
                                      <ShowMore
                                        lines={1}
                                        more="&#43;"
                                        less="&#8722;"
                                        anchorClass=""
                                      >
                                        <span className="customer-name">
                                          {this.isValueEmpty(
                                            el.HoldCustomerName
                                          )}
                                        </span>
                                      </ShowMore>
                                    </div>
                                  </div>
                                </section>
                                <section className="responsive-hold-notes">
                                  <div className="product-details_lcol">
                                    <div className="unknown product-details-label">
                                      <span className="hold-date">
                                        {this.isValueEmpty(el.HoldDate)
                                          ? moment(
                                            new Date(`${el.HoldDate}`)
                                          ).format("MM/DD/YYYY")
                                          : ""}
                                      </span>
                                      <span className="product-details-view-label">
                                        {this.isValueEmpty(el.HoldDate)
                                          ? " Hold Date"
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="product-details_mcol">
                                    <div className="unknown product-details-label">
                                      <span className="release-date">
                                        {this.isValueEmpty(el.ReleaseDate)
                                          ? moment(
                                            new Date(`${el.ReleaseDate}`)
                                          ).format("MM/DD/YYYY")
                                          : ""}
                                      </span>
                                      <span className="product-details-view-label">
                                        {this.isValueEmpty(el.ReleaseDate)
                                          ? " Release Date"
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </section>
                                <div className="holding-description">
                                  <ShowMore
                                    lines={3}
                                    more="&#43;"
                                    less="&#8722;"
                                    anchorClass="holding-description_accordion"
                                  >
                                    <span className="holding-con">
                                      {this.isValueEmpty(el.HoldText)}
                                    </span>
                                  </ShowMore>
                                </div>
                                {el.IsHoldSold === "1" && (
                                  <div className="is_hold_sold">SOLD</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="item__price__box"
                            style={
                              (this.props.basketInputObj.customer
                                .CustomerClass &&
                                this.props.basketInputObj.customer
                                  .CustomerClass === "RETAIL") ||
                                !this.props.basketInputObj.customer.CustomerClass
                                ? { width: "17%" }
                                : {}
                            }
                          >
                            <h5 className="product-info_box_heading product-light_title_color">
                              <span
                                className="pricing-title"
                                onClick={(e) =>
                                  this.handleStockPopUpModal(true, el)
                                }
                              >
                                Pricing
                              </span>
                            </h5>
                            <table width="100%">
                              <tbody>
                                <tr>
                                  <td className="price__value">
                                    <input
                                      className="basket__item__edit"
                                      type="number"
                                      style={{ display: "none" }}
                                      // value={
                                      //   parseFloat(el.RetailPrice).toFixed() ||
                                      //   0
                                      // }
                                      value={
                                        isNaN(
                                          parseFloat(el.RetailPrice).toFixed()
                                        ) === true
                                          ? 0
                                          : parseFloat(
                                            el.RetailPrice
                                          ).toFixed() || 0
                                      }
                                      onKeyDown={(e) => this.handleKeyDown(e)}
                                      onChange={(e) =>
                                        this.handlePriceEditChange(
                                          e,
                                          "retail",
                                          el
                                        )
                                      }
                                      onBlur={this.handleInputBlur}
                                    />
                                    <p>
                                      {currencyFormatter.format(
                                        `${el.RetailPrice}`,
                                        {
                                          code: "USD",
                                          precision: 0,
                                        }
                                      )}
                                    </p>
                                    <p className="price__label">Retail</p>
                                  </td>
                                  <td rowSpan="1" className="edit__price__icon">
                                    {!customer.Customer &&
                                      !customer.CustomerClass ? (
                                      <img
                                        className="edit__price"
                                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                        alt="price edit"
                                        onClick={(e) =>
                                          this.handleTogglePriceInput(
                                            e,
                                            "retail"
                                          )
                                        }
                                      />
                                    ) : customerClass === "RETAIL" ||
                                      customerClass === "TRADE" ||
                                      (contact.ContactId &&
                                        customerClass === "WHOLESALE") ||
                                      customerClass === "WHOLESALE" ? (
                                      <img
                                        className="edit__price"
                                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                        alt="price edit"
                                        onClick={(e) =>
                                          this.handleTogglePriceInput(
                                            e,
                                            "retail"
                                          )
                                        }
                                      />
                                    ) : (
                                      <img
                                        className="edit__price"
                                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                        alt="price edit"
                                        style={{ opacity: "0.3" }}
                                      />
                                    )}
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td className="price__label">Retail</td>
                                </tr> */}
                                {this.props.basketInputObj.showWholesale && (
                                  <tr>
                                    <td className="price__value">
                                      <input
                                        className="basket__item__edit"
                                        type="number"
                                        // value={
                                        //   parseFloat(
                                        //     el.WholesalePrice
                                        //   ).toFixed() || 0
                                        // }
                                        value={
                                          isNaN(
                                            parseFloat(
                                              el.WholesalePrice
                                            ).toFixed()
                                          ) === true
                                            ? 0
                                            : parseFloat(
                                              el.WholesalePrice
                                            ).toFixed() || 0
                                        }
                                        style={{ display: "none" }}
                                        onKeyDown={(e) => this.handleKeyDown(e)}
                                        onChange={(e) =>
                                          this.handlePriceEditChange(
                                            e,
                                            "wholesale",
                                            el
                                          )
                                        }
                                        onBlur={this.handleInputBlur}
                                      />
                                      <p>
                                        {(el.WholesalePrice &&
                                          currencyFormatter.format(
                                            `${el.WholesalePrice}`,
                                            {
                                              code: "USD",
                                              precision: 0,
                                            }
                                          )) ||
                                          "$0"}
                                      </p>
                                      <p className="price__label">Wholesale</p>
                                    </td>
                                    <td
                                      rowSpan="2"
                                      className="edit__price__icon"
                                    >
                                      {!customer.Customer &&
                                        !customer.CustomerClass ? (
                                        <img
                                          className="edit__price"
                                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                          alt="price edit"
                                          onClick={(e) =>
                                            this.handleTogglePriceInput(
                                              e,
                                              "wholesale"
                                            )
                                          }
                                        />
                                      ) : customerClass === "WHOLESALE" ? (
                                        <img
                                          className="edit__price"
                                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                          alt="price edit"
                                          onClick={(e) =>
                                            this.handleTogglePriceInput(
                                              e,
                                              "wholesale"
                                            )
                                          }
                                        />
                                      ) : (
                                        <img
                                          className="edit__price"
                                          src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/edit-price.png"
                                          alt="price edit"
                                          style={{ opacity: "0.3" }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                )}
                                {/* <tr>
                                  <td className="price__label">Wholesale</td>
                                </tr> */}
                              </tbody>
                            </table>
                            {this.props.basketInputObj.customer.CustomerClass &&
                              this.props.basketInputObj.customer.CustomerClass !==
                              "RETAIL" ? (
                              <select
                                value={el.assetOrmemo || ""}
                                onChange={(e) => this.handleAssetOrMemo(e, el)}
                              >
                                <option value="">Asset/Memo</option>
                                <option value="A">Asset</option>
                                <option value="M">Memo</option>
                              </select>
                            ) : (
                              ``
                            )}
                            {this.props.basketInputObj.showWholesale && (
                              <select
                                style={
                                  (this.props.basketInputObj.customer
                                    .CustomerClass &&
                                    this.props.basketInputObj.customer
                                      .CustomerClass === "RETAIL") ||
                                    !this.props.basketInputObj.customer
                                      .CustomerClass
                                    ? { width: "46%" }
                                    : {}
                                }
                                id="price__visibility__select"
                                value={el.priceVisibility || "Default"}
                                onChange={(e) =>
                                  this.handlePriceVisibility(e, el)
                                }
                              >
                                <option value="Default">Price </option>
                                <option value="Hide Retail Price">
                                  Hide Retail
                                </option>
                                <option value="Hide Wholesale Price">
                                  Hide Wholesale
                                </option>
                                <option value="Hide Both">Hide Both</option>
                              </select>
                            )}
                            <select
                              style={
                                (this.props.basketInputObj.customer
                                  .CustomerClass &&
                                  this.props.basketInputObj.customer
                                    .CustomerClass === "RETAIL") ||
                                  !this.props.basketInputObj.customer
                                    .CustomerClass
                                  ? { width: "46%" }
                                  : {}
                              }
                              // id="price__visibility__select"
                              value={el.linkVisibility || "Default"}
                              onChange={(e) => this.handleLinkVisibility(e, el)}
                            >
                              <option value="Default">Link</option>
                              <option value="Hide Link">Hide Links</option>
                              {/* <option value="Hide Wholesale Price">
                                Hide Wholesale Price
                              </option> */}
                              {/* <option value="Hide Both">Hide Both</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ReactDragListView>
            )}
          </div>
        </div>
        {showImageModal && zoomImage !== null && (
          <ImageModal
            show={showImageModal}
            callback={this.hideImageModal}
            image={zoomImage}
          />
        )}
        <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
          basketItems={this.state.data.filter(
            (item) => item.itemSelected === true
          )}
          handleItemRefresh={this.handleItemRefresh}
          bulkNoteClicked={this.state.bulkNoteClicked}
          handleBulkNoteClick = {(value)=>{
            this.setState((prev)=>{
              return {...prev, bulkNoteClicked: value}
            })
          }}
        />
        {showStockPopUpModal ? (
          holdNoteItem &&
            (holdNoteItem.ProductType === "J" || !holdNoteItem.ProductType) ? (
            <JewelryStockModal
              show={showStockPopUpModal}
              callback={this.handleStockPopUpModal}
              result={holdNoteItem}
              checked={checked}
            />
          ) : holdNoteItem && holdNoteItem.ProductType === "D" ? (
            <DiamondStockModal
              show={showStockPopUpModal}
              callback={this.handleStockPopUpModal}
              result={holdNoteItem}
              checked={checked}
            />
          ) : holdNoteItem && holdNoteItem.ProductType === "G" ? (
            <GemstoneStockModal
              show={showStockPopUpModal}
              callback={this.handleStockPopUpModal}
              result={holdNoteItem}
              checked={checked}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="show_alert_modal"
          centered="true"
          size="sm"
          show={this.state.showPriceCheckModal}
          onHide={() => this.hidePriceCheckModal()}
        >
          <Modal.Header closeButton>
            {this.state.differenceItemPrices.length} item(s) have price
            differences:
          </Modal.Header>
          <Modal.Body>
            <table className="price_check_table">
              <thead>
                <tr>
                  <th>Serial/Style</th>
                  <th>Current Pricing</th>
                  <th>Basket Pricing</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.showPriceCheckModal &&
                  this.state.differenceItemPrices.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.SerialNumber
                          ? item.SerialNumber
                          : item.StyleNumber}
                      </td>
                      <td>
                        {(item.WholesalePrice &&
                          currencyFormatter.format(`${item.WholesalePrice}`, {
                            code: "USD",
                            precision: 0,
                          })) ||
                          "$0"}{" "}
                        /{" "}
                        {(item.RetailPrice &&
                          currencyFormatter.format(`${item.RetailPrice}`, {
                            code: "USD",
                            precision: 0,
                          })) ||
                          "$0"}
                      </td>
                      {/* <td>{`${
                      item.WholesalePrice !== null ? item.WholesalePrice : 0
                    } / ${
                      item.RetailPrice !== null ? item.RetailPrice : 0
                    }`}</td> */}
                      <td>
                        {/* {console.log(
                        "value :",
                        this.state.data.filter((el) => {
                          if (
                            item.SerialNumber &&
                            item.SerialNumber === el.SerialNumber
                          )
                            return el;

                          if (
                            item.StyleNumber &&
                            item.StyleNumber === el.StyleNumber
                          )
                            return el;
                        })[0].WholesalePrice
                      )} */}
                        {`${this.state.data.filter((el) => {
                          if (
                            item.SerialNumber &&
                            item.SerialNumber === el.SerialNumber
                          )
                            return el;

                          if (
                            item.StyleNumber &&
                            item.StyleNumber === el.StyleNumber
                          )
                            return el;
                        })[0].WholesalePrice !== null
                          ? currencyFormatter.format(
                            this.state.data.filter((el) => {
                              if (
                                item.SerialNumber &&
                                item.SerialNumber === el.SerialNumber
                              )
                                return el;

                              if (
                                item.StyleNumber &&
                                item.StyleNumber === el.StyleNumber
                              )
                                return el;
                            })[0].WholesalePrice,
                            {
                              code: "USD",
                              precision: 0,
                            }
                          )
                          : `$0`
                          } /
                        ${this.state.data.filter((el) => {
                            if (
                              item.SerialNumber &&
                              item.SerialNumber === el.SerialNumber
                            )
                              return el;

                            if (
                              item.StyleNumber &&
                              item.StyleNumber === el.StyleNumber
                            )
                              return el;
                          })[0].RetailPrice !== null
                            ? currencyFormatter.format(
                              this.state.data.filter((el) => {
                                if (
                                  item.SerialNumber &&
                                  item.SerialNumber === el.SerialNumber
                                )
                                  return el;

                                if (
                                  item.StyleNumber &&
                                  item.StyleNumber === el.StyleNumber
                                )
                                  return el;
                              })[0].RetailPrice,
                              {
                                code: "USD",
                                precision: 0,
                              }
                            )
                            : `$0`
                          }`}
                      </td>
                      <td>
                        <button onClick={() => this.handlePriceChange(item)}>
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan="4">
                    <button
                      className="align-self-center"
                      onClick={this.hidePriceCheckModal}
                    >
                      OK
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <div className="price_check_wrapper">
              {this.state.differenceItemPrices.map((item, i) => (
                <div className="price_check_item_wrapper" key={i}>
                  <label className="price_check_item">
                    {item.SerialNumber ? item.SerialNumber : item.StyleNumber}
                  </label>
                  <label>{item}</label>
                  <button onClick={() => this.handlePriceChange(item)}>
                    Update
                  </button>
                  <button>Ignore</button>
                  <br />
                </div>
              ))}
              <button
                className="align-self-center"
                onClick={this.hidePriceCheckModal}
              >
                OK
              </button>
            </div> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeFromCart,
      updateQuantity,
      updateInternalNotes,
      updatePriceVisibility,
      updateLinkVisibility,
      openCartItems,
      toggleIframeModal,
      setSortOption,
      toggleLoader,
      updateItemSelected,
      setAssetOrMemo,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BaksetItemsList);
