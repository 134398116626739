import React, { Component } from "react";
import ShowMore from "react-show-more";
import currencyFormatter from "currency-formatter";

class MobileProductDetails extends Component {
  render() {
    let { res } = this.props;
    return (
      <div className="es__mobile-product">
        <table className="es__mobile-product-details-table">
          <tbody>
            <tr>
              <td>
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.Maker && res.Maker !== null ? `${res.Maker}` : ""}
                </ShowMore>
              </td>
              <td>
                {res.RetailPrice && res.RetailPrice != null
                  ? currencyFormatter.format(`${res.RetailPrice}`, {
                      code: "USD",
                      precision: 0
                    })
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPrice && res.RetailPrice != null
                    ? "Retail Price"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.Metal && res.Metal !== null ? `${res.Metal}` : ""}
                </ShowMore>
              </td>
              <td>
                {res.RetailPriceCode}
                <span className="es__mobile-product-details-table__label">
                  {res.RetailPriceCode && res.RetailPriceCode != null
                    ? "Code"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {res.DiamondCarats && res.DiamondCarats !== null
                  ? `${res.DiamondCarats}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.DiamondCarats && res.DiamondCarats != null
                    ? "DIA-CTS"
                    : ""}
                </span>
              </td>
              <td>
                {res.IsVirtual === "1" ? "Virtual - " : ""}
                {res.IsPhantom === "1" ? "Phantom - " : ""}
                {res.SerialStatus && res.SerialStatus !== null
                  ? ` ${res.SerialStatus}`
                  : ""}
                <span className="es__mobile-product-details-table__label">
                  {res.SerialStatus && res.SerialStatus != null
                    ? "Location"
                    : ""}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <ShowMore lines={1} more="&#43;" less="&#8722;" anchorClass="">
                  {res.Color && res.Color != null ? `${res.Color}` : ""}
                  {res.Clarity && res.Clarity != null
                    ? ` / ${res.Clarity}`
                    : ""}
                </ShowMore>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-default align-self-end"
                  onClick={() => this.props.handleProductModal(res)}
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default MobileProductDetails;
