import React, { Component } from 'react';
import { MultiDropdownList } from '@appbaseio/reactivesearch';
import MultiDropdownListWithFocus from '../../components/MultiDropdownListWithFocus';

class Maker extends Component {
  render() {
    return (
      <div className="maker">
        <MultiDropdownList
          title="Maker"
          className="form-field"
          componentId="Maker"
          dataField={"Maker.keyword"}
          size={100}
          renderListItem={label => (
            <div>{label !== "NULL" ? label : "None"}</div>
          )}
          showCount={false}
          showSearch={true}
          sortBy="asc"
          placeholder={this.props.appType === "original" ? "Select values" : ""}
        />
      </div>
    );
  }
}

export default MultiDropdownListWithFocus(Maker);