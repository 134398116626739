import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openCartItems, setSortOption } from "../../actions/index";
import SortItemsFun from "./SortItemsFun";

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartActions.items,
    sortOption: state.sortOption.basketSortOption,
  };
};

class SortItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOptions: [
        {
          value: "itemType",
          label: "ItemType",
          dataField: "ItemType",
        },
        {
          value: "collection",
          label: "Collection",
          dataField: "Collection",
        },
        {
          value: "retail-asc",
          label: "Retail-Asc",
          dataField: "RetailPrice",
        },
        {
          value: "retail-desc",
          label: "Retail-Desc",
          dataField: "RetailPrice",
        },
        {
          value: "ct-weight-asc",
          label: "Carat Weight-Asc",
          dataField: "DiamondCaratWeight",
        },
        {
          value: "ct-weight-desc",
          label: "Carat Weight-Desc",
          dataField: "DiamondCaratWeight",
        },
        {
          value: "wholesale-asc",
          label: "Wholesale-Asc",
          dataField: "WholesalePrice",
        },
        {
          value: "wholesale-desc",
          label: "Wholesale-Desc",
          dataField: "WholesalePrice",
        },
        {
          value: "maker",
          label: "Maker",
          dataField: "Maker",
        },
        {
          value: "ct-shape",
          label: "Center Shape",
          dataField: "CenterShape",
        },
        {
          value: "serial-asc",
          label: "Serial-Asc",
          dataField: "SerialSort",
        },
        {
          value: "serial-desc",
          label: "Serial-Desc",
          dataField: "SerialSort",
        },
        {
          value: "style-asc",
          label: "Style-Asc",
          dataField: "StyleNumber",
        },
        {
          value: "style-desc",
          label: "Style-Desc",
          dataField: "StyleNumber",
        },
        {
          value: "warehouse-asc",
          label: "Warehouse-Asc",
          dataField: "Warehouse",
        },
        {
          value: "warehouse-desc",
          label: "Warehouse-Desc",
          dataField: "Warehouse",
        },
        // {
        //   value: "orderAdded-asc",
        //   label: "OrderAdded-Asc",
        //   dataField: ""
        // },
        // {
        //   value: "orderAdded-desc",
        //   label: "OrderAdded-Desc",
        //   dataField: "",
        // },
        {
          value: "location-asc",
          label: "Location-Asc",
          dataField: "BasketLocationSort",
        },
        {
          value: "location-desc",
          label: "Location-Desc",
          dataField: "BasketLocationSort",
        },
      ],
      selectedOptions: [],
    };
    this.handleOnchange = this.handleOnchange.bind(this);
  }
  handleOnchange(selectedOption) {
    if (selectedOption.length > 2) {
      return;
    }
    this.setState({
      selectedOptions: selectedOption,
    });
    console.log("selected ", selectedOption);
    this.props.setSortOption(selectedOption);
    //new code for fixing sort issue.
    for (let i = 0; i < selectedOption.length; i++) {
      var updatedArr = SortItemsFun({
        options: [selectedOption[i]],
        items: this.props.cartItems,
      });
      console.log( {updatedArr} );
      if (updatedArr.length) {
        this.props.openCartItems(updatedArr);
      } else {
        return;
      }
    }
    // var updatedArr = SortItemsFun({
    //   options: selectedOption,
    //   items: this.props.cartItems,
    // });
    // console.log({ updatedArr });
    // if (updatedArr.length) {
    //   this.props.openCartItems(updatedArr);
    // } else {
    //   return;
    // }
  }

  disableCorrOption(selectedOptions, sortOptions) {
    let disabledOptionArr = sortOptions;
    const disabledCorrOptionFn = (toDisableOption) => {
      return sortOptions.map((obj) => {
        return obj.value === toDisableOption
          ? { ...obj, isDisabled: true }
          : obj;
      });
    };
    if (selectedOptions.find((o) => o.value === "retail-asc")) {
      disabledOptionArr = disabledCorrOptionFn("retail-desc");
    } else if (selectedOptions.find((o) => o.value === "retail-desc")) {
      disabledOptionArr = disabledCorrOptionFn("retail-asc");
    } else if (selectedOptions.find((o) => o.value === "wholesale-asc")) {
      disabledOptionArr = disabledCorrOptionFn("wholesale-desc");
    } else if (selectedOptions.find((o) => o.value === "wholesale-desc")) {
      disabledOptionArr = disabledCorrOptionFn("wholesale-asc");
    } else if (selectedOptions.find((o) => o.value === "ct-weight-asc")) {
      disabledOptionArr = disabledCorrOptionFn("ct-weight-desc");
    } else if (selectedOptions.find((o) => o.value === "ct-weight-desc")) {
      disabledOptionArr = disabledCorrOptionFn("ct-weight-asc");
    } else if (selectedOptions.find((o) => o.value === "serial-asc")) {
      disabledOptionArr = disabledCorrOptionFn("serial-desc");
    } else if (selectedOptions.find((o) => o.value === "serial-desc")) {
      disabledOptionArr = disabledCorrOptionFn("serial-asc");
    } else if (selectedOptions.find((o) => o.value === "style-asc")) {
      disabledOptionArr = disabledCorrOptionFn("style-desc");
    } else if (selectedOptions.find((o) => o.value === "style-desc")) {
      disabledOptionArr = disabledCorrOptionFn("style-asc");
    } else if (selectedOptions.find((o) => o.value === "warehouse-asc")) {
      disabledOptionArr = disabledCorrOptionFn("warehouse-desc");
    } else if (selectedOptions.find((o) => o.value === "warehouse-desc")) {
      disabledOptionArr = disabledCorrOptionFn("warehouse-asc");
    } else if(selectedOptions.find((o) => o.value === "location-asc")){
      disabledOptionArr = disabledCorrOptionFn("location-desc")
    } else if(selectedOptions.find((o) => o.value === "location-desc")){
      disabledOptionArr = disabledCorrOptionFn("location-asc")
    }
    return disabledOptionArr;
  }

  render() {
    let { sortOptions, selectedOptions } = this.state;
    if (selectedOptions.length === 2) {
      var disabledOptionArr = sortOptions.map((obj) => ({
        ...obj,
        isDisabled: true,
      }));
      sortOptions = disabledOptionArr;
    } else {
      sortOptions = this.disableCorrOption(selectedOptions, sortOptions);
    }
    return (
      <Select
        options={sortOptions}
        value={this.props.basketSortOption}
        defaultValue={this.props.sortOption}
        name="basketsortby"
        className="basic-multi-select"
        classNamePrefix="basket__sort"
        placeholder="Sort By"
        onChange={this.handleOnchange}
        isMulti={true}
        isSearchable={false}
        maxMenuHeight="400"
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openCartItems,
      setSortOption,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SortItems);
